import { Component, Input } from "@angular/core";
import { Button } from "../message.model";

@Component({
  selector: "app-message-button",
  templateUrl: "./message-button.component.html"
})
export class MessageButtonComponent {
  @Input() button: Button;

  constructor() {}

  get isNotDefault(): boolean {
    return this.button.isDefault === false;
  }

  get hasClick() {
    return !!this.button.click;
  }
}
