import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserNotificationComponent, MessageNotificationItem } from "@components/notifications/notifications.model";
import { WebViewService } from "@common/mobile/webview.service";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@components/message-item/message-item.service";
import { ActionItem } from "@common/mobile/webview.model";

@Component({
  selector: "app-notifications-message",
  templateUrl: "./notifications-message.component.html",
  styleUrls: ["./notifications-message.component.scss"]
})
export class NotificationsMessageComponent extends UserNotificationComponent implements OnInit {
  @Input() public message: MessageNotificationItem;
  @Output() public messageRemoved = new EventEmitter<MessageNotificationItem>();

  constructor(
    webViewService: WebViewService,
    private translate: TranslateService,
    private messageService: MessageService
  ) {
    super(webViewService);
  }

  ngOnInit(): void {}

  get icon(): string {
    return "message";
  }

  mainAction() {
    this.messageService.selectedMessage = this.message;
    this._webViewService.navigateFromWebView(["/message-details"]);
  }

  openMenu(event) {
    super.drawMenu(event, [
      {
        text: this.translate.instant("Notifications.Details"),
        callback: () => this.mainAction()
      } as ActionItem,
      {
        text: this.translate.instant("Notifications.Delete"),
        callback: () => {
          this.messageService.archiveMessage(this.message.id).subscribe({
            complete: () => {
              this.messageRemoved.emit(this.message);
            }
          });
        }
      } as ActionItem
    ]);
  }
}
