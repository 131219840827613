import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserService } from "../common/_core/session/user-service";
import { TranslateService } from "@ngx-translate/core";
import { WebViewService } from "./mobile/webview.service"
import { formatDate } from "@angular/common";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class GreetingService {
  constructor(
    private httpClient: HttpClient,
    private _userService: UserService,
    private _webViewService: WebViewService,
    private _translate: TranslateService,
    private _router: Router
  ) {}

  showLeiExpirationMessage() {
    const data = this._userService.leiMessage;

    if (!data.show) {
      return;
    }

    const expirationMessage = this._translate.instant(`LeiMessage.${data.expired ? "Expired" : "Expiration"}`, {
      code: data.lei.code,
      expirationDate: formatDate(data.lei.expirationDate, "dd.MM.yyyy", this._translate.currentLang)
    });

    this._webViewService.showBottomSheet({
      title: "LeiMessage.Title",
      text: expirationMessage + this._translate.instant("LeiMessage.Details", data),
      primaryButton: { text: "OK", callback: () => this.closeLei() },
      additionalButton: { text: "DoNotShowAgain", callback: () => this.skipLei() },
      onClose: () => this.closeLei()
    });
  }

  public skipLei() {
    this.httpClient.post("/user/skip-lei", null).subscribe({
      complete: () => {
        this.closeLei();
      }
    });
  }

  public closeLei() {
    this._userService.leiMessage.show = false;
  }
}
