import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";

import { DirectivesModule } from "src/app/directives/directives.module";

import { InputWrapperComponent } from "./input-wrapper/input-wrapper.component";
import { InputToggleComponent } from "./input-toggle/input-toggle.component";
import { InputAmountComponent } from "./input-amount/input-amount.component";
import { InputErrorComponent } from "./input-error/input-error.component";
import { InputDateComponent } from "./input-date/input-date.component";
import { InputPhoneNumberComponent } from "./input-phone-number/input-phone-number.component";
import { SelectCurrencyComponent } from "./select-currency/select-currency.component";
import { SelectCurrencyPairComponent } from "./select-currency-pair/select-currency-pair.component";
import { InputDateTenorComponent } from "./input-date-tenor/input-date-tenor.component";
import { InputSwitchComponent } from "./input-switch/input-switch.component";
import { InputRangeComponent } from "./input-range/input-range.component";

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, DirectivesModule],
  exports: [
    InputWrapperComponent,
    InputToggleComponent,
    InputAmountComponent,
    InputDateComponent,
    InputDateTenorComponent,
    InputPhoneNumberComponent,
    InputSwitchComponent,
    InputRangeComponent,
    SelectCurrencyComponent,
    SelectCurrencyPairComponent
  ],
  declarations: [
    InputErrorComponent,
    InputWrapperComponent,
    InputAmountComponent,
    InputDateComponent,
    InputDateTenorComponent,
    InputToggleComponent,
    InputPhoneNumberComponent,
    InputSwitchComponent,
    InputRangeComponent,
    SelectCurrencyComponent,
    SelectCurrencyPairComponent
  ],
  providers: []
})
export class InputsModule {}
