<form [formGroup]="group" class="wv-input-block wv-input-block__span">
  <app-input-wrapper for="phone-prefix" label="PhonePrefix">
    <select id="phone-prefix" formControlName="prefix">
      <option *ngFor="let prefix of prefixes" [ngValue]="prefix">{{ prefix.countryCode }} {{ prefix.code }}</option>
    </select>
  </app-input-wrapper>

  <app-input-wrapper for="phone-number" label="PhoneNumber" [control]="phoneNumber">
    <input id="phone-number" type="tel" inputmode="tel" formControlName="number" autocomplete="off" />
  </app-input-wrapper>
</form>
