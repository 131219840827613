<div class="wv-container wv-container--grey" [ngClass]="{ 'no-padding': loading }">
  <app-preloader *ngIf="loading"></app-preloader>
  <div [hidden]="loading" [@hide]="animation">
    <form [formGroup]="exchangeForm" style="padding-bottom: 100px">
      <app-input-switch
        ngDefaultControl
        formControlName="side"
        [options]="['Sell', 'Buy']"
      ></app-input-switch>

      <app-select-currency-pair
        formGroupName="currencies"
        [dealCurrencies]="currencies"
        [counterCurrencies]="counterCurrencies"
      ></app-select-currency-pair>

      <app-input-wrapper for="transaction-amount" label="DealAmount" [control]="dealAmount">
        <app-input-amount
          ngDefaultControl
          formControlName="dealAmount"
          ngId="transaction-amount"
          placeholder="InsertAmount"
          [decimals]="dealCurrency.value?.decimals"
        ></app-input-amount>
        <span detail>{{ dealCurrency.value?.code }}</span>
      </app-input-wrapper>

      <app-input-date-tenor
        formGroupName="settlementDate"
        label="SettlementDate"
        [tenorDates]="tenorDates"
        [holidays]="holidays"
      ></app-input-date-tenor>

      <small *ngIf="product === 'FXFORW'" translate>ForwardTooltip</small>

      <app-input-toggle
        *ngIf="ndfVisible"
        ngDefaultControl
        label="NDF"
        ngId="ndf"
        formControlName="ndf"
      ></app-input-toggle>

      <app-account-button
        *ngIf="!ndf.value"
        ngId="from-account"
        label="FromAccount"
        [control]="isBuying ? counterAccount : dealAccount"
        (openSelection)="openAccountOneSelection()"
      >
      </app-account-button>
      <app-account-button
        *ngIf="!ndf.value"
        ngId="to-account"
        label="ToAccount"
        [control]="isBuying ? dealAccount : counterAccount"
        (openSelection)="openAccountTwoSelection()"
      >
      </app-account-button>
      <app-account-button
        *ngIf="ndf.value"
        ngId="ndf-account"
        label="SettlementAccount"
        [control]="counterAccount"
        (openSelection)="openSettlementAccountSelection()"
      >
      </app-account-button>

      <ng-container *ngIf="areCollateralsVisible" formGroupName="collateral">
        <app-input-wrapper label="Collateral.Type" for="collateral-type">
          <select id="collateral-type" formControlName="type" required>
            <option value="" disabled hidden>{{ "Select" | translate }}</option>
            <option *ngFor="let collateral of collateralsByProduct[product]" [ngValue]="collateral">
              {{ "Collateral.Types." + collateral.type | translate }}
            </option>
          </select>
        </app-input-wrapper>

        <app-account-button
          *ngIf="collateralType?.value?.type === 'Deposit'"
          ngId="collateral-account"
          label="Collateral.Account"
          [control]="collateralAccount"
          (openSelection)="openCollateralAccountSelection()"
        >
        </app-account-button>

        <small *ngIf="product === 'FXFORW'" translate>
          ForwardRiskInfo
          <a class="anchor" (click)="openRiskInfo()" translate>here</a>
        </small>
      </ng-container>
    </form>

    <div class="wv-fixed-buttons-container" *ngIf="!working">
      <button
        class="wv-global-button wv-global-button--fixed"
        id="new-transaction-button"
        [disabled]="exchangeForm.invalid"
       (click)="quote.startGetQuote(getData())"
      >
        {{ "GetQuote" | translate }}
      </button>
    </div>
  </div>
</div>

<app-account-selection
  [show]="dealAccountSelection"
  [accounts]="dealAccounts"
  (selected)="onDealAccountSelection($event)"
></app-account-selection>
<app-account-selection
  [show]="counterAccountSelection"
  [accounts]="counterAccounts"
  (selected)="onCounterAccountSelection($event)"
></app-account-selection>
<app-account-selection
  title="AccountSelection.CollateralTitle"
  [show]="collateralAccountSelection"
  [accounts]="collateralAccounts | async"
  (selected)="onCollateralAccountSelection($event)"
></app-account-selection>
<app-exchange-quote class="fx-quote" #quote (newDeal)="newDeal()"></app-exchange-quote>
