import { Component, Input } from "@angular/core";
import { Deposit, DepositRate } from "../deposit.model";

@Component({
  selector: "app-deposit-quote-details",
  templateUrl: "./deposit-quote-details.component.html"
})
export class DepositQuoteDetailsComponent {
  @Input() details: Deposit;
  @Input() loading: boolean;
  @Input() rate: DepositRate;

  constructor() {}
}
