import { Component, Input } from "@angular/core";
import { Account } from "src/app/common/accounts/account.model";
import { FxSpot } from "../exchange.model";
import { addDays} from "src/app/utils/time.utils"

@Component({
  selector: "app-exchange-quote-details",
  templateUrl: "./exchange-quote-details.component.html"
})
export class ExchangeQuoteDetailsComponent {
  @Input() details: FxSpot;
  @Input() loading: boolean;
  @Input() rate: number;
  @Input() counterAmount: number;
  @Input() collateralAmount: number;
 
  constructor() {
  }

  get fromAccount(): Account {
    return  this.details.side === "Sell" ? this.details.dealAccount : this.details.counterAccount;
  }

  get toAccount(): Account {
    return this.details.side !== "Sell" ? this.details.dealAccount : this.details.counterAccount;
  }

  get showCollateralType(): boolean {
    const { collateral } = this.details;
    return collateral && collateral !== "AmountBlock";
  }

  get subtype(): string {
    return this.details.side === "Buy" ? "Transfer outgoing" : "Transfer incoming";
  }
}
