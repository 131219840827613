import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";
import { getParamsFromObject } from "src/app/utils/http.utils";
import { defaultOrderFilters, OrderDetails, OrderFilters, OrderItem } from "./order-history.model";
import { WebViewService } from "../../common/mobile/webview.service";
import { noCacheHeader } from "../../common/_core/cache/request-cache.model";

@Injectable({
  providedIn: "root"
})
export class OrderHistoryService {
  public filters: OrderFilters = { ...defaultOrderFilters };
  public filtersCount: number = 0;

  public currencies = new BehaviorSubject<string[]>([]);
  private _currencies: string[] = [];
  private _types: Observable<string[]>;

  public loading: boolean;
  public allLoaded: boolean;
  public orders: OrderItem[] = [];

  constructor(private http: HttpClient, private webViewService: WebViewService) {
    this.loadCurrencies();
  }

  filterCurrencies(pair: string): void {
    if (!pair) {
      this.currencies.next(this._currencies);
    } else {
      const pairs = this._currencies.filter(x => [0, 3].some(y => x === pair.substr(y, 3)));
      this.currencies.next(pairs);
    }
  }

  loadHistory(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const values = Object.entries(this.filters)
      .filter(([, value]) => value !== null)
      .reduce((all, [key, val]) => ({ ...all, [key]: val }), {});

    const params = getParamsFromObject(values);
    this.http
      .get<OrderItem[]>("/order/history", { params: params, headers: noCacheHeader })
      .pipe(catchError(() => of([])))
      .subscribe(orders => {
        this.filters.pageNumber++;
        this.loading = false;
        if (!orders.length) {
          this.allLoaded = true;
          return;
        }
        this.orders = this.orders.concat(orders);
      });
  }

  getDetails(orderId: number): Observable<OrderDetails> {
    return this.http.get<OrderDetails>(`/order/${orderId}`);
  }

  cancel(orderId: number) {
    return this.http.post("/order/cancel", orderId).subscribe(result => {
      this.webViewService.showSnackBar(`Order.${result ? "OrderCanceled" : "OrderNotCanceled"}`);
      if (result) {
        this.orders = this.orders.filter(function (x) {
          return x.id !== orderId;
        });
      }
    });
  }

  reset(initFilters: boolean = false) {
    if (initFilters) {
      this.filters = { ...defaultOrderFilters };
      this.filtersCount = 0;
    }
    this.orders = [];
    this.allLoaded = false;
    this.filters.pageNumber = 1;
  }

  loadCurrencies(): void {
    this.http
      .get<string[]>("/settings/currencies")
      .pipe(shareReplay())
      .subscribe(currencies => {
        this._currencies = currencies;
        this.currencies.next(currencies);
      });
  }

  get types(): Observable<string[]> {
    return this._types ?? (this._types = this.http.get<string[]>("/order/types").pipe(shareReplay()));
  }
}
