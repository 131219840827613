import { Injectable } from "@angular/core";
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor } from "@angular/common/http";
import { MonoTypeOperatorFunction, Observable, of, range, throwError, timer, zip } from "rxjs";
import { retryWhen, mergeMap, map, timeout } from "rxjs/operators";
import { ConfigStore } from "../common/_core/config/config.service";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private configStore: ConfigStore) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith("/browser/config")) {
      return next.handle(req).pipe(backoff(1, 500));
    }

    const { httpTimeout, httpRetries } = this.configStore.config;
    const interceptedObservable = httpTimeout ? next.handle(req).pipe(timeout(httpTimeout)) : next.handle(req);

    if (req.headers.has("x-no-retry")) {
      return interceptedObservable;
    }

    const retries = httpRetries || 0;
    return interceptedObservable.pipe(backoff(retries, 250));
  }
}

/**
 * Retries an action with an exponential wait time between calls.
 * @returns a pipeable operator
 */
function backoff(retries: number, startingDelay = 0): MonoTypeOperatorFunction<any> {
  return retryWhen(attempts =>
    zip(range(1, retries + 1), attempts).pipe(
      mergeMap(([i, err]) => (i > retries ? throwError(err) : of(i))),
      map(i => i * i),
      mergeMap(v => timer(v * startingDelay))
    )
  );
}
