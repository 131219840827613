<app-summary-item label="Collateral.Type" *ngIf="collateral.type">
  {{ "Collateral.Types." + collateral.type | translate }}
</app-summary-item>

<app-summary-item-amount
  label="Collateral.Amount"
  *ngIf="collateral.amount"
  [amount]="collateral.amount"
  [currency]="collateral.currency"
></app-summary-item-amount>

<app-summary-item-account label="Collateral.Account" *ngIf="collateral.account" [account]="collateral.account"> </app-summary-item-account>
