import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";

@Component({ template: "" })
export class FormGroupComponent implements OnInit {
  @Input() formGroupName: string;

  group: FormGroup;

  constructor(private _groupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.group = this._groupDirective.control.get(this.formGroupName) as FormGroup;
  }
}
