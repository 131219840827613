import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, interval, merge, Subject } from "rxjs";
import { switchMap, shareReplay, takeUntil } from "rxjs/operators";

import { IndicativesService } from "./indicatives.service";
import { IndicativeRate, IndicativePair, IndicativeGraph } from "./indicatives.model";
import { ConfigStore } from "src/app/common/_core/config/config.service";
import { WebViewService } from "src/app/common/mobile/webview.service";

@Component({
  selector: "app-indicatives",
  templateUrl: "./indicatives.component.html",
  styleUrls: ["./indicatives.component.scss"],
})
export class IndicativesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  public graphs$: Observable<IndicativeGraph[]>;
  public interval$: Observable<number>;

  constructor(
    private _indicativesService: IndicativesService,
    private _configStore: ConfigStore,
    private _wv: WebViewService
  ) {}

  get pairs$(): Observable<IndicativePair[]> {
    return this._indicativesService.pairs;
  }

  get rates$(): Observable<IndicativeRate[]> {
    return this._indicativesService.rates;
  }

  ngOnInit(): void {
    this.startPolling();
    this.loadGraphs();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showInfo() {
    this._wv.showInfotip("Indicatives.MoreInfoMessage", "Indicatives.MoreInfoHeader");
  }

  trackByPair(_: number, item: IndicativeRate) {
    return item.pair.code;
  }

  private startPolling() {
    const { pollingInterval } = this._configStore.config.indicatives;

    merge(this.pairs$, interval(pollingInterval * 1000))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._indicativesService.loadRates());
  }

  private loadGraphs() {
    this.graphs$ = this.pairs$.pipe(
      switchMap(() => this._indicativesService.getGraphs()),
      shareReplay()
    );
  }
}
