import { addDays } from "../../utils/time.utils";


//export const currencies = ["PLN", "USD", "EUR", "CHF", "GBP"];
export const currencies = ["PLN", "USD", "EUR"];

export const currencyPairs = ["EURPLN", "EURUSD", "USDPLN",];// "CHFPLN", "GBPPLN"];

export const customers = [{ pid: "12345678", name: "Zażółć Gęśląjaźń" }];

export const counterCurrencies = {
  "PLN": [
    { code: "PLN", decimals: 2, dealingDays: '', holidays: [] },
    { code: "EUR", decimals: 2, dealingDays: '', holidays: [] },
    { code: "USD", decimals: 2, dealingDays: '', holidays: [] },
  ],
  "USD": [
    { code: "PLN", decimals: 2, dealingDays: '', holidays: [] },
    { code: "EUR", decimals: 2, dealingDays: '', holidays: [] },
    { code: "USD", decimals: 2, dealingDays: '', holidays: [] },
  ],
  "EUR": [
    { code: "PLN", decimals: 2, dealingDays: '', holidays: [] },
    { code: "EUR", decimals: 2, dealingDays: '', holidays: [] },
    { code: "USD", decimals: 2, dealingDays: '', holidays: [] },
  ]
};


export const accounts = [
  { pid: "12345678", number: "57 1020 1111 0000 0000 0102 0901", name: "BIZNES PARTNER", currency: "PLN", balance: 125678.45, isSelected: false },
  { pid: "12345678", number: "58 1020 1111 0000 0000 0102 0902", name: "BIZNES PARTNER", currency: "PLN", balance: 234678.67, isSelected: true, isDefault: true },
  { pid: "12345678", number: "59 1020 1111 0000 0000 0102 0903", name: "BIZNES PARTNER", currency: "PLN", balance: 75498.54, isSelected: false },
  { pid: "12345678", number: "87 1020 1111 0000 0000 0601 8771", name: "BIZNES PARTNER", currency: "EUR", balance: 1236982.03, isSelected: false, isDefault: true },
  { pid: "12345678", number: "88 1020 1111 0000 0000 0601 8772", name: "BIZNES PARTNER", currency: "EUR", balance: 323567.37, isSelected: false },
  { pid: "12345678", number: "17 1020 1111 0000 0000 0506 8703", name: "BIZNES PARTNER", currency: "USD", balance: 345003.09, isSelected: false, isDefault: true },
  { pid: "12345678", number: "47 1020 1111 0000 0000 0306 0076", name: "BIZNES PARTNER", currency: "GBP", balance: 34567.33, isSelected: false },
  { pid: "12345678", number: "48 1020 1111 0000 0000 0306 0077", name: "BIZNES PARTNER", currency: "GBP", balance: 25670.50, isSelected: false, isDefault: true },
  { pid: "12345678", number: "22 1020 1111 0000 0000 0226 3001", name: "BIZNES PARTNER", currency: "CHF", balance: 125678.45, isSelected: false, isDefault: true }
];

export const orders = [
  {
    id: 112546,
    pair: "EURPLN",
    side: "Buy",
    rate: 4.65,
    amount: 5000,
    counterAmount: 0,
    counterCurrency: "PLN",
    dealCurrency: "EUR",
    status: "Expired"
  },

  {
    id: 112550,
    pair: "EURPLN",
    side: "Sell",
    rate: 4.5,
    amount: 5000,
    counterAmount: 0,
    counterCurrency: "PLN",
    dealCurrency: "EUR",
    status: "Active"
  }
];


export const dealHistory = [

  {
    counterAmount: 78409.10,
    counterCurrency: "PLN",
    settlementStatus: "Settled",
    typeName: "FX ON",
    amount: -17000,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000048",
    dealDate: "2022-05-19"
  },

  {
    counterAmount: 35762.40,
    counterCurrency: "PLN",
    settlementStatus: "Unsettled",
    typeName: "FORW",
    amount: -8000,
    currency: "CHF",
    side: "Sell",
    systemReference: "120000023",
    dealDate: "2022-05-09"
  },

  {
    counterAmount: -788834.00,
    counterCurrency: "PLN",
    settlementStatus: "Unsettled",
    typeName: "FSWP",
    amount: 170000,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000016",
    dealDate: "2022-05-06"
  },
  {
    counterAmount: 785604.00,
    counterCurrency: "PLN",
    settlementStatus: "Unsettled",
    typeName: "FSWP",
    amount: -170000,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000016",
    dealDate: "2022-05-06"
  },
  {
    counterAmount: 788834.00,
    counterCurrency: "PLN",
    settlementStatus: "Unsettled",
    typeName: "FORW",
    amount: -170000,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000015",
    dealDate: "2022-05-06"
  },

  {
    counterAmount: 927.80,
    counterCurrency: "PLN",
    settlementStatus: "Settled",
    typeName: "FxSpot",
    amount: -200,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000010",
    dealDate: "2022-05-05T12:28:50"
  },

  {
    counterAmount: 4644.80,
    counterCurrency: "PLN",
    settlementStatus: "Settled",
    typeName: "FX ON",
    amount: -1000,
    currency: "EUR",
    side: "Sell",
    systemReference: "120000006",
    dealDate: "2022-05-05T12:28:50"
  }
];

export const depositHistory = [
  {
    amount: 250000.00,
    currency: "USD",
    startDate: addDays(new Date("2022-05-10"), 0),
    endDate: addDays(new Date("2023-05-10"), 0),
    systemReference: "12000022",
    interestRate: 2.56
  },
  {
    amount: 1000000,
    currency: "PLN",
    startDate: addDays(new Date("2022-05-09"), 0),
    endDate: addDays(new Date("2022-05-10"), 0),
    systemReference: "12000021",
    interestRate: 2.96
  }
];

export const alertHistory = [
  {
    amount: 10000,
    currency: "EUR",
    currencyPair: "EURPLN",
    side: "Sell",
    targetRate: 4.9,
    expirationDate: addDays(new Date(), 10),
    phoneNumber: "+48 800 302 302",
  },
  {
    amount: 25000,
    currency: "USD",
    currencyPair: "USDPLN",
    side: "B",
    targetRate: 4.6,
    expirationDate: addDays(new Date(), 20),
    phoneNumber: "+48 800 302 302",
  }
]