import { Component, Input, EventEmitter, Output } from "@angular/core";
import { DpwElement } from "./dpw-element.model";
import { FormInitialState, FxSpot } from "../../exchange/exchange.model";

@Component({
  selector: "app-dpw-element",
  templateUrl: "./dpw-element.component.html"
})
export class DpwElementComponent {
  @Input() dpw: DpwElement;
  @Output() selectEvent = new EventEmitter<FxSpot>();
  constructor() {}

  get showButton(): boolean {
    return this.dpw.isActive;
  }

  startDeal() {
    this.selectEvent.emit({
      dealCurrency: this.dpw.currency,
      counterCurrency: this.dpw.counterCurrency,
      currencyPair: this.dpw.currencyPair,
      side: this.dpw.type === "IN" ? "Sell" : "Buy",
      settlementDate: new Date(),
      amount: this.dpw.amount,
      isNetSettlement: false,
      dealType: "FXON",
      dpwNumber: this.dpw.ssrid,
      isDpw: true
    } as FxSpot);
  }
}
