import { Component } from "@angular/core";

import { LeiStore } from "@common/lei/lei.service";
import { UserService } from "@common/_core/session/user-service";
import { WebViewService } from "@common/mobile/webview.service";
import { Lei } from "@common/lei/lei.model";

@Component({
  selector: "app-user-data",
  templateUrl: "./user-data.component.html"
})
export class UserDataComponent {
  constructor(private _userService: UserService, private _wv: WebViewService, private _leiStore: LeiStore) {}

  get customerName(): string {
    return this._userService.appData.customerName;
  }

  get customerShortName(): string {
    return this._userService.appData.customerShortName;
  }

  get lei(): Lei {
    return this._leiStore.lei;
  }

  tooltipClicked(): void {
    this._wv.showInfotip("Tooltip.KNumber.Message", "Tooltip.KNumber.Title");
  }
}
