import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "app-account-button",
  templateUrl: "./account-button.component.html"
})
export class AccountButtonComponent implements OnChanges {
  @Input("ngId") id: string;
  @Input() label: string;
  @Input() control: AbstractControl;
  @Output() openSelection = new EventEmitter<any>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.account) {
      this.control.setValue(changes.account?.currentValue);
    }
  }

  public openAccountSelection() {
    this.openSelection.emit();
    this.control.markAsTouched();
  }
}
