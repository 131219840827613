<div class="normal-content-container" style="z-index: 1">
  <div class="wv-view-digit-enter">
    <div class="wv-view-digit-enter__info">
      <div class="wv-view-digit-enter__icon--header"></div>
      <div class="wv-view-digit-enter__header">iPKO dealer</div>
      <div class="wv-view-digit-enter__description" translate>Activation.PKODealerDesription</div>
      <div class="wv-view-digit-enter__text">
        <a translate class="anchor" (click)="openInfo()">Activation.MoreInfo</a>
      </div>
      <ng-container *ngIf="!userService.isPotentialCustomer">
        <ng-container *ngIf="!userService.noAccess">
          <div
            class="wv-view-digit-enter__text"
            style="margin-top: 20px"
            *ngIf="!userService.hasCustomerForex || userService.hasMm"
            translate
          >
            Activation.ActivateForexInDesktop
          </div>
          <div
            class="wv-view-digit-enter__text"
            style="margin-top: 20px"
            *ngIf="userService.hasCustomerForex && !userService.hasMm"
            translate
          >
            Activation.AttorneyNotAuthorized
          </div>
        </ng-container>
        <ng-container *ngIf="userService.noAccess">
          <div class="wv-view-digit-enter__text" style="margin-top: 20px" *ngIf="!userService.hasAgreement" translate>
            Activation.ActivateInDesktop
          </div>
          <ng-container *ngIf="userService.hasAgreement">
            <div class="wv-view-digit-enter__text" style="margin-top: 20px" *ngIf="userService.hasAgreement" translate>
              Activation.AttorneyNotAuthorized
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="wv-container wv-container--fixed">
  <div class="wv-view-digit-enter__info-button">
    <ng-container *ngIf="userService.isPotentialCustomer">
      <div class="wv-view-digit-enter__info-button-text" *ngIf="!activationService.canApply">
        {{ "Activation." + this.activationService.pageData.blockReason | translate }}
      </div>
      <button id="digitStepOne"
              class="wv-global-button ripple"
              [disabled]="!activationService.canApply"
              [class.wv-global-button--white-normal]="activationService.canApply && userService.hasMm"
              [class.wv-global-button--disabled]="!activationService.canApply"
              (click)="activationService.next()"
              [translate]="userService.hasMm ? 'Activation.ActivateButtonMm' : 'Activation.ActivateButton'"></button>
    </ng-container>
    <button
      id="go-to-ipko-dealer-button"
      *ngIf="userService.hasMm"
      class="wv-global-button ripple"
      routerLink="/"
      [translate]="'Activation.GoToPKODealerButton'"
    ></button>
  </div>
</div>
