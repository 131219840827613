<div class="wv-transaction-details__text-container">
  <app-summary-item label="Order.Details.Id">{{ details.id }}</app-summary-item>
</div>

<div class="wv-transaction-details__text-container wv-transaction-details__text-container--bottom">
  <app-summary-item label="Status">
    {{ "Order.Statuses." + details.status | translate }}
  </app-summary-item>

  <app-summary-item label="Order.Details.LinkedDeal" *ngIf="executed">
    <a
      *ngIf="details.executedTransaction as trx; else multiFx"
      [routerLink]="['/history/exchange', trx]"
    >
      {{ trx }}
    </a>
  </app-summary-item>

  <ng-template #multiFx>
    <a [routerLink]="['/history']">{{ "Transaction.MultiFx" | translate }}</a>
  </ng-template>

  <app-summary-item-amount
    label="Order.Details.{{ details.side }}"
    [amount]="details.amount"
    [currency]="details.dealCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Vs"
    *ngIf="isLimit"
    [amount]="details.counterAmount"
    [currency]="details.counterCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item label="Order.Points" *ngIf="details.type === 'TrailingStop'">
    {{ details.points }}
  </app-summary-item>

  <app-summary-item-amount
    label="ExpectedRate"
    [amount]="details.rate"
    [decimals]="4"
    [currency]="isLimit ? '' : (details.pair | pair)"
  ></app-summary-item-amount>

  <app-summary-item label="Order.Details.Type">
    {{ "Order.Types." + details.type | translate }}
  </app-summary-item>

  <app-summary-item label="Order.Details.Tenor" *ngIf="!cancelled && !expired">
    {{ details.tenor | translate }}
  </app-summary-item>

  <app-summary-item-account label="FromAccount" *ngIf="fromAccount" [account]="fromAccount">
  </app-summary-item-account>

  <app-summary-item-account label="ToAccount" *ngIf="toAccount" [account]="toAccount">
  </app-summary-item-account>

  <app-summary-item-date
    label="ExpirationDate"
    *ngIf="!executed && !cancelled"
    [date]="details.expirationDateTime"
    format="dd.MM.yyyy, HH:mm"
  ></app-summary-item-date>

  <app-summary-item-date
    label="Order.Details.ExecutionDate"
    *ngIf="executed"
    [date]="details.expirationDateTime"
    format="dd.MM.yyyy, HH:mm"
  ></app-summary-item-date>

  <app-summary-item label="PhoneNumber">
    {{details.phone}}
  </app-summary-item> 

</div>
