<div *ngIf="show" class="wv-global-modal wv-global-modal--white wv-global-modal--info new-transaction-last-modal wv-global-modal--displayed wv-global-modal--visible" [@slideInOut]>
  <div class="wv-container wv-global-modal__content-container">
    <div class="wv-global-modal__icon wv-global-modal__icon--success"></div>
    <div class="wv-global-modal__header" translate>Deposit.SuccessHeader</div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Deposit.SuccessDescription1</span>
        <span class="wv-global-modal__subheader--important">{{ depositId }}</span>
      </div>
      <div>
        <span translate>Deposit.SuccessDescription2</span>
        <span class="wv-global-modal__subheader--important">{{ rate }}%</span>
      </div>
      <div>
        <span translate>Deposit.SuccessDescription3</span>
        <span class="wv-global-modal__subheader--important">{{ amount | number:"1.2-2" }} {{ currency }}</span>
      </div>
    </div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Deposit.SuccessDescription4</span>
        <!--<a class="wv-text-blue" [routerLink]="['/history/deposit', depositId]" [state]="{ isFromSuccessPage: 'true'}" translate>Deposit.OrderLink</a>-->
        <a class="wv-text-blue" translate>Deposit.OrderLink</a>
      </div>
    </div>
  </div>
  <div class="wv-global-modal__button-container">
    <button class="wv-global-button wv-global-button--white-normal" (click)="back()" translate>OK</button>
    <button class="wv-global-button ripple" (click)="openNewDeposit()" translate>Deposit.NextDeposit</button>
  </div>
</div>
