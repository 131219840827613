import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { noCacheHeader } from "src/app/common/_core/cache/request-cache.model";
import { WebViewService } from "../../common/mobile/webview.service";
import { AlertFormData, RateAlert, InitializationResult, AlertStatus } from "./alert.model";

@Injectable({ providedIn: "root" })
export class AlertService {
  public returnPage: "history" | "more" | "graph" | "dashboard";

  constructor(private http: HttpClient, private webViewService: WebViewService) {}

  getFormData(): Observable<AlertFormData> {
    return this.http.get<any>("/alert/data");
  }

  create(data: RateAlert): Observable<InitializationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<InitializationResult>("alert/create", data, { headers });
  }

  getStatus(guid: string): Observable<AlertStatus> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<AlertStatus>(`alert/status/${guid}`, { headers });
  }

  returnToInitialPage(): void {
    if (this.returnPage === "graph") {
      this.webViewService.navigateFromWebView(["/graph"]);
    } else if (this.returnPage === "history") {
      this.webViewService.navigateFromWebView(["/alerts"]);
    } else if (this.returnPage === "dashboard") {
      this.webViewService.navigateFromWebView(["/"]);
    } else {
      this.webViewService.navigateFromWebView(["/more"]);
    }
  }
}
