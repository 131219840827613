import { Component, Input, OnInit } from "@angular/core";
import { DepositHistoryItem } from "../deposit-history.model";

@Component({
  selector: "app-deposit-history-element",
  templateUrl: "./deposit-history-element.component.html",
  styleUrls: ["./deposit-history-element.component.scss"]
})
export class DepositHistoryElementComponent implements OnInit {
  @Input() deposit: DepositHistoryItem;

  constructor() {}

  ngOnInit(): void {}
}
