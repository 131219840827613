import { Component, OnInit } from "@angular/core";
import { WebViewService } from "../../../common/mobile/webview.service";
import { Message } from "../../message/message.model";
import { MessageService } from "../message-item.service";

@Component({
  selector: "app-message-item-details",
  templateUrl: "./message-item-details.component.html",
  styleUrls: ["./message-item-details.component.scss"]
})
export class MessageItemDetailsComponent implements OnInit {
  public message: Message;

  constructor(private webView: WebViewService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.webView.updateScreen("messageDetails");
    this.message = {
      header: this.messageService.selectedMessage.title,
      description: this.messageService.selectedMessage.description,
      date: this.messageService.selectedMessage.startDate,
      buttons: [{ text: "Button.Accept", isDefault: true }]
    };
  }
}
