import { Component, EventEmitter, Output } from "@angular/core";
import { slideRightAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { OrderRequest, OrderRequestDto } from "../order.model";
import { OrderService } from "../order.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-order-confirmation",
  templateUrl: "./order-confirmation.component.html",
  styleUrls: ["./order-confirmation.component.scss"],
  animations: [slideRightAnimation]
})
export class OrderConfirmationComponent {
  @Output() public openOrderForm = new EventEmitter<boolean>();

  public order: OrderRequest;
  public orderId: number;
  public errorTitle: string;
  public errorDescription: string;
  public hideRetry: boolean;
  public success: boolean = false;
  public failure: boolean = false;
  public loading: boolean = true;

  private retryCount: number = 0;

  constructor(private orderService: OrderService, private webViewService: WebViewService, private translate: TranslateService) { }

  displayConfirmation(request: OrderRequest) {
    this.loading = true;
    this.orderService.validate(this.getDto(request)).subscribe(
      result => {
        if (result.isValid) {
          this.webViewService.updateScreen("order", () => this.openNewOrder());
          this.order = request;
          this.loading = false;
        } else {
          this.setErrorDetails();
        }
      },
      msg => {
        console.log("Validation failed", msg);
        this.setErrorDetails();
      }
    );
  }

  confirm() {
    this.loading = true;
    this.orderService.initializeOrder(this.getDto(this.order)).subscribe(
      result => {
        console.log("Init order succeeded");
        if (result.success) {
          this.getStatus(result.guid);
        } else {
          this.setErrorDetails(result.error);
        }
      },
      msg => {
        console.log("Get init failed", msg);
        this.setErrorDetails();
      }
    );
  }

  getDto(order: OrderRequest): OrderRequestDto {
    const { settlementAccount, counterSettlementAccount, ...dto } = order;
    return { ...dto, settlementAccountId: settlementAccount?.id, counterSettlementAccountId: counterSettlementAccount?.id };
  }

  getStatus(guid: string) {
    this.orderService.getStatus(guid).subscribe(
      result => {
        if (result.retry) {
          this.retryCount++;
          if (this.retryCount < 20) {
            setTimeout(() => this.getStatus(guid), 500);
          } else {
            this.setErrorDetails();
          }
        } else {
          if (result.state === "Confirmed") {
            this.loading = false;
            this.orderId = result.orderId;
            this.success = true;
          } else {
            this.setErrorDetails(result.failCode.toString(), result.failReason);
          }
        }
      },
      msg => {
        console.log("Get Status failed", msg);
        this.setErrorDetails();
      }
    );
  }

  openNewOrder(clearForm: boolean = false) {
    this.reset();
    this.openOrderForm.emit(clearForm);
  }

  reset() {
    this.order = null;
    this.loading = false;
    this.success = false;
    this.failure = false;
    this.orderId = null;
    this.order = null;
    this.webViewService.updateScreen("order", () => this.orderService.returnToInitialPage());
  }

  setErrorDetails(failCode: string = "0", failReason: string = "") {
    this.hideRetry = false;
    if (failCode === "10001") {
      this.errorTitle = "Errors.RateTitle";
      this.errorDescription = this.translate.instant(`Errors.${failCode}`, { start: failReason });
    }
    else {
      const result = this.translate.instant(`Errors.${failCode}`);
      if (result !== `Errors.${failCode}`) {
        this.errorTitle = "Errors.RateTitle";
        this.errorDescription = result;
      }
      else {
        this.errorTitle = "Errors.GenericTitleOrder";
        this.errorDescription = "Errors.GenericDescription";
      }
    }

    this.loading = false;
    this.failure = true;
  }
}
