import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { sleep, slideBottomAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { OrderRequest } from "../order.model";
import { OrderService } from "../order.service";

@Component({
  selector: "app-order-success",
  templateUrl: "./order-success.component.html",
  animations: [slideBottomAnimation]
})
export class OrderSuccessComponent implements OnInit {
  @Input() id: string;
  @Input() order: OrderRequest;
  @Output() newOrder = new EventEmitter<any>();

  public show: boolean = true;

  constructor(private orderService: OrderService, private webViewService: WebViewService) {}

  get trailingStop(): boolean {
    return this.order.type === "TrailingStop";
  }

  get rate(): number {
    return this.trailingStop ? this.order.points / 10000 : this.order.rate;
  }

  ngOnInit(): void {
    this.webViewService.updateScreen("orderModal", () => this.back());
  }

  openNewOrder(): void {
    this.show = false;
    sleep().then(() => {
      this.newOrder.emit();
    });
  }

  back() {
    this.show = false;
    sleep().then(() => {
      this.orderService.returnToInitialPage();
    });
  }
}
