<app-preloader [absolute]="true" *ngIf="(loading || terminating) && !success"></app-preloader>
<div
  class="
    wv-transaction-details
    wv-global-modal
    wv-global-modal--hidden-swipe-bottom
    wv-global-modal--displayed
    wv-global-modal--visible-swipe-bottom
  "
  *ngIf="!loading"
  [hidden]="success"
>
  <div #container>
    <app-deposit-details-simple [details]="details"></app-deposit-details-simple>

    <!--<div class="wv-container wv-container--grey" [ngClass]="{ 'wv-global-button--sticky-to-bottom--full-width': shouldButtonBeSticky}">-->
    <div class="wv-container wv-container--grey" >
      <button
        class="wv-global-button wv-global-button--white-normal"
        *ngIf="details.terminationAvailable"
        (click)="showTerminationDialog()"
        translate
      >
        Deposit.Terminate
      </button>
      <button class="wv-global-button" routerLink="/deposit" translate>Deposit.New</button>
    </div>
  </div>
  <app-message-modal [message]="message"></app-message-modal>
</div>

<app-deposit-termination-success
  *ngIf="success"
  [depositId]="details.id"
  [rate]="details.interestRate"
  [amount]="details.amount"
  [currency]="details.currency"
  (closed)="back()"
></app-deposit-termination-success>
