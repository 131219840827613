<form [formGroup]="form" class="wv-view-edit-currency-pairs">
  <app-indicative-pair-group
    title="Indicatives.Pairs.SelectedTitle"
    subtitle="Indicatives.Pairs.SelectedSubtitle"
    [group]="selectedPairGroup"
    [disabled]="selectedCount <= min"
  ></app-indicative-pair-group>

  <app-indicative-pair-group
    title="Indicatives.Pairs.UnselectedTitle"
    subtitle="Indicatives.Pairs.UnselectedSubtitle"
    [group]="unselectedNonDefaultOrderPairGroup"
    [disabled]="selectedCount >= max"
  ></app-indicative-pair-group>

  <div class="wv-container wv-container--grey" *ngIf="unselectedNonDefaultCount > 0">
    <hr />
  </div>

  <app-indicative-pair-group
    [group]="unselectedDefaultOrderPairGroup"
    [disabled]="selectedCount >= max"
  ></app-indicative-pair-group>
</form>
