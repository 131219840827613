import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

import { WebViewService } from "src/app/common/mobile/webview.service";
import { ExchangeHistoryService } from "../exchange-history.service";
import { DealDetails } from "../exchange-history.model";

@Component({
  selector: "app-exchange-details",
  templateUrl: "./exchange-details.component.html",
  styleUrls: ["./exchange-details.component.scss"]
})
export class ExchangeDetailsComponent implements OnInit {
  public details$: Observable<DealDetails>;

  private _isFromSuccessPage: boolean = false;
  private _returnPage: "dashboard" | "history";

  constructor(
    private historyService: ExchangeHistoryService,
    private route: ActivatedRoute,
    private webViewService: WebViewService
  ) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("exchangeDetail", () => this.back());
    this._returnPage = history.state.returnPage || "history";
    this._isFromSuccessPage = history.state.isFromSuccessPage === "true";
    this.details$ = this.route.paramMap.pipe(switchMap(params => this.load(params.get("id"))));
  }

  load(id: string) {
    return this.historyService.getDetails(id);
  }

  back() {
    if (this._returnPage === "dashboard") {
      this.webViewService.navigateFromWebView(["/"]);
    } else {
      this.webViewService.navigateFromWebView(["/history"], { state: { noRefresh: !this._isFromSuccessPage } });
    }
  }
}
