import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { SummaryCollateralComponent } from "./summary-collateral/summary-collateral.component";
import { SummaryItemAccountComponent } from "./summary-item-account/summary-item-account.component";
import { SummaryItemAmountComponent } from "./summary-item-amount/summary-item-amount.component";
import { SummaryItemDateComponent } from "./summary-item-date/summary-item-date.component";
import { SummaryItemComponent } from "./summary-item/summary-item.component";
import { PipesModule } from "src/app/pipes/pipes.module";

@NgModule({
  imports: [CommonModule, TranslateModule, PipesModule],
  exports: [
    SummaryItemComponent,
    SummaryItemDateComponent,
    SummaryItemAmountComponent,
    SummaryItemAccountComponent,
    SummaryCollateralComponent
  ],
  declarations: [
    SummaryItemComponent,
    SummaryItemDateComponent,
    SummaryItemAmountComponent,
    SummaryItemAccountComponent,
    SummaryCollateralComponent
  ],
  providers: []
})
export class SummaryModule {}
