<div
  class="wv-digit-step-one-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible"
  style="z-index: 2"
  *ngIf="activationService.page >= 1"
  [@slideInOut]
>
  <div class="wv-view-digit-step-one">
    <div class="wv-top-progress-bar">
      <div class="wv-top-progress-bar__inner" style="width: 25%"></div>
    </div>

    <div class="wv-section-header">
      <div class="wv-section-header__title" translate>Activation.InfoHeader</div>
    </div>

    <div class="wv-container wv-container--extra-padding">
      <div class="wv-view-digit-step-one__info" [innerHTML]="'Activation.Info' | translate"></div>
    </div>
    <div class="wv-container wv-container--fixed">
      <div class="wv-view-digit-step-one__info-button">
        <button id="digitStepTwo" class="wv-global-button ripple" (click)="activationService.next()" translate>Next</button>
      </div>
    </div>
  </div>
</div>
