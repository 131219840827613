import { Component, OnInit } from "@angular/core";
import { NewsService } from "./news.service";
import { NewsItem } from "./news.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
})
export class NewsComponent implements OnInit {
  constructor(private _newsService: NewsService) {}

  get news$(): Observable<NewsItem[]> {
    return this._newsService.news$;
  }

  get limit() {
    return this.expanded ? undefined : 3;
  }

  get expanded(): boolean {
    return this._newsService.expanded;
  }

  ngOnInit(): void {
    this._newsService.loadNews();
  }

  toggle() {
    this._newsService.toggle();
  }
}
