import { Component, OnInit, Input } from "@angular/core";
import { WebViewService } from "@common/mobile/webview.service";
import { UserNotificationComponent, EndingDeposit } from "@components/notifications/notifications.model";
import { TranslateService } from "@ngx-translate/core";
import { ActionItem } from "@common/mobile/webview.model";


@Component({
  selector: "app-notifications-ending-deposit",
  templateUrl: "./notifications-ending-deposit.component.html",
  styleUrls: ["./notifications-ending-deposit.component.scss"]
})
export class NotificationsEndingDepositComponent extends UserNotificationComponent implements OnInit {
  @Input() public deposit: EndingDeposit;

  constructor(webViewService: WebViewService, private translate: TranslateService) {
    super(webViewService);
  }

  ngOnInit(): void {}

  get icon(): string {
    return "new_deposit";
  }

  mainAction() {
    this._webViewService.navigateFromWebView([`/history/deposit/${this.deposit.dealId}`], { state: { returnPage: "dashboard" } });
  }

  openMenu(event) {
    super.drawMenu(event, [
      {
        text: this.translate.instant("Notifications.Details"), callback: () => this.mainAction()
      } as ActionItem
    ]);
  }
}
