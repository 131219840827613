import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WebViewService } from "@common/mobile/webview.service";
import { slideBottomAnimation } from "@common/animations/animations";

@Component({
  selector: "app-deposit-termination-success",
  templateUrl: "./deposit-termination-success.component.html",
  animations: [slideBottomAnimation],
})
export class DepositTerminationSuccessComponent implements OnInit {
  public show: boolean = true;
  @Input() public rate: number;
  @Input() public depositId: string;
  @Input() public amount: number;
  @Input() public currency: string;
  @Output() public closed = new EventEmitter<any>();

  constructor(private webViewService: WebViewService) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("depositModal", () => this.back());
  }

  back() {
    this.closed.emit();
  }
}
