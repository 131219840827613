import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Message } from "../message.model";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-message-container",
  templateUrl: "./message-container.component.html"
})
export class MessageContainerComponent implements OnInit, OnDestroy {
  @Input() public message: Message;

  constructor(@Inject(DOCUMENT) private _document: Document, private _renderer: Renderer2) {}

  ngOnInit(): void {
    this._renderer.addClass(this._document.body, "wv-message");
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(this._document.body, "wv-message");
  }
}
