import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { RateAlert } from "../../alert/alert.model";
import { AlertHistoryService } from "../alert-history.service";
import { WebViewService } from "../../../common/mobile/webview.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-alert-history-element",
  templateUrl: "./alert-history-element.component.html",
  styleUrls: ["./alert-history-element.component.scss"]
})
export class AlertHistoryElementComponent implements OnInit {
  @Input() public alert: RateAlert;

  @Output() canceled = new EventEmitter<any>();

  constructor(
    private historyService: AlertHistoryService,
    private _webViewService: WebViewService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void { }

  cancel() {
    this.historyService.cancel(this.alert.id).subscribe(
      () => {
        this.canceled.emit({ success: true, id: this.alert.id });
      },
      () => {
        this.canceled.emit({ success: false, id: this.alert.id });
      }
    );
  }

  openMenu(event) {
    /*                                            // <<-------- NOWE stare
        console.log("ddasdad")
        const action = {
          text: this.translate.instant("Alerts.RemoveAlert"),
          callback: () => {
            this._webViewService.showBottomSheet({
              title: "Alerts.RemoveAlertQuestion",
              primaryButton: { text: "Yes", callback: () => this.cancel() },
              additionalButton: { text: "No" }
            });
          }
         };
    
        this._webViewService.showActionSheet(event.target.getBoundingClientRect(), [action]);
     */
  }
}
