<div class="wv-digit-step-six-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible" style="z-index: 7" *ngIf="activationService.page>=6" [@slideInOut]>
  <div class="wv-view-digit-success" *ngIf="activationService.success">
    <div class="wv-container">
      <div class="wv-view-digit-success__ico-container">
        <div class="wv-view-digit-success__ico"></div>
      </div>
      <div class="wv-view-digit-success__title" [innerHTML]="'Activation.PKODealerEnabled' | translate">
      </div>
    </div>
    <div class="wv-container wv-container--border-top wv-container--fixed">
      <div class="wv-view-digit-step-two__info-button">
        <button class="wv-global-button ripple" routerLink="/" translate>Activation.GoToPKODealerButton</button>
      </div>
    </div>
  </div>
  <div class="wv-view-digit-cancel" *ngIf="!activationService.success">
    <div class="wv-container">
      <div class="wv-view-digit-cancel__ico-container" *ngIf="activationService.error">
        <div class="wv-view-digit-cancel__ico"></div>
      </div>
      <div class="wv-global-modal__content-container" *ngIf="!activationService.error">
        <div class="wv-global-modal__icon wv-global-modal__icon--info"></div>
      </div>
      <div class="wv-view-digit-cancel__title" translate>
        Activation.PKODealerNotEnabled
      </div>
      <div class="wv-view-digit-cancel__info" translate>
        Activation.TryAgainLater
      </div>
    </div>
    <div class="wv-container wv-container--border-top wv-container--fixed" *ngIf="activationService.error">
      <div class="wv-view-digit-step-two__info-button">
        <button class="wv-global-button ripple" (click)="activationService.goToStart()" translate>Activation.Retry</button>
      </div>
    </div>
    <div class="wv-container wv-container--border-top wv-container--fixed" *ngIf="!activationService.error">
      <div class="wv-view-digit-step-two__info-button">
        <button class="wv-global-button ripple" (click)="activationService.logout()" translate>Close</button>
      </div>
    </div>
  </div>
</div>
