import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, filter } from "rxjs/operators";

import { groupByCurrency } from "@common/dealing";
import { hasRole } from "@utils/cookie.utils";
import { Account, DefaultAccountsModel } from "./account.model";

@Injectable({ providedIn: "root" })
export class AccountService {
  private _technicalCustomerNumber: string;

  constructor(private http: HttpClient) {}

  defaultAccountsByCurrency: Record<string, Account[]>;

  getCollateralAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>("/collateral/accounts");
  }

  loadDefaultAccounts(): void {
    if (!hasRole("DefaultAccounts")) {
      return;
    }

    this.http
      .get<DefaultAccountsModel>("/settings/accounts")
      .pipe(
        catchError(() => of({ accounts: [] } as DefaultAccountsModel)),
        filter(({ accounts }) => !!accounts.length)
      )
      .subscribe(({ technicalCustomerNumber, accounts }) => {
        this._technicalCustomerNumber = technicalCustomerNumber;
        this.defaultAccountsByCurrency = groupByCurrency(accounts);
      });
  }

  saveDefaultAccounts(updatedAccounts: Account[]): Observable<any> {
    return this.http.put("/settings/accounts", {
      technicalCustomerNumber: this._technicalCustomerNumber,
      accounts: updatedAccounts
    });
  }
}
