import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Customer } from "./customer.model";
import { UserService } from "src/app/common/_core/session/user-service";
import { WebViewService } from "src/app/common/mobile/webview.service";
import { GreetingService } from "../../common/greeting.service";

@Component({
  selector: "app-customer-component",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent implements OnInit {
  public customers$: Observable<Customer[]>;
  public selecting: boolean;

  constructor(
    private http: HttpClient,
    private greetingService: GreetingService,
    private userService: UserService,
    private webViewService: WebViewService) { }

  ngOnInit() {
    this.webViewService.updateScreen("customer");

    this.customers$ = this.http.get<Customer[]>("/customer");
  }

  public set(customer: Customer) {
    this.selecting = true;
    this.http.post("/customer", JSON.stringify(customer.id)).subscribe(() => 
      window.location.href = "/" //reload page to destroy previous user context in cache, properties in services
    );
  }
}
