import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import { Lei } from "./lei.model";
import { UserService } from "../_core/session/user-service";

@Injectable({ providedIn: "root" })
export class LeiStore {
  constructor(private httpClient: HttpClient, private userService: UserService) { }

  public lei: Lei;

  load() {
    
    this.userService.hasCustomer && this.httpClient
      .get<Lei>("customer/lei")
      .pipe(catchError(() => of(<Lei>null)))
      .subscribe(lei => (this.lei = lei));
    /*
      const lei: Lei = {
        code: "1234",
        expirationDate: null
        }
        this.lei = lei;
*/
  }
}

