import { Component, Input } from "@angular/core";
import { Side } from "src/app/common/dealing/dealing.model";
import { DealDetails, SwapLeg } from "../exchange-history.model";

@Component({
  selector: "app-exchange-details-swap",
  templateUrl: "exchange-details-swap.component.html",
  styleUrls: ["exchange-details-swap.component.scss"]
})
export class ExchangeDetailsSwapComponent {
  private _details: DealDetails;
  private _farLeg: SwapLeg;

  @Input() set details(value: DealDetails) {
    this._details = value;
    this.setFarLeg();
  }

  constructor() {}

  get details(): DealDetails {
    return this._details;
  }

  get nearLeg(): SwapLeg {
    return this.details as SwapLeg;
  }

  get farLeg(): SwapLeg {
    return this._farLeg;
  }

  get tabs(): Tab[] {
    return [
      { title: "NearLeg", context: this.nearLeg },
      { title: "FarLeg", context: this.farLeg }
    ];
  }

  trackByTitle(_: number, tab: Tab): string {
    return tab.title;
  }

  private setFarLeg() {
    const { farLeg, collateral, buyAccount, sellAccount } = this.details;

    this._farLeg = {
      ...farLeg,
      side: this.details.side === Side.Sell ? Side.Buy : Side.Sell,
      buyAccount: sellAccount,
      sellAccount: buyAccount,
      collateral
    };
  }
}

interface Tab {
  title: string;
  context: object;
}
