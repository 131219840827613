import { Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import { OrderHistoryService } from "./order-history.service";
import { OrderHistoryFiltersComponent } from "./order-history-filters/order-history-filters.component";
import { hideAnimation } from "../../common/animations/animations";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.scss"],
  animations: [hideAnimation]
})
export class OrderHistoryComponent implements OnInit {
  public statuses = ["All", "Active", "Executed", "Expired", "Cancelled"];

  get filtersCount() {
    return this.orderService.filtersCount;
  }

  get filters() {
    return this.orderService.filters;
  }

  get startStatus() {
    return this.orderService.filters?.status;
  }

  @ViewChild("filters", { static: true })
  private _filters: OrderHistoryFiltersComponent;

  get filtersOpen() {
    return this._filters.filtersOpen;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public orderService: OrderHistoryService
  ) { }

  ngOnInit(): void {
    if (history.state.noRefresh) {
      return;
    }

    this.orderService.reset(true);
    this.orderService.loadHistory();
  }

  openFilters() {
    this._filters.filtersOpen = true;
  }

  onFilter() {
    this.reset();
    this.orderService.loadHistory();
  }

  reset() {
    this.orderService.reset();
  }

  onStatusChanged(status) {
    this.filters.status = status;
    this.reset();
    this.orderService.loadHistory();
  }

  @HostListener("body:scroll", [])
  onScroll(): void {
    // if (this.filtersOpen || this.orderService.allLoaded) return;      // <<-------- NOWE

    // const { clientHeight } = document.getElementsByClassName("wv-view-my-orders")[0];

    // if (this.document.body.clientHeight + this.document.body.scrollTop >= clientHeight - 30) {
    //   this.orderService.loadHistory();
    // }
  }
}
