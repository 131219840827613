import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { WebViewService } from "@common/mobile/webview.service";
import { Message } from "@components/message/message.model";
import { NewsService } from "../news.service";

@Component({
  selector: "app-news-details",
  templateUrl: "news-details.component.html",
})
export class NewsDetailsComponent implements OnInit {
  public message$: Observable<Message>;

  constructor(
    private _newsService: NewsService,
    private _route: ActivatedRoute,
    private _wv: WebViewService
  ) {}

  ngOnInit() {
    this._wv.updateScreen("news");

    this.message$ = this._route.paramMap.pipe(switchMap((params) => this.load(params.get("id"))));
  }

  load(id: string) {
    return this._newsService.getDetails(id).pipe(
      map((message) => ({
        ...message,
        buttons: [{ text: "Ok" }],
      }))
    );
  }
}
