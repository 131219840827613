<app-notifications-main
  *ngFor="let n of notifications | slice: 0:limit; trackBy: trackByKey"
  [notification]="n"
  [@slideOut]="n.deleted ? 'deleted' : ''"
>
  <ng-container [ngSwitch]="n.name">
    <app-notifications-unsettled-deal #child [deal]="n" *ngSwitchCase="'UnsettledDeal'">
    </app-notifications-unsettled-deal>
    <app-notifications-ending-deposit #child [deposit]="n" *ngSwitchCase="'EndingDeposit'">
    </app-notifications-ending-deposit>
    <app-notifications-new-dpw #child [dpw]="n" *ngSwitchCase="'NewDpw'">
    </app-notifications-new-dpw>
    <app-notifications-expired-order #child [order]="n" *ngSwitchCase="'ExpiredOrder'">
    </app-notifications-expired-order>
    <app-notifications-executed-order #child [order]="n" *ngSwitchCase="'ExecutedOrder'">
    </app-notifications-executed-order>
    <app-notifications-expired-alert #child [alert]="n" *ngSwitchCase="'ExpiredAlert'">
    </app-notifications-expired-alert>
    <app-notifications-executed-alert #child [alert]="n" *ngSwitchCase="'ExecutedAlert'">
    </app-notifications-executed-alert>
    <app-notifications-message
      #child
      [message]="n"
      *ngSwitchCase="'Message'"
      (messageRemoved)="onMessageRemoved($event)"
    >
    </app-notifications-message>
  </ng-container>
</app-notifications-main>
