export const commonDatesUTC = {
  yesterday: () => onlyDateUTC(addDays(new Date(), -1)),
  today: () => onlyDateUTC(new Date()),
  tomorrow: () => onlyDateUTC(addDays(new Date(), 1)),
};

/**
 * Returns a new date that adds the specified number of days to the value of the supplied date.
 * @param date A source date
 * @param days A number of whole and fractional days. The value parameter can be negative or positive.
 */
export function addDays(date: Date, days: number): Date {
  const result = new Date(date.valueOf());
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Returns a new date that adds the specified number of minutes to the value of the supplied date.
 * @param date A source date
 * @param minutes A number of whole and fractional minutes. The value parameter can be negative or positive.
 */
export function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.valueOf() + minutes * 60000);
}

/**
 * Shifts a given date, so that current local time becomes current UTC time.
 * @param date a date to convert to UTC
 */
export function shiftToUTC(date: Date): Date {
  const result = new Date(date.valueOf());
  result.setHours(result.getHours() - result.getTimezoneOffset() / 60);
  return result;
}

/**
 * Returns a date object set to midnight UTC. This means that local time might be the next day.
 * @param date a date to remove the time part
 */
export function onlyDateUTC(date: Date): Date {
  const result = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  result.setHours(result.getHours() - result.getTimezoneOffset() / 60);
  return result;
}

/**
 * Returns a date object set to midnight local time. This means that UTC time might be the previous day.
 * @param date a date to remove the time part
 */
export function onlyDateLocal(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Turns a date object into an ISO string compatible with input[type="date"].
 * @param date a date to format
 * @returns date in yyyy-MM-dd format
 */
export function datepickerFormat(date: Date | string): string {
  const dateString = typeof date === "string" ? date : date.toISOString();
  return dateString.slice(0, 10);
}

/**
 * Turns a date object into an string compatible with input[type="time"].
 * @param date a date to format
 * @returns date in hh:mm format
 */
export function timepickerFormat(date: Date): string {
  return date.toTimeString().slice(0, 5);
}

/**
 * Verifies if an argument is a Date type.
 * @param date Value to check
 * @returns True, if the argument is a Date.
 */
export function isDate(date: any): boolean {
  return typeof date?.getMonth === "function";
}

/**
 * Verifies if given dates are the same day.
 * @returns true, if the given dates are the same day.
 */
export function isSameDay(dateOne: Date, dateTwo: Date): boolean {
  return (
    dateOne.getFullYear() === dateTwo.getFullYear() &&
    dateOne.getMonth() === dateTwo.getMonth() &&
    dateOne.getDate() === dateTwo.getDate()
  );
}

export function isWeekend(date = new Date()): boolean {
  return date.getDay() % 6 === 0;
}

/**
 * Counts the difference between two dates.
 * @param units unit of measurement.
 * @returns Difference between the two dates in the supplied unit of measurement.
 */
export function difference(dateOne: Date, dateTwo: Date, units: Units): number {
  const diff = dateOne.valueOf() - dateTwo.valueOf();
  return diff / divider[units];
}

/**
 * Returns first date after weekend if the source date occurs on weekend
 * @param date a source date
 */
export function firstDateAfterWeekend(date: Date): Date {
  const dayOfWeek = date.getDay();
  const dayOffset = [1, 0, 0, 0, 0, 0, 2];

  return addDays(date, dayOffset[dayOfWeek]);
}

const divider: Record<Units, number> = {
  days: 1000 * 60 * 60 * 24,
  hours: 1000 * 60 * 60,
  minutes: 1000 * 60,
  seconds: 1000,
};

type Units = "days" | "hours" | "minutes" | "seconds";
