import { Observable } from "rxjs";
import { shareReplay, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Filters, defaultDepositFilters } from "@common/history/history.model";
import { parseNumberRange } from "@common/dealing";
import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import {
  DepositDetails,
  DepositHistoryItem,
  DepositTerminationResult,
  FiltersForm,
} from "./deposit-history.model";

@Injectable({
  providedIn: "root",
})
export class DepositService {
  public loading: boolean;
  public end: boolean;
  private _currencies: Observable<string[]>;
  public deposits: DepositHistoryItem[] = [];
  public filters: Filters = { ...defaultDepositFilters };
  public filtersCount: number = 0;
  public hasDeals?: boolean = null;

  constructor(private http: HttpClient) {
    http.post<DepositHistoryItem[]>(
      "/deposit/history",
      { ...this.filters, ...{ type: "All", } },
      { headers: noCacheHeader }).pipe(map(x => x.length > 0)).subscribe(x => this.hasDeals = x);
  }

  getHistory() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.http
      .post<DepositHistoryItem[]>("/deposit/history", this.filters, { headers: noCacheHeader })
      .subscribe({
        next: (result) => {
          if (result.length === 0) {
            this.end = true;
            return;
          }
          this.deposits = this.deposits.concat(result);
        },
        complete: () => {
          this.filters.pageNumber++;
          this.loading = false;
        },
      });
  }

  review(id: string, forceRefresh: boolean): Observable<DepositDetails> {
    const headers = forceRefresh ? { "Cache-Control": "no-cache" } : {};
    return this.http.get<DepositDetails>(`/deposit/${id}`, { headers });
  }

  terminate(id: number): Observable<DepositTerminationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<any>("/deposit/terminate", `${id}`, { headers });
  }

  get currencies(): Observable<string[]> {
    return (
      this._currencies ??
      (this._currencies = this.http.get<string[]>("/settings/currencies").pipe(shareReplay()))
    );
  }

  reset(initFilters: boolean = false) {
    if (initFilters) {
      this.filters = { ...defaultDepositFilters };
      this.filtersCount = 0;
    }
    this.end = false;
    this.deposits = [];
    this.filters.pageNumber = 1;
  }

  setFilters({ amount, currency, date, dealId, interest, settleDate }: FiltersForm) {
    const filters: Filters = {
      transactionId: dealId,
      type: this.filters.type,
      currency,
      amountRange: parseNumberRange(amount),
      rateRange: parseNumberRange(interest),
      dateRange: date,
      settlementRange: settleDate,
    };

    this.filters = filters;
  }
}
