import { Router, NavigationEnd } from "@angular/router";
import { topMenu } from "../components/menu-section/menu.model";
import { Directive, HostListener } from "@angular/core";
import { filter } from "rxjs/operators";

@Directive({
  selector: "[swipe]"
})
export class SwipeDirective {
  private index: number = 0;
  private startX?: number;
  private endX: number;
  private threshold: number = 100;

  constructor(
    private router: Router
  ) {
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe(x => {
        const active = topMenu.indexOf(topMenu.find(y => y.link === x.url));

        this.index = active;
    });
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent) {
    this.startX = event.clientX;
  }

  @HostListener("touchmove", ["$event"])
  onTouchMove(event: TouchEvent) {
    this.endX = event.touches[0].clientX;
  }

  @HostListener("touchend", ["$event"])
  onTouchEnd(event: TouchEvent) {
    if (!this.startX || !this.endX) return;

    this.changeRoute(this.endX - this.startX);
    this.reset();
  }

  @HostListener("mouseup", ["$event"])
  onMouseUp(event: MouseEvent) {
    if (!this.startX || !this.endX) return;

    this.changeRoute(event.clientX - this.startX);
    this.reset();
  }

  private reset() {
    this.startX = null;
    this.endX = null;
  }

  private changeRoute(diff: number) {
    if (this.index === -1) return;

    if (diff < -this.threshold) {
      this.index = Math.min(this.index + 1, topMenu.length - 1);
      this.router.navigate([topMenu[this.index].link]);
    } else if (diff > this.threshold) {
      this.index = Math.max(this.index - 1, 0);
      this.router.navigate([topMenu[this.index].link]);
    }
  }
}
