import { NgModule } from "@angular/core";
import { BackButtonDirective } from "./back-button.directive";
import { NumericDirective } from "./numeric.directive";
import { SwipeDirective } from "./swipe.directive";

@NgModule({
  imports: [],
  exports: [NumericDirective, BackButtonDirective, SwipeDirective],
  declarations: [NumericDirective, BackButtonDirective, SwipeDirective],
  providers: []
})
export class DirectivesModule {}
