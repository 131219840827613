<div class="wv-container wv-container--grey" [ngClass]="{ 'no-padding': loading }">
  <app-preloader *ngIf="loading"></app-preloader>
  <div [hidden]="loading || working || accountSelection">
    <form [formGroup]="depositForm" style="padding-bottom: 100px">
      <app-input-wrapper label="DealAmount" for="transaction-amount" [control]="amountControl">
        <app-input-amount
          ngDefaultControl
          formControlName="amount"
          ngId="transaction-amount"
          placeholder="InsertAmount"
          [decimals]="currencyControl.value?.decimals"
        ></app-input-amount>
      </app-input-wrapper>

      <app-input-wrapper label="Currency" for="currency-select">
        <app-select-currency
          ngId="currency-select"
          [control]="currencyControl"
          [currencies]="currencies"
        ></app-select-currency>
      </app-input-wrapper>

      <app-account-button
        ngId="account"
        [control]="accountControl"
        label="FromAccount"
        (openSelection)="openAccountSelection()"
      >
      </app-account-button>

      <div class="wv-input-block wv-input-block__span">
        <app-input-date-tenor
          formGroupName="depositStart"
          label="Deposit.StartDate"
          [tenorDates]="nearTenorDates"
          [holidays]="holidays"
          maxTenor="SPOT"
        ></app-input-date-tenor>
        <app-input-date-tenor
          formGroupName="depositEnd"
          label="Deposit.EndDate"
          [tenorDates]="farTenorDates"
          [holidays]="holidays"
          [minDate]="minEndDate | datepicker"
        ></app-input-date-tenor>
      </div>

      <app-input-wrapper label="Deposit.FarTenor" for="endtenor-select">
        <select id="endtenor-select" [formControl]="endTenorControl">
          <option *ngFor="let tenor of endTenors" [ngValue]="tenor">
            {{ tenor | translate }}
          </option>
        </select>
      </app-input-wrapper>

      <span>
        {{ (daysUntilEnd === 1 ? "Deposit.SingleDay" : "Deposit.MultipleDays") | translate: { value: daysUntilEnd } }}
      </span>
    </form>
    <div class="wv-fixed-buttons-container">
      <button
        class="wv-global-button wv-global-button--fixed"
        id="new-transaction-button"
        [disabled]="depositForm.invalid"
        (click)="prepareData()"
      >
        {{ "Deposit.GetQuote" | translate }}
      </button>
    </div>
  </div>
</div>

<app-account-selection
  [show]="accountSelection"
  [accounts]="accounts"
  (selected)="onAccountSelection($event)"
></app-account-selection>
<app-deposit-quote
  *ngIf="working"
  [data]="deposit"
  (closed)="onClosed($event)"
  [scrollTop]="working"
></app-deposit-quote>
