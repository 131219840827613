import { Component, Input } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";

@Component({
  selector: "app-input-date",
  templateUrl: "input-date.component.html"
})
export class InputDateComponent {
  constructor(private ngControl: NgControl) {}

  @Input("ngId") id: string;
  @Input() placeholder: string = "____-__-__";
  @Input() min: string;
  @Input() max: string;

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get text(): string {
    return this.control?.value;
  }

  get disabled(): boolean {
    return this.control?.disabled;
  }
}
