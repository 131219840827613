import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { slideRightAnimation } from "@common/animations/animations";
import { WebViewService } from "@common/mobile/webview.service";
import {
  countDirtyControls,
  markAsPristineWhenDefault,
  resetFieldWhenDefault,
  setRangeFormField,
  setSingleField,
} from "@utils/form.utils";
import { DepositService } from "../deposit-history.service";

@Component({
  selector: "app-deposit-history-filters",
  templateUrl: "./deposit-history-filters.component.html",
  styleUrls: ["./deposit-history-filters.component.scss"],
  animations: [slideRightAnimation],
})
export class DepositHistoryFiltersComponent implements OnInit {
  private _filtersOpen: boolean;
  private _refreshOnClose: boolean;

  public filterForm: FormGroup;

  @Output() filterEvent = new EventEmitter<any>();

  constructor(
    private _fb: FormBuilder,
    private _wv: WebViewService,
    private depositService: DepositService
  ) {}

  get currencies(): Observable<string[]> {
    return this.depositService.currencies;
  }

  get filtersOpen(): boolean {
    return this._filtersOpen;
  }

  set filtersOpen(value: boolean) {
    if (value) {
      this._refreshOnClose = false;
      this._wv.updateScreen("depositFilters", () => {
        this.filtersOpen = false;
        if (this._refreshOnClose) {
          this.filter();
        }
      });
      setTimeout(() => this.setDefaultValues(), 0);
    } else {
      this._wv.updateScreen("depositHistory", () => this._wv.navigateFromWebView(["/more"]));
    }
    this._filtersOpen = value;
  }

  ngOnInit(): void {
    this.createGroup();

    markAsPristineWhenDefault(this.filterForm.get("currency"), null);
    resetFieldWhenDefault(this.filterForm.get("dealId"));
  }

  setDefaultValues() {
    setSingleField(this.filterForm, "currency", this.depositService.filters.currency);
    setSingleField(this.filterForm, "dealId", this.depositService.filters.transactionId);

    setRangeFormField(this.filterForm, "date", this.depositService.filters.dateRange);
    setRangeFormField(this.filterForm, "settleDate", this.depositService.filters.settlementRange);
    setRangeFormField(this.filterForm, "interest", this.depositService.filters.rateRange);
    setRangeFormField(this.filterForm, "amount", this.depositService.filters.amountRange);
  }

  clear() {
    this.filterForm.reset();

    if (!this.filtersOpen) {
      return this.filter();
    }

    this._refreshOnClose = true;
  }

  filter() {
    this.depositService.setFilters(this.filterForm.value);
    this.depositService.filtersCount = countDirtyControls(this.filterForm.controls);
    this.filtersOpen = false;
    setTimeout(() => this.filterEvent.emit(), 400);
  }

  createGroup() {
    this.filterForm = this._fb.group({
      dealId: [null],
      currency: [null],
      date: this._fb.group({}),
      settleDate: this._fb.group({}),
      interest: this._fb.group({}),
      amount: this._fb.group({}),
    });
  }
}
