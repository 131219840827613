import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GraphPoint, GraphButtonConfig } from "./graph.model";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { baseCurrency, quoteCurrency } from "../../common/dealing";

@Injectable({ providedIn: "root" })
export class GraphService {
  public returnPage: "history" | "more";
  public data: Record<string, GraphPoint[]> = {};
  public graphPair: string = "";
  public graphSide: string = "Sell";
  public graphRange: number = 0;

  get graphCurrencies(): string[] {
    return [baseCurrency(this.graphPair), quoteCurrency(this.graphPair)];
  }

  constructor(private http: HttpClient) { }

  getData(pair: string): Observable<GraphPoint[]> {
    const data = this.data[pair] || [];
    const last = data.length ? data[data.length - 1].time : 0;

    if (last + new Date(last).getTimezoneOffset() * 6e4 >= Date.now() - 6e4 * 5) {
      return of(data);
    }
    else {
      return this.http.get<GraphPoint[]>(`/graph/data/${pair}/${last}`).pipe(
        tap((result) => {
          this.data[pair] = data.concat(result);
        }),
        map(() => {
          return this.data[pair];
        }));
    };
  }

  getConfig(): Observable<GraphButtonConfig[]> {
    return this.http.get<GraphButtonConfig[]>(`/graph/config`);
  }
}
