<div *ngIf="details" class="wv-transaction-details__quote-container">
  <app-summary-item label="Deposit.InterestRate">
    <span class="wv-transaction-details__quote-rate">{{ loading ? ("MissingData.Amount" | translate) : rate.rate + "%" }}</span>
</app-summary-item>

  <app-summary-item-amount
    label="Deposit.Details.Amount"
    [amount]="details.amount"
    [currency]="details.currency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Deposit.InterestAmount"
    *ngIf="!loading; else noInterestAmountBlock"
    [amount]="rate.interestAmount"
    [currency]="details.currency"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Deposit.SettlementAmount"
    *ngIf="!loading; else noSettlementAmountBlock"
    [amount]="rate.maturityAmount"
    [currency]="details.currency"
  ></app-summary-item-amount>

  <app-summary-item-account label="FromAccount" [account]="details.account"></app-summary-item-account>

  <app-summary-item-date label="Deposit.StartDate" [date]="details.nearDate"></app-summary-item-date>

  <app-summary-item-date label="Deposit.EndDate" [date]="details.farDate"></app-summary-item-date>

  <app-summary-item label="Deposit.FarTenor">
    {{ details.farTenor || "NonStandardTenor" | translate }}
  </app-summary-item>

  <app-summary-item label="Deposit.DaysCount">
    {{ details.daysUntilEnd }}
  </app-summary-item>
</div>

<ng-template #noInterestAmountBlock>
  <app-summary-item label="Deposit.InterestAmount">{{ "MissingData.Amount" | translate }}</app-summary-item>
</ng-template>

<ng-template #noSettlementAmountBlock>
  <app-summary-item label="Deposit.SettlementAmount">{{ "MissingData.Amount" | translate }}</app-summary-item>
</ng-template>
