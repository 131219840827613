import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import {
  IndicativePair,
  IndicativeRate,
  IndicativeGraph,
  IndicativeRateDto,
} from "./indicatives.model";
import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import { areEqual } from "@utils/collection.utils";
import { getIndicativePairs } from "../../common/fake-backend/fake-backend.service";
import { getIndicativeRates } from "../../common/fake-backend/fake-backend.service";
import { getIndicativeGraphs } from "../../common/fake-backend/fake-backend.service";


@Injectable({
  providedIn: "root",
})
export class IndicativesService {
  constructor(private http: HttpClient) {
    this.loadPairs();
  }

  private _pairs: BehaviorSubject<IndicativePair[]> = new BehaviorSubject(null);
  private _rates: BehaviorSubject<IndicativeRate[]> = new BehaviorSubject(null);

  get pairs(): Observable<IndicativePair[]> {
    return this._pairs.asObservable();
  }

  get rates(): Observable<IndicativeRate[]> {
    return this._rates.asObservable();
  }

  private get selectedCodes(): string[] {
    return this._pairs.getValue().filter(getSelected).map(getCode);
  }

  loadPairs() {
    //this.http.get<IndicativePair[]>("/indicatives/pairs").subscribe((pairs) => {
    //this._pairs.next([...pairs]);
    //});
    this._pairs.next([...getIndicativePairs()]);

  }

  savePairs(codes: string[]): Observable<any> {
    if (areEqual(this.selectedCodes, codes)) {
      return of({});
    }

    return this.http.post("/indicatives/pairs", codes).pipe(
      tap(() => {
        const nextPairs = this._pairs.getValue().map((pair) => {
          const isSelected = codes.includes(pair.code);
          return { ...pair, isSelected };
        });
        nextPairs.sort((pair1, pair2) => this.compareOrder(pair1, pair2, codes));

        this._pairs.next(nextPairs);
      })
    );
  }

  loadRates(): void {
    const pairs = this._pairs.getValue()?.filter(getSelected);

    if (!pairs?.length) {
      return;
    }

    const params = new HttpParams({
      fromObject: { pairs: pairs.map(getCode) },
    });
/*
    this.http
      .get<IndicativeRateDto[]>("/indicatives/rates", { params, headers: noCacheHeader })
      .pipe(
        map((rates) =>
          pairs.map((pair) => {
            const rate = rates.find((x) => x.pair === pair.code);
            return { ...rate, pair } as IndicativeRate;
          })
        )
      )
      .subscribe((rates) => {
        this._rates.next([...rates]);
      });
    */
      this._rates.next([...getIndicativeRates()]);

  }

  getGraphs(): Observable<IndicativeGraph[]> {

    const pairs = this.selectedCodes;

    if (!pairs.length) {
      return of([]);
    }

    const params = new HttpParams({
      fromObject: { pairs },
    });

    return this.http
      .get<IndicativeGraph[]>("/graph/indicatives", { params })
      .pipe(catchError(() => of([])));
  }

  private compareOrder(pair1: IndicativePair, pair2: IndicativePair, codes: string[]): number {
    switch (true) {
      case pair1.isSelected && pair2.isSelected:
        return codes.indexOf(pair1.code) > codes.indexOf(pair2.code) ? 1 : -1;
      case pair1.isSelected && !pair2.isSelected:
        return -1;
      case !pair1.isSelected && pair2.isSelected:
        return 1;
      default:
        return pair1.order > pair2.order ? -1 : 1;
    }
  }
}

const getCode = ({ code }: IndicativePair) => code;
const getSelected = ({ isSelected }: IndicativePair) => isSelected;
