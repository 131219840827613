import { Component, OnInit, Input } from "@angular/core";
import { UserNotificationComponent, ExpiredOrder } from "@components/notifications/notifications.model";
import { WebViewService } from "@common/mobile/webview.service";
import { TranslateService } from "@ngx-translate/core";
import { ActionItem } from "@common/mobile/webview.model";

@Component({
  selector: "app-notifications-expired-order",
  templateUrl: "./notifications-expired-order.component.html",
  styleUrls: ["./notifications-expired-order.component.scss"]
})
export class NotificationsExpiredOrderComponent extends UserNotificationComponent implements OnInit {
  @Input() public order: ExpiredOrder;

  constructor(webViewService: WebViewService, private translate: TranslateService) {
    super(webViewService);
  }

  ngOnInit(): void {}

  get icon(): string {
    return "expired_order";
  }

  mainAction() {
    this._webViewService.navigateFromWebView([`/history/order/${this.order.orderId}`], { state: { returnPage: "dashboard" } });
  }

  openMenu(event) {
    super.drawMenu(event, [
      {
        text: this.translate.instant("Notifications.Details"), callback: () => this.mainAction()
      } as ActionItem
    ]);
  }
}
