<div class="wv-input-block">
  <label *ngIf="groupLabel" class="wv-input-block__label" [for]="fromId">{{ groupLabel | translate }}</label>
  <form [formGroup]="group" class="wv-input-block__span">
    <app-input-wrapper [for]="fromId" [label]="labelFrom">
      <app-input-date
        *ngIf="type === 'date'"
        ngDefaultControl
        [ngId]="fromId"
        formControlName="from"
        [placeholder]="(placeholder?.from | translate) || ''"
      ></app-input-date>

      <app-input-amount
        *ngIf="type === 'amount'"
        ngDefaultControl
        [ngId]="fromId"
        formControlName="from"
        [placeholder]="(placeholder?.from | translate) || ''"
        [decimals]="decimals"
      ></app-input-amount>
    </app-input-wrapper>

    <app-input-wrapper [for]="toId" [label]="labelTo">
      <app-input-date
        *ngIf="type === 'date'"
        ngDefaultControl
        [ngId]="toId"
        formControlName="to"
        [placeholder]="(placeholder?.to | translate) || ''"
      ></app-input-date>

      <app-input-amount
        *ngIf="type === 'amount'"
        ngDefaultControl
        [ngId]="toId"
        formControlName="to"
        [placeholder]="(placeholder?.to | translate) || ''"
        [decimals]="decimals"
      ></app-input-amount>
    </app-input-wrapper>
  </form>
</div>
