<div class="wv-transaction-details__text-container">
  <app-summary-item label="Deposit.Details.DepositNo">{{ details.id }}</app-summary-item>
</div>

<div class="wv-transaction-details__text-container wv-transaction-details__text-container--bottom">
  <app-summary-item label="Deposit.Details.Status" *ngIf="details.isTerminated">
    {{ "Deposit.Statuses.Terminated" | translate }}
  </app-summary-item>

  <app-summary-item-amount
    label="Deposit.Details.Amount"
    [amount]="details.amount"
    [currency]="details.currency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item label="Deposit.InterestRate">
    {{ details.interestRate / 100 | percent: "1.2-2" }}
  </app-summary-item>

  <app-summary-item-amount
    label="Deposit.InterestAmount"
    [amount]="details.interestAmount"
    [currency]="details.currency"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Deposit.SettlementAmount"
    [amount]="details.amountWithInterest"
    [currency]="details.currency"
  ></app-summary-item-amount>

  <app-summary-item-account label="FromAccount" [account]="details.account"></app-summary-item-account>

  <app-summary-item-date label="Deposit.DealDate" [date]="details.dealDate"></app-summary-item-date>

  <app-summary-item-date label="Deposit.StartDate" [date]="details.startDate"></app-summary-item-date>

  <app-summary-item-date label="Deposit.EndDate" [date]="details.endDate"></app-summary-item-date>
</div>
