<section *ngIf="rates$ | async as rates; else loading">
  <div class="wv-my-currency-pairs" *ngFor="let rate of rates; trackBy: trackByPair">
    <app-indicative-chart
      *ngIf="graphs$ | async as graphs; else graphLoading"
      [graphs]="graphs"
      [pair]="rate.pair"
    ></app-indicative-chart>
    <app-indicative-rate-item
      side="Sell"
      [rate]="rate.sell"
      [pair]="rate.pair"
    ></app-indicative-rate-item>
    <app-indicative-rate-item
      side="Buy"
      [rate]="rate.buy"
      [pair]="rate.pair"
    ></app-indicative-rate-item>
  </div>

  <div class="wv-container wv-container--border-bottom">
    <div class="wv-view-desktop__info">
      {{ "Indicatives.MoreInfo" | translate }}
      <a [routerLink]="" (click)="showInfo()">{{ "here" | translate }}</a>
    </div>
    <button class="wv-global-button ripple" routerLink="/exchange">
      {{ "Button.ToExchange" | translate }}
    </button>
  </div>
</section>

<ng-template #loading>
  <app-preloader height="50vw"></app-preloader>
</ng-template>

<ng-template #graphLoading>
  <div>
    <app-preloader height="11vw" width="auto"></app-preloader>
  </div>
</ng-template>
