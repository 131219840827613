import { Component, Input } from "@angular/core";
import { ErrorKeyValue } from "../inputs.model";

@Component({
  selector: "app-input-error",
  templateUrl: "input-error.component.html"
})
export class InputErrorComponent {
  @Input() error: ErrorKeyValue;

  /**
   * Allows to override ValidationError from the reactive forms API.
   * @type {Record<string, ErrorKeyValue | string>}
   * @example
   * - Change translation key, leave validation API value:
   * ```js
   * { required: 'newKey' }
   * ```
   * - Change translation key and value (any type):
   * ```js
   * { min: { key: 'newKey', value: 99 } }
   * ```
   */
  @Input() overrides?: Record<string, ErrorKeyValue | string>;

  constructor() {}

  get message(): ErrorKeyValue {
    const { overrides, error } = this;
    const override = overrides?.[error.key];

    if (!override) {
      return error;
    }

    return typeof override === "string" ? { ...error, key: override } : override;
  }
}
