import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { addMinutes, datepickerFormat, timepickerFormat } from "../utils/time.utils";

export function minTimeValidator(time: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return Validators.required(control);
    }

    const now = datepickerFormat(new Date());
    const min = new Date(now + "T" + time);
    const value = new Date(now + "T" + control.value);

    return value <= min ? { min: { min: time } } : null;
  };
}

/**
 * Validates that the selected time is not in the past in reference to the current day.
 * @param control Timepicker
 * @returns "min" validation error if selected date-time is in the past
 */
export const trailingTimeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return Validators.required(control);
  }

  const min = addMinutes(new Date(), 2);
  min.setSeconds(0, 0);
  const value = new Date(datepickerFormat(min) + "T" + control.value);

  return value < min ? { min: { min: timepickerFormat(min) } } : null;
};
