import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Currency } from "src/app/common/dealing/dealing.model";

@Component({
  selector: "app-select-currency",
  templateUrl: "select-currency.component.html"
})
export class SelectCurrencyComponent {
  constructor() {}

  @Input("ngId") id: string;
  @Input() control: FormControl;

  @Input() currencies: Currency[];

  get code(): string {
    return this.control.value?.code;
  }
}
