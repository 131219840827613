import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Documents } from "./document-list.model";

@Injectable({ providedIn: "root" })
export class DocumentService {
  constructor(private http: HttpClient) {}

  getData(): Observable<Documents> {
    return this.http.get<any>("/document/config");
  }
}
