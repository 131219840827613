import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { WebViewService } from "../../common/mobile/webview.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "./alert.service";
import { Side, Currency, CurrencyPair } from "../../common/dealing/dealing.model";
import { AlertFormData, errorDetails, InitialState, RateAlert } from "./alert.model";
import { PhonePrefix } from "../../common/phones/phone.model";
import { addDays, datepickerFormat, firstDateAfterWeekend } from "src/app/utils/time.utils";
import { findCurrencyPair, findDefaultCurrency } from "src/app/common/dealing";
import { weekendValidator, minDateValidator, maxDateValidator } from "src/app/validators";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  public alertForm: FormGroup;
  public currencies: Currency[] = [];
  public minDate: string = datepickerFormat(new Date());
  public maxDate: string = datepickerFormat(addDays(new Date(), 90));
  public loading: boolean = true;
  public success: boolean = false;
  public failure: boolean = false;
  public alertRequest: RateAlert;
  public errorTitle: string;
  public errorDescription: string;
  public hideRetry: boolean;
  public phonePrefixes: PhonePrefix[] = [];
  public useSmsNotification: boolean = false;
  public saving: boolean = false;

  private _currencyPairs: CurrencyPair[] = [];
  private _counterCurrenciesByCurrency: Record<string, Currency[]> = {};
  private _defaultDate: string = datepickerFormat(firstDateAfterWeekend(addDays(new Date(), 7)));
  private _initialState: InitialState = {};

  get dealCurrency() {
    return this.alertForm.get("currencies.dealCurrency");
  }

  get dealAmount() {
    return this.alertForm.get("dealAmount");
  }

  get counterCurrency() {
    return this.alertForm.get("currencies.counterCurrency");
  }

  get expirationDate() {
    return this.alertForm.get("expirationDate");
  }

  get rate() {
    return this.alertForm.get("rate");
  }

  get side() {
    return this.alertForm.get("side");
  }

  get phonePrefix() {
    return this.alertForm.get("phone.prefix");
  }

  get phoneNumber() {
    return this.alertForm.get("phone.number");
  }

  get currencyPair(): CurrencyPair {
    return findCurrencyPair(this._currencyPairs, this.dealCurrency.value?.code, this.counterCurrency.value?.code);
  }

  get counterCurrencies(): Currency[] {
    return this._counterCurrenciesByCurrency[this.dealCurrency?.value?.code];
  }

  @ViewChild("container")
  container: ElementRef;

  get shouldButtonBeSticky(): boolean {
    if (this.container) {
      return window.innerHeight > this.container.nativeElement.offsetHeight + 100;
    }
    return false;
  }

  constructor(private formBuilder: FormBuilder, private webViewService: WebViewService, private alertService: AlertService) {
    this._initialState = history.state.initialData || {};
    this.alertService.returnPage = history.state.returnPage || "history";
  }

  ngOnInit(): void {
    this.webViewService.updateScreen("alert", () => this.alertService.returnToInitialPage());
    this.createGroup();

    this.alertService.getFormData().subscribe(results => {
      this.handleFormData(results);
      this.loading = false;
    });
  }

  getData(): RateAlert {
    return {
      amount: parseFloat(this.dealAmount.value),
      currency: this.dealCurrency.value.code,
      currencyPair: this.currencyPair.code,
      expirationDate: this.expirationDate.value,
      side: this.side.value,
      targetRate: parseFloat(this.rate.value),
      phonePrefix: this.phonePrefix?.value?.code || "",
      phoneNumber: this.phoneNumber?.value || "",
      useSmsNotification: this.useSmsNotification
    } as RateAlert;
  }

  public openNewAlert(clearData: boolean = false) {
    this.webViewService.updateScreen("alert", () => this.alertService.returnToInitialPage());
    if (clearData) {
      this.alertForm.reset();
      this.setDefaultValues();
    }
    this.saving = false;
    this.success = this.failure = false;
    this.alertRequest = null;
  }

  save() {
    this.saving = true;
    this.alertRequest = this.getData();
    this.alertService.create(this.alertRequest).subscribe(
      result => {
        if (result.success) {
          this.getStatus(result.guid);
        } else {
          this.setErrorDetails(result.failCode);
        }
      },
      () => {
        this.setErrorDetails();
      }
    );
  }

  getStatus(guid: string) {
    this.alertService.getStatus(guid).subscribe(
      result => {
        if (result.success) {
          this.saving = false;
          this.success = true;
        } else if (result.retry) {
          setTimeout(() => this.getStatus(guid), 1000);
        } else {
          this.setErrorDetails(result.failCode);
        }
      },
      () => {
        this.setErrorDetails();
      }
    );
  }

  private createGroup(): void {
    this.alertForm = this.formBuilder.group({
      side: [null, Validators.required],
      dealAmount: [null, [Validators.required, Validators.min(1)]],
      currencies: this.formBuilder.group({}),
      phone: this.formBuilder.group({}),
      rate: [null, Validators.required],
      expirationDate: [null, [weekendValidator, minDateValidator(this.minDate), maxDateValidator(this.maxDate)]]
    });
  }

  private handleFormData(data: AlertFormData): void {
    const { currencies, currencyPairs, counterCurrencies, phonePrefixes, useSmsNotification } = data;
    this._currencyPairs = currencyPairs;
    this.currencies = currencies;
    this._counterCurrenciesByCurrency = counterCurrencies;
    this.phonePrefixes = phonePrefixes;
    this.useSmsNotification = useSmsNotification;

    setTimeout(() => this.setDefaultValues(), 0);
  }

  private setDefaultValues(): void {
    const dealCurrency = findDefaultCurrency({
      currency: this._initialState.currency,
      defaultCurrency: "EUR",
      currencies: this.currencies
    });

    const counterCurrency = findDefaultCurrency({
      currency: this._initialState.counterCurrency,
      defaultCurrency: "PLN",
      currencies: this._counterCurrenciesByCurrency[dealCurrency.code]
    });

    this.dealCurrency.setValue(dealCurrency);
    this.counterCurrency.setValue(counterCurrency);
    this.side.setValue(this._initialState.side || Side.Sell);
    this.expirationDate.setValue(this._defaultDate);
  }

  private setErrorDetails(failCode: number = 0) {
    if (failCode == 1002) {
      this.errorTitle = "Errors.RateTitle";
      this.errorDescription = "Errors.1002";
      this.hideRetry = true;
    } else {
      this.errorTitle = "Errors.GenericTitleAlert";
      this.errorDescription = errorDetails[failCode] || "Errors.GenericDescription";
      this.hideRetry = false;
    }

    this.saving = false;
    this.failure = true;
  }
}
