<div class="wv-view-desktop" [@hide]="userService.leiMessage.show || userService.hasUnreadMessages ? 'modalOpen' : ''">
  <!-- // <<-------- NOWE -->
  <app-section-header [title]="'Dashboard.Indicatives' | translate" [notification]='[]'></app-section-header>
  <app-indicatives></app-indicatives>

  <app-section-header [title]="'Dashboard.Notifications' | translate" [tooltip]="showNotificationsTooltip">
  </app-section-header>
  <app-notifications></app-notifications>

  <app-section-header [title]="'Dashboard.News' | translate" id="news"></app-section-header>
  <app-news></app-news>
</div>

<app-message-item-viewer *ngIf="!userService.leiMessage.show && userService.hasUnreadMessages">
</app-message-item-viewer>