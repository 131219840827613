import { Component, Input } from "@angular/core";
import { Side } from "@common/dealing/dealing.model";
import { WebViewService } from "@common/mobile/webview.service";
import { IndicativePair } from "@components/indicatives/indicatives.model";
import { baseCurrency, quoteCurrency } from "@common/dealing";
import { NavigationService } from "@common/_core/navigation/navigation.service";
import { hasRole } from "@utils/cookie.utils";

@Component({
  selector: "app-indicative-rate-item",
  templateUrl: "indicative-rate-item.component.html",
  styleUrls: ["indicative-rate-item.component.scss"]
})
export class IndicativeRateItemComponent {
  @Input() side: Side;
  @Input() pair: IndicativePair;
  @Input() set rate(value: number) {
    this.direction = value < this.rate ? "down" : "up";
    this._rate = value;
  }

  private _rate: number;
  public direction: string = "up";

  constructor(private _navigation: NavigationService, private _wv: WebViewService) {}

  get rate() {
    return this._rate;
  }

  get disabled(): boolean {
    return !this._rate;
  }

  get accountMissing(): boolean {
    return this.pair.accountMissing;
  }

  openNewDeal() {
    if (this.accountMissing && !hasRole("FxForward")) {
      return this.displayMissingAccountInfo();
    }

    const initialData = {
      currency: baseCurrency(this.pair.code),
      counterCurrency: quoteCurrency(this.pair.code),
      side: this.side,
      tenor: this.accountMissing ? "SN" : "TOD",
      onlyNdfDeal: this.accountMissing
    };
    this._navigation.navigate(["/exchange"], { state: { initialData } });
  }

  private displayMissingAccountInfo() {
    this._wv.showBottomSheet({
      title: "MissingAccount.Title",
      text: "MissingAccount.Text",
      primaryButton: {
        text: "MissingAccount.Button"
      }
    });
  }
}
