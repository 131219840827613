import { Component, Input } from "@angular/core";

@Component({
  selector: "app-summary-item-amount",
  templateUrl: "summary-item-amount.component.html"
})
export class SummaryItemAmountComponent {
  @Input() label: string;
  @Input() decimals: number = 2;
  @Input() amount: number;
  @Input() currency: string;
  @Input() flag: boolean;

  constructor() {}

  get format(): string {
    return `1.${this.decimals}-${this.decimals}`;
  }
}
