import { Component, Input } from "@angular/core";
import { slideRightAnimation } from "src/app/common/animations/animations";
import { Message } from "../message.model";

@Component({
  selector: "app-message-modal",
  templateUrl: "./message-modal.component.html",
  animations: [slideRightAnimation]
})
export class MessageModalComponent {
  @Input() public message: Message;

  constructor() {}
}

// NB: this has old styles. For new features, use app-message-container
