<div [@hide]="accountSelection ? 'modalOpen' : ''">
  <app-section-header [title]="'Profile.Data' | translate"></app-section-header>
  <app-user-data></app-user-data>

  <ng-container *ngIf="showSettings">
    <app-section-header [title]="'Profile.Settings' | translate"></app-section-header>
    <app-user-settings></app-user-settings>
  </ng-container>

  <app-section-header [title]="'Profile.DefaultSettings' | translate"></app-section-header>
  <app-menu-button *ngFor="let item of defaultSettingsItems" [item]="item"></app-menu-button>

  <app-default-accounts (selected)="openDefaultAccountSelection($event)"></app-default-accounts>
</div>

<app-account-selection [show]="accountSelection" [accounts]="selectableAccounts" (selected)="onDefaultAccountSelection($event)">
</app-account-selection>
