<div class="wv-transaction-details__text-container">
  <app-summary-item label="Transaction.Details.Id">{{ details.id }}</app-summary-item>
</div>

<div class="wv-transaction-details__text-container wv-transaction-details__text-container--bottom">
  <app-summary-item-amount
    label="Transaction.Details.{{ details.side }}"
    [amount]="details.dealAmount"
    [currency]="details.dealCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Vs"
    [amount]="details.counterAmount"
    [currency]="details.counterCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount label="Transaction.Details.Rate" [amount]="details.rate" [decimals]="4"></app-summary-item-amount>

  <app-summary-item label="Transaction.Details.Type">
    {{ "Transaction.DealTypes." + details.productType | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.SubType" *ngIf="subType">
    {{ "Transaction.SubTypes." + subType | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.LinkedDeal" *ngIf="details.linkedDealId">
    <a [routerLink]="['../', details.linkedDealId]">{{ details.linkedDealId }}</a>
  </app-summary-item>

  <app-summary-item-date label="Transaction.Details.Date" [date]="details.dealDate"></app-summary-item-date>

  <app-summary-item-date label="SettlementDate" [date]="details.settlementDate"></app-summary-item-date>

  <app-summary-item label="Status">{{ "Transaction.Statuses." + status | translate }}</app-summary-item>

  <app-summary-item label="Transaction.Details.Dpw" *ngIf="details.isIob && details.dpwReference">
    {{ details.dpwReference }}
  </app-summary-item>

  <app-summary-item-amount
    label="Transaction.Details.SettlementAmount"
    *ngIf="details.netSettlement"
    [amount]="-details.netSettlement.amount"
    [currency]="details.netSettlement.currency"
  ></app-summary-item-amount>

  <app-summary-item label="Transaction.Details.SettlementType" *ngIf="details.isNdf">
    {{ "Transaction.Details.Netto" | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.ReferenceRate" *ngIf="details.isNdf">
    {{ "Transaction.ReferenceRate." + details.referenceRate | translate }}
  </app-summary-item>

  <app-summary-item-account
    [label]="details.isNdf ? 'SettlementAccount' : 'FromAccount'"
    *ngIf="details.sellAccount"
    [account]="details.sellAccount"
  >
  </app-summary-item-account>

  <app-summary-item-account
    [label]="details.isNdf ? 'SettlementAccount' : 'ToAccount'"
    *ngIf="details.buyAccount"
    [account]="details.buyAccount"
  >
  </app-summary-item-account>

  <app-summary-collateral *ngIf="details.collateral" [collateral]="details.collateral"></app-summary-collateral>

  <app-summary-item label="Transaction.Details.OrderId" *ngIf="details.hasOrderReference && details.orderId">
    <a [routerLink]="['/history/order', details.orderId]">{{ details.orderId }}</a>
  </app-summary-item>
  <button *ngIf="showButton" class="wv-global-button wv-global-button--fixed" routerLink="/exchange" [state]="{ returnPage: 'history' }" translate>
    Button.ToExchange
  </button>
</div>
