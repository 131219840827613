import { Component, OnInit } from '@angular/core';
import { ActivationService } from './activation.service';
import { WebViewService } from '../../common/mobile/webview.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit {
  public loading: boolean;

  constructor(private activationService: ActivationService, private webViewService: WebViewService) {
    this.webViewService.updateScreen('activationStart');
  }

  get page() {
    return this.activationService.page;
  }

  ngOnInit(): void {
    this.loading = true;
    this.activationService.getPageData().subscribe(() => {
      this.loading = false;
    });
  }
}
