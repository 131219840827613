import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Message } from "@components/message/message.model";
import { NewsItem } from "./news.model";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  private _news: BehaviorSubject<NewsItem[]> = new BehaviorSubject(null);
  private _loaded: boolean = false;

  public expanded: boolean = false;

  constructor(private http: HttpClient) {}

  get news$(): Observable<NewsItem[]> {
    return this._news.asObservable();
  }

  toggle() {
    this.expanded = !this.expanded;
    this.loadNews();
  }

  loadNews() {
    if (this._loaded) {
      return;
    }

    this.http
      .get<NewsItem[]>("/news", { params: { all: this.expanded.toString() } })
      .subscribe((news) => {

        this._loaded = this.expanded;
        this._news.next(news);
      });
  }

  getDetails(id: string): Observable<Message> {
    return this.http.get<NewsItem>(`news/${id}`).pipe(
      map(({ headline, content, date }) => {
        return {
          header: headline,
          description: content.replace(/(<\/?.*?>)/g, ""),
          date,
        };
      })
    );
  }
}
