<div class="wv-list__dpw-element_info_area">
  <div class="wv-exchange-deposits__info">
    <div class="wv-exchange-deposits__header">{{ 'Dpw.' + dpw.type | translate }}</div>
    <div class="wv-exchange-deposits__value">{{ (dpw.amount  | number: "1.2-2")}} {{ dpw.currency }}</div>
    <div class="wv-exchange-deposits__header">{{ 'Dpw.TransferNumber' | translate }}</div>
    <div class="wv-exchange-deposits__value">{{ dpw.ssrid }}</div>
  </div>
  <div class="wv-exchange-deposits__info">
    <div class="wv-exchange-deposits__header">{{ 'Dpw.Expiring' | translate }}</div>
    <div class="wv-exchange-deposits__value">{{ dpw.expireTime | date: "dd.MM.yyyy, HH:mm"}}</div>

  </div>
</div>
<button *ngIf="showButton" class="wv-global-button wv-global-button--white-normal ripple" (click)="startDeal()">{{ 'Dpw.CheckRate' | translate }}</button>

