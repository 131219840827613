import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { isWeekend } from "../utils/time.utils";

export const weekendValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return isWeekend(new Date(control.value)) ? { weekend: true } : null;
};

export function minDateValidator(date: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return Validators.required(control);
    }

    return new Date(control.value) < new Date(date) ? { min: { min: date } } : null;
  };
}

export function maxDateValidator(date: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return Validators.required(control);
    }
    return new Date(control.value) > new Date(date) ? { max: { max: date } } : null;
  };
}

export function holidaysValidator(holidays: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (holidays.some(holiday => holiday === control.value)) {
      return { holiday: true };
    }
    return null;
  };
}
