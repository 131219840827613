import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { WebViewService } from "../../common/mobile/webview.service";
import { Documents } from "./document-list.model";
import { DocumentService } from "./document-list.service";

@Component({
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrls: ["./document-list.component.scss"]
})
export class DocumentListComponent implements OnInit {
  public documents$: Observable<Documents>;

  constructor(private _webViewService: WebViewService, private _documentService: DocumentService) {}

  ngOnInit(): void {
    this._webViewService.updateScreen("documents");
    this.documents$ = this._documentService.getData();
  }
}
