import { MenuItem } from "../menu-section/menu.model";

export const defaultSettingsMenu: MenuItem[] = [
  { name: "DefaultSettingsSection.Exchange.Title", link: "/settings/exchange", requiredRoles: "FxSpot" },
  { name: "DefaultSettingsSection.Deposit.Title", link: "/settings/deposit", requiredRoles: "MmDeposit" }
];

export interface UserPreference {
  key: string;
  value: any;
  disabled: boolean;
}
