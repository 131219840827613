<div [@hide]="quote.working ? 'modalOpen' : ''">
  <ng-container *ngIf="data; else loading">
    <ng-container *ngIf="!data.isEmpty; else noPlannedTransfers">
      <app-section-header [title]="'Dpw.ActiveTitle' | translate"></app-section-header>
      <ng-container *ngIf="data.active.length; else noActive">
        <div class="wv-container wv-container--grey wv-container--border-bottom">
          <div *ngFor="let dpw of data.active" class="wv-exchange-deposits wv-list__dpw-element">
            <app-dpw-element [dpw]="dpw" (selectEvent)="startDeal($event)"></app-dpw-element>
          </div>
        </div>
      </ng-container>
      <app-section-header [title]="'Dpw.PendingTitle' | translate"></app-section-header>
      <ng-container *ngIf="data.pending.length; else noPending">
        <div class="wv-container wv-container--grey">
          <div *ngFor="let dpw of data.pending" class="wv-exchange-deposits wv-list__dpw-element">
            <app-dpw-element [dpw]="dpw"></app-dpw-element>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <app-preloader *ngIf="!isInitialized" [absolute]="true"></app-preloader>
</ng-template>

<ng-template #noPlannedTransfers>
  <div class="wv-transactions-empty-list">
    <span class="wv-summary-modal__text-label">
      {{ 'MissingData.DpwList' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #noActive>
  <div class="wv-transactions-empty-list wv-container--border-bottom">
    <span class="wv-summary-modal__text-label">
      {{ 'MissingData.DpwActive' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #noPending>
  <div class="wv-transactions-empty-list">
    <span class="wv-summary-modal__text-label">
      {{ 'MissingData.DpwPending' | translate }}
    </span>
  </div>
</ng-template>

<app-exchange-quote class="fx-quote" #quote (closed)="onDealCompleted($event)"></app-exchange-quote>
