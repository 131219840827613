export default function (Highcharts) {
  Highcharts.theme = {
    chart: {
      backgroundColor: '#1A1A1A',
      style: {
        fontFamily: "PKOBankPolski-Regular"
      },
      plotBorderColor: '#606063'
    },
    xAxis: {
      gridLineColor: '#707073',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#707073',
      minorGridLineColor: '#505053',
      tickColor: '#707073',
      title: {
        style: {
          color: '#A0A0A3'

        }
      }
    },
    yAxis: {
      gridLineColor: '#707073',
      labels: {
        style: {
          color: '#E0E0E3'
        }
      },
      lineColor: '#707073',
      minorGridLineColor: '#505053',
      tickColor: '#707073',
      tickWidth: 1,
      title: {
        style: {
          color: '#A0A0A3'
        }
      }
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      style: {
        color: "#585858",
        cursor: "default",
        fontSize: "13px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
        fontFamily: "PKOBankPolski-Regular"
      }
    },

    rangeSelector: {
      buttonTheme: {
        height: 12,
        width: "auto",
        padding: 14,
        fill: "none",
        stroke: "#004c9a",
        "stroke-width": 1,
        r: 0,
        style: {
          color: "#006EF5",
          borderWidth: 1
        },
        states: {
          hover: {
            fill: "white",
            style: {
              color: "#585858",
              fontWeight: "normal"
            }
          },
          select: {
            fill: "#006EF5",
            style: {
              color: "#000000",
              fontWeight: "normal"
            }
          }
        }
      }
    }
  };

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme);

}
