import { Component, OnInit } from "@angular/core";
import { RateAlert } from "../alert/alert.model";
import { AlertHistoryService } from "./alert-history.service";
import { WebViewService } from "../../common/mobile/webview.service";

@Component({
  selector: "app-alert-history",
  templateUrl: "./alert-history.component.html",
  styleUrls: ["./alert-history.component.scss"]
})
export class AlertHistoryComponent implements OnInit {
  public alerts: RateAlert[] = [];
  public loading: boolean;

  constructor(private historyService: AlertHistoryService, private webViewService: WebViewService) {
    this.webViewService.updateScreen("alertHistory", () => this.webViewService.navigateFromWebView(["/more"]));
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts() {
    this.loading = true;
    this.historyService.get().subscribe(
      result => {
        this.alerts = result;
      },
      error => console.error(error),
      () => {
        this.loading = false;
      }
    );
  }

  onCancel(result) {
    this.webViewService.showSnackBar(`Alerts.${result.success ? "AlertCanceled" : "AlertNotCanceled"}`);
    if (result) {
      this.alerts = this.alerts.filter(function (x) {
        return x.id !== result.id;
      });
    }
  }
}
