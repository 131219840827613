/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { delay, mergeMap, materialize, dematerialize } from "rxjs/operators";

import fakeService from "../common/fake-backend/fake-backend.service";
import { URLSearchParams } from "url";
import { getParamsFromObject } from "@utils/http.utils";
import { identifierName } from "@angular/compiler";
import { FakeBackendService } from "@common/fake-backend/fake-backend.utils";
import { GraphPoint } from "@components/graph/graph.model";
import { WebViewService } from "@common/mobile/webview.service";

// DO WYSZUKANIA NOWOŚĆ -   <<-------- NOWE

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

  public newCommonData: any = {};                               // <<-------- NOWE

  constructor(private fakeBackend: FakeBackendService, private _wv: WebViewService) {        // <<-------- NOWE
    this.fakeBackend.commonData$.subscribe(data => {            // <<-------- NOWE
      this.newCommonData = data;                                // <<-------- NOWE
    })                                                          // <<-------- NOWE
  }                                                             // <<-------- NOWE

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, body, params, urlWithParams } = req;

    // wrap in delayed observable to simulate server api call


    const handleRoute = () => {
      console.log(method)
      console.log(url)
      //console.log(params)
      //console.log(urlWithParams)
      //console.log(body)

      switch (true) {

        // case url.startsWith("/user") && method === "GET":
        //   console.error("** GET USER FAKE")
        //   return ok({
        //     isDarkMode: true,
        //   });

        case url.endsWith("customer/lei") && method === "GET":
          return ok(fakeService.customerLei());

        case url.endsWith("/customer") && method === "GET":
          return ok(fakeService.getCustomer());

        case url.endsWith("/customer/set") && method === "POST":
          return ok();
        //RM - wyłączone, bo inaczej nie działają
        //case url.endsWith("/indicatives") && method === "GET":
        //return ok(fakeService.getIndicativePairs());

        case url.endsWith("/indicatives/getrates") && method === "POST":
          return ok(fakeService.getIndicativeRates());

        case url.endsWith("/indicatives/setpairs") && method === "POST":
          return ok(fakeService.setIndicativePairs(body));

        case url.includes("/settings/accounts") && method === "GET":
          return ok(fakeService.getDefaultAccounts());

        case url.endsWith("/settings/currencies") && method === "GET":
          return ok(fakeService.currencies);

        case url.includes("/settings/tenors") && method === "GET": {
          const id = urlWithParams.split("=").slice(-1)[0];
          switch (id) {
            case ("fx"):
              return ok(fakeService.getFxTenors().map((x) => x.tenor));
            case ("mm"):
              return ok(fakeService.getMmTenors().map((x) => x.tenor));
          }
        }


        case url.includes("/settings") && method === "GET":
          return ok(fakeService.getSettings());

        case url.includes("/settings") && method === "POST":
          return ok();


        case url.includes("/collateral/types") && method === "GET":
          const params = urlWithParams.split("=").slice(-1)[0];
          //const params = "FXON"
          return ok(fakeService.getCollateralTypes(params))

        case url.endsWith("/collateral/accounts") && method === "GET":
          return ok(fakeService.getAccounts())

        case url.includes("/graph/indicatives") && method === "GET":
          return ok(fakeService.getIndicativeGraphs());

        case url.endsWith("/graph/config") && method === "GET":
          return ok(fakeService.getGraphConfig());

        case url.includes("/graph/data") && method === "GET":
          try {
            const [pair] = url.split("/").slice(-2);
            const urlArray = url.split("/");                                                  // <<-------- NOWE
            if (urlArray[urlArray.length - 1] === '0') {                                      // <<-------- NOWE
              const graphData: GraphPoint[] = this.newCommonData["/graph/" + pair] ?? [];     // <<-------- NOWE
              return ok(graphData);                                                           // <<-------- NOWE
            } else {                                                                          // <<-------- NOWE
              return ok([]);                                                                  // <<-------- NOWE
            }
          } catch (exc) {
          }

        case url.endsWith("notification/dates") && method === "GET":
          return ok(fakeService.getNotificationDates());

        case url.endsWith("notification/actions") && method === "GET": {
          const id = urlWithParams.split("=").slice(-1)[0];
          return ok(fakeService.getNotificationActions(id));
        }

        case url.endsWith("news") && method === "GET":
          return ok(fakeService.getNews());

        case url.includes("news/") && method === "GET": {
          const [id] = url.split("/").slice(-1);
          return ok(fakeService.getNewsDetal(id));
        }

        case url.endsWith("/document/config") && method === "GET":
          return ok()

        case url.endsWith("/exchange/data") && method === "GET":
          return ok({
            //fakeService.getFxTenors(),
            currencies: fakeService.currencies.map((x) => ({ code: x, decimals: 2 })),
            currencyPairs: fakeService.currencyPairs.map((x) => ({ code: x })),
            tenors: fakeService.getFxTenors().map((x) => x.tenor),
            counterCurrencies: fakeService.getcounterCurrencies(),
            settlementAccounts: fakeService.getAccounts()
          });

        case url.endsWith("/exchange/preferences") && method === "GET":
          return ok(fakeService.getFxNormalPreferences()).pipe(delay(1000));

        case url.endsWith("/exchange/preferences") && method === "POST":
          return ok();

        case url.includes("/exchange/tenor-dates") && method === "GET":
          return ok(fakeService.getFxTenors());

        case url.endsWith("exchange/initialize") && method === "POST": {
          const currencyPairInit = body.currencyPair;
          const sideInit = body.side;
          const amountInit = body.amount;
          const dealCurrencyInit = body.dealCurrency;
          return ok(fakeService.PostExchangeInit(currencyPairInit, dealCurrencyInit, sideInit, amountInit))
        }

        case url.includes("exchange/rate/1a-2b-3c-4d-5e") && method === "GET":
          const [currencyPairRate] = url.split(/=|,/g).slice(1, 2);
          const [dealCurrencyRate] = url.split(/=|,/g).slice(3, 4);
          const [sideRate] = url.split(/=|,/g).slice(5, 6);
          const [amountRate] = url.split(/=|,/g).slice(-1);
          return ok(fakeService.GetExchangeRate(currencyPairRate, dealCurrencyRate, sideRate, amountRate));

        case url.endsWith("exchange/reject") && method === "POST":
          return ok(
            //fakeService.decisionTimeChange()
          );

        case url.endsWith("exchange/confirm") && method === "POST":
          //const dealGuidConfirm = body.dealGuid;
          //const rateGuidConfirm = body.rateGuid
          //return ok()
          return ok({
            // dealGuidConfirm,
            //rateGuidConfirm
          });

        case url.includes("exchange/status") && method === "GET":
          const [status] = url.split("/").slice(-1);
          return ok(fakeService.GetExchangeStatus(status));

        case url.includes("/sessionaccounts") && method === "GET":
          return ok([]);

        case url.includes("sessionaccounts/store") && method === "POST":
          return ok();


        case url.endsWith("/deposit/data") && method === "GET":
          return ok({
            currencies: fakeService.currencies.map((x) => ({ code: x, decimals: 2 })),
            tenors: fakeService.getMmTenors().map((x) => x.tenor),
            settlementAccounts: fakeService.getAccounts(),
          });

        case url.endsWith("/deposit/preferences") && method === "GET":
          return ok(fakeService.getDepositPreferences()).pipe(delay(1000));

        case url.endsWith("/deposit/preferences") && method === "POST":
          return ok();

        case url.includes("/deposit/tenor-dates") && method === "GET":
          return ok(fakeService.getMmTenors());

        case url.endsWith("deposit/initialize") && method === "POST":
          return ok(fakeService.PostDepositInit(body))

        case url.includes("deposit/rate/1a-2b-3c-4d-5e") && method === "GET":
          const [DepositcurrencyRate] = url.split(/=|,/g).slice(1, 2);
          const [DepositamountRate] = url.split(/=|,/g).slice(-1);
          return ok(fakeService.GetDepositRate(DepositamountRate, DepositcurrencyRate));

        case url.endsWith("deposit/confirm") && method === "POST":
          return ok();

        case url.includes("deposit/status") && method === "GET":
          const [Depositstatus] = url.split("/").slice(-1);
          return ok(fakeService.GetExchangeStatus(Depositstatus));

        case url.endsWith("/order/history") && method === "GET":
          return ok(fakeService.getOrders());

        case url.endsWith("/order/types") && method === "GET":
          return ok();

        case url.includes("/order/") && method === "GET": {
          const [id] = url.split("/").slice(-1);
          return ok(fakeService.getOrderDetails(id));
        }

        case url.endsWith("/alert") && method === "GET":
          return ok(fakeService.getAlertHistory());

        case url.endsWith("/alert/data") && method === "GET":
          return ok(console.log("aaaa"));


        case url.includes("history/review/") && method === "GET": {
          const [id] = url.split("/").slice(-1);
          return ok(fakeService.getDealReview(id));
        }

        case url.endsWith("deposit/history") && method === "POST":
          return ok(fakeService.getDepositHistory());

        case url.includes("deposit/1") && method === "GET": {
          const [id] = url.split("/").slice(-1);
          return ok(fakeService.getDepositDetails(id));
        }

        case url.endsWith("/dpw/history") && method === "GET":
          return ok(fakeService.getNotificationActionsNewDPW());

        case url.endsWith("/history") && method === "POST":
          return ok(fakeService.getDealHistory());

        case url.endsWith("/exchange/ndf-data") && method === "GET":
          return ok({
            currencies: fakeService.currencies.map((x) => ({ code: x, decimals: 2 })),
            counterCurrencies: fakeService.getcounterCurrencies(),
            currencyPairs: fakeService.currencyPairs.map((x) => ({ code: x })),
          })

        default:
          // pass through any requests not handled above
          return next.handle(req);
      }
    }

    return (
      of(null)
        .pipe(mergeMap(handleRoute))
        // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
        .pipe(materialize())
        .pipe(delay(500))
        .pipe(dematerialize())
    );
  }
}

function ok(body?) {
  return of(new HttpResponse({ status: 200, body }));
}

function error(message: string) {
  return throwError({ error: { message } });
}

function unauthorized() {
  return throwError({ status: 401, error: { message: "Unauthorized" } });
}
