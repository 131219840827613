import { Component, Input } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";

@Component({
  selector: "app-input-amount",
  templateUrl: "input-amount.component.html"
})
export class InputAmountComponent {
  constructor(private ngControl: NgControl) {}

  @Input("ngId") id: string;
  @Input() placeholder: string = "";
  @Input() decimals: number = 0;

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
