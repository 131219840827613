import { animate, style, transition, trigger, query, group, state, AnimationMetadata } from "@angular/animations";

export const hideAnimation = [
  trigger("hide", [
    state(
      "modalOpen",
      style({
        height: "100vh",
        overflow: "hidden"
      })
    )
  ])
];

export const slideRightAnimationWithoutInitial = [
  trigger("slideInOut", [
    state(
      "modalOpen",
      style({
        height: "100vh",
        overflow: "hidden"
      })
    ),
    state(
      "enter",
      style({
        right: 0,
        "min-height": "100vh",
        width: "100vw",
        display: "block"
      })
    ),
    state(
      "leave",
      style({
        display: "none"
      })
    ),
    transition("leave => enter", [
      style({
        right: "-100vw",
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate(
        "400ms ease-in",
        style({
          right: 0
        })
      ),
      style({
        right: 0,
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        display: "block"
      })
    ]),
    transition("enter => leave", [
      style({
        right: 0,
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate("400ms ease-in", style({ right: "-100vw" }))
    ])
  ])
];

export const slideRightAnimation = [
  trigger("slideInOut", [
    state(
      "modalOpen",
      style({
        height: "100vh",
        overflow: "hidden"
      })
    ),
    state(
      "enter",
      style({
        right: 0,
        "min-height": "100vh",
        width: "100vw",
        display: "block"
      })
    ),
    state(
      "leave",
      style({
        display: "none"
      })
    ),
    transition(":enter, * => enter", [
      style({
        right: "-100vw",
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate(
        "400ms ease-in",
        style({
          right: 0
        })
      ),
      style({
        right: 0,
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        display: "block"
      })
    ]),
    transition(":leave, * => leave", [
      style({
        right: 0,
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate("400ms ease-in", style({ right: "-100vw" }))
    ])
  ])
];

export const slideBottomAnimation = [
  trigger("slideInOut", [
    state(
      "enter",
      style({
        top: 0,
        "min-height": "100vh",
        width: "100vw",
        display: "block"
      })
    ),
    state(
      "leave",
      style({
        display: "none"
      })
    ),
    transition(":enter, * => enter", [
      style({
        top: "100vh",
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate(
        "400ms ease-in",
        style({
          top: 0
        })
      ),
      style({
        top: 0,
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        display: "block"
      })
    ]),
    transition(":leave, * => leave", [
      style({
        top: 0,
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate("400ms ease-in", style({ top: "100vh" }))
    ])
  ])
];

export const slideBottomAnimationWithoutInitial = [
  trigger("slideInOut", [
    state(
      "enter",
      style({
        top: 0,
        "min-height": "100vh",
        width: "100vw",
        display: "block"
      })
    ),
    state(
      "leave",
      style({
        display: "none"
      })
    ),
    transition("leave => enter", [
      style({
        top: "100vh",
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate(
        "400ms ease-in",
        style({
          top: 0
        })
      ),
      style({
        top: 0,
        height: "100vh",
        width: "100vw",
        overflow: "auto",
        display: "block"
      })
    ]),
    transition("enter => leave", [
      style({
        top: 0,
        height: "100vh",
        width: "100vw",
        display: "block"
      }),
      animate("400ms ease-in", style({ top: "100vh" }))
    ])
  ])
];

const rightRouteAnimationMetadata: AnimationMetadata[] = [
  query(":enter", [
    style({
      position: "absolute",
      right: "-100vw",
      top: "0",
      width: "100vw",
      height: "100vh"
    })
  ]),
  query(
    ":leave",
    [
      style({
        height: "100vh"
      })
    ],
    { optional: true }
  ),
  group([
    query(":enter", [animate("400ms 0s ease-in", style({ right: 0 }))]),
    query(":leave", [animate("0s 400ms ease-in", style({ display: "none" }))], { optional: true })
  ])
];

const leftRouteAnimationMetadata: AnimationMetadata[] = [
  query(":enter", [
    style({
      position: "absolute",
      left: "-100vw",
      top: "0",
      width: "100vw",
      height: "100vh"
    })
  ]),
  query(
    ":leave",
    [
      style({
        height: "100vh"
      })
    ],
    { optional: true }
  ),
  group([
    query(":enter", [animate("400ms 0s ease-in", style({ left: 0 }))]),
    query(":leave", [animate("0s 400ms ease-in", style({ display: "none" }))], { optional: true })
  ])
];

export const slideRouteAnimation = [
  trigger("routeAnimations", [
    transition("History => Dashboard, Profile => History, More => Profile, More => History, More => Dashboard, Profile => Dashboard", leftRouteAnimationMetadata),
    transition("Dashboard => History, History => Profile, Profile => More, Dashboard => Profile, Dashboard => More, History => More", rightRouteAnimationMetadata),
    transition("details => *", [
      query(":enter", [
        style({
          position: "absolute",
          right: "0",
          top: "0",
          width: "100vw",
          height: "100vh",
          "z-index": 2
        })
      ]),
      query(
        ":leave",
        [
          style({
            position: "absolute",
            right: "0",
            top: "0",
            width: "100vw",
            height: "100vh",
            "z-index": 3
          })
        ],
        { optional: true }
      ),
      group([
        query(":enter", [animate("400ms 0s ease-in", style({ top: 0 }))]),
        query(":leave", [animate("0s 400ms ease-in", style({ top: "100vh" }))], { optional: true })
      ])
    ]),
    transition("* => details", [
      query(":enter", [
        style({
          position: "absolute",
          left: 0,
          top: "100vh",
          width: "100vw",
          height: "100vh"
        })
      ]),
      query(
        ":leave",
        [
          style({
            height: "100vh"
          })
        ],
        { optional: true }
      ),
      group([
        query(":enter", [animate("400ms 0s ease-in", style({ top: 0 }))]),
        query(":leave", [animate("0s 400ms ease-in", style({ display: "none" }))], { optional: true })
      ])
    ]),
    transition("* <=> exchange", [
      query(":enter", [
        style({
          position: "absolute",
          left: 0,
          top: "100vh",
          width: "100vw",
          height: "100vh"
        })
      ]),
      query(
        ":leave",
        [
          style({
            height: "100vh"
          })
        ],
        { optional: true }
      ),
      group([
        query(":enter", [animate("400ms 0s ease-in", style({ top: 0 }))]),
        query(":leave", [animate("0s 400ms ease-in", style({ display: "none" }))], { optional: true })
      ])
    ]),
    transition("indicative-select => indicative, rightSlidePage => *", [
      query(":enter", [
        style({
          position: "absolute",
          right: "0",
          top: "0",
          width: "100vw",
          height: "100vh",
          "z-index": 2
        })
      ]),
      query(
        ":leave",
        [
          style({
            position: "absolute",
            right: "0",
            top: "0",
            width: "100vw",
            height: "100vh",
            "z-index": 3
          })
        ],
        { optional: true }
      ),
      group([
        query(":enter", [animate("400ms 0s ease-in", style({ right: 0 }))]),
        query(":leave", [animate("0s 400ms ease-in", style({ right: "-100vw" }))], { optional: true })
      ])
    ]),
    transition("* <=> indicative, * => indicative-select, * => rightSlidePage", rightRouteAnimationMetadata)
  ])
];

export function sleep(time: number = 400) {
  return new Promise(resolve => setTimeout(resolve, time));
}
