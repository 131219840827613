<div
  class="wv-digit-step-four-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible"
  style="z-index: 5"
  *ngIf="activationService.page >= 4"
  [@slideInOut]
>
  <div class="wv-view-digit-step-four">
    <div class="wv-top-progress-bar">
      <div class="wv-top-progress-bar__inner" style="width: 100%"></div>
    </div>

    <div class="wv-section-header">
      <div class="wv-section-header__title" translate>Activation.EmailHeader</div>
    </div>

    <div class="wv-container wv-container--extra-padding wv-container--border-bottom">
      <div class="wv-view-digit-step-four__info" translate>Activation.InsertEmail</div>
      <div class="wv-view-digit-step-four__email-header" translate>Activation.YourEmails</div>
      <div class="wv-view-digit-step-four__email-sub-header" translate>Activation.MaxNoEmails</div>
      <div class="wv-input-block wv-input-block--remove" *ngFor="let email of emails; let i = index">
        <label for="'email-' + i" class="visually-hidden" translate>Email</label>
        <input
          [id]="'email-' + i"
          type="email"
          name="email"
          class="wv-global-input wv-global-input--disabled"
          [value]="email"
        />
        <span class="wv-input-block__ico-remove" (click)="removeAddress(email)"></span>
      </div>

      <div class="wv-view-digit-step-four__email-add">
        <div>
          <form [formGroup]="addressForm">
            <div *ngFor="let control of emailControls">
              <div class="wv-input-block" [class.has-error]="getEmailControl(control).invalid">
                <div class="wv-input-block--remove">
                  <label for="email" class="visually-hidden" translate>Email</label>
                  <input type="email" [formControlName]="control" class="wv-global-input wv-global-input" />
                  <span class="wv-input-block__ico-remove" (click)="removeNewAddress(control)"></span>
                </div>
                <ng-container
                  *ngIf="
                    getEmailControl(control).invalid && (getEmailControl(control).dirty || getEmailControl(control).touched)
                  "
                >
                  <small class="error-message" *ngIf="getEmailControl(control).errors.required" translate>CannotBeEmpty</small>
                  <small class="error-message" *ngIf="getEmailControl(control).errors.incorrectEmail" translate
                    >IncorrectEmail</small
                  >
                </ng-container>
              </div>
            </div>
          </form>
        </div>
        <a [routerLink]="" (click)="addAddress()" translate [ngStyle]="{ color: addEmailDisabled ? 'grey' : '' }"
          >Activation.AddEmail</a
        >
      </div>
    </div>
    <div class="wv-section-header">
      <div class="wv-section-header__title" translate>Activation.RegulationsHeader</div>
    </div>

    <form [formGroup]="dataForm">
      <div class="wv-container wv-container--extra-padding">
        <div class="wv-input-checkbox">
          <input id="agreement" type="checkbox" class="wv-input-checkbox__input" formControlName="emailConsent" />
          <label for="agreement" class="wv-input-checkbox__label" translate> Activation.EmailConsent </label>
          <span class="wv-tooltip"></span>
        </div>

        <div class="wv-view-digit-step-four__email-file" *ngFor="let document of documents" (click)="openDocument(document)">
          <span class="wv-view-digit-step-four__email-file-ico"></span
          ><span class="wv-view-digit-step-four__email-file-name">{{ document.name }}</span>
        </div>
      </div>
      <div class="wv-container wv-container--border-top wv-container--fixed">
        <div class="wv-view-digit-step-four__info-button">
          <button id="digitWait" class="wv-global-button ripple" (click)="save()" [disabled]="saveDisabled() || sending" translate>
            Activation.Send
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
