<input
  [id]="id"
  [formControl]="control"
  [placeholder]="placeholder | translate"
  autocomplete="off"
  type="text"
  inputmode="decimal"
  numeric
  [decimals]="decimals"
/>
