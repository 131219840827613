import { Component, OnInit } from "@angular/core";
import { slideRightAnimation } from "../../common/animations/animations";
import { WebViewService } from "../../common/mobile/webview.service";
import { UserService } from "../../common/_core/session/user-service";
import { MessageItem } from "../message-item/message-item.model";
import { MessageService } from "../message-item/message-item.service";

@Component({
  selector: "app-message-item-viewer",
  templateUrl: "./message-item-viewer.component.html",
  styleUrls: ["./message-item-viewer.component.scss"],
  animations: [slideRightAnimation]
})
export class MessageItemViewerComponent implements OnInit {
  public message: MessageItem;
  private ids: string[] = [];

  constructor(
    private webViewService: WebViewService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.message = this.userService.unreadMessages[0];
    this.updateScreen();
  }

  accept(): void {
    this.ids.push(this.message.id);
    this.openNextMessage();
  }

  openNextMessage(): void {
    this.userService.unreadMessages.shift();
    this.message = this.userService.unreadMessages[0];
    if (!this.userService.hasUnreadMessages) {
      this.messageService.readAndAcceptMessages(this.ids).subscribe(() => {});
    }
    this.updateScreen();
  }

  logout() {
    this.webViewService.logout();
  }

  updateScreen() {
    if (!this.message) {
      return this.webViewService.updateScreen("dashboard");
    }

    if (this.message.requiresAgreement) {
      return this.webViewService.updateScreen("message");
    }

    this.webViewService.updateScreen("dashboard");

    const { title, content: text } = this.message;
    this.webViewService.showBottomSheet({
      title,
      text,
      primaryButton: {
        text: "Button.Accept",
        callback: () => this.accept()
      },
      onClose: () => this.openNextMessage()
    });
  }
}
