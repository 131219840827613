<div
  class="wv-global-modal wv-global-modal--info wv-global-modal--visible"
  [@slideInOut]="filtersOpen ? 'enter' : 'leave'"
>
  <form
    [formGroup]="filterForm"
    class="wv-container wv-container--grey wv-filters{{ filterForm.dirty ? '--dirty' : '' }}"
  >
    <app-input-wrapper for="deposit-id" label="Filters.Search">
      <app-input-amount
        ngDefaultControl
        formControlName="dealId"
        ngId="deposit-id"
        placeholder="Filters.InsertDepositNumber"
      ></app-input-amount>
    </app-input-wrapper>

    <app-input-range
      type="date"
      formGroupName="date"
      label="Filters.DealDate"
      [placeholder]="{ from: 'Filters.From', to: 'Filters.To' }"
    ></app-input-range>

    <app-input-range
      type="date"
      formGroupName="settleDate"
      label="Filters.SettlementDateFrom"
      [placeholder]="{ from: 'Filters.From', to: 'Filters.To' }"
    ></app-input-range>

    <app-input-range
      formGroupName="interest"
      type="amount"
      labelFrom="Filters.InterestFrom"
      labelTo="Filters.InterestTo"
      [decimals]="4"
    ></app-input-range>

    <app-input-range
      formGroupName="amount"
      type="amount"
      labelFrom="Filters.AmountFrom"
      labelTo="Filters.AmountTo"
      [decimals]="2"
    ></app-input-range>

    <app-input-wrapper label="Filters.Currency" for="currency">
      <select id="currency" formControlName="currency">
        <option [ngValue]="null">{{ "Filters.All" | translate }}</option>
        <option *ngFor="let currency of currencies | async" [ngValue]="currency">
          {{ currency }}
        </option>
      </select>
    </app-input-wrapper>
  </form>

  <div class="wv-fixed-buttons-container">
    <button
      *ngIf="filterForm.dirty"
      class="wv-global-button wv-global-button--fixed wv-global-button--white-normal ripple"
      (click)="clear()"
    >
      {{ "Filters.Clear" | translate }}
    </button>
    <button class="wv-global-button wv-global-button--fixed ripple" (click)="filter()">
      {{ "Filters.Show" | translate }}
    </button>
  </div>
</div>
