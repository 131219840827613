<nav id="wvTabs" class="wv-tabs wv-tabs--top-bar dragscroll" *ngIf="visible | async">
  <div id="wvTabsNavInner" class="wv-tabs__inner">
    <a
      class="wv-tabs__link ripple"
      [ngClass]="{ 'wv-tabs__link--selected': currentPath === item.link }"
      *ngFor="let item of menuItems; trackBy: trackByName"
      [routerLink]="item.link"
      [name]="item.link"
    >
      {{ item.name | translate }}
    </a>
    <span id="wvTabsIndicator" class="wv-tabs__indicator"></span>
  </div>
</nav>
