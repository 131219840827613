import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { SharedModule } from "src/app/shared.module";
import { NotificationsEndingDepositComponent } from "./details/notifications-ending-deposit/notifications-ending-deposit.component";
import { NotificationsExecutedAlertComponent } from "./details/notifications-executed-alert/notifications-executed-alert.component";
import { NotificationsExecutedOrderComponent } from "./details/notifications-executed-order/notifications-executed-order.component";
import { NotificationsExpiredAlertComponent } from "./details/notifications-expired-alert/notifications-expired-alert.component";
import { NotificationsExpiredOrderComponent } from "./details/notifications-expired-order/notifications-expired-order.component";
import { NotificationsMessageComponent } from "./details/notifications-message/notifications-message.component";
import { NotificationsNewDpwComponent } from "./details/notifications-new-dpw/notifications-new-dpw.component";
import { NotificationsUnsettledDealComponent } from "./details/notifications-unsettled-deal/notifications-unsettled-deal.component";
import { NotificationsListComponent } from "./notifications-list/notifications-list.component";
import { NotificationsMainComponent } from "./notifications-main/notifications-main.component";
import { NotificationsComponent } from "./notifications.component";

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CommonModule, SharedModule],
  declarations: [
    NotificationsUnsettledDealComponent,
    NotificationsEndingDepositComponent,
    NotificationsNewDpwComponent,
    NotificationsExecutedOrderComponent,
    NotificationsExpiredOrderComponent,
    NotificationsExpiredAlertComponent,
    NotificationsExecutedAlertComponent,
    NotificationsMessageComponent,
    NotificationsMainComponent,
    NotificationsListComponent,
    NotificationsComponent,
  ],
  exports: [NotificationsComponent, SharedModule],
})
export class NotificationsModule {}
