import { Component, Input } from "@angular/core";
import { Side } from "../../../common/dealing/dealing.model";
import { OrderItem, OrderStatus } from "../order-history.model";

@Component({
  selector: "app-order-history-element",
  templateUrl: "./order-history-element.component.html",
  styleUrls: ["./order-history-element.component.scss"]
})
export class OrderHistoryElementComponent {
  @Input() order: OrderItem;

  constructor() {}

  get isBuying(): boolean {
    return this.order.side === Side.Buy;
  }

  get color(): string {
    switch (this.order.status) {
      case OrderStatus.Active:
        return "blue";
      case OrderStatus.Cancelled:
        return "orange";
      case OrderStatus.Executed:
        return "green";
      case OrderStatus.Expired:
        return "black";
      default:
        return "black";
    }
  }
}
