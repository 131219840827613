import { MobileScreen } from "./webview.model";

export const dashboard: MobileScreen = {
  title: "App",
  navigationIcon: "close",
  logoutOnClose: true,
  showNavbar: true,
  showCustomer: true
};

export const history: MobileScreen = {
  title: "App",
  navigationIcon: "close",
  logoutOnClose: true,
  showNavbar: true,
  showCustomer: true
};

export const profile: MobileScreen = {
  title: "App",
  navigationIcon: "close",
  logoutOnClose: true,
  showNavbar: true,
  showCustomer: true
};

export const profileAccounts: MobileScreen = {
  title: "App",
  navigationIcon: "back",
  logoutOnClose: false
};

export const more: MobileScreen = {
  title: "App",
  navigationIcon: "close",
  logoutOnClose: true,
  showNavbar: true,
  showCustomer: true
};

export const dpw: MobileScreen = {
  title: "Dpw",
  navigationIcon: "back",
  logoutOnClose: false,
  showNavbar: false
};

export const depositHistory: MobileScreen = {
  title: "DepositHistory",
  navigationIcon: "back",
  logoutOnClose: false
};

export const exchange: MobileScreen = {
  title: "FxSpot",
  navigationIcon: "back",
  logoutOnClose: false
};

export const exchangeModal: MobileScreen = {
  title: "FxSpot",
  navigationIcon: "close",
  logoutOnClose: false
};

export const exchangeSettings: MobileScreen = {
  title: "ExchangeSettings",
  navigationIcon: "back",
  logoutOnClose: false
};

export const fxConfirmation: MobileScreen = {
  title: "FxSpot",
  navigationIcon: "back",
  logoutOnClose: false
};

export const deposit: MobileScreen = {
  title: "Deposit",
  navigationIcon: "back",
  logoutOnClose: false
};

export const depositSettings: MobileScreen = {
  title: "DepositSettings",
  navigationIcon: "back",
  logoutOnClose: false
};

export const alert: MobileScreen = {
  title: "NewAlert",
  navigationIcon: "back",
  logoutOnClose: false
};

export const alertModal: MobileScreen = {
  title: "NewAlert",
  navigationIcon: "close",
  logoutOnClose: false
};

export const alertHistory: MobileScreen = {
  title: "AlertHistory",
  navigationIcon: "back",
  logoutOnClose: false
};

export const depositModal: MobileScreen = {
  title: "Deposit",
  navigationIcon: "close",
  logoutOnClose: false
};

export const customer: MobileScreen = {
  title: "Customer",
  navigationIcon: "back",
  logoutOnClose: true
};

export const indicativePairs: MobileScreen = {
  title: "IndicativePairs",
  navigationIcon: "back",
  logoutOnClose: false
};

export const exchangeDetail: MobileScreen = {
  title: "ExchangeDetail",
  navigationIcon: "close",
  logoutOnClose: false
};

export const orderDetail: MobileScreen = {
  title: "OrderDetails",
  navigationIcon: "close",
  logoutOnClose: false
};

export const depositDetail: MobileScreen = {
  title: "DepositDetails",
  navigationIcon: "close",
  logoutOnClose: false
};

export const order: MobileScreen = {
  title: "NewOrder",
  navigationIcon: "back",
  logoutOnClose: false
};

export const orderModal: MobileScreen = {
  title: "NewOrder",
  navigationIcon: "close",
  logoutOnClose: false
};

export const activationStart: MobileScreen = {
  title: "ActivationStart",
  navigationIcon: "close",
  logoutOnClose: true,
  showCustomer: true
};

export const activation: MobileScreen = {
  title: "Activation",
  navigationIcon: "back",
  logoutOnClose: false,
  showCustomer: true
};

export const activationResult: MobileScreen = {
  title: "Activation",
  navigationIcon: "close",
  logoutOnClose: true,
  showCustomer: false
};

export const graph: MobileScreen = {
  title: "Graph",
  navigationIcon: "back",
  logoutOnClose: false
};

export const depositFilters: MobileScreen = {
  title: "DepositFilters",
  navigationIcon: "close",
  logoutOnClose: false
};

export const orderFilters: MobileScreen = {
  title: "OrderFilters",
  navigationIcon: "close",
  logoutOnClose: false
};

export const exchangeFilters: MobileScreen = {
  title: "ExchangeFilters",
  navigationIcon: "close",
  logoutOnClose: false
};

export const news: MobileScreen = {
  title: "MarketNews",
  navigationIcon: "back",
  logoutOnClose: false
};

export const documents: MobileScreen = {
  title: "Documents",
  navigationIcon: "back",
  logoutOnClose: false
};

export const leiMessage: MobileScreen = {
  title: "LeiExpiration",
  navigationIcon: "close",
  logoutOnClose: false
};

export const messageDetails: MobileScreen = {
  title: "MessageDetails",
  navigationIcon: "back",
  logoutOnClose: false
};

export const message: MobileScreen = {
  title: "Message",
  navigationIcon: "none",
  logoutOnClose: true
};
