import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { sleep, slideBottomAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { MmDepositService } from "../deposit.service";

@Component({
  selector: "app-deposit-success",
  templateUrl: "./deposit-success.component.html",
  styleUrls: ["./deposit-success.component.scss"],
  animations: [slideBottomAnimation]
})
export class DepositSuccessComponent implements OnInit {
  @Input() public rate: number;
  @Input() public depositId: string;
  @Input() public amount: number;
  @Input() public currency: string;
  @Output() public newDeposit = new EventEmitter<any>();

  public show: boolean = true;

  constructor(private depositService: MmDepositService, private webViewService: WebViewService) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("depositModal", () => this.back());
  }

  openNewDeposit(): void {
    this.show = false;
    sleep().then(() => {
      this.newDeposit.emit();
    });
  }

  back() {
    this.show = false;
    sleep().then(() => {
      this.depositService.returnToInitialPage();
    });
  }
}
