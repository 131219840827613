import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { splitDepositTenors } from "@common/dealing";

@Injectable({ providedIn: "root" })
export class DefaultSettingsService {
  constructor(private httpClient: HttpClient) {}

  private _currencies: Observable<string[]>;
  
  get currencies(): Observable<string[]> {
   return this._currencies ?? (this._currencies = this.getCurrencies());
  }

  getExchangeTenors(): Observable<string[]> {
    return this.httpClient.get<string[]>("/settings/tenors", { params: { market: "fx" } });
  }

  getDepositTenors(): Observable<{ nearTenors: string[]; farTenors: string[] }> {
    return this.httpClient.get<string[]>("/settings/tenors", { params: { market: "mm" } }).pipe(
      shareReplay(),
      map((tenors) => splitDepositTenors(tenors))
    );
  }

  private getCurrencies(): Observable<string[]> {
    return this.httpClient.get<string[]>("/settings/currencies").pipe(shareReplay());
  }
}
