import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { noCacheHeader } from "../_core/cache/request-cache.model";

@Injectable({ providedIn: "root" })
export class SessionAccountsService {
  constructor(private http: HttpClient) {}

  getAccounts(): Observable<string[]> {
    return this.http.get<string[]>("/sessionaccounts", { headers: noCacheHeader });
  }

  store(accounts: string[]) {
    this.http.post<any>("/sessionaccounts/store", accounts).subscribe();
  }
}
