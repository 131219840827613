import { Component, OnInit } from '@angular/core';
import { ActivationService } from '../activation.service';
import { slideRightAnimation } from '../../../common/animations/animations';

@Component({
  selector: 'app-activation-result',
  templateUrl: './activation-result.component.html',
  styleUrls: ['./activation-result.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationResultComponent implements OnInit {

  constructor(public activationService: ActivationService) { }

  ngOnInit(): void {
  }
}
