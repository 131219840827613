import { Component, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { ActionItem } from "src/app/common/mobile/webview.model";
import { WebViewService } from "src/app/common/mobile/webview.service";

@Component({
  selector: "app-section-header",
  templateUrl: "section-header.component.html"
})
export class SectionHeaderComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() noBorder: boolean;
  @Input() tooltip?: Function;
  @Input() notification?: ActionItem[];

  public notificationId = "notification";
  private notificationClick?: Subscription;

  constructor(private _webViewService: WebViewService) {}

  ngAfterViewInit(): void {
    this.setNotification();
  }

  ngOnDestroy(): void {
    this.notificationClick?.unsubscribe();
  }

  private setNotification() {
    if (!this.notification?.length) {
      return;
    }

    this.notificationClick = fromEvent(document.getElementById(this.notificationId), "click")
      .pipe(
        debounceTime(300),
        map(({ target }: Event) => target as HTMLElement)
      )
      .subscribe(element => {
        this._webViewService.showActionSheet(element.getBoundingClientRect(), this.notification);
      });
  }
}
