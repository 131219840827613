<div class="wv-my-notifications ripple" (click)="child.mainAction()">
  <div *ngIf="child" class="wv-my-notifications__ico">
    <img src="assets/wv-img/wv-icons/{{child.icon}}.svg" class="wv-my-currency-pairs__chart-img" alt="">
  </div>
  <div class="wv-my-notifications__info">
    <ng-content></ng-content>
  </div>
  <div class="wv-notification-badge__container" (click)="child.openMenu($event)">
    <span class="wv-notification-badge__indicator-container ripple">
      <span class="wv-notification-badge__indicator"></span>
    </span>
  </div>
</div>
