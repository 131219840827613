import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, delay } from "rxjs/operators";

import { removePropsByValue } from "@utils/object.utils";
import { DepositPreferences, ExchangePreferences, IPreferences } from "./settings.model";
import defaults from "./settings-defaults";

@Injectable({ providedIn: "root" })
export class SettingsStore {
  constructor(private httpClient: HttpClient) {}

  private _loadedDeposit = false;
  private _loadedExchange = false;

  private _exchangePreferences: ExchangePreferences = defaults.exchange;
  private _depositPreferences: DepositPreferences = defaults.deposit;

  loadExchangePreferences(): Observable<ExchangePreferences> {
    if (this._loadedExchange) {
      return of(this._exchangePreferences).pipe(delay(100));
    }

    return this.httpClient.get<ExchangePreferences>("/exchange/preferences").pipe(
      map(preferences => {
        this._loadedExchange = true;

        const nextPreferences = this._override(this._exchangePreferences, preferences);
        this._exchangePreferences = nextPreferences;
        return this._exchangePreferences;
      })
    );
  }

  loadDepositPreferences() {
    if (this._loadedDeposit) {
      return of(this._depositPreferences);
    }

    return this.httpClient.get<DepositPreferences>("/deposit/preferences").pipe(
      map(preferences => {
        this._loadedDeposit = true;

        const nextPreferences = this._override(this._depositPreferences, preferences);
        this._depositPreferences = nextPreferences;
        return this._depositPreferences;
      })
    );
  }

  saveExchangePreferences(preferences: ExchangePreferences) {
    this._exchangePreferences = preferences;
    this.httpClient.post("/exchange/preferences", preferences).toPromise();
  }

  saveDepositPreferences(preferences: DepositPreferences) {
    this._depositPreferences = preferences;
    this.httpClient.post("/deposit/preferences", preferences).toPromise();
  }

  private _override<T extends IPreferences>(prev: T, next: T): T {
    const updatedCopy = removePropsByValue(next, undefined);
    return { ...prev, ...updatedCopy };
  }
}
