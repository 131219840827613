import { Component, Input } from "@angular/core";
import { hasValue } from "@utils/number.utils";
import { Account } from "src/app/common/accounts/account.model";

@Component({
  selector: "app-account-button-content",
  templateUrl: "./account-button-content.component.html",
  styleUrls: ["./account-button-content.component.scss"]
})
export class AccountButtonContentComponent {
  @Input() public title: string;
  @Input() public account: Account;
  @Input() public hideNumber: boolean;

  constructor() {}

  get showBalance(): boolean {
    return hasValue(this.account.balance);
  }
}
