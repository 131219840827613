import { Component, OnInit } from "@angular/core";

import { hideAnimation } from "@common/animations/animations";
import { WebViewService } from "@common/mobile/webview.service";
import { Account } from "@common/accounts/account.model";
import { UserProfileService } from "./user-profile.service";
import { defaultSettingsMenu } from "./user-profile.model";
import { hasRole } from "@utils/cookie.utils";

@Component({
  selector: "app-user-profile",
  templateUrl: "user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
  animations: [hideAnimation]
})
export class UserProfileComponent implements OnInit {
  constructor(private webViewService: WebViewService, private profileService: UserProfileService) {}

  private _accountSelection: boolean;
  selectableAccounts: Account[];
  defaultSettingsItems = defaultSettingsMenu.filter(menuItem => hasRole(menuItem.requiredRoles));

  get showSettings(): boolean {
    return hasRole("FxSpot");
  }

  get accountSelection(): boolean {
    return this._accountSelection;
  }

  set accountSelection(value: boolean) {
    if (value !== this._accountSelection) {
      this._accountSelection = value;
      if (value) {
        this.webViewService.updateScreen("profileAccounts", () => (this.accountSelection = false));
      } else {
        this.webViewService.updateScreen("profile");
      }
    }
  }

  openDefaultAccountSelection(accounts: Account[]) {
    this.selectableAccounts = accounts;
    this.accountSelection = true;
  }

  onDefaultAccountSelection(account: Account) {
    this.accountSelection = false;
    this.profileService.setDefaultAccounts(this.selectableAccounts, account);
  }

  ngOnInit() {
    this.webViewService.updateScreen("profile");
    this.accountSelection = false;
  }
}
