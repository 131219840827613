declare global {
  interface Window {
    nativeAppApi: any;
    webkit: { messageHandlers: any };
  }
}

declare global {
  interface Window {
    onGetWebViewInfo: any;
  }
}

declare global {
  interface Window {
    onReturnCallback: Function;
    onActionCallback: Function;
  }
}

export interface ActionItem {
  text: string;
  callback?: Function;
}

/**
 * Renders (as a button etc.) with the following properties:
 * * text {string}: button's text
 * * callback {string}: globally accessible code formatted as string,
 * e.g. `window.doSth()` or `() => { window.doSth() }`
 */
export interface ActionItemDto {
  text: string;
  callback?: string;
}

export interface DialogData {
  text: string;
  title: string;
}

export interface GetWebViewInfoModel {
  system: string;
  webViewVersion: string;
  appBuildNumber: string;
  interfaceVersion: number;
}

export interface MobileScreen {
  title: string;
  navigationIcon: "close" | "back" | "none";
  logoutOnClose: boolean;
  back?: Function;
  showNavbar?: boolean;
  showCustomer?: boolean;
}

export interface BottomSheetData {
  title: string;
  text?: string;
  primaryButton: ActionItem;
  additionalButton?: ActionItem;
  onClose?: Function;
}

export const isIOS = (): boolean => !!window.webkit?.messageHandlers;
export const isAndroid = (): boolean => !!window.nativeAppApi;
