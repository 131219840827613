import { groupBy } from "src/app/utils/collection.utils";
import { Account } from "../accounts/account.model";

export const groupByCurrency = (accounts: Account[]): Record<string, Account[]> => groupBy("currency", accounts);


export const findDefaultAccount = (accounts: Account[]): Account => accounts?.find(x => x.isDefault) || null;

export const selectAccount = (prev: Account, next: Account): void => {
  if (prev) {
    prev && (prev.isSelected = false);
  }

  if (next) {
    next.isSelected = true;
  }
};
