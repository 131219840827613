<app-preloader *ngIf="loading" [absolute]="true"></app-preloader>
<div *ngIf="!loading">
  <div class="flow-digit">
    <app-activation-start></app-activation-start>
    <app-activation-info></app-activation-info>
    <app-activation-client-data></app-activation-client-data>
    <app-activation-address></app-activation-address>
    <app-activation-data></app-activation-data>
    <app-activation-waiting-page *ngIf="page>=5"></app-activation-waiting-page>
    <app-activation-result *ngIf="page>=6"></app-activation-result>
  </div>
</div>
