<app-preloader *ngIf="isFirstRate || confirming" [absolute]="true"></app-preloader>
<div class="wv-container wv-container--grey" *ngIf="!depositId && !failure" [@slideInOut]="dealerWait ? 'modalOpen' : ''"
     style="padding-bottom: 140px">
  <app-deposit-quote-details [loading]="loading" [details]="data" [rate]="depositRate"></app-deposit-quote-details>
  <div class="wv-fixed-buttons-container" [hidden]="isFirstRate">
    <div class="wv-summary-modal__action-text" *ngIf="!loading">
      {{ "TimeInfo.YetFor" | translate }}
      <span class="wv-summary-modal__action-text--timer" [class]="timeEnd ? 'wv-text-red' : 'wv-text-blue'">
        {{ "TimeInfo.TimeLeft" | translate: { time: decisionTime } }}
      </span>
      {{ "TimeInfo.YouCanDeposit" | translate }}
      <span class="wv-bold wv-text-blue">{{ data.currency }}</span> <br />
      {{ "TimeInfo.Procent" | translate }}
      <span class="wv-bold wv-text-blue">{{ depositRate?.rate }} %</span>
    </div>
    <div class="wv-summary-modal__action-text" *ngIf="loading">
      {{ "ExpiredTimeInfo.OfferedDeposit" | translate }}
      <span class="wv-bold wv-text-yellow">{{ "ExpiredTimeInfo.ProcentExpired" | translate }}</span>.<br />
      {{ "RefreshDepositInfo" | translate }}
    </div>
    <div class="wv-summary-modal__action-timer wv-summary-modal__action-timer--active">
      <div class="progress"></div>
    </div>
    <button *ngIf="!loading" class="wv-global-button wv-global-button--fixed" (click)="confirm()" translate>Confirm</button>
    <button *ngIf="loading" class="wv-global-button wv-global-button--fixed" (click)="renewQuote()" translate>Deposit.RenewQuote</button>
  </div>
</div>

<app-deposit-success
  *ngIf="depositId"
  [depositId]="depositId"
  [rate]="depositRate.rate"
  [amount]="data.amount"
  [currency]="data.currency"
  (newDeposit)="openNewDeposit(true)"
></app-deposit-success>

<app-dealer-wait
  [show]="dealerWait"
  [title]="'depositModal'"
  (cancelled)="returnToInitialPage()"
  (closed)="returnToInitialPage()"
  [message]="'DealerWait.DescriptionDeposit'"
></app-dealer-wait>
<app-deposit-failure
  *ngIf="failure"
  [error]="error"
  (newDeposit)="openNewDeposit()"
></app-deposit-failure>
