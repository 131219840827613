import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { RateAlert } from "../alert/alert.model";
import { noCacheHeader } from "src/app/common/_core/cache/request-cache.model";

@Injectable({
  providedIn: "root"
})
export class AlertHistoryService {
  constructor(private http: HttpClient, @Inject("BASE_URL") private baseUrl: string) {}

  get(): Observable<RateAlert[]> {
    return this.http.get<RateAlert[]>(this.baseUrl + "alert", { headers: noCacheHeader });
  }

  cancel(id: number): Observable<any> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<any>(this.baseUrl + "alert/cancel", `"${id}"`, { headers });
  }
}
