export enum LogSource {
  Global = "global",
  ApiJs = "apiJs"
}

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6
}

export class LogEntry {
  source: LogSource = LogSource.Global;
  message: string = "";
  level: LogLevel = LogLevel.Debug;
  date: Date = new Date();
  location: string = location.href;

  toString() {
    return `[${LogLevel[this.level]} | ${this.source}]: ${this.message}`;
  }
}
