import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const pointsRequiredValidator: ValidatorFn = (pointsControl: AbstractControl): ValidationErrors | null => {
  const orderType = pointsControl.parent?.get("type")?.value?.id;

  return orderType === "TrailingStop" ? Validators.required(pointsControl) : null;
};

export const rateRequiredValidator: ValidatorFn = (rateControl: AbstractControl): ValidationErrors | null => {
  const orderType = rateControl.parent?.get("type")?.value?.id;

  return orderType === "TrailingStop" ? null : Validators.required(rateControl);
};
