<div class="no-select" swipe>
  <ng-container *ngIf="this.fakeBackend.isReady$ | async as isReady; else showMsg">
    <!-- // <<-------- NOWE -->
    <app-nav-menu></app-nav-menu>

    <div class="swipe-tabs-container">
      <div class="swipe-tab-content swipe-tab-content-container" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </ng-container> <!-- // <<-------- NOWE -->
  <ng-template #showMsg>
    <div
      style="position: fixed; top: 0;left:0;width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center;">
      Proszę czekać. Trwa wczytywanie danych
    </div>
  </ng-template>
</div>