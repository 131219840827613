import { Injectable } from "@angular/core";
import { LogSource } from "../_core/logging/logging.model";
import { LoggingService } from "../_core/logging/logging.service";

@Injectable({ providedIn: "root" })
export class MobileApiService {
  constructor(private _logger: LoggingService) {}

  private get androidApi(): any {
    return window.nativeAppApi;
  }

  private get appleApi(): any {
    return window.webkit?.messageHandlers;
  }

  call(command: string, data?: any): any {
    const payload = JSON.stringify(data) || null;
    let response: any;

    if (this.appleApi?.[command]) {
      response = this.appleApi[command].postMessage(payload);
    } else if (this.androidApi?.[command]) {
      response = payload ? this.androidApi[command](payload) : this.androidApi[command]();
    } else {
      this._logger.logWarning(LogSource.ApiJs, `Command *${command}* not found, payload: ${payload}`);
      return;
    }

    this._logger.logDebug(LogSource.ApiJs, `Command *${command}* payload: ${payload} response: ${response}`);
    return response;
  }
}
