<div class="wv-global-modal wv-global-modal--visible" [@slideInOut]="show ? 'enter' : 'leave'">
  <form class="wv-view-edit-currency-pairs" [formGroup]="form">
    <div class="wv-container wv-container--extra-padding">
      <div class="wv-input-search">
        <label for="search" class="visually-hidden" translate>SearchedPair</label>
        <input id="search"
               type="search"
               formControlName="search"
               autocomplete="off"
               class="wv-global-input wv-global-input--search"
               placeholder="{{ 'SearchPair' | translate }}" />
        <button type="submit" class="wv-input-search__button" translate>Search</button>
      </div>
    </div>
    <app-section-header [title]="'Indicatives.Pairs.SelectedTitle' | translate"
                        [subtitle]="'Indicatives.Pairs.SelectedSubtitle' | translate"
                        [noBorder]="true">
    </app-section-header>

    <div class="wv-container wv-container--grey">
      <div *ngFor="let item of selectedPairs"
           class="wv-input-my-currency-pairs"
           [ngClass]="{
           'wv-input-my-currency-pairs--hidden': hide(item)
        }">
        <input type="radio"
               [id]="item.code"
               [formControlName]="'pair'"
               [value]="item.code"
               class="wv-input-my-currency-pairs__input" />
        <label class="wv-input-my-currency-pairs__label" [for]="item.code">
          <app-pair-selection-item [pair]="item.code"></app-pair-selection-item>
        </label>
      </div>
    </div>

    <app-section-header [title]="'Indicatives.Pairs.UnselectedTitle' | translate"
                        [subtitle]="'Indicatives.Pairs.UnselectedSubtitle' | translate"
                        [noBorder]="true">
    </app-section-header>

    <div class="wv-container wv-container--grey">
      <div *ngFor="let item of unselectedNonDefaultOrderingPairs"
           class="wv-input-my-currency-pairs"
           [class]="{ 'wv-input-my-currency-pairs--hidden': hide(item) }">
        <input type="radio"
               [id]="item.code"
               [formControlName]="'pair'"
               [value]="item.code"
               class="wv-input-my-currency-pairs__input" />
        <label class="wv-input-my-currency-pairs__label" [for]="item.code">
          <app-pair-selection-item [pair]="item.code"></app-pair-selection-item>
        </label>
      </div>

      <div *ngIf ="unselectedNonDefaultOrderingPairs.length > 0">
        <hr />
      </div>

      <div *ngFor="let item of unselectedDefaultOrderingPairs"
           class="wv-input-my-currency-pairs"
           [class]="{ 'wv-input-my-currency-pairs--hidden': hide(item) }">
        <input type="radio"
               [id]="item.code"
               [formControlName]="'pair'"
               [value]="item.code"
               class="wv-input-my-currency-pairs__input" />
        <label class="wv-input-my-currency-pairs__label" [for]="item.code">
          <app-pair-selection-item [pair]="item.code"></app-pair-selection-item>
        </label>
      </div>
    </div>
  </form>
</div>
