<div class="wv-transaction-details__text-container">
  <app-summary-item label="UserData.Company">
    {{ customerName }}
  </app-summary-item>

  <app-summary-item label="UserData.KNumber">
    <span class="wv-tooltip" (click)="tooltipClicked()"></span>
    {{ customerShortName }}
  </app-summary-item>

  <app-summary-item label="UserData.LEINumber" *ngIf="lei?.code">
    {{ lei.code }}
  </app-summary-item>

  <app-summary-item-date label="UserData.LEIExpireDate" *ngIf="lei?.expirationDate" [date]="lei.expirationDate">
  </app-summary-item-date>
</div>
