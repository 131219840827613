<div class="wv-switches-sell-buy">
  <label
    *ngFor="let option of options"
    class="wv-switches-sell-buy__switch"
    [ngClass]="{
      'wv-switches-sell-buy__switch--selected': control.value === (option?.value || option),
      'arrow-pointer': showArrow
    }"
  >
    {{ option?.text || option | translate }}
    <input type="radio" [value]="option?.value || option" [formControl]="control" />
  </label>
</div>
