import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroupDirective, ValidatorFn } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { TenorDate } from "@common/dealing/dealing.model";
import { datepickerFormat, isSameDay } from "@utils/time.utils";
import { weekendValidator, minDateValidator, maxDateValidator, holidaysValidator } from "src/app/validators";

import { FormGroupComponent } from "../_form-group/form-group.component";

@Component({
  selector: "app-input-date-tenor",
  templateUrl: "input-date-tenor.component.html"
})
export class InputDateTenorComponent extends FormGroupComponent implements OnInit {
  public maxDate: string;

  private readonly destroy$ = new Subject();
  private _dateValidators: ValidatorFn[] = [Validators.required, weekendValidator];
  private _initialized: boolean;
  private _tenorDates: TenorDate[] = [];
  private _holidays: string[];
  private _minDate: string = datepickerFormat(new Date());

  @Input() label: string;
  @Input() maxTenor: string;

  @Input() set minDate(value: string) {
    this._minDate = value;
    if (this._initialized) {
      this.updateValidators();
    }
  }

  @Input() set holidays(value: string[]) {
    if (this._initialized) {
      this._holidays = value;
      this.updateValidators();
    }
  }

  @Input() set tenorDates(value: TenorDate[]) {
    if (value && value.length) {
      this._tenorDates = value;
      this.updateTenorDates();
    }
  }

  get minDate(): string {
    return this._minDate;
  }

  get selectedTenor() {
    return this.group.get("tenor");
  }

  get selectedDate() {
    return this.group.get("date");
  }

  constructor(_groupDirective: FormGroupDirective, private _fb: FormBuilder) {
    super(_groupDirective);
  }

  ngOnInit() {
    super.ngOnInit();
    this.addControls();
    this.onChanges();
    this._initialized = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private addControls() {
    const controls = {
      tenor: this._fb.control(null),
      date: this._fb.control(null, [...this._dateValidators, minDateValidator(this._minDate)])
    };

    for (const [key, value] of Object.entries(controls)) {
      this.group.addControl(key, value);
    }
  }

  private onChanges(): void {
    this.selectedDate.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(date => this.setTenorByDate(date));
    this.selectedTenor.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(tenor => this.setDateByTenor(tenor));
  }

  private updateTenorDates() {
    this.setMaxDate();

    const currentTenor = this.selectedTenor.value;
    const currentDate = this.selectedDate.value;

    if (currentTenor) {
      this.setDateByTenor(currentTenor);
    } else if (currentDate) {
      // NB: case when a date without tenor is selected and then dates get updated
      this.setTenorByDate(currentDate);
    }
  }

  private updateValidators() {
    const validators = [...this._dateValidators, minDateValidator(this._minDate)];
    this.maxDate && validators.push(maxDateValidator(this.maxDate));
    this._holidays?.length && validators.push(holidaysValidator(this._holidays));

    this.selectedDate.setValidators(Validators.compose(validators));
    this.selectedDate.updateValueAndValidity({ emitEvent: false });
    this.selectedDate.markAsTouched();
  }

  private setMaxDate() {
    const maxDateAvailableTenors = this.maxTenor
      ? this._tenorDates.filter(x => x.tenor === this.maxTenor)
      : this._tenorDates.sort((x, y) => x.date.getTime() - y.date.getTime());

    const { [maxDateAvailableTenors.length - 1]: maxTenor } = maxDateAvailableTenors;
    this.maxDate = datepickerFormat(maxTenor.date);

    this.updateValidators();
  }

  private setDateByTenor(tenor: string): void {
    const selectedDate = this._tenorDates.find(x => x.tenor === tenor);

    if (selectedDate) {
      this.selectedDate.setValue(datepickerFormat(selectedDate.date), { emitEvent: false });
    }
  }

  private setTenorByDate(date: string): void {
    const selectedDate = new Date(date);
    const respectiveTenor = this._tenorDates.find(x => isSameDay(x.date, selectedDate));

    this.selectedTenor.setValue(respectiveTenor?.tenor || null, { emitEvent: false });
  }
}
