import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { tap, map } from "rxjs/operators";
import { UserNotification, UnsettledDeal } from "@components/notifications/notifications.model";
import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import { datepickerFormat } from "@utils/time.utils";
//import { addDays, onlyDateUTC } from "../../utils/time.utils";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private _dates: string[];
  private _notifications: Map<string, UserNotification[]> = new Map();
  public loading: boolean; 
  constructor(private http: HttpClient) {
    this.loadDates();
  }

  loadDates() {
    return this._dates
      ? of(this._dates)
      : this.http.get<string[]>("/notification/dates").pipe(
        map((dates) => dates.map(datepickerFormat)),
          tap((dates) => {
            this._dates = dates;
          })
        );
  }

  getNotifications(dateIndex: number): UserNotification[] {
    return this._notifications.get(this._dates[dateIndex]);
  }

  removeFromCache(notification: UserNotification) {
    for (const key of Array.from(this._notifications.keys())) {
      const update = this._notifications.get(key).filter((x) => x.key !== notification.key);
      this._notifications.set(key, [...update]);
    }
  }

  loadNotifications(date: string, reload?: boolean): Observable<UserNotification[]> {
    if (!reload && this._notifications.has(date)) {
      return;
    }
    /*
    this._notifications.set(String(this._dates[1]), [{
      key: 'UnsettledDeal',
      name: 'UnsettledDeal',
      date: addDays(new Date(), 1),
      dealId: "140000002",
      dealSide: "Sell",
      currency1: 'EUR',
      amount1: 1000,
      currency2: 'PLN',
      amount2: 4637.20
     } as UnsettledDeal]);   
     this._notifications.set(String(this._dates[2]), [{
      key: 'UnsettledDeal',
      name: 'UnsettledDeal',
      date: addDays(new Date(), 2),
      dealId: '140000001',
      dealSide: "Buy",
      currency1: 'USD',
      amount1: 500,
      currency2: 'PLN',
      amount2: 2216.25
     }as UnsettledDeal]);   
   */
    this.loading = !this._notifications.has(date);
    this.http
      .get<UserNotification[]>("/notification/actions", {
        params: { date },
        headers: noCacheHeader,
      })
      .subscribe((notifications) => {
        reload && this._notifications.clear();
        this._notifications.set(date, notifications);
        this.loading = false;
      });
    }
}
