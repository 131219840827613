import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { of, forkJoin, merge } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

import { MmDepositService } from "./deposit.service";
import { Deposit, DepositFormData } from "./deposit.model";

import { Account } from "@common/accounts/account.model";
import { findDefaultAccount } from "@common/dealing/account.utils";
import { Currency, TenorDate } from "@common/dealing/dealing.model";
import { splitDepositTenorDates, findDefaultCurrency } from "@common/dealing";
import { WebViewService } from "@common/mobile/webview.service";
import { DepositPreferences as Preferences } from "@common/user/settings.model";
import { SettingsStore } from "@common/user/settings.service";
import { addDays, difference } from "@utils/time.utils";

@Component({
  selector: "app-deposit",
  templateUrl: "./deposit.component.html",
  styleUrls: ["./deposit.component.scss"],
})
export class DepositComponent implements OnInit, AfterViewInit {
  public depositForm: FormGroup;

  public currencies: Currency[] = [];
  public endTenors: string[] = [];
  public deposit: Deposit = null;
  public loading: boolean = true;
  public working: boolean = false;
  public holidays: string[];
  public minEndDate: Date = addDays(new Date(), 1);
  public nearTenorDates: TenorDate[];
  public farTenorDates: TenorDate[];

  private _preferences: Preferences;
  private _accountsByCurrency: Record<string, Account[]> = {};
  private _tenorDatesByStartTenor: Record<string, TenorDate[]> = {};
  private _accountSelection: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private depositService: MmDepositService,
    private webViewService: WebViewService,
    private settingsService: SettingsStore
  ) {
    settingsService.loadDepositPreferences();
    this.depositService.returnPage = history.state.returnPage || "history";
  }

  get currencyControl() {
    return this.depositForm.get("currency");
  }

  get amountControl() {
    return this.depositForm.get("amount");
  }

  get accountControl() {
    return this.depositForm.get("account");
  }

  get startTenorControl() {
    return this.depositForm.get("depositStart.tenor");
  }

  get startTenorDateControl() {
    return this.depositForm.get("depositStart.date");
  }

  get endTenorControl() {
    return this.depositForm.get("depositEnd.tenor");
  }

  get endTenorDateControl() {
    return this.depositForm.get("depositEnd.date");
  }

  get accounts(): Account[] {
    return this._accountsByCurrency[this.currencyControl.value?.code];
  }

  get daysUntilEnd(): number {
    const endDate = this.endTenorDateControl.value;
    const startDate = this.startTenorDateControl.value;
    if (!endDate || !startDate) {
      return 0;
    }

    return difference(new Date(endDate), new Date(startDate), "days");
  }

  public get accountSelection(): boolean {
    return this._accountSelection;
  }

  public set accountSelection(value: boolean) {
    if (value === this._accountSelection) {
      return;
    }

    const returnCallback = value
      ? () => (this.accountSelection = false)
      : () => this.depositService.returnToInitialPage();

    this._accountSelection = value;
    this.webViewService.updateScreen("deposit", returnCallback);
  }

  ngOnInit(): void {
    this.webViewService.updateScreen("deposit", () => this.depositService.returnToInitialPage());
    this.createGroup();

    const preferencesTask = this.settingsService.loadDepositPreferences();
    const formDataTask = this.depositService.getFormData();

    forkJoin([preferencesTask, formDataTask]).subscribe(([prefs, form]) => {
      this.handlePreferences(prefs);
      this.handleFormData(form);
      setTimeout(() => this.setDefaultValues(), 0);
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.onChanges();
  }

  public prepareData(): void {
    this.deposit = {
      account: this.accountControl.value,
      amount: parseFloat(this.amountControl.value),
      currency: this.currencyControl.value.code,
      nearTenor: this.startTenorControl.value,
      nearDate: this.startTenorDateControl.value,
      farTenor: this.endTenorControl.value,
      farDate: this.endTenorDateControl.value,
      daysUntilEnd: this.daysUntilEnd,
    } as Deposit;
    this.working = true;
  }

  public onClosed(clearForm: boolean) {
    this.working = false;
    if (clearForm) {
      this.depositForm.reset();
      this.setDefaultValues();
    }
  }

  public openAccountSelection() {
    this.accountSelection = true;
  }

  public onAccountSelection(account: Account) {
    this.accountSelection = false;
    this.accountControl.setValue(account);
  }

  private onChanges(): void {
    merge(
      ...[
        this.startTenorControl.valueChanges.pipe(distinctUntilChanged()),
        this.startTenorDateControl.valueChanges.pipe(distinctUntilChanged()),
      ]
    ).subscribe(() => this.updateTenorDates());

    this.currencyControl.valueChanges
      .pipe(filter((x) => !!x))
      .subscribe((x) => this.onCurrencyChanged(x));
  }

  private handleFormData(data: DepositFormData): void {
    this.currencies = data.currencies;
    this.endTenors = data.farTenors;
    this._accountsByCurrency = data.accountsByCurrency;
  }

  private setDefaultValues() {
    this.amountControl.setValue(this._preferences.amount);
    this.setDefaultCurrency();
    this.setDefaultTenors();
    this.setDefaultAccount();
  }

  private handlePreferences(preferences: Preferences): void {
    this._preferences = { ...preferences };
  }

  private onCurrencyChanged({ holidays }: Currency) {
    this.setDefaultAccount();
    this.holidays = holidays;
  }

  private updateTenorDates(): void {
    const startTenor = this.startTenorControl.value;

    if (!startTenor) {
      return;
    }

    const currentDates = this._tenorDatesByStartTenor[startTenor];
    const currentDatesObservable = currentDates
      ? of(currentDates)
      : this.depositService.getTenorDates(startTenor);

    currentDatesObservable.subscribe((tenorDates) => {
      this._tenorDatesByStartTenor[startTenor] = tenorDates;

      const { nearTenorDates, farTenorDates } = splitDepositTenorDates(tenorDates);
      this.nearTenorDates = nearTenorDates;
      this.farTenorDates = farTenorDates;

      this.updateMinEndDate();
    });
  }

  private updateMinEndDate(): void {
    this.minEndDate = this.farTenorDates.find(({ tenor }) => tenor === "1D").date;
  }

  private setDefaultCurrency(): void {
    const currency = findDefaultCurrency({
      currency: this._preferences.currency,
      defaultCurrency: "PLN",
      currencies: this.currencies,
      index: 0
    })
    this.currencyControl.setValue(currency);
  }

  private setDefaultAccount(): void {
    const account = findDefaultAccount(this.accounts);
    this.accountControl.setValue(account);
  }

  private setDefaultTenors(): void {
    const { startTenor, endTenor } = this._preferences;
    this.startTenorControl.setValue(startTenor);
    this.endTenorControl.setValue(endTenor);
  }

  private createGroup(): void {
    this.depositForm = this._fb.group({
      amount: [null, [Validators.required, Validators.min(1)]],
      currency: [null, Validators.required],
      account: [null, [Validators.required]],
      depositStart: this._fb.group({}),
      depositEnd: this._fb.group({}),
    });
  }
}
