<form [formGroup]="group" class="wv-input-block wv-input-block__span">
  <app-input-wrapper for="currency-select">
    <app-select-currency ngId="currency-select" [control]="dealCurrency" [currencies]="dealCurrencies"></app-select-currency>
  </app-input-wrapper>

  <span class="wv-input-block__span-separator">{{ "vs" | translate }}</span>

  <app-input-wrapper for="counter-currency-select">
    <app-select-currency
      ngId="counter-currency-select"
      [control]="counterCurrency"
      [currencies]="counterCurrencies"
    ></app-select-currency>
  </app-input-wrapper>
</form>
