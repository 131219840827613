<!-- <a [routerLink]="item.link" [state]="state" (click)="handleClick($event)">
  <div class="wv-menu-item ripple">
    <div class="wv-my-notifications__ico" *ngIf="item.icon">
      <img src="assets/wv-img/wv-icons/{{ item.icon }}.svg" class="wv-my-currency-pairs__chart-img" [alt]="item.icon" />
    </div>
    <div class="wv-menu-item__text wv-arrow-container">
      {{ item.name | translate }}
    </div>
  </div>
</a> -->

<span (click)="handleClick($event)">
  <div class="wv-menu-item ripple">
    <div class="wv-my-notifications__ico" *ngIf="item.icon">
      <img src="assets/wv-img/wv-icons/{{ item.icon }}.svg" class="wv-my-currency-pairs__chart-img" [alt]="item.icon" />
    </div>
    <div class="wv-menu-item__text wv-arrow-container">
      {{ item.name | translate }}
    </div>
  </div>
</span>