<div class="wv-transactions ripple">
  <div class="wv-transactions__info">
    <div class="wv-transactions__name">
      {{ (isBuying ? "Buy" : "Sell") | translate }} {{ order.dealCurrency }} {{ 'vs' | translate }} {{ order.counterCurrency }}
    </div>
    <div class="wv-transactions__status wv-transactions__status--{{color}}">
      {{ ("Order.Statuses." + order.status) | translate }}
    </div>
  </div>
  <div class="wv-transactions__value">
    <div class="wv-transactions__value-num wv-transactions__value-num--default">
      {{ order.amount | number: '1.2-2' }} {{ order.dealCurrency }}
    </div>
    <div class="wv-transactions__value-num wv-transactions__value-num--default">
      {{ order.rate | number: '1.4-4' }}
    </div>
  </div>
</div>
