import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { groupByCurrency } from "src/app/common/dealing";
import { WebViewService } from "../../common/mobile/webview.service";
import { noCacheHeader } from "../../common/_core/cache/request-cache.model";
import { OrderFormData, OrderInitResult, OrderRequestDto, OrderStatus, OrderValidationResult } from "./order.model";

@Injectable({ providedIn: "root" })
export class OrderService {
  public returnPage: "graph" | "history" | "more";

  constructor(private http: HttpClient, private webViewService: WebViewService) {}

  getFormData(): Observable<OrderFormData> {
    return this.http.get<any>("/order/data").pipe(
      map(data => {
        const { settlementAccounts, nightShiftStart, nightShiftEnd, nextNightShiftStart, orderTypes } =
          data;

        const accountsByCurrency = groupByCurrency(settlementAccounts);

        const types = orderTypes.map(({ id, minTime, maxTime }) => {
          return {
            id,
            maxTime: maxTime && new Date(maxTime),
            minTime: minTime && new Date(minTime)
          };
        });

        return {
          ...data,
          nightShiftStart: new Date(nightShiftStart),
          nightShiftEnd: new Date(nightShiftEnd),
          nextNightShiftStart: new Date(nextNightShiftStart),
          orderTypes: types,
          accountsByCurrency
        };
      })
    );
  }

  validate(data: OrderRequestDto): Observable<OrderValidationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<OrderValidationResult>("/order/validate", data, { headers });
  }

  initializeOrder(data: OrderRequestDto): Observable<OrderInitResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<OrderInitResult>("/order/initialize", data, { headers });
  }

  getStatus(guid: string): Observable<OrderStatus> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<OrderStatus>(`/order/status/${guid}`, { headers });
  }

  returnToInitialPage(): void {
    if (this.returnPage === "graph") {
      this.webViewService.navigateFromWebView(["/graph"]);
    } else if (this.returnPage === "history") {
      this.webViewService.navigateFromWebView(["/history"], { state: { template: "orders" } });
    } else {
      this.webViewService.navigateFromWebView(["/more"]);
    }
  }
}
