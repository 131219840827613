import { Component, OnInit } from "@angular/core";
import { MenuSection, menuSections } from "src/app/components/menu-section/menu.model";
import { WebViewService } from "src/app/common/mobile/webview.service";

@Component({
  selector: "app-more",
  templateUrl: "./more.component.html"
})
export class MoreComponent implements OnInit {
  public menus: MenuSection[] = menuSections;

  constructor(private webViewService: WebViewService) { }

  ngOnInit(): void {
    this.webViewService.updateScreen("more");
  }

  trackByHeader(_: number, item: MenuSection) {
    return item.header;
  }
}
