import { Account } from "src/app/common/accounts/account.model";

export enum OrderStatus {
  All = "All",
  Active = "Active",
  Executed = "Executed",
  Cancelled = "Cancelled",
  Expired = "Expired", 
  ExecutedPendingConversion = "ExecutedPendingConversion",
}

export interface OrderFilters {
  pageSize: number;
  pageNumber: number;
  status: OrderStatus;
  amountFrom?: number;
  amountTo?: number;
  rateFrom?: number;
  rateTo?: number;
  currency: string;
  expirationDateStart: Date;
  expirationDateEnd: Date;
  orderId: number;
  orderType: string;
  currencyPair: string;
  side: string;
}

export interface OrderItem {
  id: number;
  pair: string;
  side: string;
  dealCurrency: string;
  amount: number;
  counterAmount: number;
  counterCurrency: string;
  rate: number;
  overnightPricing: boolean;
  tenor: string;
  type: string;
  status: OrderStatus;
  expirationDateTime: Date;
  subOrder: OrderItem;
  oCORef: number;
  expirationType: string;
}

export interface OrderDetails {
  advisorName: string;
  cancellable: boolean;
  expirationType: string;
  executedTransaction: string;
  overnightPricing: boolean;
  status: OrderStatus;
  type: string;
  userName: string;
  customerName: string;
  subOrder: OrderDetails;
  expirationDateTime: Date;
  pendingConversion: boolean;
  points: string;
  phone: string;
  rate: string;
  tenor: string;
  counterSettlementAccount: Account;
  settlementAccount: Account;
  side: string;
  counterCurrency: string;
  dealCurrency: string;
  counterAmount: number;
  amount: number;
  pair: string;
  id: number;
  phonePrefix: string;
  spotDesk: boolean;
}

export const defaultOrderFilters: OrderFilters = {
  pageNumber: 1,
  status: "All",
  orderType: "All",
  side: "All",
} as OrderFilters;
