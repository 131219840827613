import { AfterContentChecked, ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-indicative-pair-group",
  templateUrl: "indicative-pair-group.component.html"
})
export class IndicativePairGroupComponent implements AfterContentChecked {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() group: FormGroup;
  @Input() disabled: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  trackByKey(_: number, item: { key: string; value: boolean }): string {
    return item.key;
  }
}

/*
NB: In order for the disable feature to work, all this is needed:
    - [formControl]="group.controls[item.key]" instead of [formControlName]="item.key",
      because it's removed on click
    - ChangeDetectorRef.detectChanges in ngAfterContentChecked, because ng-untouched changes
      from true to false when the fieldset switches to disabled
*/
