<app-preloader [absolute]="true" *ngIf="loading"></app-preloader>
<div class="wv-measure-container" [hidden]="filtersOpen">
  <div class="wv-container wv-container--grey wv-container--border-bottom">
    <app-status-filter
      [startStatus]="startStatus"
      [statuses]="statuses"
      [filtersCount]="filtersCount"
      translationPath="Transaction.StatusFilter."
      (openFilters)="openFilters()"
      (statusChange)="onStatusChanged($event)"
    >
    </app-status-filter>
  </div>
  <div *ngIf="!empty">
    <div *ngFor="let item of dealsByDate | keyvalue: dateOrder">
      <div class="wv-section-header">
        <div class="wv-section-header__title">
          {{ item.key | date: "dd.MM.yyyy" }}
        </div>
      </div>
      <app-exchange-history-item
        *ngFor="let deal of item.value"
        [deal]="deal"
        [routerLink]="['/history/exchange', deal.systemReference]"
      >
      </app-exchange-history-item>
    </div>
  </div>
  <div class="wv-transactions-empty-list" *ngIf="empty && !loading">
    {{ (hasDeals ? "MissingData.NoSearchResults" : "MissingData.ExchangeHistory") | translate }}
  </div>
</div>
<app-exchange-history-filters #filters (filterEvent)="onFilter()"></app-exchange-history-filters>
