import { Component, OnInit } from "@angular/core";
import { WebViewService } from "../../common/mobile/webview.service";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IndicativesService } from "../indicatives/indicatives.service";
import { GraphService } from "./graph.service";
import { GraphPoint, GraphButtonConfig } from "./graph.model";
import * as Highcharts from "highcharts/highstock";
import { forkJoin } from "rxjs";
import { FormInitialState } from "@components/exchange/exchange.model";
import { filter, take, switchMap, tap } from "rxjs/operators";
import { floor, ceil } from "@utils/number.utils";
import darkTheme from './themes/graph.dark.theme';
import lightTheme from './themes/graph.light.theme';
import { UserService } from "@common/_core/session/user-service";


@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.scss"]
})
export class GraphComponent implements OnInit {
  public loading: boolean = true;
  public hasData: boolean;
  public pairSelection: boolean = false;

  public form: FormGroup;

  private _data: GraphPoint[];

  private _config: GraphButtonConfig[];

  public Highcharts: typeof Highcharts = Highcharts; // required
  public chartOptions: Highcharts.Options; // required

  public chartCallback: Highcharts.ChartCallbackFunction = chart => {

    setTimeout(() => {
      chart.rangeSelector.buttons
        .map(o => o.element)
        .forEach((x, i) => {
          const p = x.ontouchstart;
          x.ontouchstart = e => {
            this.graphService.graphRange = i;
            p(e);
          };
          this.updateFlag = false;
        });
    }, 0);
  }; // optional function, defaults to null
  public updateFlag: boolean = false; // optional boolean
  public oneToOneFlag: boolean = true; // optional boolean, defaults to false
  public runOutsideAngularFlag: boolean = false; // optional boolean, defaults to false

  constructor(
    private webViewService: WebViewService,
    private indicativeService: IndicativesService,
    private graphService: GraphService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private userService: UserService

  ) {
  }

  get darkMode(): boolean {
    return this.userService.appData.isDarkMode;
  }

  get side() {
    return this.form.get("side");
  }

  get pair() {
    return this.graphService.graphPair;
  }

  get currency() {
    return this.graphService.graphCurrencies[0];
  }

  get counterCurrency() {
    return this.graphService.graphCurrencies[1];
  }

  get initialState() {
    return {
      returnPage: "graph",
      initialData: {
        side: this.graphService.graphSide,
        currency: this.currency,
        counterCurrency: this.counterCurrency,
        tenor: "TOD"
      } as FormInitialState
    };
  }

  ngOnInit(): void {
    this.darkMode ? darkTheme(Highcharts) : lightTheme(Highcharts);

    this.webViewService.updateScreen("graph");

    const setDefaults = (history.state.returnPage === "dashboard" || !this.graphService.graphPair) && !history.state.pair;

    if (history.state.pair) {
      this.graphService.graphPair = history.state.pair;
      this.graphService.graphSide = "Sell";
      this.graphService.graphRange = 0;
    }

    if (setDefaults) {
      this.graphService.graphSide = "Sell";
      this.graphService.graphRange = 0;
    }

    this.createGroup();
    this.onChanges();

    this.indicativeService.pairs.pipe(
      filter(x => x && x.length > 1),
      take(1),
      tap(x => {
        if (setDefaults) {
          this.graphService.graphPair = (x.filter(x => x.isSelected)[0] || { code: "EURPLN" }).code
        }
      }),
      switchMap(() => forkJoin([
        this.graphService.getConfig(),
        this.graphService.getData(this.graphService.graphPair)])))
      .subscribe(([config, data]) => {
        this._config = config;
        this._data = data;
        this.hasData = data.length > 0;
        this.chartOptions = this.getChartOptions();
        this.updateData();
      });
  }

  onChanges() {
    this.side.valueChanges.pipe(filter(() => !this.updateFlag)).subscribe(value => {
      this.graphService.graphSide = value;
      this.updateData();
    });
  }

  openInfo() {
    this.webViewService.showInfotip(
      this.translate.instant("Graphs.MoreInfoMessage"),
      this.translate.instant("Graphs.MoreInfoHeader")
    );
  }

  private updateData() {
    this.loading = true;
    setTimeout(() => {

      this.chartOptions.series = [
        {
          name: this.translate.instant(this.side.value),
          data: this._data.map(x => [x.time, this.side.value === "Sell" ? x.bid : x.ask]),
          color: this.getSeriesColor()
        }
      ];

      this.chartOptions.rangeSelector.selected = this.graphService.graphRange;
      this.updateFlag = true;
      this.loading = false;
    }, 0);

  }

  private createGroup(): void {
    this.form = this.formBuilder.group({
      side: [this.graphService.graphSide, Validators.required]
    });
  }

  private getChartOptions(): Highcharts.Options {
    return {
      global: {
        useUTC: true
      },
      credits: {
        enabled: false
      },
      tooltip: {
        borderColor: "#bababa",
        borderRadius: 4,
        borderWidth: 1,
        headerFormat:
          '<span style="display: block; font-size: 11px; color: #8d8d8d; margin-bottom: 4px;">{point.x:%d.%m.%Y; %H:%M}</span><table>',
        pointFormatter: function () {
          return (
            '<tr ><td style="padding-right: 20px"><b>' +
            this.series.name +
            '</b></td> <td style="color:' +
            this.color +
            '; text-align: right"><b>' +
            (this.side === "Sell" ? floor(this.y, 4) : ceil(this.y, 4)).toFixed(4) +
            "</b></td></tr>"
          );
        },
        positioner: function (boxWidth, boxHeight, point) {
          const { x, y } = this.getPosition(boxWidth, boxHeight, point);
          return { x, y: y - 10 };
        },
        shadow: false,
        useHTML: true,
        footerFormat: "</table>",
        shared: true,
        dateTimeLabelFormats: {
          millisecond: "%d/%m/%Y %H:%M:%S.%L",
          second: "%d/%m/%Y %H:%M:%S",
          minute: "%d/%m/%Y %H:%M",
          hour: "%d/%m/%Y %H:%M",
          day: "%d/%m/%Y",
          week: "%d/%m/%Y",
          month: "%d/%m/%Y",
          year: "%d/%m/%Y"
        }
      },
      yAxis: {
        title: "none",
        //minPadding: 20,
        align: "right",
        x: 0,
        labels: {
          format: '{value:.4f}'
        },
        opposite: false
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      chart: {
        marginTop: 5,
        marginBottom: 100,
        alignTicks: true,
        zoomType: "",
        pinchType: "",
        panning: false
      },
      xAxis: {
        ordinal: false,
        breaks: [{
          from: Date.UTC(2020, 0, 11, 0, 0, 30, 0),
          to: Date.UTC(2020, 0, 12, 23, 59, 30, 0),
          repeat: 7 * 24 * 36e5,
          breakSize: 4e5
        }],
        labels: {
          useHTML: true,
          style: {
            width: "50px",
            whiteSpace: "normal"
          },
          formatter: function () {
            let value = Highcharts.dateFormat(this.dateTimeLabelFormat, this.value);

            if (this.chart.rangeSelector.selected === 0) {
              value = Highcharts.dateFormat("%d/%m/%Y %H:%M", this.value);

            } else {
              value = Highcharts.dateFormat("%d/%m/%Y", this.value);
            }

            const v = value.split(" ");

            if (v.length > 1) {
              return `<div style="text-align: center">${v[0]}<br/>${v[1]}</div>`;
            } else {
              return `<div>${value}</div>`;
            }
          },
          reserveSpace: true,
          allowOverlap: false,
          y: 30
        },
        tickPositioner: function () {
          var positions = [],

            tick = Math.floor(this.min),
            increment = Math.ceil((this.max - this.min) / 2);

          if (this.max !== null && this.min !== null) {
            for (tick; tick - increment <= this.max; tick += increment) {
              positions.push(tick);
            }
          }

          return positions;
        }
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          marker: {
            symbol: "circle"
          }
        }
      },
      rangeSelector: {
        selected: this.graphService.graphRange,
        allButtonsEnabled: true,
        buttonPosition: {
          x: ((document.getElementsByClassName("wv")[0] as HTMLElement).clientWidth - 302) / 2,
          y: 350
        },
        buttons: this.graphButtons(),
        buttonSpacing: 10,
        inputStyle: {
          display: "none"
        },
        labelStyle: {
          display: "none"
        }
      },
      series: [
        {
          name: "sell",
          data: [],
          color: "#e4172c"
        }
      ]
    };
  }

  graphButtons() {
    const dg = units => {
      return {
        approximation: "open",
        dateTimeLabelFormats: this.getDateTimeLabelFormats(),
        forced: true,
        enabled: true,
        units: units
      };
    };

    const bc = this._config;
    return [
      {
        type: bc[0].interval,
        count: bc[0].count,
        text: this.translate.instant("Graphs.Buttons.Day"),
        dataGrouping: dg([["minute", [5]]])
      },
      {
        type: bc[1].interval,
        count: bc[1].count,
        text: this.translate.instant("Graphs.Buttons.Week"),
        dataGrouping: dg([["hour", [1]]])
      },
      {
        type: bc[2].interval,
        count: bc[2].count,
        text: this.translate.instant("Graphs.Buttons.Month1"),
        dataGrouping: dg([["hour", [8]]])
      },
      {
        type: bc[3].interval,
        count: bc[3].count,
        text: this.translate.instant("Graphs.Buttons.Month3"),
        dataGrouping: dg([["day", [1]]])
      },
      {
        type: bc[4].interval,
        count: bc[4].count,
        text: this.translate.instant("Graphs.Buttons.Month12"),
        // dataGrouping: dg([["day", [1]]])
        dataGrouping: dg([["week", [1]]])
      }
    ];
  }

  getDateTimeLabelFormats() {
    return {
      millisecond: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M"],
      second: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S"],
      minute: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M:%S"],
      hour: ["%d.%m.%Y %H:%M", "%d.%m.%Y %H:%M"],
      day: ["%d.%m.%Y", "%d.%m.%Y"],
      week: ["%d.%m.%Y", "%d.%m.%Y"],
      month: ["%d.%m.%Y", "%d.%m.%Y"],
      year: ["%b/%Y", "%b/%Y"]
    };
  }

  selectPair() {
    this.pairSelection = true;
  }

  onPairSelected(pair: string) {
    this.pairSelection = false;
    this.webViewService.updateScreen("graph");
    if (!pair) {
      return;
    }

    this.graphService.graphPair = pair;
    this.graphService.getData(pair).subscribe(data => {
      this._data = data;
      this.hasData = data.length > 0;
      this.chartOptions = this.getChartOptions();
      this.updateData();
    });
  }

  private getSeriesColor() {
    return this.darkMode
      ? (this.side.value === "Sell" ? "#006EF5" : "#e4172c")
      : (this.side.value === "Sell" ? "#004c9a" : "#e4172c");
  }
}
