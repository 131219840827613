import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { WebViewService } from "../../../common/mobile/webview.service";
import { DepositDetails } from "../deposit-history.model";
import { DepositService } from "../deposit-history.service";
import { Message } from "../../message/message.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-deposit-details",
  templateUrl: "./deposit-details.component.html"
})
export class DepositDetailsComponent implements OnInit {
  private depositId: string;
  public details: DepositDetails;
  public loading: boolean = true;
  public terminating: boolean;
  public errorText: string;
  public message: Message;
  public success: boolean = false;

  private _shouldRefresh: boolean = false;
  private _returnPage: "dashboard" | "history";

  @ViewChild("container")
  container: ElementRef;

  get shouldButtonBeSticky(): boolean {
    return this.container && window.screen.height > this.container.nativeElement.offsetHeight + 100;
  }

  constructor(
    private depositService: DepositService,
    private activatedRoute: ActivatedRoute,
    private webViewService: WebViewService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.webViewService.updateScreen("depositDetail", () => this.back());
    this._returnPage = history.state.returnPage || "history";
    this._shouldRefresh = history.state.isFromSuccessPage === "true";
    this.depositId = this.activatedRoute.snapshot.paramMap.get("id");
    this.load(false);
  }

  load(forceRefresh: boolean) {
    this.loading = true;
    this.terminating = false;
    this.depositService.review(this.depositId, forceRefresh).subscribe(
      result => {
        this.details = result;
      },
      error => console.error(error),
      () => (this.loading = false)
    );
  }

  showTerminationDialog() {
    /*                                          // <<-------- NOWE stare
    this.webViewService.showBottomSheet({
      title: "Deposit.TerminationQuestion",
      primaryButton: { text: "Yes", callback: () => this.terminate() },
      additionalButton: { text: "No" }
    });
    */
  }

  terminate() {
    this.terminating = true;
    this.depositService.terminate(this.details.id).subscribe(
      result => {
        console.log("Deposit termination succeeded");
        if (result.success) {
          this.load(true);
          this._shouldRefresh = true;
          this.success = true;
        } else {
          this.showTerminationError(
            result.error == "DepositTerminationRestricted"
              ? this.translate.instant("Deposit.DepositTerminationErrorOutsideWorkingHours", result.workingHours)
              : this.translate.instant("Deposit.DepositTerminationErrorGeneric")
          );
        }
      },
      () => {
        this.showTerminationError(this.translate.instant("Deposit.DepositTerminationErrorGeneric"));
      }
    );
  }

  showTerminationError(description: string) {
    this.message = {
      header: this.translate.instant("Deposit.DepositTerminationErrorHeader"),
      description: description,
      success: false,
      error: true,
      buttons: [
        {
          text: "Ok",
          isDefault: true,
          click: () => (this.message = null)
        }
      ]
    };
    setTimeout(() => (this.terminating = false), 400);
  }

  back() {
    this.success = false;
    if (this._returnPage === "dashboard") {
      this.webViewService.navigateFromWebView(["/"]);
    } else {
      this.webViewService.navigateFromWebView(["/history/deposit"], { state: { noRefresh: !this._shouldRefresh } });
    }
  }
}
