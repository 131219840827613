import { DOCUMENT, ViewportScroller } from "@angular/common";
import { Component, Inject, OnInit, Renderer2, HostListener } from "@angular/core";
import { Router, RouterOutlet, Scroll } from "@angular/router";
import { filter } from "rxjs/operators";

import { UserService } from "@common/_core/session/user-service";
import { slideRouteAnimation } from "./common/animations/animations";
import { isAndroid, isIOS } from "./common/mobile/webview.model";
import { FakeBackendService } from "@common/fake-backend/fake-backend.utils";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  animations: [slideRouteAnimation],
})
export class AppComponent implements OnInit {
  private _darkMode: boolean;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _renderer: Renderer2,
    userService: UserService,
    router: Router,
    viewportScroller: ViewportScroller,
    public fakeBackend: FakeBackendService        // <<-------- NOWE
  ) {
    this.ensureNavigationScroll(router, viewportScroller);

    this._darkMode = userService.appData.isDarkMode;
  }

  ngOnInit(): void {
    const { body } = this._document;
    if (this._darkMode) {
      this._renderer.addClass(body, "dark");
    }

    console.error("AppComponent isAndroid() = ", isAndroid())         // <<-------- NOWE
    console.error("AppComponent isIOS() = ", isIOS())
    this._renderer.addClass(body, isAndroid() ? "android" : "ios");
  }

  @HostListener("window:resize", ["$event"])
  handleKeyboardOpen() {
    if (this._document.activeElement.tagName !== "INPUT") {
      return;
    }

    setTimeout(() => {
      this._document.activeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  /**
   * Ensures scrollPositionRestoration works.
   * It's an open issue on Github:
   * @see https://github.com/angular/angular/issues/24547
   * @todo overflow-y: scroll on html, body makes position always to be [0,0], so this still won't work :/
   */
  ensureNavigationScroll(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      if (e.position) {
        // backward navigation
        setTimeout(() => viewportScroller.scrollToPosition(e.position), 100);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
