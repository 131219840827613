import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { slideBottomAnimationWithoutInitial } from "@common/animations/animations";
import { WebViewService } from "@common/mobile/webview.service";
import {
  countDirtyControls,
  markAsPristineWhenDefault,
  resetFieldWhenDefault,
  setRangeFormField,
  setSingleField,
} from "@utils/form.utils";
import { ExchangeHistoryService } from "../exchange-history.service";

@Component({
  selector: "app-exchange-history-filters",
  templateUrl: "./exchange-history-filters.component.html",
  styleUrls: ["./exchange-history-filters.component.scss"],
  animations: [slideBottomAnimationWithoutInitial],
})
export class ExchangeHistoryFiltersComponent implements OnInit {
  private _filtersOpen: boolean = false;

  public readonly dealTypes: string[] = ["Forex", "FxOn", "FxTn", "FxSpot", "FxForw", "Swap"];
  public filterForm: FormGroup;
  public pairSelection: boolean = false;

  @Output() filterEvent = new EventEmitter<any>();

  get side() {
    return this.filterForm.get("side");
  }

  get dealType() {
    return this.filterForm.get("dealType");
  }

  get currencyPair() {
    return this.filterForm.get("currencyPair");
  }

  get currency() {
    return this.filterForm.get("currency");
  }

  get filtersOpen(): boolean {
    return this._filtersOpen;
  }

  set filtersOpen(value: boolean) {
    if (value) {
      this._wv.updateScreen("exchangeFilters", () => {
        this.filtersOpen = false;
      });
      setTimeout(() => this.setDefaultValues(), 0);
    } else {
      this._wv.updateScreen("history");
    }
    this._filtersOpen = value;
  }

  constructor(
    private _fb: FormBuilder,
    private _wv: WebViewService,
    private historyService: ExchangeHistoryService
  ) {}

  get currencies(): Observable<string[]> {
    return this.historyService.currencies;
  }

  ngOnInit(): void {
    this.createGroup();
    this.side.setValue("Any");
    this.dealType.setValue(this.dealTypes[0]);

    this.currencyPair.valueChanges.subscribe((pair: string) => {
      const currency = this.historyService.filterCurrencies(pair);
      this.currency.setValue(currency);
      if (pair) {
        this.currency.markAsDirty();
        this.currencyPair.markAsDirty();
      }
    });

    markAsPristineWhenDefault(this.currency, null);
    markAsPristineWhenDefault(this.side, "Any");
    markAsPristineWhenDefault(this.dealType, this.dealTypes[0]);
    resetFieldWhenDefault(this.filterForm.get("transactionId"));
  }

  setDefaultValues() {
    setSingleField(
      this.filterForm,
      "dealType",
      this.historyService.filters.dealType,
      this.dealTypes[0]
    );
    setSingleField(this.filterForm, "side", this.historyService.filters.side, "Any");
    setSingleField(this.filterForm, "transactionId", this.historyService.filters.transactionId);
    setSingleField(this.filterForm, "currency", this.historyService.filters.currency);
    setSingleField(this.filterForm, "currencyPair", this.historyService.selectedPair);

    setRangeFormField(this.filterForm, "amount", this.historyService.filters.amountRange);
    setRangeFormField(this.filterForm, "date", this.historyService.filters.dateRange);
    setRangeFormField(this.filterForm, "settleDate", this.historyService.filters.settlementRange);
    setRangeFormField(this.filterForm, "rate", this.historyService.filters.rateRange);
  }

  clear() {
    this.filterForm.reset();
    this.dealType.setValue(this.dealTypes[0]);
    this.side.setValue("Any");
  }

  filter() {
    this.historyService.setFilters(this.filterForm.value);
    this.historyService.filtersCount = countDirtyControls(this.filterForm.controls);
    this.filtersOpen = false;
    this.filterEvent.emit();
  }

  selectPair() {
    this.pairSelection = true;
  }

  onPairSelected(pair: string) {
    this.pairSelection = false;

    if (pair) {
      this.currencyPair.setValue(pair);
    }

    this._wv.updateScreen("exchangeFilters", () => {
      this.filtersOpen = false;
    });
  }

  createGroup() {
    this.filterForm = this._fb.group({
      transactionId: [null],
      dealType: [null],
      side: [null],
      currency: [null],
      currencyPair: [null],
      date: this._fb.group({}),
      settleDate: this._fb.group({}),
      rate: this._fb.group({}),
      amount: this._fb.group({}),
    });
  }
}
