import { AbstractControl, FormGroup } from "@angular/forms";
import { Range } from "@common/history/history.model";
import { distinctUntilChanged, filter } from "rxjs/operators";

export function countDirtyControls(controls: Record<string, AbstractControl>): number {
  return Object.entries(controls).filter(([, control]) => control.dirty).length;
}

export function getInvalidControls(form: FormGroup): Record<string, AbstractControl> {
  return Object.entries(form.controls).reduce((invalidControls, [name, control]) => {
    control.invalid && (invalidControls[name] = control);
    return invalidControls;
  }, {});
}

export function setSingleField(
  form: FormGroup,
  fieldName: string,
  value: Object,
  defaultValue?: string
) {
  if (value && value !== defaultValue) {
    form.get(fieldName).setValue(value);
    form.get(fieldName).markAsDirty();
  }
}

export function setRangeFormField(form: FormGroup, fieldName: string, value: Range<number | Date>) {
  if (!value) {
    return;
  }

  if (value.from) {
    form.get(`${fieldName}.from`).setValue(value.from);
    form.get(fieldName).markAsDirty();
  }
  if (value.to) {
    form.get(`${fieldName}.to`).setValue(value.to);
    form.get(fieldName).markAsDirty();
  }
}

export function markAsPristineWhenDefault(formControl: AbstractControl, defaultValue: any) {
  formControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      filter((value) => !value || value === defaultValue)
    )
    .subscribe(() => {
      formControl.markAsPristine();
    });
}

export function resetFieldWhenDefault(formControl: AbstractControl) {
  formControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      filter((value) => !value)
    )
    .subscribe(() => {
      formControl.reset();
    });
}
