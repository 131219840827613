<section class="my-notifications" *ngIf="dates; else loadingBlock">
  <div class="wv-container wv-container--border-bottom" [formGroup]="form">
    <div class="wv-switches-date">
      <div
        *ngFor="let date of dates; index as i"
        class="wv-switches-date__switch ripple"
        [ngClass]="{ 'wv-switches-date__switch--selected': dateIndex.value === i }"
      >
        <input
          id="notification-date-{{ i }}"
          type="radio"
          [value]="i"
          formControlName="dateIndex"
        />
        <label for="notification-date-{{ i }}">
          {{ i === 1 ? ("Notifications.Today" | translate) : (date | date: "EE, dd.MM") }}
        </label>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading; else loadingBlock">
    <app-notifications-list
      *ngIf="notifications.length; else missing"
      [notifications]="notifications"
      [limit]="limit"
    ></app-notifications-list>
    <div
      *ngIf="isToggleVisible"
      class="wv-container wv-container--border-bottom"
      (click)="toggleExpansion()"
    >
      <div class="wv-view-desktop__show-less">
        <a class="anchor">{{ (expanded ? "ShowLess" : "ShowMore") | translate }}</a>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #loadingBlock>
  <app-preloader height="50vw"></app-preloader>
</ng-template>

<ng-template #missing>
  <div class="wv-container--border-bottom wv-my-notifications-empty">
    {{ "MissingData.Notifications" | translate }}
  </div>
</ng-template>
