import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { sleep, slideBottomAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { MmDepositService } from "../deposit.service";
import { TranslateService } from "@ngx-translate/core";
import { DepositError } from "../deposit.model";

@Component({
  selector: "app-deposit-failure",
  templateUrl: "./deposit-failure.component.html",
  styleUrls: ["./deposit-failure.component.scss"],
  animations: [slideBottomAnimation]
})
export class DepositFailureComponent implements OnInit {
  @Output() public newDeposit = new EventEmitter<any>();
  @Input() public error: DepositError;

  public show: boolean = true;

  constructor(
    private depositService: MmDepositService,
    private webViewService: WebViewService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("depositModal", () => this.back());
  }

  restart(): void {
    this.show = false;
    sleep().then(() => {
      this.newDeposit.emit();
    });
  }

  back() {
    this.show = false;
    sleep().then(() => {
      this.depositService.returnToInitialPage();
    });
  }

  get message(): string {
    const data = this.error;

    if (data.failCode === 1801) {
      const match = /Product failed: (.*): (\d{2}:\d{2}):00 - (\d{2}:\d{2}):00/.exec(data.failReason);
      return this.translate.instant("Errors.1801", { type: match[1], start: match[2], end: match[3] });
    }

    let result = this.translate.instant(`Errors.${data.failReason}`);
    if (result !== `Errors.${data.failReason}`) {
      return result;
    }

    result = this.translate.instant(`Errors.${data.failCode}`);
    if (result !== `Errors.${data.failCode}`) {
      return result;
    }

    result = this.translate.instant(`Errors.${data.errorCode}`);
    if (result !== `Errors.${data.errorCode}`) {
      return result;
    }

    return this.translate.instant("Errors.GenericDescription");
  }
}
