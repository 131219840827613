<app-section-header *ngIf="title && subtitle" [title]="title | translate" [subtitle]="subtitle | translate" [noBorder]="true"></app-section-header>

<fieldset [formGroup]="group" class="wv-container wv-container--grey indicative-pairs" [disabled]="disabled">
  <div *ngFor="let item of group.value | keyvalue; trackBy: trackByKey" class="wv-input-currency-pairs-edit">
    <app-indicative-pair-item [item]="item"></app-indicative-pair-item>
    <input
      type="checkbox"
      [id]="item.key"
      [formControl]="group.controls[item.key]"
      [value]="item.value"
      class="wv-input-currency-pairs-edit__input"
    />
  </div>
</fieldset>
