import { Injectable, NgZone } from "@angular/core";
import { uid } from "src/app/utils/string.utils";
import { LogSource } from "../_core/logging/logging.model";

import { LoggingService } from "../_core/logging/logging.service";
import { NavigationService } from "../_core/navigation/navigation.service";
import { ActionItem, ActionItemDto } from "./webview.model";

@Injectable({ providedIn: "root" })
export class ActionService {
  private _actions: Map<string, Function>;
  private _return: Function;

  constructor(private _logger: LoggingService, private _navigation: NavigationService, private _zone: NgZone) {
    this._actions = new Map<string, Function>();
    window.onActionCallback = this.handleAction.bind(this);
    window.onReturnCallback = this.handleReturn.bind(this);
  }

  public setActions(actionItems: ActionItem[]): ActionItemDto[] {
    this._actions.clear();
    const items: ActionItemDto[] = [];

    for (const { text, callback } of actionItems) {
      if (!callback) {
        items.push({ text });
        continue;
      }

      const key = uid();

      items.push({ text, callback: `onActionCallback('${key}')` });
      this._actions.set(key, this.wrapCallback(callback));
    }

    return items;
  }

  public setReturn(callback?: Function): string {
    this._return = callback ? this.wrapCallback(callback) : () => this._navigation.back();
    return "onReturnCallback()";
  }

  private handleAction(key: string) {
    this.execute({
      callback: this._actions.get(key),
      errorMessage: `Unknown action key: ${key}`
    });
  }

  private handleReturn() {
    this.execute({
      callback: this._return,
      errorMessage: "Return action not set"
    });
  }

  private execute({ callback, errorMessage }): void {
    if (!callback) {
      this._logger.logError(LogSource.ApiJs, errorMessage);
      return;
    }
    this._logger.logDebug(LogSource.ApiJs, `Callback executed: ${callback}`);

    callback();
  }

  private wrapCallback(callback: Function): Function {
    return () => this._zone.run(() => callback());
  }
}
