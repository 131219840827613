import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MessageNotificationItem } from "../notifications/notifications.model";

@Injectable({
  providedIn: "root"
})
export class MessageService {
  selectedMessage: MessageNotificationItem;
  constructor(private http: HttpClient) {}

  archiveMessage(id: number): Observable<any> {

    return this.http.post<any>("/messages/archive", id);
  }

  readAndAcceptMessages(ids: string[]): Observable<any> {

    return this.http.post<any>("/messages/read", ids);
  }
}
