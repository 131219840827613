export default function (Highcharts) {
  Highcharts.theme = {
    tooltip: {
      style: {
        color: "#585858",
        cursor: "default",
        fontSize: "13px",
        pointerEvents: "none",
        whiteSpace: "nowrap",
        fontFamily: "PKOBankPolski-Regular"
      }
    },

    rangeSelector: {
      buttonTheme: {
        height: 12,
        width: "auto",
        padding: 14,
        fill: "none",
        stroke: "#dedede",
        "stroke-width": 1,
        r: 0,
        style: {
          color: "#004c9a",
          borderWidth: 1
        },
        states: {
          hover: {
            fill: "white",
            style: {
              color: "#585858",
              fontWeight: "normal"
            }
          },
          select: {
            fill: "#f2f2f2",
            style: {
              color: "black",
              fontWeight: "normal"
            }
          }
        }
      }
    }
  };

  // Apply the theme
  Highcharts.setOptions(Highcharts.theme);

}
