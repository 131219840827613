import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroupDirective, Validators } from "@angular/forms";
import { merge, Subject } from "rxjs";
import { pairwise, takeUntil, filter, map } from "rxjs/operators";
import { Currency } from "src/app/common/dealing/dealing.model";
import { FormGroupComponent } from "../_form-group/form-group.component";

@Component({
  selector: "app-select-currency-pair",
  templateUrl: "select-currency-pair.component.html"
})
export class SelectCurrencyPairComponent extends FormGroupComponent implements OnInit, OnDestroy {
  @Input() set dealCurrencies(value: Currency[]) {
    this._dealCurrencies = value;
    value?.length && this.updateCurrency(this.dealCurrency, this.dealCurrencies);
  }

  @Input() set counterCurrencies(value: Currency[]) {
    this._counterCurrencies = value;
    /*
    this._counterCurrencies = [
      {code: "EUR", decimals: 2, dealingDays: '', holidays: []},
      {code: "USD", decimals: 2, dealingDays: '', holidays: []},
      {code: "PLN", decimals: 2, dealingDays: '', holidays: []},  
      {code: "CHF", decimals: 2, dealingDays: '', holidays: []},  
      {code: "GBP", decimals: 2, dealingDays: '', holidays: []}  
    ] 
    */
    value?.length && this.updateCurrency(this.counterCurrency, this.counterCurrencies);
  }

  private _dealCurrencies: Currency[];
  private _counterCurrencies: Currency[];

  private readonly destroy$ = new Subject();

  constructor(_groupDirective: FormGroupDirective, private _fb: FormBuilder) {
    super(_groupDirective);
  }

  get dealCurrency() {
    return this.group?.get("dealCurrency") as FormControl;
  }

  get counterCurrency() {
    return this.group?.get("counterCurrency") as FormControl;
  }

  get dealCurrencies(): Currency[] {
    return this._dealCurrencies;
  }

  get counterCurrencies(): Currency[] {
    return this._counterCurrencies;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addControls();
    this.subscribeToSwap();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private addControls() {
    for (const name of ["dealCurrency", "counterCurrency"]) {
      this.group.addControl(name, this._fb.control([null, Validators.required]));
    }
  }

  private updateCurrency(control: FormControl, currencies: Currency[]) {
    if (control === undefined || control === null) {
      return;
    }

    const newValue = currencies?.find(x => x.code === control.value?.code);
    control.setValue(newValue ?? (currencies.length > 1 && currencies[1]));
  }

  private subscribeToSwap() {
    merge(...[this.observeSwap("deal", "counter"), this.observeSwap("counter", "deal")]).subscribe(
      ({ destination, previousValue }) => {
        const update = this[`${destination}Currencies`].find((x: Currency) => x.code === previousValue.code);
        this[`${destination}Currency`].setValue(update);
      }
    );
  }

  private observeSwap(source: string, destination: string) {
    return this[`${source}Currency`].valueChanges.pipe(
      takeUntil(this.destroy$),
      pairwise(),
      filter(([prev, next]) => next && prev?.code !== next.code && next.code === this[`${destination}Currency`].value?.code),
      map(([previousValue]) => ({ destination, previousValue }))
    );
  }
}
