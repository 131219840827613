import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ForexRate, FxSpot, FxSpotDto, InitState } from "../exchange.model";
import { FxSpotService } from "../exchange.service";
import { WebViewService } from "../../../common/mobile/webview.service";
import { Message } from "../../message/message.model";
import { slideRightAnimation } from "../../../common/animations/animations";
import { UserService } from "../../../common/_core/session/user-service";
import { TranslateService } from "@ngx-translate/core";
import { formatDate } from "@angular/common";
import { SessionAccountsService } from "../../../common/session/session-accounts.service";
import { difference, isSameDay } from "../../../utils/time.utils";
import { NavigationService } from "src/app/common/_core/navigation/navigation.service";
//import { timeStamp } from "console";

@Component({
  selector: "app-exchange-quote",
  templateUrl: "./exchange-quote.component.html",
  styleUrls: ["./exchange-quote.component.scss"],
  animations: [slideRightAnimation]
})
export class ExchangeQuoteComponent implements OnInit {
  private _rateCaughtTime: Date;
  private _countdownInterval: ReturnType<typeof setInterval>;
  private _dealGuid: string;
  private _rateGuid: string;
  private _totalDecisionTime: number;
  private _working: boolean;
  private _fromRefresh: boolean;
  public data: FxSpot;
  public confirming: boolean;
  public rate: number;
  public counterAmount: number;
  public collateralAmount: number;
  public decisionTime: number;
  public loading: boolean;
  public insufficientFunds: boolean;
  public balance: number;
  public message: Message;
  public showRefresh: boolean;
  public success: boolean;
  public dealerWait: boolean;
  public isFirstRate: boolean;

  @Output() public newDeal = new EventEmitter<any>();
  @Output() public closed = new EventEmitter<any>();

  public get working(): boolean {
    return this._working;
  }

  public set working(value: boolean) {
    this._working = value;
    if (!value) {
      this._fromRefresh = false;
    }
    this.fxSpotService.working.next(value);
  }

  public get timeEnd(): boolean {
    return this.decisionTime <= Math.ceil(this._totalDecisionTime / 4);
  }

  constructor(
    public userService: UserService,
    private fxSpotService: FxSpotService,
    private webViewService: WebViewService,
    private navigation: NavigationService,
    public translate: TranslateService,
    private sessionaccountsService: SessionAccountsService
  ) {}

  ngOnInit(): void {}

  startGetQuote(data: FxSpot) {
    this.webViewService.updateScreen("fxConfirmation", this.back.bind(this));
    this.isFirstRate = true;
    this.showRefresh = false;
    this.working = true;
    this.loading = true;
    this.confirming = false;
    this.data = data;
    this.getQuote();
    
  }

  back() {
    if (this.confirming) {
      return;
    }

    if (this.showRefresh) {
      this._fromRefresh = false;
    }

    if (this._dealGuid) {
      this.reject();
    } else {
      this.reset();
    }
    this._fromRefresh = false;
  }

  openNewExchange() {
    this._fromRefresh = false;
    this.success = false;
    this.reset();
    this.newDeal.emit();
  }

  getDto(data: FxSpot): FxSpotDto {
    const { dealAccount, counterAccount, collateralAccount, ...dto } = data;
    return {
      ...dto,
      dealAccount: dealAccount?.number,
      counterAccount: counterAccount?.number,
      collateralAccount: collateralAccount?.number      
    };
  }

  getQuote(state: InitState = null): void {  
    this.isFirstRate = true;
    const data = state ? { ...this.data, ...state } : this.data;
    this.fxSpotService.initialize(this.getDto(data)).subscribe(
      result => {
        if (result.errorCode) {
          this.handleError(result, state);
        } else {
          this._dealGuid = result.guid;
          this.getRate(result.guid);
        }
      },
      () => {
        this.handleError({ errorCode: "GENERIC" }, state);
      }
    );
  }

  isGetRateBlocked(guid: string) {
    return !this.working || this.confirming || !this._dealGuid || this._dealGuid !== guid;
  }

  getRate(guid: string) {
    if (this.isGetRateBlocked(guid)) {
      return;
    }

    this.fxSpotService.getRate(guid).subscribe(
      result => {
        if (this.isGetRateBlocked(guid)) {
          return;
        }
        if (result.failCode || result.failReason) {
          clearInterval(this._countdownInterval);
          this.handleError(result);
        } else {
          switch (result.responseType) {
            case "RETRY":
              setTimeout(() => this.getRate(guid), 500);
              this.setBalance(result);
              return;
            case "DEALERWAIT":
              this.isFirstRate = false;
              this._dealGuid = result.dealGuid;
              this.insufficientFunds = false;
              clearInterval(this._countdownInterval);
              this.dealerWait = true;
              setTimeout(() => this.getRate(guid), 500);
              return;
            case "PRICE":
              this.isFirstRate = false;
              this.loading = false;
              this.dealerWait = false;
              this.message = null;
              this.rate = parseFloat(result.rate);
              this.counterAmount = parseFloat(result.counterAmount);
              this.collateralAmount = parseFloat(result.collateralAmount);
              if (result.isFirstRate) {
                this.decisionTime = this._totalDecisionTime = parseInt(result.decisionTime);
                this._rateCaughtTime = new Date();
                this.countdown();
              }
              this._dealGuid = result.dealGuid;
              this._rateGuid = result.rateGuid;
              this.setBalance(result);
              setTimeout(() => this.getRate(guid), 500);
          }
        }
      },
      () => this.handleError({ errorCode: "GENERIC" })
    );
  }

  setBalance(result: ForexRate) {
    if (result.insufficientFunds) {
      this.insufficientFunds = true;
      this.balance = result.balance;
      if (result.isFirstRate && result.showBalanceWarning) {
        this.handleError({ errorCode: "BalanceWarning" });
      }
    } else {
      this.insufficientFunds = false;
      this.balance = null;
    }
  }

  countdown() {
    const progressBar = ".wv-summary-modal__action-timer--active";
    const el = document.querySelector(progressBar),
      newone = el.cloneNode(true) as Element;

    const prev = el.previousSibling as Element;
    el.remove();

    newone.setAttribute(
      "style",
      `animation-duration: ${this._totalDecisionTime}s; -webkit-animation-duration: ${this._totalDecisionTime}s`
    );

    prev.after(newone);

    this._countdownInterval = setInterval(() => {
      const expired = difference(new Date(), this._rateCaughtTime, "seconds");
      this.decisionTime = Math.round(this._totalDecisionTime - expired);

      if (this.decisionTime <= 0) {
        this._fromRefresh = true;
        this.reject();
      }
    }, 1000);
  }

  reject() {
    this.working = this._fromRefresh;
    this.loading = true;
    this.showRefresh = true;
    this.insufficientFunds = false;
    this.message = null;
    this.dealerWait = false;
    clearInterval(this._countdownInterval);
    this.fxSpotService.reject(this._dealGuid).subscribe(
      () => {
        console.log("Rejected");
      },
      msg => {
        console.log("Reject failed", msg);
      }
    );
    this._dealGuid = "";
  }

  reset() {
    this.working = this._fromRefresh;
    this.loading = true;
    this.showRefresh = true;
    this.insufficientFunds = false;
    this.message = null;
    this.dealerWait = false;
    this.webViewService.updateScreen("exchange", () => this.fxSpotService.returnToInitialPage());
    clearInterval(this._countdownInterval);
  }

  confirm() {
    this.confirming = true;
    clearInterval(this._countdownInterval);
    this.fxSpotService.confirm(this._dealGuid, this._rateGuid).subscribe(
      result => {
        if (result) {
          this.getStatus();
        } else {
          this.confirming = false;
          this.handleError({ errorCode: "GENERIC" });
        }
      },
      () => {
        this.confirming = false;
        this.handleError({ errorCode: "GENERIC" });
      }
    );
  }

  closeResult() {
    if (this.data.isDpw && this.fxSpotService.returnPage === "dpw") {
      const success = this.success;
      this._fromRefresh = false;
      this.success = false;
      this.reset();

      if (success) this.closed.emit(this.data.dpwNumber);
      return;
    }

    this.fxSpotService.returnToInitialPage();
  }

  getStatus() {
    this.fxSpotService.getStatus(this._dealGuid).subscribe(
      result => {
        if (!result) {
          setTimeout(() => this.getStatus(), 500);
        } else {
          if (result.errorCode === "CONFIRMED") {
            this.webViewService.updateScreen("exchangeModal", () => this.closeResult());
            this.message = {
              header: "",
              success: true,
              data: { ...this.data, counterAmount: this.counterAmount, rate: this.rate, dealId: result.transactionId }
            };
            this.success = true;
            this.storeSessionAccounts();
          }
          else {
            this.handleError(result);
          }
          this.confirming = false;
        }
      },
      () => {
        this.confirming = false;
        this.handleError({ errorCode: "UNKNOWN" });
      }
    );
  }

  private storeSessionAccounts() {
    if (isSameDay(this.data.settlementDate, new Date()) || this.data.dealType === "FXON") {
      this.sessionaccountsService.store([this.data.dealAccount?.number, this.data.counterAccount?.number]);
    }
  }

  private handleError(data: any, state: InitState = null): void {
    this.isFirstRate = false;
    if (data.failReason === "InsufficientFundsMessage") {
      this.webViewService.updateScreen("exchangeModal", () => this.fxSpotService.returnToInitialPage());
      this.message = {
        header: this.translate.instant("Errors.InsufficientFunds"),
        description: this.translate.instant("Errors.InsufficientFundsMessage"),
        success: false,
        error: false,
        info: true,
        buttons: [
          {
            text: "GoToProfile",
            isDefault: false,
            click: () => this.navigation.navigate(["/settings"])
          },
          {
            text: "NextDeal",
            click: () => this.openNewExchange()
          }
        ]
      };
      return;
    }

    const questionCodes: string[] = ["LeiExpired", "OutOfTargetMarket", "HasSimilarDeal", "BalanceWarning"];
    let code = data.failReason || data.errorCode;
    if (questionCodes.some(x => x === code)) {
      let header = "";
      let getQuote: Function, rejectQuote: Function;
      const content = { code, data: {} };
      switch (code) {
        case "LeiExpired":
          (content.data = {
            code: data.additionalData?.Code,
            date: formatDate(new Date(data.additionalData?.ExpirationDate), "dd-MM-yyyy", this.userService.appData.language)
          }),
            (content.code = code);
          header = "ExtendLei";
          getQuote = () => this.getQuote(state || ({ leiAccepted: true } as InitState));
          rejectQuote = () => this.reset();
          break;
        case "OutOfTargetMarket":
          getQuote = () => this.getQuote({ ...(state || {}), targetMarketAccepted: true } as InitState);
          rejectQuote = () => this.reset();
          content.code = "TargetMarketText";
          header = "TargetMarketTitle";
          break;
        case "HasSimilarDeal":
          getQuote = () => this.getQuote({ ...(state || {}), similarDealsAccepted: true } as InitState);
          rejectQuote = () => this.reset();
          content.code = "SimilarDealsText";
          break;
        case "BalanceWarning":
          getQuote = () => (this.message = null);
          rejectQuote = () => this.reject();
          content.code = "InsufficientFundsQuestion";
          header = "InsufficientFundsQuestionHeader";
          break;
      }

      this.webViewService.showBottomSheet({
        title: header !== "" ? `Questions.Fx.${header}` : header,
        text: this.translate.instant(`Questions.Fx.${content.code}`, content.data),
        primaryButton: { text: "Yes", callback: getQuote },
        additionalButton: { text: "Resign", callback: rejectQuote },
        onClose: rejectQuote
      });

      return;
    }

    if (code == "GENERIC") {
      code = "GenericDescription";
    }

    this.webViewService.updateScreen("exchangeModal", () => this.fxSpotService.returnToInitialPage());
    this.message = {
      header: this.translate.instant("Errors.GenericTitle"),
      description: this.getSpecificErrors(data) || this.getErrorMessage(data),
      success: false,
      error: true,
      buttons: [
        {
          text: "Retry",
          isDefault: false,
          click: () => this.reset()
        },
        {
          text: "Ok",
          isDefault: true,
          click: () => this.fxSpotService.returnToInitialPage()
        }
      ]
    };
  }

  private getErrorMessage(data: any): string {
    let result = this.translate.instant(`Errors.${data.failReason}`);
    if (result !== `Errors.${data.failReason}`) {
      return result;
    }
    result = this.translate.instant(`Errors.${data.failCode}`);
    if (result !== `Errors.${data.failCode}`) {
      return result;
    }
    result = this.translate.instant(`Errors.${data.errorCode}`);
    if (result !== `Errors.${data.errorCode}`) {
      return result;
    }
    return this.translate.instant("Errors.GenericDescription");
  }

  private getSpecificErrors(data: any): string {
    if (data.failCode === 1801) {
      const match = /Product failed: (.*): (\d{2}\\:\d{2}):00 - (\d{2}\\:\d{2}):00/.exec(data.failReason);
      return this.translate.instant("Errors.1801", { type: match[1], start: match[2], end: match[3] });
    }

    if (data.failCode === 10001) {
      return this.translate.instant("Errors.10001", { start: data.failReason });
    }
    return null;
  }
}
