<ng-container *ngIf="documents$ | async as documents; else noDocumentsBlock">
  <app-section-header [title]="'Documents.Instructions' | translate"></app-section-header>
  <app-document-item *ngFor="let instruction of documents.instructions" [document]="instruction"> </app-document-item>
  <app-section-header [title]="'Documents.Regulations' | translate"></app-section-header>
  <app-document-item *ngFor="let regulation of documents.regulations" [document]="regulation"> </app-document-item>
</ng-container>

<ng-template #noDocumentsBlock>
  <app-preloader [absolute]="true"></app-preloader>
</ng-template>
