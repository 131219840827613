<div
  class="wv-global-modal wv-global-modal--info new-transaction-last-modal wv-global-modal--displayed wv-global-modal--visible"
  *ngIf="show"
  [@slideInOut]
>
  <div class="wv-global-modal__content-container">
    <div class="wv-global-modal__icon wv-global-modal__icon--success"></div>
    <div class="wv-global-modal__header" translate>Order.Success.Header</div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Order.Success.Description1</span>
        <span class="wv-global-modal__subheader--important">{{ id }}</span>
      </div>
      <div>
        <span translate>Order.Success.{{ order.side }}</span>
        <span class="wv-global-modal__subheader--important">
          {{ order.amount | number: "1.2-2" }} {{ order.dealCurrency }}
        </span>
      </div>
      <div>
        <span translate> Order.Success.{{ trailingStop ? "Points" : "Rate" }}</span>
        <span class="wv-global-modal__subheader--important">
          {{ rate | number: "1.4-4" }}
        </span>
      </div>
    </div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Order.Success.Description5</span>
        <a class="wv-text-blue" [routerLink]="['/history/order', id]" [state]="{ isFromSuccessPage: 'true'}" translate>Order.OrderLink</a>
      </div>
    </div>
  </div>
  <div class="wv-global-modal__button-container">
    <button class="wv-global-button wv-global-button--white-normal" (click)="back()" translate>OK</button>
    <button class="wv-global-button ripple" (click)="openNewOrder()" translate>Order.NextOrder</button>
  </div>
</div>
