import { Component, Input } from "@angular/core";

@Component({
  selector: "app-summary-item-date",
  templateUrl: "summary-item-date.component.html"
})
export class SummaryItemDateComponent {
  @Input() label: string;
  @Input() format: string = "dd.MM.yyyy";
  @Input() date: Date | string;

  constructor() {}
}
