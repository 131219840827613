import { Component, Input } from "@angular/core";
import { isAndroid } from "@common/mobile/webview.model";

@Component({
  selector: "app-preloader",
  templateUrl: "./preloader.component.html",
  styleUrls: ["./preloader.component.scss"],
})
export class PreloaderComponent {
  @Input() absolute: boolean;
  @Input() height: string;
  @Input() width: string;

  constructor() {}

  get isAndroid() {
    return isAndroid();
  }
}
