export function groupBy<T>(key: string | number, items: Array<T>): { [key in string | number]: T[] } {
  return items.reduce((accumulator, item) => {
    // Group initialization
    if (!accumulator[item[key]]) {
      accumulator[item[key]] = [];
    }

    // Grouping
    accumulator[item[key]].push(item);
    return accumulator;
  }, {});
}

/**
 * Compares values of two arrays.
 * @param a First array
 * @param b Second array
 * @returns whether two arrays have the same values or not.
 */
export function areEqual(a: Array<any>, b: Array<any>): boolean {
  return a.length === b.length && a.every((v, i) => v === b[i]);
}

export function removeDuplicates<T>(array: Array<T>): Array<T> {
  return [...new Set(array)];
}

/**
 * Merges given arrays removing duplicates.
 * @param arrays Arrays to merge
 * @returns A single array without duplicates.
 */
export function merge<T extends number | string>(...arrays: Array<T[]>): T[] {
  return removeDuplicates([].concat(...arrays));
}
