import { Component, Input } from "@angular/core";
import { DepositDetails } from "../deposit-history.model";

@Component({
  selector: "app-deposit-details-simple",
  templateUrl: "./deposit-details-simple.component.html"
})
export class DepositDetailsSimpleComponent {
  @Input() details: DepositDetails;

  constructor() {}
}
