import { Component, Input } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { SwitcherOption } from "../inputs.model";

@Component({
  selector: "app-input-switch",
  templateUrl: "input-switch.component.html"
})
export class InputSwitchComponent {
  @Input("arrow") showArrow: boolean;
  @Input() options: Array<SwitcherOption | string>;

  constructor(private ngControl: NgControl) {}

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
