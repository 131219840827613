import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { noCacheHeader } from "src/app/common/_core/cache/request-cache.model";
import { Deal, DealDetails, FiltersForm } from "./exchange-history.model";
import { defaultExchangeFilters, Filters } from "../../common/history/history.model";
import { shareReplay } from "rxjs/operators";
import { breakCurrencyPair, parseNumberRange } from "@common/dealing";

@Injectable({
  providedIn: "root",
})
export class ExchangeHistoryService {
  public dealsByDate: Record<string, Deal[]> = {};

  public filters: Filters = { ...defaultExchangeFilters };
  public selectedPair = "";

  public filtersCount: number = 0;
  public loading: boolean = false;
  public end: boolean = false;
  public empty: boolean = true;
  public hasDeals?: boolean = null;
  public currencies = new BehaviorSubject<string[]>([]);
  private _currencies: string[] = [];

  constructor(private http: HttpClient) {
    this.loadCurrencies();
  }

  getList(): Observable<Deal[]> {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this.http.post<Deal[]>("/history", this.filters, { headers: noCacheHeader }).subscribe(
      (result) => {
        if (this.empty && result.length > 0) {
          this.empty = false;
        }

        if (this.hasDeals === null) {
          this.hasDeals = !this.empty;
        }

        if (result.length == 0) {
          this.end = true;
          return;
        }

        this.concatenate(result);
      },
      (error) => console.error(error),
      () => {
        this.filters.pageNumber++;
        this.loading = false;
      }
    );
  }

  reset(initFilters: boolean = false): void {
    if (initFilters) {
      this.filters = { ...defaultExchangeFilters };
      this.selectedPair = "";
      this.filtersCount = 0;
    }
    this.filters.pageNumber = 1;
    this.dealsByDate = {};
    this.empty = true;
    this.end = false;
  }

  getDetails(id: string): Observable<DealDetails> {
    return this.http.get<DealDetails>(`/history/review/${id}`, { headers: noCacheHeader });
  }

  loadCurrencies(): void {
    this.http
      .get<string[]>("/settings/currencies")
      .pipe(shareReplay())
      .subscribe((currencies) => {
        this._currencies = currencies;
        this.currencies.next(currencies);
      });
  }

  filterCurrencies(pair: string): string {
    if (!pair) {
      this.currencies.next(this._currencies);
      return null;
    } else {
      const pairs = this._currencies.filter((x) => [0, 3].some((y) => x === pair.substr(y, 3)));
      this.currencies.next(pairs);
      return pairs[0];
    }
  }

  setFilters({ side, dealType, transactionId, currencyPair, currency, ...form }: FiltersForm) {

    const filters: Filters = {
      transactionId,
      dealType,
      side,
      currencyPair,
      currency,
      amountRange: parseNumberRange(form.amount),
      rateRange: parseNumberRange(form.rate),
      dateRange: form.date,
      settlementRange: form.settleDate,
      settlementStatus: this.filters.settlementStatus
    };

    this.filters = filters;
    this.selectedPair = currencyPair;
  }

  private concatenate(deals: Deal[]): void {
    deals.forEach((deal) => {
      const date = deal.dealDate.split("T")[0];
      if (this.dealsByDate[date]) {
        this.dealsByDate[date].push(deal);
      } else {
        this.dealsByDate[date] = [deal];
      }
    });
  }
}
