<div
  class="wv-my-currency-pairs__rate wv-my-currency-pairs__{{ side | lowercase }} ripple"
  [ngClass]="{ 'wv-global-input--disabled': disabled }"
  (click)="openNewDeal()"
>
  <div class="wv-my-currency-pairs__title">
    {{ side | translate }}
  </div>
  <div class="wv-my-currency-pairs__value">
    <ng-container *ngIf="!!rate; else noData">
      <span class="wv-my-currency-pairs__value-num">{{ rate | number: "1.4-4" }}</span>
      <img src="assets/wv-img/icon_currency_{{ direction }}.svg" class="wv-my-currency-pairs__icon" />
    </ng-container>
  </div>
</div>

<ng-template #noData>
  {{ "MissingData.IndicativeRate" | translate }}
</ng-template>
