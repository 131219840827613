import { Component, OnInit, Input, ContentChild, AfterContentInit } from "@angular/core";
import { timer } from "rxjs";
import { UserNotification, UserNotificationComponent } from "@components/notifications/notifications.model";


@Component({
  selector: "app-notifications-main",
  templateUrl: "./notifications-main.component.html",
  styleUrls: ["./notifications-main.component.scss"]
})
export class NotificationsMainComponent implements OnInit, AfterContentInit {
  @Input() notification: UserNotification;
  @ContentChild("child")
  public childSelector: UserNotificationComponent;

  public child: UserNotificationComponent = null;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    timer(0).subscribe(() => this.child = this.childSelector);
  }
}
