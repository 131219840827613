import { Component, OnInit } from '@angular/core';
import { ActivationService } from '../activation.service';
import { slideRightAnimation } from '../../../common/animations/animations';
import { UserService } from '../../../common/_core/session/user-service';

@Component({
  selector: 'app-activation-info',
  templateUrl: './activation-info.component.html',
  styleUrls: ['./activation-info.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationInfoComponent implements OnInit {

  constructor(public activationService: ActivationService, public userService: UserService) { }

  ngOnInit(): void {
  }
}
