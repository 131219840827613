<div class="wv-exchange-deposits ripple">
  <div class="wv-exchange-deposits__info">
    <div class="wv-exchange-deposits__header" translate>
      Deposit.Amount
    </div>
    <div class="wv-exchange-deposits__value">
      {{ deposit.amount | number:"1.2-2" }} {{ deposit.currency }}
    </div>

    <div class="wv-exchange-deposits__header" translate>
      Deposit.StartDate
    </div>
    <div class="wv-exchange-deposits__value">
      {{ deposit.startDate | date: "dd.MM.yyyy" }}
    </div>
  </div>
  <div class="wv-exchange-deposits__info">
    <div class="wv-exchange-deposits__header" translate>
      Deposit.InterestRate
    </div>
    <div class="wv-exchange-deposits__value">
      {{ deposit.interestRate | number:"1.2-2" }} %
    </div>

    <div class="wv-exchange-deposits__header" translate>
      Deposit.EndDate
    </div>
    <div class="wv-exchange-deposits__value">
      {{ deposit.endDate | date: "dd.MM.yyyy" }}
    </div>
  </div>
</div>
