import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd, NavigationExtras } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class NavigationService {
  private history: string[] = [];

  constructor(private _router: Router, private _location: Location) {
    this.subscribe();
  }

  navigate(commands: any[], extras?: NavigationExtras) {
    return this._router.navigate(commands, extras);
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this._location.back();
    } else {
      this._router.navigateByUrl("/");
    }
  }

  home(): void {
    this.history = [];
    this._router.navigateByUrl("/");
  }

  private subscribe() {
    this._router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.history.push(event.urlAfterRedirects);
      });
  }
}
