<form [formGroup]="statusFilterFormGroup">
  <div class="wv-select-block wv-select-block--filter">
    <label for="status" class="wv-select-block__label" translate>Status</label>
    <div class="wv-select-block__select-wrap wv-select-block__select-wrap--filter">
      <select id="status" class="wv-global-selects" formControlName="status">
        <option *ngFor="let stat of statuses" [ngValue]="stat">
          {{ translationPath + stat | translate }}
        </option>
      </select>
      <!-- tymczasowo zmienione na potrzeby demo  <span class="wv-select-block__filter" translate (click)="filter()">Filter</span>-->
      <span class="wv-select-block__filter" translate >Filter</span>
      <span class="wv-select-block__filter-count">{{ filtersCount }}</span>
    </div>
  </div>
</form>
