<span class="wv-input-my-currency-pairs__label-left-container">
  <img src="assets/wv-img/wv-flags/{{ baseCurrency }}.svg"
        class="wv-input-my-currency-pairs__label-img"
        alt="{{ baseCurrency }} flag"
        style="vertical-align: unset" />
  <span class="wv-input-my-currency-pairs__label-curr">{{ baseCurrency }}</span>
</span>
<span class="wv-input-my-currency-pairs__label-divider">/</span>
<span class="wv-input-my-currency-pairs__label-right-container">
  <img src="assets/wv-img/wv-flags/{{ quoteCurrency }}.svg"
        class="wv-input-my-currency-pairs__label-img"
        alt="{{ quoteCurrency }} flag"
        style="vertical-align: unset" />
  <span class="wv-input-my-currency-pairs__label-curr">{{ quoteCurrency }}</span>
</span>
