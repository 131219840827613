import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { PageData, ActivationRequest, ActivationResult, StatusResult } from "./activation.model";
import { WebViewService } from "../../common/mobile/webview.service";

@Injectable({
  providedIn: "root",
})
export class ActivationService {
  public page: number = 0;
  public pageData: PageData;
  public activationResult: ActivationResult;
  public success: boolean;
  public error: boolean;
  public canApply: boolean;

  constructor(private http: HttpClient, private webViewService: WebViewService) { }

  getPageData(): Observable<PageData> {
    const headers = { "Cache-Control": "no-cache" };
    return this.http.get<PageData>("/contract", { headers: headers })
      .pipe(tap(result => {
        this.pageData = result;
        this.canApply = result.applyAllowed;
      }));
  }

  save(data: ActivationRequest): Observable<ActivationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<any>("/contract", data, { headers: headers }).pipe(
      tap(result => {
        this.activationResult = result;
      })
    );
  }


  getStatus(): Observable<StatusResult> {
    const headers = { "Cache-Control": "no-cache", "x-no-retry": "1" };
    return this.http.get<string>(`/contract/status/${this.activationResult.applicationId}`, { headers: headers })
      .pipe(
        map(data => {
          var rejected = data ? /rejected/i.test(data) : false;
          var gateError = data ? /errorgate/i.test(data) : false;
          var success = data ? /completed/i.test(data) : false;

          return {
            success: success,
            error: rejected || gateError
          } as StatusResult;
        }),
        tap(result => {
          this.success = result.success;
          this.error = result.error;
        }));
  }

  next() {
    window.scroll(0, 0);
    this.page++;
    this.webViewService.updateScreen(this.page > 4 ? 'activationResult' : 'activation', this.page <= 4 ? () => this.back() : undefined);
  }

  back() {
    if (this.page === 0) {
      return;
    }

    window.scroll(0, 0);
    this.page--;
    this.webViewService.updateScreen(this.page > 0 ? 'activation' : 'activationStart', this.page > 0 ? () => this.back() : undefined);
  }

  showError() {
    this.page = 6;
    this.webViewService.updateScreen('activationResult');
    this.error = true;
  }

  goToStart() {
    this.page = 0;
    this.webViewService.updateScreen('activationStart');
    this.error = false;
    this.activationResult = null;
  }

  logout() {
    this.webViewService.logout();
  }
}
