import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActivationStartComponent } from "./components/activation/activation-start/activation-start.component";
import { ActivationComponent } from "./components/activation/activation.component";
import { AlertHistoryComponent } from "./components/alert-history/alert-history.component";
import { AlertComponent } from "./components/alert/alert.component";
import { CustomerComponent } from "./components/customer/customer.component";
import { DepositDetailsComponent } from "./components/deposit-history/deposit-details/deposit-details.component";
import { DepositHistoryComponent } from "./components/deposit-history/deposit-history.component";
import { DepositComponent } from "./components/deposit/deposit.component";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { DpwComponent } from "./components/dpw/dpw.component";
import { ExchangeDetailsComponent } from "./components/exchange-history/exchange-details/exchange-details.component";
import { ExchangeComponent } from "./components/exchange/exchange.component";
import { GraphComponent } from "./components/graph/graph.component";
import { HistoryComponent } from "./components/history/history.component";
import { HomeComponent } from "./components/home/home.component";
import { IndicativePairSelectionComponent } from "./components/indicatives/indicative-pair-selection/indicative-pair-selection.component";
import { MessageItemDetailsComponent } from "./components/message-item/message-item-details/message-item-details.component";
import { MoreComponent } from "./components/more/more.component";
import { NewsDetailsComponent } from "./components/news/news-details/news-details.component";
import { OrderDetailsComponent } from "./components/order-history/order-details/order-details.component";
import { OrderComponent } from "./components/order/order.component";
import { DepositSettingsComponent } from "./components/user-profile/default-settings/deposit-settings/deposit-settings.component";
import { ExchangeSettingsComponent } from "./components/user-profile/default-settings/exchange-settings/exchange-settings.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { AuthGuard } from "./guards/auth.guard";

const appRoutes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "Dashboard" },
    pathMatch: "full"
  },
  {
    path: "history",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "History" },
    component: HistoryComponent
  },
  {
    path: "settings",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "Profile" },
    component: UserProfileComponent
  },
  {
    path: "more",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "More" },
    component: MoreComponent
  },
  {
    path: "exchange",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: ExchangeComponent
  },
  {
    path: "settings/exchange",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer" },
    component: ExchangeSettingsComponent
  },
  {
    path: "customer",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer", animation: "exchange" },
    component: CustomerComponent
  },
  {
    path: "deposit",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: DepositComponent
  },
  {
    path: "settings/deposit",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer" },
    component: DepositSettingsComponent
  },
  {
    path: "alert",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: AlertComponent
  },
  {
    path: "order",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: OrderComponent
  },
  {
    path: "alerts",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: AlertHistoryComponent
  },
  {
    path: "indicative-pairs",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "indicative" },
    component: IndicativePairSelectionComponent
  },
  {
    path: "history/deposit",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: DepositHistoryComponent
  },
  {
    path: "history/deposit/:id",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "details" },
    component: DepositDetailsComponent
  },
  {
    path: "dpw",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: DpwComponent
  },
  {
    path: "history/order/:id",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "details" },
    component: OrderDetailsComponent
  },
  {
    path: "history/exchange/:id",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "details" },
    component: ExchangeDetailsComponent
  },
  {
    path: "news/:id",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer" },
    component: NewsDetailsComponent
  },
  {
    path: "message-details",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer" },
    component: MessageItemDetailsComponent
  },
  {
    path: "apply",
    canActivate: [AuthGuard],
    data: { expectedRole: "PotentialCustomer" },
    component: ActivationComponent
  },
  {
    path: "graph",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "indicative" },
    component: GraphComponent
  },
  {
    path: "documents",
    canActivate: [AuthGuard],
    data: { expectedRole: "Customer,HasCustomer", animation: "rightSlidePage" },
    component: DocumentListComponent
  },
  { path: "no-access", component: ActivationStartComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: true, // <-- debugging purposes only
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      initialNavigation: "disabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
