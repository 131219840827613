import { Component, EventEmitter, Output } from "@angular/core";

import { Account } from "@common/accounts/account.model";
import { AccountService } from "@common/accounts/account.service";
import { findDefaultAccount } from "@common/dealing/account.utils";

@Component({
  selector: "app-default-accounts",
  templateUrl: "./default-accounts.component.html"
})
export class DefaultAccountsComponent {
  @Output() selected = new EventEmitter<Account[]>();

  constructor(private accountService: AccountService) {}

  get accountsByCurrency(): Record<string, Account[]> {
    return this.accountService.defaultAccountsByCurrency;
  }

  openDefaultAccountSelection(accounts: Account[]): void {
    this.selected.emit(accounts);
  }

  getDefaultAccount(accounts: Account[]) {
    return findDefaultAccount(accounts);
  }
}
