export function floor(x: number, precision: number): number {
  const pow = Math.pow(10, precision);
  return Math.floor(x * pow) / pow;
}

export function ceil(x: number, precision: number): number {
  const pow = Math.pow(10, precision);
  return Math.ceil(x * pow) / pow;
}

/**
 * Checks if a number has assigned value
 * @param variable number to check
 * @returns true, if the variable is defined and not null.
 */
export const hasValue = (variable: number): boolean => !!variable || variable === 0;
