<app-preloader *ngIf="confirming || isFirstRate" [absolute]="true"></app-preloader>
<div class="wv-global-modal wv-global-modal--displayed wv-global-modal--visible wv-global-modal--visible-swipe-bottom wv-container wv-container--grey"
     *ngIf="working"
     [@slideInOut]="success || message || dealerWait ? 'modalOpen' : ''"
     style="padding-bottom: 280px"
     [scrollTop]="working">
  <div class="wv-container wv-container--grey">
    <app-exchange-quote-details [loading]="loading"
                                [details]="data"
                                [rate]="rate"
                                [counterAmount]="counterAmount"
                                [collateralAmount]="collateralAmount"></app-exchange-quote-details>

    <div class="wv-summary-modal__action-container" [hidden]="loading || confirming">
      <div class="wv-summary-modal__action-text">
        {{ "TimeInfo.YetFor" | translate }}
        <span class="wv-summary-modal__action-text--timer-container wv-bold" [class]="timeEnd ? 'wv-text-red' : 'wv-text-blue'">
          <span class="wv-summary-modal__action-text--timer wv-bold" [class]="timeEnd ? 'wv-text-red' : 'wv-text-blue'">
            {{ "TimeInfo.TimeLeft" | translate: { time: decisionTime } }}
          </span>
        </span>
        {{ "TimeInfo.YouCan" | translate }} {{ "TimeInfo." + data.side | translate }}
        <span class="wv-text-blue wv-bold">{{ data.dealCurrency }}</span><br/>
        {{ "TimeInfo.Rate" | translate }}
        <span class="wv-text-blue wv-bold">{{ rate | number: "1.4-4" }} {{ data.currencyPair | pair }}</span>

        <div *ngIf="insufficientFunds" class="wv-summary-modal__action-text--funds wv-text-red" translate>InsufficientFunds</div>
      </div>

      <div class="wv-summary-modal__action-timer wv-summary-modal__action-timer--active"><div class="progress"></div></div>

      <button class="wv-global-button wv-global-button--fixed" id="confirm-transaction" (click)="confirm()" translate>Confirm</button>
    </div>
    <div class="wv-summary-modal__action-container" [hidden]="!showRefresh">
      <div class="wv-summary-modal__action-text wv-summary-modal__action-text-refresh">
        {{ "ExpiredTimeInfo.Offered" | translate }}
        <span class="rate-expired">{{ "ExpiredTimeInfo.RateExpired" | translate }}.</span><br />
        {{ "RefreshInfo" | translate }} {{ data.currencyPair | pair }}.
      </div>
      <div class="wv-summary-modal__action-timer"></div>
      <button class="wv-global-button wv-global-button--fixed" id="refresh-rate" (click)="startGetQuote(data)" translate>Refresh</button>
    </div>
  </div>

  <app-dealer-wait [show]="dealerWait" (cancelled)="back()" [message]="'DealerWait.DescriptionForex'"></app-dealer-wait>
  <app-exchange-success *ngIf="success" [message]="message" (newExchange)="openNewExchange()" (finishEvent)="closeResult()"></app-exchange-success>
  <app-message-modal *ngIf="!success" [message]="message"></app-message-modal>
</div>
