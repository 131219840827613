import { Component, OnInit } from "@angular/core";
import { WebViewService } from "../../common/mobile/webview.service";
import { HomeService } from "./home.service";
import { ActionItem } from "src/app/common/mobile/webview.model";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../common/_core/session/user-service";
import { hideAnimation } from "../../common/animations/animations";
import { GreetingService } from "../../common/greeting.service";
import { NavigationService } from "src/app/common/_core/navigation/navigation.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  animations: [hideAnimation]
})
export class HomeComponent implements OnInit {
  public actionItems: ActionItem[];

  constructor(
    public userService: UserService,
    private _homeService: HomeService,
    private _webViewService: WebViewService,
    private _translate: TranslateService,
    private _navigation: NavigationService,
    private _greetingsService: GreetingService
  ) {}

  ngOnInit(): void {
    this._webViewService.updateScreen("dashboard");
    this._homeService.track();
    this._greetingsService.showLeiExpirationMessage();
    this.setActionItems();
  }

  setActionItems() {
    this.actionItems = [
      {
        text: this._translate.instant("ActionTitles.Charts"),
        callback: () => this._navigation.navigate(["/graph"], { state: { returnPage: "dashboard" } })
      },
      {
        text: this._translate.instant("ActionTitles.Pairs"),
        callback: () => this._navigation.navigate(["/indicative-pairs"])
      }
    ];
  }

  showNotificationsTooltip() {
    this._webViewService.showInfotip("Notifications.Tooltip", "Notifications.Header", true);
  }
}

