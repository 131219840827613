<div *ngIf="details$ | async as details; else noDetailsBlock">
  <div *ngIf="show" class="wv-container-for-sticky-button">
    <div #container>
      <app-order-details-simple [details]="details"></app-order-details-simple>
<!--      <div class="wv-container wv-container--grey" [ngClass]="{ 'wv-global-button--sticky-to-bottom--full-width': shouldButtonBeSticky }">-->
      <div class="wv-container wv-container--grey" >
        <button class="wv-global-button wv-global-button--white-normal" *ngIf="details.cancellable" (click)="cancel()">
          Anuluj zlecenie warunkowe
        </button>
        <!--RM
        <button class="wv-global-button" routerLink="/order" [state]="{ returnPage: 'history' }">
          Nowe zlecenie warunkowe
        </button>
        -->
        <button class="wv-global-button" >
          Nowe zlecenie warunkowe
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #noDetailsBlock>
  <app-preloader [absolute]="true"></app-preloader>
</ng-template>
