<app-preloader [absolute]="true" *ngIf="loading"></app-preloader>
<div class="wv-view-my-alerts">
  <app-alert-history-element *ngFor="let alert of alerts" [alert]="alert" (canceled)="onCancel($event)">
  </app-alert-history-element>
  <div class="wv-container wv-container--grey empty" *ngIf="!alerts.length">
    {{ "MissingData.AlertHistory" | translate }}
  </div>
  <!-- RM 
  <button
    class="wv-floating-button wv-floating-button--active"
    style="background-image: url('assets/wv-img/icon_add_fab.svg'); display: block"
    routerLink="/alert"
    [state]="{ returnPage: 'history' }"
  ></button>
  -->
  <button
  class="wv-floating-button wv-floating-button--active"
  style="background-image: url('assets/wv-img/icon_add_fab.svg'); display: block"
  ></button>

</div>
