import { Component, OnInit } from '@angular/core';
import { ActivationDocument, ActivationRequest } from '../activation.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivationService } from '../activation.service';
import { emailValidator } from '../../../validators/email-validator';
import { WebViewService } from '../../../common/mobile/webview.service';
import { slideRightAnimation } from '../../../common/animations/animations';
import { UserService } from '../../../common/_core/session/user-service';

@Component({
  selector: 'app-activation-data',
  templateUrl: './activation-data.component.html',
  styleUrls: ['./activation-data.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationDataComponent implements OnInit {

  public emails: string[] = [];
  public documents: ActivationDocument[];

  public dataForm: FormGroup;
  public addressForm: FormGroup;
  public emailControls: string[];

  public sending: boolean;

  constructor(public userService: UserService, private formBuilder: FormBuilder, public activationService: ActivationService, private webViewService: WebViewService) {
    this.documents = this.activationService.pageData.documents;
    this.emails = this.activationService.pageData.customerEmails;
  }

  get emailConsent() {
    return this.dataForm.get("emailConsent");
  }

  getEmailControl(control) {
    return this.addressForm.get(control);
  }

  ngOnInit(): void {
    this.createGroup();
  }

  get addEmailDisabled() {
    return this.emailsCount >= 5;
  }

  get emailsCount() {
    return this.emails.length + Object.keys(this.addressForm.controls).length;
  }

  addAddress() {
    if (this.addEmailDisabled) {
      return;
    }

    const keys = Object.keys(this.addressForm.controls).map(x => parseInt(x.replace('newEmail', '')));
    const newNumber = !keys.length ? 0 : Math.max(...keys);
    this.addressForm.addControl(`newEmail${newNumber + 1}`, new FormControl('', [Validators.required, emailValidator]));
    this.emailControls = Object.keys(this.addressForm.controls);
  }

  removeNewAddress(address) {
    this.addressForm.removeControl(address);
    this.emailControls = Object.keys(this.addressForm.controls);
  }

  removeAddress(email) {
    this.emails.splice(this.emails.indexOf(email), 1);
  }

  private createGroup(): void {
    this.dataForm = this.formBuilder.group({
      emailConsent: [null, Validators.required]
    });
    this.addressForm = this.formBuilder.group({
    });
  }

  openDocument(document) {
    this.webViewService.openUrl(document.path);
  }

  saveDisabled() {
    return !this.emailConsent.value
      || this.emailsCount === 0
      || Object.keys(this.addressForm.controls).find(x => this.getEmailControl(x).invalid);
  }

  save() {
    this.sending = true;
    const data = {
      emails: this.emails.concat(Object.keys(this.addressForm.controls).map(x => this.addressForm.get(x).value))
    } as ActivationRequest;
    this.activationService.save(data).subscribe((result) => {
      if (result.success && result.applicationId) {
        this.activationService.next();
      }
      else {
        this.activationService.showError();
      }
    });
  }

  trackByEmail(_: number, email: string) {
    return email;
  }
}
