import { Component, Input, OnInit } from "@angular/core";
import { baseCurrency, quoteCurrency } from "@common/dealing/currency.utils";

@Component({
  selector: "app-pair-selection-item",
  templateUrl: "./pair-selection-item.component.html"
})
export class PairSelectionItemComponent implements OnInit {
  @Input() pair: string;

  get baseCurrency() {
    if (this.pair) {
      return baseCurrency(this.pair);
    }

    return "";
  }

  get quoteCurrency() {
    if (this.pair) {
      return quoteCurrency(this.pair);
    }

    return "";
  }

  constructor() {}

  ngOnInit(): void {}
}
