import { Component, Input } from "@angular/core";

import { Account } from "@common/accounts/account.model";
import { Side } from "@common/dealing/dealing.model";
import { OrderDetails } from "../order-history.model";

@Component({
  selector: "app-order-details-simple",
  templateUrl: "./order-details-simple.component.html",
})
export class OrderDetailsSimpleComponent {
  @Input() details: OrderDetails;

  constructor() {}

  get isLimit(): boolean {
    return this.details.type === "Limit";
  }

  get executed(): boolean {
    return this.details.status === "Executed";
  }

  get cancelled(): boolean {
    return this.details.status === "Cancelled";
  }

  get expired(): boolean {
    return this.details.status === "Expired";
  }

  get fromAccount(): Account {
    return this.details.side === Side.Sell
      ? this.details.settlementAccount
      : this.details.counterSettlementAccount;
  }

  get toAccount(): Account {
    return this.details.side !== Side.Sell
      ? this.details.settlementAccount
      : this.details.counterSettlementAccount;
  }
}
