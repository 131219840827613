import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { slideRightAnimation } from "@common/animations/animations";
import { Message } from "@components/message/message.model";
import { FxSpotService } from "../exchange.service";

@Component({
  selector: "app-exchange-success",
  templateUrl: "./exchange-success.component.html",
  animations: [slideRightAnimation]
})
export class ExchangeSuccessComponent {
  @Input() message: Message;
  @Output() newExchange = new EventEmitter<any>();
  @Output() finishEvent = new EventEmitter<any>();

  constructor(public translate: TranslateService, private fxSpotService: FxSpotService) {}

  get isDpw(): boolean {
    return this.message.data.isDpw;
  }

  openNewExchange(): void {
    this.newExchange.emit();
  }

  finish(): void {
    this.finishEvent.emit();
  }
}
