<div
  class="
    wv-global-modal wv-global-modal--white wv-global-modal--info
    new-transaction-last-modal
    wv-global-modal--displayed wv-global-modal--visible
  "
  *ngIf="message"
  [@slideInOut]
>
  <div class="wv-container wv-global-modal__content-container">
    <div class="wv-global-modal__icon wv-global-modal__icon--success"></div>
    <div class="wv-global-modal__header" translate>DealConfirmed</div>
    <div class="wv-global-modal__subheader">
      <span class="wv-global-modal__text">{{ "DealId" | translate }}</span
      >&nbsp;
      <span class="wv-global-modal__subheader--important">{{ message.data.dealId }}</span>
      <br />
      <span class="wv-global-modal__text">{{ "Transaction.Details." + message.data.side | translate }}</span
      >&nbsp;
      <span class="wv-global-modal__subheader--important">
        {{ message.data.amount | number: "1.2-2" }}
        {{ message.data.dealCurrency }}
      </span>
      <br />
      <span class="wv-global-modal__text">
        {{ "WithRate" | translate }}
      </span>
      &nbsp;
      <span class="wv-global-modal__subheader--important">{{ message.data.rate | number: "1.4-4" }}</span>
    </div>
    <div class="wv-global-modal__text">
      <span translate>Check</span>
      &nbsp;
      <!--
      <a
        class="wv-text-blue"
        routerLink="{{ '/history/exchange/' + message.data.dealId }}"
        [state]="{ isFromSuccessPage: 'true' }"
        >{{ "DealDetails" | translate }}
      </a>
      -->
      <a
        class="wv-text-blue"
        >{{ "DealDetails" | translate }}
      </a>
    </div>
  </div>
  <div class="wv-global-modal__button-container">
    <button class="wv-global-button ripple" [class.wv-global-button--white-normal]="!isDpw" (click)="finish()">
      {{ "OK" | translate }}
    </button>
    <button *ngIf="!isDpw" class="wv-global-button ripple" (click)="openNewExchange()" translate>NextDeal</button>
  </div>
</div>
