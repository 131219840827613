import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroupDirective, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { PhonePrefix } from "src/app/common/phones/phone.model";
import { FormGroupComponent } from "../_form-group/form-group.component";

@Component({
  selector: "app-input-phone-number",
  templateUrl: "input-phone-number.component.html"
})
export class InputPhoneNumberComponent extends FormGroupComponent implements OnInit, OnDestroy {
  @Input() prefixes: PhonePrefix[];

  private readonly destroy$ = new Subject();

  constructor(_groupDirective: FormGroupDirective, private _fb: FormBuilder) {
    super(_groupDirective);
  }

  get phonePrefix(): AbstractControl {
    return this.group.get("prefix");
  }

  get phoneNumber(): AbstractControl {
    return this.group.get("number");
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addControls();
    this.phonePrefix.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(prefix => !prefix)
      )
      .subscribe(() => this.phonePrefix.setValue(this.getDefaultPrefix()));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDefaultPrefix(): PhonePrefix {
    return this.prefixes.find(({ countryCode }) => countryCode === "PL");
  }

  private addControls() {
    const controls = {
      prefix: this._fb.control(this.getDefaultPrefix(), Validators.required),
      number: this._fb.control(null, [Validators.required, Validators.pattern(/^\d{3}\s?\d{3}\s?\d{3}$/)])
    };

    for (const [key, value] of Object.entries(controls)) {
      this.group.addControl(key, value);
    }
  }
}
