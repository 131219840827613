import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PreloaderComponent } from "@components/preloader/preloader.component";

@NgModule({
  imports: [CommonModule],
  declarations: [PreloaderComponent],
  exports: [CommonModule, PreloaderComponent],
  providers: [],
})
export class SharedModule {}

