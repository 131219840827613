import { Injectable } from "@angular/core";
import { HttpRequest, HttpResponse } from "@angular/common/http";

import { RequestCache, RequestCacheEntry } from "./request-cache.model";

const maxAge = 30000; // maximum cache age (30 s)

@Injectable()
export class RequestCacheWithMap implements RequestCache {
  public cache = new Map<string, RequestCacheEntry>();

  constructor() {}

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - maxAge;
    const expired = isExpired ? "expired " : "";
    console.log(`Found ${expired}cached response for "${url}".`);

    return isExpired ? undefined : cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;

    const newEntry = { url, response, lastRead: Date.now() };
    this.cache.set(url, newEntry);

    // remove expired cache entries
    const expired = Date.now() - maxAge;
    this.cache.forEach(entry => {
      if (entry.lastRead < expired) {
        this.cache.delete(entry.url);
      }
    });

    console.log(`Cached response from "${url}". Cache size: ${this.cache.size}.`);
  }
}
