<div class="wv-container wv-container--grey" [ngClass]="{ 'no-padding': loading }">
  <app-preloader *ngIf="loading"></app-preloader>
  <div [hidden]="loading || confirming || dealAccountSelection || counterAccountSelection">
    <form [formGroup]="orderForm" style="padding-bottom: 100px">
      <app-input-switch ngDefaultControl formControlName="side" [options]="['Sell', 'Buy']"></app-input-switch>

      <app-select-currency-pair
        formGroupName="currencies"
        [dealCurrencies]="currencies"
        [counterCurrencies]="counterCurrencies"
      ></app-select-currency-pair>

      <app-input-wrapper for="order-amount" label="DealAmount" [control]="dealAmount">
        <app-input-amount
          ngDefaultControl
          formControlName="dealAmount"
          ngId="order-amount"
          placeholder="InsertAmount"
          [decimals]="dealCurrency.value?.decimals"
        ></app-input-amount>
        <span detail>{{ dealCurrency.value?.code }}</span>
      </app-input-wrapper>

      <app-input-wrapper for="order-type" label="Order.Type">
        <select id="order-type" formControlName="type">
          <option *ngFor="let type of types" [ngValue]="type">
            {{ "Order.Types." + type.id | translate }}
          </option>
        </select>
      </app-input-wrapper>

      <app-input-wrapper for="order-rate" label="ExpectedRate" *ngIf="!isTrailingStop" [control]="rate">
        <app-input-amount ngDefaultControl formControlName="rate" ngId="order-rate" [decimals]="4"></app-input-amount>
        <span detail>{{ currencyPair?.code | pair }}</span>
      </app-input-wrapper>

      <app-input-wrapper for="order-points" label="Order.Points" [control]="points" *ngIf="isTrailingStop">
        <app-input-amount ngDefaultControl formControlName="points" ngId="order-points"></app-input-amount>
      </app-input-wrapper>

      <app-input-wrapper for="order-tenor" label="SettlementDeadline" [control]="tenor">
        <select id="order-tenor" formControlName="tenor" required>
          <option value="" disabled hidden>{{ "Select" | translate }}</option>
          <option *ngFor="let tenor of tenors" [ngValue]="tenor">
            {{ tenor | translate }}
          </option>
        </select>
      </app-input-wrapper>

      <app-account-button
        [control]="isBuying ? counterAccount : dealAccount"
        ngId="from-account"
        label="FromAccount"
        (openSelection)="openAccountOneSelection()"
      >
      </app-account-button>
      <app-account-button
        [control]="isBuying ? dealAccount : counterAccount"
        ngId="to-account"
        label="ToAccount"
        (openSelection)="openAccountTwoSelection()"
      >
      </app-account-button>

      <div class="wv-input-block wv-input-block__span" formGroupName="expiration">
        <app-input-wrapper for="date" label="ExpirationDate" [control]="expirationDate">
          <app-input-date
            ngDefaultControl
            ngId="date"
            formControlName="expirationDate"
            [min]="minExpirationDate"
            [max]="maxExpirationDate"
          ></app-input-date>
        </app-input-wrapper>
        <app-input-wrapper for="time" label="ExpirationTime" [control]="expirationTime" [style]="{ width: '80%' }">
          <input type="time" id="time" formControlName="expirationTime" />
        </app-input-wrapper>
      </div>

      <app-input-phone-number
        *ngIf="useSmsNotification"
        formGroupName="phone"
        [prefixes]="phonePrefixes"
      ></app-input-phone-number>
    </form>

    <div class="wv-fixed-buttons-container">
      <button
        class="wv-global-button wv-global-button--fixed"
        id="new-order-button"
        [disabled]="orderForm.invalid"
        (click)="confirm()"
      >
        {{ "Next" | translate }}
      </button>
    </div>
  </div>
</div>

<app-account-selection
  [show]="dealAccountSelection"
  [accounts]="dealAccounts"
  (selected)="onDealAccountSelection($event)"
></app-account-selection>
<app-account-selection
  [show]="counterAccountSelection"
  [accounts]="counterAccounts"
  (selected)="onCounterAccountSelection($event)"
></app-account-selection>
<app-order-confirmation [hidden]="!confirming" #confirmation (openOrderForm)="openNewOrder($event)" [scrollTop]="confirming"></app-order-confirmation>
