<app-preloader *ngIf="loading" [absolute]="true"></app-preloader>
<div class="wv-container wv-container--grey" *ngIf="!success && !failure && order" [@slideInOut]
     style="padding-bottom: 100px">
  <app-order-quote-details [details]="order" [loading]="loading"></app-order-quote-details>
  <div class="wv-fixed-buttons-container">
    <button class="wv-global-button wv-global-button--fixed" (click)="confirm()" translate>Confirm</button>
  </div>
</div>

<app-order-success *ngIf="success" [id]="orderId" [order]="order" (newOrder)="openNewOrder(true)"></app-order-success>
<app-order-failure
  *ngIf="failure"
  [title]="errorTitle"
  [description]="errorDescription"
  [hideRetry]="hideRetry"
  (newOrder)="openNewOrder()"
></app-order-failure>
