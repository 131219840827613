import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { CachingInterceptor } from "./caching.interceptor";
import { HeaderInterceptor } from "./header.interceptor";
import { LoggingInterceptor } from "./logging.interceptor";
import { FakeBackendInterceptor } from "./fake-backend.interceptor";
import { ServerErrorInterceptor } from "./server-error.interceptor";

import { environment } from "src/environments/environment";

// Order is important!
const httpInterceptorProviders: any[] = [     // <<-------- NOWE
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }
];

if (!environment.production) {
  console.warn("Activating fake backend...");
  console.warn("Activating http logging...");
  httpInterceptorProviders.push({ provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true });
  httpInterceptorProviders.push({ provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true });
}

export { httpInterceptorProviders };
