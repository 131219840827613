<div *ngIf="details" class="wv-transaction-details__quote-container">
  <app-summary-item label="ExpectedRate" *ngIf="!isTrailingStop; else pointsBlock">
    <span class="wv-transaction-details__quote-rate"> {{ details.rate | number: "1.4-4" }} {{ details.pair | pair }} </span>
  </app-summary-item>

  <ng-template #pointsBlock>
    <app-summary-item label="Order.Points">
      {{ details.points / 10000 | number: "1.4-4" }}
    </app-summary-item>
  </ng-template>

  <app-summary-item-amount
    [label]="details.side"
    [amount]="details.amount"
    [currency]="details.dealCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Vs"
    *ngIf="isLimit"
    [amount]="details.counterAmount"
    [currency]="details.counterCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item label="Order.Type">
    {{ "Order.Types." + details.type | translate }}
  </app-summary-item>

  <app-summary-item label="Order.Details.Tenor">
    {{ details.tenor | translate }}
  </app-summary-item>

  <app-summary-item-account label="FromAccount" [account]="fromAccount"> </app-summary-item-account>

  <app-summary-item-account label="ToAccount" [account]="toAccount"> </app-summary-item-account>

  <app-summary-item-date
    label="ExpirationDate"
    [date]="details.expirationDateTime"
    format="dd.MM.yyyy, HH:mm"
  ></app-summary-item-date>
</div>
