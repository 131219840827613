import { Component, OnInit } from '@angular/core';
import { Company } from '../activation.model';
import { ActivationService } from '../activation.service';
import { slideRightAnimation } from '../../../common/animations/animations';
import { UserService } from '../../../common/_core/session/user-service';

@Component({
  selector: 'app-activation-client-data',
  templateUrl: './activation-client-data.component.html',
  styleUrls: ['./activation-client-data.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationClientDataComponent implements OnInit {

  public client: Company;

  constructor(public userService: UserService, public activationService: ActivationService) {
    this.client = this.activationService.pageData.client;
  }

  ngOnInit(): void {
  }
}
