import { HttpParams } from "@angular/common/http";

/**
 * Converts a simple object to HttpParams
 * @param {Object} object
 * @returns {HttpParams}
 */
export function getParamsFromObject(object: Object): HttpParams {
  return Object.entries(object).reduce((params, [key, value]) => params.set(key, `${value}`), new HttpParams());
}
