<div
  class="wv-global-modal wv-global-modal--info new-transaction-last-modal wv-global-modal--displayed wv-global-modal--visible"
  *ngIf="show"
  [@slideInOut]
>
  <div class="wv-global-modal__content-container">
    <div class="wv-global-modal__icon wv-global-modal__icon--success"></div>
    <div class="wv-global-modal__header" translate>Alerts.Success.Header</div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Alerts.Success.{{ alert.side }}</span>
        <span>{{ alert.currency }} </span>
        <span class="wv-global-modal__subheader--important"
          >{{ alert.targetRate | number: "1.4-4" }} {{ alert.currencyPair | pair }}</span
        >
      </div>
      <div>
        <span translate>Alerts.Success.Description2</span>
        <span class="wv-global-modal__subheader--important">{{ alert.expirationDate | date: "dd.MM.yyyy" }}</span>
      </div>
    </div>
    <div class="wv-global-modal__subheader">
      <div>
        <span translate>Alerts.Success.Description3</span>
        <a class="wv-text-blue" [routerLink]="['/alerts']" translate>Alerts.MyAlertsLink</a>
      </div>
    </div>
  </div>
  <div class="wv-global-modal__button-container">
    <button class="wv-global-button wv-global-button--white-normal" (click)="back()" translate>OK</button>
    <button class="wv-global-button ripple" (click)="openNewAlert()" translate>Alerts.NextAlert</button>
  </div>
</div>
