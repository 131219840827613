<div class="wv-transactions ripple">
  <div class="wv-transactions__info">
    <div class="wv-transactions__name">
      {{ 'Transaction.DealTypes.' + deal.typeName | translate }} <span class="wv-transactions__num">{{ deal.systemReference }}</span>
    </div>
    <div class="wv-transactions__status" [ngClass]="settlementStatusClass">
      {{ 'Transaction.Statuses.' + deal.settlementStatus | translate }}
    </div>
  </div>
  <div class="wv-transactions__value">
    <div class="wv-transactions__value-num" [ngClass]="deal.amount > 0 ? 'wv-transactions__value-num--default' : 'wv-transactions__value-num--red'">
      {{ deal.amount | number: "1.2-2" }} {{ deal.currency }}
    </div>
    <div class="wv-transactions__value-num" [ngClass]="deal.counterAmount > 0 ? 'wv-transactions__value-num--default' : 'wv-transactions__value-num--red'">
      {{ deal.counterAmount | number: "1.2-2" }} {{ deal.counterCurrency }}
    </div>
  </div>
</div>
