import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HighchartsChartModule } from "highcharts-angular";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { MenuNavbarComponent } from "./components/menu-navbar/menu-navbar.component";
import { HomeComponent } from "./components/home/home.component";
import { CustomerComponent } from "./components/customer/customer.component";

import { RequestCache } from "./common/_core/cache/request-cache.model";
import { RequestCacheWithMap } from "./common/_core/cache/request-cache.service";

import { httpInterceptorProviders } from "./interceptors";
import { GlobalErrorHandler } from "./common/_core/error-handling/global-error-handler";
import { LoggingService } from "./common/_core/logging/logging.service";
import { ExchangeComponent } from "./components/exchange/exchange.component";
import { ExchangeQuoteComponent } from "./components/exchange/exchange-quote/exchange-quote.component";
import { ExchangeQuoteDetailsComponent } from "./components/exchange/exchange-quote-details/exchange-quote-details.component";
import { ExchangeSuccessComponent } from "./components/exchange/exchange-success/exchange-success.component";
import { ExchangeHistoryComponent } from "./components/exchange-history/exchange-history.component";
import { ExchangeHistoryItemComponent } from "./components/exchange-history/exchange-history-item/exchange-history-item.component";
import { ExchangeDetailsComponent } from "./components/exchange-history/exchange-details/exchange-details.component";
import { ExchangeDetailsSwapComponent } from "./components/exchange-history/exchange-details-swap/exchange-details-swap.component";
import { ExchangeDetailsSimpleComponent } from "./components/exchange-history/exchange-details-simple/exchange-details-simple.component";
import { ExchangeHistoryFiltersComponent } from "./components/exchange-history/exchange-history-filters/exchange-history-filters.component";
import { AuthGuard } from "./guards/auth.guard";
import { SessionService } from "./common/_core/session/session-service";
import { DepositComponent } from "./components/deposit/deposit.component";
import { DepositQuoteComponent } from "./components/deposit/deposit-quote/deposit-quote.component";
import { DepositSuccessComponent } from "./components/deposit/deposit-success/deposit-success.component";
import { DepositFailureComponent } from "./components/deposit/deposit-failure/deposit-failure.component";
import { DepositDetailsSimpleComponent } from "./components/deposit-history/deposit-details-simple/deposit-details-simple.component";
import { DepositQuoteDetailsComponent } from "./components/deposit/deposit-quote-details/deposit-quote-details.component";
import { DpwComponent } from "./components/dpw/dpw.component";
import { DpwElementComponent } from "./components/dpw/dpw-element/dpw-element.component";
import { DepositHistoryComponent } from "./components/deposit-history/deposit-history.component";
import { DepositDetailsComponent } from "./components/deposit-history/deposit-details/deposit-details.component";
import { DepositHistoryElementComponent } from "./components/deposit-history/deposit-history-element/deposit-history-element.component";
import { DepositHistoryFiltersComponent } from "./components/deposit-history/deposit-history-filters/deposit-history-filters.component";
import { MessageModalComponent } from "./components/message/message-modal/message-modal.component";
import { MessageButtonComponent } from "./components/message/message-button/message-button.component";
import { MessageContainerComponent } from "./components/message/message-container/message-container.component";
import { AccountButtonComponent } from "./components/account-button/account-button.component";
import { AccountButtonContentComponent } from "./components/account-button-content/account-button-content.component";
import { AccountSelectionComponent } from "./components/account-selection/account-selection.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { DepositSettingsComponent } from "./components/user-profile/default-settings/deposit-settings/deposit-settings.component";
import { ExchangeSettingsComponent } from "./components/user-profile/default-settings/exchange-settings/exchange-settings.component";
import { DefaultAccountsComponent } from "./components/user-profile/default-accounts/default-accounts.component";
import { UserDataComponent } from "./components/user-profile/user-data/user-data.component";
import { UserSettingsComponent } from "./components/user-profile/user-settings/user-settings.component";
import { AlertComponent } from "./components/alert/alert.component";
import { AlertHistoryComponent } from "./components/alert-history/alert-history.component";
import { AlertHistoryElementComponent } from "./components/alert-history/alert-history-element/alert-history-element.component";
import { AlertSuccessComponent } from "./components/alert/alert-success/alert-success.component";
import { AlertFailureComponent } from "./components/alert/alert-failure/alert-failure.component";
import { NewsComponent } from "./components/news/news.component";
import { NewsDetailsComponent } from "./components/news/news-details/news-details.component";
import { MoreComponent } from "./components/more/more.component";
import { OrderHistoryElementComponent } from "./components/order-history/order-history-element/order-history-element.component";
import { OrderDetailsComponent } from "./components/order-history/order-details/order-details.component";
import { OrderDetailsSimpleComponent } from "./components/order-history/order-details-simple/order-details-simple.component";
import { OrderHistoryComponent } from "./components/order-history/order-history.component";
import { HistoryComponent } from "./components/history/history.component";
import { OrderComponent } from "./components/order/order.component";
import { OrderConfirmationComponent } from "./components/order/order-confirmation/order-confirmation.component";
import { OrderSuccessComponent } from "./components/order/order-success/order-success.component";
import { OrderFailureComponent } from "./components/order/order-failure/order-failure.component";
import { ActivationComponent } from "./components/activation/activation.component";
import { ActivationStartComponent } from "./components/activation/activation-start/activation-start.component";
import { ActivationInfoComponent } from "./components/activation/activation-info/activation-info.component";
import { ActivationClientDataComponent } from "./components/activation/activation-client-data/activation-client-data.component";
import { ActivationAddressComponent } from "./components/activation/activation-address/activation-address.component";
import { ActivationDataComponent } from "./components/activation/activation-data/activation-data.component";
import { ActivationWaitingPageComponent } from "./components/activation/activation-waiting-page/activation-waiting-page.component";
import { ActivationResultComponent } from "./components/activation/activation-result/activation-result.component";

import { NavbarService } from "./components/menu-navbar/navbar.service";
import { DealerWaitComponent } from "./components/dealer-wait/dealer-wait.component";
import { IndicativePairItemComponent } from "./components/indicatives/indicative-pair-item/indicative-pair-item.component";
import { IndicativesComponent } from "./components/indicatives/indicatives.component";
import { IndicativePairSelectionComponent } from "./components/indicatives/indicative-pair-selection/indicative-pair-selection.component";
import { IndicativeChartComponent } from "./components/indicatives/indicative-chart/indicative-chart.component";
import { IndicativeRateItemComponent } from "./components/indicatives/indicative-rate-item/indicative-rate-item.component";
import { IndicativePairGroupComponent } from "./components/indicatives/indicative-pair-group/indicative-pair-group.component";
import { SectionHeaderComponent } from "./components/section-header/section-header.component";
import { GraphComponent } from "./components/graph/graph.component";
import { MenuSectionComponent } from "./components/menu-section/menu-section.component";
import { StatusFilterComponent } from "./components/status-filter/status-filter.component";
import { OrderHistoryFiltersComponent } from "./components/order-history/order-history-filters/order-history-filters.component";
import { PairSelectionComponent } from "./components/pair-selection/pair-selection.component";
import { OrderQuoteDetailsComponent } from "./components/order/order-quote-details/order-quote-details.component";
import { AppRoutingModule } from "./app-routing.module";
import { DocumentItemComponent } from "./components/document-list/document-item/document-item.component";
import { DocumentListComponent } from "./components/document-list/document-list.component";
import { MenuButtonComponent } from "./components/menu-button/menu-button.component";
import { InputsModule } from "./components/inputs/inputs.module";
import { SummaryModule } from "./components/summary/summary.module";
import { PipesModule } from "./pipes/pipes.module";
import { DirectivesModule } from "./directives/directives.module";
import { MessageItemDetailsComponent } from "./components/message-item/message-item-details/message-item-details.component";
import { MessageItemViewerComponent } from "./components/message-item/message-item-viewer.component";
import { NavigationService } from "./common/_core/navigation/navigation.service";
import {
  initialAsyncProvider,
  initialBlockingProvider,
  languageProvider,
} from "@common/service-providers";
import { NotificationsModule } from "./components/notifications/notifications.module";
import { PairSelectionItemComponent } from "./components/pair-selection/pair-selection-item/pair-selection-item.component";
import { DepositTerminationSuccessComponent } from "./components/deposit-history/deposit-termination-success/deposit-termination-success.component";
import { SharedModule } from "./shared.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CustomerComponent,
    IndicativesComponent,
    IndicativeRateItemComponent,
    IndicativePairGroupComponent,
    IndicativePairItemComponent,
    IndicativePairSelectionComponent,
    ExchangeComponent,
    ExchangeHistoryComponent,
    ExchangeDetailsComponent,
    ExchangeDetailsSimpleComponent,
    ExchangeDetailsSwapComponent,
    ExchangeHistoryItemComponent,
    ExchangeSettingsComponent,
    ExchangeSuccessComponent,
    ExchangeQuoteDetailsComponent,
    ExchangeQuoteComponent,
    MessageModalComponent,
    MessageContainerComponent,
    MessageButtonComponent,
    UserProfileComponent,
    DpwComponent,
    DpwElementComponent,
    DefaultAccountsComponent,
    UserDataComponent,
    UserSettingsComponent,
    DepositComponent,
    DepositHistoryComponent,
    DepositHistoryElementComponent,
    DepositDetailsComponent,
    DepositDetailsSimpleComponent,
    DepositQuoteComponent,
    DepositQuoteDetailsComponent,
    DepositSettingsComponent,
    DepositSuccessComponent,
    DepositFailureComponent,
    DepositTerminationSuccessComponent,
    AccountButtonComponent,
    AccountButtonContentComponent,
    AccountSelectionComponent,
    AlertComponent,
    AlertHistoryComponent,
    AlertHistoryElementComponent,
    AlertSuccessComponent,
    AlertFailureComponent,
    NewsComponent,
    NewsDetailsComponent,
    MenuNavbarComponent,
    MenuSectionComponent,
    MenuButtonComponent,
    MoreComponent,
    OrderHistoryElementComponent,
    OrderDetailsComponent,
    OrderDetailsSimpleComponent,
    OrderHistoryComponent,
    HistoryComponent,
    StatusFilterComponent,
    OrderComponent,
    OrderConfirmationComponent,
    OrderQuoteDetailsComponent,
    OrderSuccessComponent,
    OrderFailureComponent,
    ActivationComponent,
    ActivationStartComponent,
    ActivationInfoComponent,
    ActivationClientDataComponent,
    ActivationAddressComponent,
    ActivationDataComponent,
    ActivationWaitingPageComponent,
    ActivationResultComponent,
    DealerWaitComponent,
    SectionHeaderComponent,
    GraphComponent,
    DepositHistoryFiltersComponent,
    OrderHistoryFiltersComponent,
    ExchangeHistoryFiltersComponent,
    PairSelectionComponent,
    PairSelectionItemComponent,
    DocumentItemComponent,
    DocumentListComponent,
    IndicativeChartComponent,
    MessageItemDetailsComponent,
    MessageItemViewerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HighchartsChartModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    PipesModule,
    DirectivesModule,
    SummaryModule,
    InputsModule,
    NotificationsModule,
  ],
  providers: [
    initialBlockingProvider,
    initialAsyncProvider,
    languageProvider,
    LoggingService,
    AuthGuard,
    SessionService,
    NavbarService,
    NavigationService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    ...httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}

console.log(environment.version);
