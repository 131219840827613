import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { merge, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, skipWhile, takeUntil } from "rxjs/operators";

import { UserService } from "src/app/common/_core/session/user-service";
import { UserPreference } from "../user-profile.model";
import { UserProfileService } from "../user-profile.service";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"]
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  constructor(
    public userService: UserService,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService
  ) {}

  private readonly destroy$ = new Subject();

  settingsForm: FormGroup;
  settingsData: UserPreference[];

  get settingsFormArray() {
    return this.settingsForm.get("settings") as FormArray;
  }

  ngOnInit(): void {
    this.userProfileService.getConfigurablePreferences().subscribe(settings => {
      this.settingsData = settings;
      this.addCheckboxes();
      this.onChanges();
    });
    this.createGroup();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onChanges(): void {
    merge(
      ...this.settingsFormArray.controls.map((control: AbstractControl, index: number) =>
        control.valueChanges.pipe(
          debounceTime(500),
          // this is for any subsequent change.
          distinctUntilChanged(),
          // this is for the initial change. No update after save is necessary.
          skipWhile(x => x === this.settingsData[index].value),
          map(value => ({ index, value }))
        )
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ index, value }) => {
        const { key } = this.settingsData[index];
        this.userProfileService.savePreference(key, value).subscribe();
      });
  }

  private createGroup(): void {
    this.settingsForm = this.formBuilder.group({
      settings: this.formBuilder.array([])
    });
  }

  private addCheckboxes() {
    this.settingsData.forEach(({ value, disabled }) =>
      this.settingsFormArray.push(this.formBuilder.control({ value, disabled }))
    );
  }
}
