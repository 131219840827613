<div class="wv-input-block" [class.wv-input-block--error]="hasError">
  <label [for]="for" class="wv-input-block__label" *ngIf="label">{{ label | translate }}</label>
  <div [ngClass]="{ 'wv-input-block__detail-container': hasDetail }">
    <div class="wv-input-block__input">
      <ng-content></ng-content>

      <ng-container *ngIf="hasError">
        <app-input-error
          *ngFor="let error of control.errors | keyvalue"
          [error]="error"
          [overrides]="errorOverrides"
        ></app-input-error>
      </ng-container>
    </div>

    <ng-content select="[detail]"></ng-content>
  </div>
</div>
