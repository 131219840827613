import { Customer } from "../../components/customer/customer.model";
import { IndicativeGraph, IndicativePair, IndicativeRate } from "../../components/indicatives/indicatives.model";
import { addDays, onlyDateLocal, onlyDateUTC, datepickerFormat, firstDateAfterWeekend } from "../../utils/time.utils";
import { Side, TenorDate, TenorDateDto } from "../dealing/dealing.model";
import { DepositPreferences, ExchangePreferences } from "../user/settings.model";
import { OrderItem, OrderDetails } from "src/app/components/order-history/order-history.model";
import { currencies, currencyPairs, counterCurrencies, customers, accounts, orders, dealHistory, depositHistory, alertHistory } from "./fake-backend.db";
import { Deal, DealDetails } from "src/app/components/exchange-history/exchange-history.model";
import { Account } from "../accounts/account.model";
import { DepositHistoryItem, DepositDetails } from "src/app/components/deposit-history/deposit-history.model";
import { Collateral } from "src/app/components/exchange/exchange.model";
import { UserNotification, NewDpw, UnsettledDeal } from "src/app/components/notifications/notifications.model";
import { DpwElement } from "src/app/components/dpw/dpw-element/dpw-element.model";
import { GraphPoint, GraphButtonConfig } from "src/app/components/graph/graph.model";
import { NewsItem } from "src/app/components/news/news.model";
import { Lei } from "src/app/common/lei/lei.model"
import { UserPreference } from "src/app/components/user-profile/user-profile.model"
import { DatePipe } from "@angular/common";
import { formatDate } from '@angular/common';
import { ThrowStmt } from "@angular/compiler";
import { RateAlert } from "src/app/components/alert/alert.model";
import { oldCommonData } from "./fake-backend.utils";


export function getCustomer(): Array<Customer> {
  return customers as Customer[];
}

export function getIndicativePairs(): Array<IndicativePair> {
  const selectedPairs = JSON.parse(localStorage.getItem("mock.selected-indicative-pairs"));
  const getPair = (code: string): IndicativePair => {
    const selected = !selectedPairs || selectedPairs.includes(code);
    return { code, isSelected: selected } as IndicativePair;
  };
  const pairs = currencyPairs.map(x => getPair(x));
  return pairs;
}

export function setIndicativePairs(pairs: Array<string>): boolean {
  localStorage.setItem("mock.selected-indicative-pairs", JSON.stringify(pairs));
  return true;
}

export function getIndicativeRates(): Array<IndicativeRate> {
  // const getRandomRate = (int: number) => {
  //   const rnd = int + Math.random() / 100;
  //   return { buy: roundNumber(rnd), sell: roundNumber(rnd - 0.016) };
  // };
  // return [
  //   { pair: { code: "EURPLN" } as IndicativePair, ...getRandomRate(4.64) },
  //   { pair: { code: "USDPLN" } as IndicativePair, ...getRandomRate(4.4) },
  //   { pair: { code: "EURUSD" } as IndicativePair, ...getRandomRate(1.1) }
  // ];
  const oldData = oldCommonData["/indicatives/getrates"] as IndicativeRate[];     // <<-------- NOWE
  return oldData ?? [];
}


export function getFxTenors(): TenorDateDto[] {
  return [
    { tenor: "TOD", date: datepickerFormat(new Date()) },
    { tenor: "TOM", date: datepickerFormat(addDays(new Date(), 1)) },
    { tenor: "SPOT", date: datepickerFormat(addDays(new Date(), 2)) },
    { tenor: "SN", date: datepickerFormat(addDays(new Date(), 3)) },
    { tenor: "1W", date: datepickerFormat(addDays(new Date(), 7)) },
    { tenor: "1M", date: datepickerFormat(addDays(new Date(), 30)) }
  ];
}

export function getMmTenors(): TenorDateDto[] {
  return [
    { tenor: "ON", date: datepickerFormat(new Date()) },
    { tenor: "TN", date: datepickerFormat(addDays(new Date(), 1)) },
    { tenor: "SPOT", date: datepickerFormat(addDays(new Date(), 2)) },
    { tenor: "1D", date: datepickerFormat(addDays(new Date(), 1)) },
    { tenor: "1W", date: datepickerFormat(addDays(new Date(), 7)) },
    { tenor: "2W", date: datepickerFormat(addDays(new Date(), 14)) },
    { tenor: "3W", date: datepickerFormat(addDays(new Date(), 21)) },
    { tenor: "1M", date: datepickerFormat(addDays(new Date(), 30)) }
  ];
}

export function getAccounts() {
  const customerAccounts = accounts.filter(x => x.pid === "12345678");
  return customerAccounts;
}

export function getFxNormalPreferences(): ExchangePreferences {
  return {
    side: Side.Buy,
    amount: 0,
    dealCurrency: "PLN",
    counterCurrency: "EUR",
    nearTenor: "TOD"
  };
}

export function getDepositPreferences(): DepositPreferences {
  return {
    amount: 0,
    currency: "PLN",
    startTenor: "ON",
    endTenor: "1D"
  };
}

export function getCollateralTypes(params): Collateral[] {
  switch (params) {
    case "FXFORW":
      return [{ type: "Limit" }, { type: "Deposit" }];
    default:
      return [{ type: "AmountBlock" }];
  }
}

export function getDefaultAccounts() {
  return { technicalCustomerNumber: "123456789", accounts };
}

export function getOrders(): OrderItem[] {
  return orders.map(
    x =>
    ({
      ...x
    } as unknown as OrderItem)
  );
}

function getOrderDetails(id: string): OrderDetails {
  switch (id) {
    case "112546":
      return {
        id: 112546,
        status: "Expired",
        side: "Buy",
        amount: 5000,
        dealCurrency: "EUR",
        counterAmount: 23250,
        counterCurrency: "PLN",
        rate: "4.65",
        type: "Limit",
        expirationDateTime: addDays(new Date('2022-05-16 17:02'), 0),
        phone: "+48 800 302 302"
      } as OrderDetails;
    case "112550":
      return {
        cancellable: true,
        status: "Active",
        type: "StopMarket",
        expirationDateTime: addDays(new Date(), 1),
        phone: "+48 800 302 302",
        rate: "4.5",
        tenor: "TOM",
        counterSettlementAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        settlementAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
        side: "Sell",
        counterCurrency: "PLN",
        dealCurrency: "EUR",
        counterAmount: 1,
        amount: 5000,
        pair: "EURPLN",
        id: 112550
      } as OrderDetails;
  }
}

export function getDealHistory(): Deal[] {
  return dealHistory.map(x => x as Deal);
}

function getDealReview(id: string): DealDetails {
  switch (id) {
    case "140000001":
      return {
        id,
        side: Side.Buy,
        dealAmount: 500,
        dealCurrency: "USD",
        counterAmount: 2216.25,
        counterCurrency: "PLN",
        rate: parseFloat("4.4325"),
        productType: "FX ON",
        dealDate: firstDateAfterWeekend(addDays(new Date(), -2)),
        settlementDate: firstDateAfterWeekend(addDays(new Date(), +1)),
        isSettled: false,
        buyAccount: { number: "17 1020 1111 0000 0000 0506 8703", currency: "USD", balance: 345003.09, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
      } as DealDetails;

    case "140000002":
      return {
        id,
        side: Side.Sell,
        dealAmount: 1000,
        dealCurrency: "EUR",
        counterAmount: 4637.20,
        counterCurrency: "PLN",
        rate: parseFloat("4.6372"),
        productType: "FX ON",
        dealDate: firstDateAfterWeekend(addDays(new Date(), -2)),
        settlementDate: addDays(new Date(), 0),
        isSettled: false,
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
      } as DealDetails;

    case "120000006":
      return {
        id,
        side: Side.Sell,
        dealAmount: 1000,
        dealCurrency: "EUR",
        counterAmount: 4644.80,
        counterCurrency: "PLN",
        rate: parseFloat("4.6448"),
        productType: "FX ON",
        dealDate: addDays(new Date('2022-05-05'), 0),
        settlementDate: addDays(new Date('2022-05-05'), 0),
        isSettled: true,
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
      } as DealDetails;

    case "120000010":
      return {
        id,
        side: Side.Sell,
        dealAmount: 200,
        dealCurrency: "EUR",
        counterAmount: 927.80,
        counterCurrency: "PLN",
        rate: parseFloat("4.6390"),
        productType: "SPOT",
        dealDate: addDays(new Date('2022-05-05'), 0),
        settlementDate: addDays(new Date('2022-05-09'), 0),
        isSettled: true,
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
      } as DealDetails;

    case "120000015":
      return {
        id,
        side: Side.Sell,
        dealAmount: 170000,
        dealCurrency: "EUR",
        counterAmount: 788834.00,
        counterCurrency: "PLN",
        rate: parseFloat("4.6402"),
        productType: "FORW",
        dealDate: addDays(new Date('2022-05-06'), 0),
        settlementDate: addDays(new Date('2022-05-31'), 0),
        isSettled: false,
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
        collateral: {
          type: "Limit",
        }
      } as DealDetails;

    case "120000016":
      return {
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        sellAccount: { number: "87 1020 1111 0000 0000 0601 8771", currency: "EUR", balance: 1236982.03, name: "BIZNES PARTNER" } as Account,
        side: Side.Sell,
        dealAmount: 170000,
        counterAmount: 785604,
        dealCurrency: "EUR",
        counterCurrency: "PLN",
        dealDate: addDays(new Date('2022-05-06'), 0),
        id,
        rate: parseFloat("4.6212"),
        settlementDate: addDays(new Date('2022-05-17'), 0),
        collateral: {
          type: "Limit"
        },
        productType: "FSWP",
        isNdf: false,
        isSettled: false,
        linkedDealId: "120000015",
        netSettlement: {
        },
        subType: "HistoricalRollback",
        paymentType: "PaymentType",
        farLeg: {
          dealAmount: 170000,
          dealCurrency: "EUR",
          rate: 4.6402,
          counterAmount: 788834,
          counterCurrency: "PLN",
          settlementDate: addDays(new Date("2022-05-31"), 0)
        },
      } as DealDetails;

    case "120000023":
      return {
        id,
        side: Side.Sell,
        dealAmount: 8000,
        dealCurrency: "CHF",
        counterAmount: 35762.40,
        counterCurrency: "PLN",
        rate: parseFloat("4.4703"),
        productType: "FORW",
        dealDate: addDays(new Date('2022-05-09'), 0),
        settlementDate: addDays(new Date('2022-05-31'), 0),
        isSettled: false,
        buyAccount: { number: "57 1020 1111 0000 0000 0102 0901", currency: "PLN", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        collateral: {
          type: "Deposit",
          currency: "CHF",
          amount: 400,
          account: { number: "22 1020 1111 0000 0000 0226 3001", currency: "CHF", balance: 125678.45, name: "BIZNES PARTNER" } as Account,
        },
        isNdf: true,
        referenceRate: "Market Rate"
      } as DealDetails;

    case "120000048":
      return {
        id,
        side: Side.Sell,
        dealAmount: 17000,
        dealCurrency: "EUR",
        counterAmount: 78409.10,
        counterCurrency: "PLN",
        rate: parseFloat("4.6123"),
        productType: "FX ON",
        subType: "Iob.Transfer incoming",
        dealDate: addDays(new Date('2022-05-19'), 0),
        settlementDate: addDays(new Date('2022-05-19'), 0),
        isSettled: true,
        isIob: true,
        dpwReference: "SR22SN00060388DR"
      } as DealDetails;
  }
}

export function getDepositHistory(): DepositHistoryItem[] {
  return depositHistory.map(x => x as DepositHistoryItem);
}

export function getDepositDetails(id: string): DepositDetails {
  switch (id) {
    case "12000021":
      return {
        id: 12000021,
        amount: 1000000,
        currency: "PLN",
        dealDate: addDays(new Date('2022-05-09'), 0),
        startDate: addDays(new Date('2022-05-09'), 0),
        endDate: addDays(new Date('2022-05-10'), 0),
        interestRate: 2.96,
        interestAmount: 81.10,
        amountWithInterest: 1000081.10,
        //isTerminated: false,
        account: { number: "154567890987654306254900", currency: "PLN", balance: 79525.54, name: "BIZNES PARTNER" } as Account,
        terminationAvailable: false
      } as DepositDetails;

    case "12000022":
      return {
        id: 12000022,
        amount: 250000.00,
        currency: "USD",
        dealDate: addDays(new Date('2022-05-09'), 0),
        startDate: addDays(new Date('2022-05-10'), 0),
        endDate: addDays(new Date('2023-05-10'), 0),
        interestRate: 2.56,
        interestAmount: 6400.00,
        amountWithInterest: 256400.00,
        //isTerminated: false,
        account: { number: "554567890987654309926942", currency: "USD", balance: 10586.36, name: "RACH.BIEŻĄCY INSTYTUCJONALNY" } as Account,
        terminationAvailable: true
      } as DepositDetails
  }
}

export function getAlertHistory(): RateAlert[] {
  return alertHistory.map(x => x as RateAlert);
}

export function setCookie(name, value) {
  var expires = "";
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
setCookie("roles", "Customer,HasCustomer, FxSpot, MmDeposit");

export function getNotificationDates() {
  return [
    this.dates = firstDateAfterWeekend(addDays(new Date(), -1)), firstDateAfterWeekend(addDays(new Date(), 0)), firstDateAfterWeekend(addDays(new Date(), +1)),
  ]
}

export function getNotificationActions(id: string): UserNotification[] {
  switch (id) {
    case (datepickerFormat(firstDateAfterWeekend(addDays(new Date(), -1)))):
      return [];

    case (datepickerFormat(firstDateAfterWeekend(addDays(new Date(), 0)))):
      return [{
        key: 'UnsettledDeal',
        name: 'UnsettledDeal',
        date: addDays(new Date(), 1),
        dealId: "140000002",
        dealSide: "Sell",
        currency1: 'EUR',
        amount1: 1000,
        currency2: 'PLN',
        amount2: 4637.20
      } as UnsettledDeal]


    case (datepickerFormat(firstDateAfterWeekend(addDays(new Date(), +1)))):
      return [{
        key: 'UnsettledDeal',
        name: 'UnsettledDeal',
        date: firstDateAfterWeekend(addDays(new Date(), +1)),
        dealId: '140000001',
        dealSide: "Buy",
        currency1: 'USD',
        amount1: 500,
        currency2: 'PLN',
        amount2: 2216.25
      } as UnsettledDeal]
  }
}

export function getNotificationActionsNewDPW(): DpwElement[] {
  return [
    /*
    {
      type: 'IN',
      amount: 100,
      currency: 'EUR',
      expireTime: addDays(new Date(), +20),
      ssrid: 'ABCD12345678',
      isActive: false, 
      currencyPair: 'EURPLN',
      counterCurrency: 'EUR'
    }
    */
  ];
}

export function getNews(): NewsItem[] {
  return [{
    id: '1',
    content: '25 kwietnia (Reuters) - KGHM Polska Miedz SA KGH.WA * SPRZEDAZ MIEDZI W III 66,1 TYS. TON WOBEC 66,6 TYS. TON ROK WCZESNIEJ * PRODUKCJA MIEDZI PLATNEJ W III 63,9 TYS. TON WOBEC 65,8 TYS. TON ROK WCZESNIEJ Pełny komunikat spółki w serwisie Eikon jest dostępny tutaj: ID:nEM587pZ7a Aby zobaczyć pozostałe depesze na temat spółki, kliknij tutaj KGH.WA (Gdańsk Newsroom) ((gdansk.newsroom@thomsonreuters.com; +48 58 769 66 00; ))',
    headline: 'W SKRÓCIE-W III sprzedaż miedzi KGHM wyniosła 66,1 tys. ton',
    date: addDays(new Date('2022-04-25 15:14'), 0)
  },
  {
    id: '2',
    content: "WARSZAWA, 25 kwietnia (Reuters) - Ministerstwo Finansów (MF) poinformowało w poniedziałek, ze po pierwszych trzech miesiącach 2022 roku deficyt budżetu wyniósł 0,3 miliarda złotych. Po lutym budżet miał 11,2 miliarda złotych nadwyżki. (Autorzy: Anna Koper i Pawel Florkiewicz, Redagował: Alan Charlish) ((warsaw.newsroom@thomsonreuters.com; +48 22 6539700; Reuters Messaging: warsaw.newsroom.reuters.com@thomsonreuters.net))",
    headline: "Po marcu deficyt budżetu państwa wyniósł 0,3 mld zł-MF",
    date: addDays(new Date('2022-04-25 14:12'), 0)
  },
  {
    id: '3',
    content: "WARSZAWA, 25 kwietnia (Reuters) - W marcu podaż pieniądza M3 wzrosła o 7,9 procent rok do roku do 2009,57 miliarda złotych, poinformował w poniedziałek Narodowy Bank Polski (NBP). Analitycy ankietowani przez agencje Reutera spodziewali się wzrostu o 7,7 procent. III ZMIANA R./R. M./M. (mld zł) (proc.) (proc.) PODAZ PIENIADZA M3 2009,57 7,9 1,3 PIENIADZ GOTÓWKOWY W OBIEGU 372,38 DEPOZYTY 1626,89 gospodarstw domowych 1019,58 przedsiębiorstw 415,64 NALEZNOSCI 1476,09 od gospodarstw domowych 823,62 od przedsiębiorstw 400,90 ZADLUZENIE NETTO INSTYTUCJI RZADOWYCH 383,77 AKTYWA ZAGRANICZNE NETTO (w mld euro) 113,10 (Autor: Anna Włodarczak-Semczuk; Redagował: Paweł Florkiewicz) ((pawel.florkiewicz@thomsonreuters.com)(+48 22 6539700) (Reuters Messaging: pawel.florkiewicz.reuters.com@thomsonreuters.net))",
    headline: "TABELA- W III podaż pieniądza M3 wzrosła o 7,9 proc.-NBP",
    date: addDays(new Date('2022-04-25 14:00'), 0)
  },
  {
    id: '4',
    content: "INDEKS WARTOSC OTWARCIE / ZAMKNIECIE POPRZEDNIEJ SESJI (pkt.) (zmiana proc.) WIG20 1.951,03 -1,13 WIG20lev 388,71 -0,01 WIG20short 3.402,16 0,02 mWIG40 4.561,05 -0,03 WIG 59.997,97 -1,49 WIGDIV 1.132,32 -0,63 sWIG80 18.917,40 -1,43",
    headline: "GPW – NOTOWANIA CIAGLE: Na otwarciu WIG20 spadł o 1,13 proc.",
    date: addDays(new Date('2022-04-25 09:01'), 0)
  }]
}

export function getNewsDetal(id: string): NewsItem {
  switch (id) {
    case "1":
      return {
        id: '1',
        content: '25 kwietnia (Reuters) - KGHM Polska Miedz SA KGH.WA * SPRZEDAZ MIEDZI W III 66,1 TYS. TON WOBEC 66,6 TYS. TON ROK WCZESNIEJ * PRODUKCJA MIEDZI PLATNEJ W III 63,9 TYS. TON WOBEC 65,8 TYS. TON ROK WCZESNIEJ Pełny komunikat spółki w serwisie Eikon jest dostępny tutaj: ID:nEM587pZ7a Aby zobaczyć pozostałe depesze na temat spółki, kliknij tutaj KGH.WA (Gdańsk Newsroom) ((gdansk.newsroom@thomsonreuters.com; +48 58 769 66 00; ))',
        headline: 'W SKRÓCIE-W III sprzedaż miedzi KGHM wyniosła 66,1 tys. ton',
        date: addDays(new Date('2022-04-25 15:14'), 0)
      };

    case "2":
      return {
        id: '2',
        content: "WARSZAWA, 25 kwietnia (Reuters) - Ministerstwo Finansów (MF) poinformowało w poniedziałek, ze po pierwszych trzech miesiącach 2022 roku deficyt budżetu wyniósł 0,3 miliarda złotych. Po lutym budżet miał 11,2 miliarda złotych nadwyżki. (Autorzy: Anna Koper i Pawel Florkiewicz, Redagował: Alan Charlish) ((warsaw.newsroom@thomsonreuters.com; +48 22 6539700; Reuters Messaging: warsaw.newsroom.reuters.com@thomsonreuters.net))",
        headline: "Po marcu deficyt budżetu państwa wyniósł 0,3 mld zł-MF",
        date: addDays(new Date('2022-04-25 14:12'), 0)
      };

    case "3":
      return {
        id: '3',
        content: "WARSZAWA, 25 kwietnia (Reuters) - W marcu podaż pieniądza M3 wzrosła o 7,9 procent rok do roku do 2009,57 miliarda złotych, poinformował w poniedziałek Narodowy Bank Polski (NBP). Analitycy ankietowani przez agencje Reutera spodziewali się wzrostu o 7,7 procent. III ZMIANA R./R. M./M. (mld zł) (proc.) (proc.) PODAZ PIENIADZA M3 2009,57 7,9 1,3 PIENIADZ GOTÓWKOWY W OBIEGU 372,38 DEPOZYTY 1626,89 gospodarstw domowych 1019,58 przedsiębiorstw 415,64 NALEZNOSCI 1476,09 od gospodarstw domowych 823,62 od przedsiębiorstw 400,90 ZADLUZENIE NETTO INSTYTUCJI RZADOWYCH 383,77 AKTYWA ZAGRANICZNE NETTO (w mld euro) 113,10 (Autor: Anna Włodarczak-Semczuk; Redagował: Paweł Florkiewicz) ((pawel.florkiewicz@thomsonreuters.com)(+48 22 6539700) (Reuters Messaging: pawel.florkiewicz.reuters.com@thomsonreuters.net))",
        headline: "TABELA- W III podaż pieniądza M3 wzrosła o 7,9 proc.-NBP",
        date: addDays(new Date('2022-04-25 14:00'), 0)
      };

    case "4":
      return {
        id: '4',
        content: "INDEKS WARTOSC OTWARCIE / ZAMKNIECIE POPRZEDNIEJ SESJI (pkt.) (zmiana proc.) WIG20 1.951,03 -1,13 WIG20lev 388,71 -0,01 WIG20short 3.402,16 0,02 mWIG40 4.561,05 -0,03 WIG 59.997,97 -1,49 WIGDIV 1.132,32 -0,63 sWIG80 18.917,40 -1,43",
        headline: "GPW – NOTOWANIA CIAGLE: Na otwarciu WIG20 spadł o 1,13 proc.",
        date: addDays(new Date('2022-04-25 09:01'), 0)
      };
  }
}

export function customerLei(): Lei {
  return {
    code: '12345600000000000007',
    expirationDate: addDays(new Date(), +365)
  }
}

export function getSettings(): UserPreference[] {
  return [{
    key: 'CheckSimilarDeals',
    value: 1,
    disabled: false
  },
  {
    key: 'AllowDealsWithoutBalance',
    value: 0,
    disabled: false
  }]
}

export function PostExchangeInit(currencyPairInit, dealCurrencyInit, sideInit, amountInit) {
  return {
    success: true,
    errorCode: "",
    additionalData: "",
    guid: "1a-2b-3c-4d-5e-Pair=" + currencyPairInit + ",dealCurrency=" + dealCurrencyInit + ",side=" + sideInit + ",amount=" + amountInit,
    responseType: "PRICE",
    loading: false,
  }
}

export function GetExchangeRate(currencyPairRate: string, dealCurrencyRate: string, sideRate, amountRate) {
  switch (currencyPairRate + dealCurrencyRate) {
    case "EURPLNEUR":
      return {
        rate: 4.6249,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate * 4.6249,
        dealGuid: "CONFIRMED",
      }
    case "EURPLNPLN":
      return {
        rate: 4.6249,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate / 4.6249,
        dealGuid: "CONFIRMED",
      }
    case "USDPLNUSD":
      return {
        rate: 4.3866,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate * 4.3866,
        dealGuid: "CONFIRMED"
      }
    case "USDPLNPLN":
      return {
        rate: 4.3866,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate / 4.3866,
        dealGuid: "CONFIRMED"
      }
    case "EURUSDEUR":
      return {
        rate: 1.0919,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate * 1.0919,
        dealGuid: "CONFIRMED"
      }
    case "EURUSDUSD":
      return {
        rate: 1.0919,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "20",
        counterAmount: amountRate / 1.0919,
        dealGuid: "CONFIRMED"
      }
  }
}

export function GetExchangeStatus(status) {
  return {
    errorCode: status,
    transactionId: "12000099"
  }
}
/*
export function decisionTimeChange () {
 return{
   decisionTime:"30"
 }
}
*/

export function PostDepositInit(body) {
  return {
    success: true,
    errorCode: "",
    additionalData: "",
    guid: "1a-2b-3c-4d-5e-currency=" + body.currency + ",amount=" + body.amount,
    responseType: "PRICE",
    loading: false
  }
}

export function GetDepositRate(DepositamountRate, DepositcurrencyRate: string) {
  switch (DepositcurrencyRate) {
    case "PLN":
      return {
        rate: 2.6,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "120",
        interestAmount: DepositamountRate * 2.6 / 100,
        maturityAmount: DepositamountRate * 1 + DepositamountRate * 2.6 / 100,
        dealGuid: "CONFIRMED",
      }
    case "USD":
      return {
        rate: 0.5,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "120",
        interestAmount: DepositamountRate * 0.5 / 100,
        maturityAmount: DepositamountRate * 1 + DepositamountRate * 0.5 / 100,
        dealGuid: "CONFIRMED",
      }
    case "EUR":
      return {
        rate: 0.01,
        responseType: "PRICE",
        isFirstRate: true,
        decisionTime: "120",
        interestAmount: DepositamountRate * 0.01 / 100,
        maturityAmount: DepositamountRate * 1 + DepositamountRate * 0.01 / 100,
        dealGuid: "CONFIRMED",
      }
  }
}

export function getGraphConfig(): GraphButtonConfig[] {
  return [
    { interval: "day", count: 1 },
    { interval: "day", count: 7 },
    { interval: "month", count: 1 },
    { interval: "month", count: 3 },
    { interval: "month", count: 12 }
  ]
}

export function getCurrencies() {
  return currencies;
}

export function getcounterCurrencies() {
  return counterCurrencies;
}


export function getIndicativeGraphs(): IndicativeGraph[] {
  return [
    {
      pair: "EURPLN",
      points: [
        { time: 1650561960000, mid: 4.6325 },
        { time: 1650563760000, mid: 4.6311 },
        { time: 1650565560000, mid: 4.6342 },
        { time: 1650567360000, mid: 4.6378 },
        { time: 1650569160000, mid: 4.641 },
        { time: 1650570960000, mid: 4.642 },
        { time: 1650572760000, mid: 4.642 },
        { time: 1650574560000, mid: 4.6413 },
        { time: 1650576360000, mid: 4.6413 },
        { time: 1650578160000, mid: 4.6403 },
        { time: 1650579960000, mid: 4.6386 },
        { time: 1650581760000, mid: 4.6384 },
        { time: 1650583560000, mid: 4.6398 },
        { time: 1650585360000, mid: 4.6398 },
        { time: 1650587160000, mid: 4.6381 },
        { time: 1650588960000, mid: 4.6381 },
        { time: 1650590760000, mid: 4.638 },
        { time: 1650592560000, mid: 4.6378 },
        { time: 1650594360000, mid: 4.6397 },
        { time: 1650596160000, mid: 4.6407 },
        { time: 1650597960000, mid: 4.6394 },
        { time: 1650599760000, mid: 4.6405 },
        { time: 1650601560000, mid: 4.6401 },
        { time: 1650603360000, mid: 4.6404 },
        { time: 1650605160000, mid: 4.6409 },
        { time: 1650606960000, mid: 4.6403 },
        { time: 1650608760000, mid: 4.6394 },
        { time: 1650610560000, mid: 4.64 },
        { time: 1650612360000, mid: 4.6387 },
        { time: 1650614160000, mid: 4.6392 },
        { time: 1650615960000, mid: 4.6335 },
        { time: 1650617760000, mid: 4.6349 },
        { time: 1650619560000, mid: 4.6414 },
        { time: 1650621360000, mid: 4.6364 },
        { time: 1650623160000, mid: 4.6396 },
        { time: 1650624960000, mid: 4.6366 },
        { time: 1650626760000, mid: 4.6404 },
        { time: 1650628560000, mid: 4.637 },
        { time: 1650630360000, mid: 4.6351 },
        { time: 1650632160000, mid: 4.6358 },
        { time: 1650633960000, mid: 4.6358 },
        { time: 1650635760000, mid: 4.6353 },
        { time: 1650637560000, mid: 4.6331 },
        { time: 1650639360000, mid: 4.6317 },
        { time: 1650641160000, mid: 4.6351 },
        { time: 1650642960000, mid: 4.6381 },
        { time: 1650644760000, mid: 4.6462 },
        { time: 1650646560000, mid: 4.6475 },

      ]
    },
    {
      pair: "USDPLN",
      points: [
        { time: 1650561960000, mid: 4.2665 },
        { time: 1650563760000, mid: 4.273 },
        { time: 1650565560000, mid: 4.277 },
        { time: 1650567360000, mid: 4.2774 },
        { time: 1650569160000, mid: 4.2767 },
        { time: 1650570960000, mid: 4.2755 },
        { time: 1650572760000, mid: 4.2808 },
        { time: 1650574560000, mid: 4.2805 },
        { time: 1650576360000, mid: 4.284 },
        { time: 1650578160000, mid: 4.2834 },
        { time: 1650579960000, mid: 4.2786 },
        { time: 1650581760000, mid: 4.2803 },
        { time: 1650583560000, mid: 4.2817 },
        { time: 1650585360000, mid: 4.2828 },
        { time: 1650587160000, mid: 4.2802 },
        { time: 1650588960000, mid: 4.2789 },
        { time: 1650590760000, mid: 4.2799 },
        { time: 1650592560000, mid: 4.2803 },
        { time: 1650594360000, mid: 4.2813 },
        { time: 1650596160000, mid: 4.283 },
        { time: 1650597960000, mid: 4.2778 },
        { time: 1650599760000, mid: 4.2813 },
        { time: 1650601560000, mid: 4.2807 },
        { time: 1650603360000, mid: 4.28 },
        { time: 1650605160000, mid: 4.2809 },
        { time: 1650606960000, mid: 4.2805 },
        { time: 1650608760000, mid: 4.2776 },
        { time: 1650610560000, mid: 4.2786 },
        { time: 1650612360000, mid: 4.2781 },
        { time: 1650614160000, mid: 4.2775 },
        { time: 1650615960000, mid: 4.2728 },
        { time: 1650617760000, mid: 4.2798 },
        { time: 1650619560000, mid: 4.2921 },
        { time: 1650621360000, mid: 4.29 },
        { time: 1650623160000, mid: 4.2969 },
        { time: 1650624960000, mid: 4.2936 },
        { time: 1650626760000, mid: 4.2945 },
        { time: 1650628560000, mid: 4.2921 },
        { time: 1650630360000, mid: 4.2893 },
        { time: 1650632160000, mid: 4.2875 },
        { time: 1650633960000, mid: 4.2831 },
        { time: 1650635760000, mid: 4.2837 },
        { time: 1650637560000, mid: 4.2812 },
        { time: 1650639360000, mid: 4.2802 },
        { time: 1650641160000, mid: 4.2791 },
        { time: 1650642960000, mid: 4.2895 },
        { time: 1650644760000, mid: 4.3013 },
        { time: 1650646560000, mid: 4.3111 }
      ]
    },
    {
      pair: "EURUSD",
      points: [
        { time: 1650561960000, mid: 1.0858 },
        { time: 1650563760000, mid: 1.0838 },
        { time: 1650565560000, mid: 1.0835 },
        { time: 1650567360000, mid: 1.0843 },
        { time: 1650569160000, mid: 1.0852 },
        { time: 1650570960000, mid: 1.0857 },
        { time: 1650572760000, mid: 1.0844 },
        { time: 1650574560000, mid: 1.0843 },
        { time: 1650576360000, mid: 1.0834 },
        { time: 1650578160000, mid: 1.0833 },
        { time: 1650579960000, mid: 1.0842 },
        { time: 1650581760000, mid: 1.0837 },
        { time: 1650583560000, mid: 1.0836 },
        { time: 1650585360000, mid: 1.0834 },
        { time: 1650587160000, mid: 1.0836 },
        { time: 1650588960000, mid: 1.0839 },
        { time: 1650590760000, mid: 1.0837 },
        { time: 1650592560000, mid: 1.0835 },
        { time: 1650594360000, mid: 1.0837 },
        { time: 1650596160000, mid: 1.0835 },
        { time: 1650597960000, mid: 1.0845 },
        { time: 1650599760000, mid: 1.0839 },
        { time: 1650601560000, mid: 1.084 },
        { time: 1650603360000, mid: 1.0842 },
        { time: 1650605160000, mid: 1.0841 },
        { time: 1650606960000, mid: 1.0841 },
        { time: 1650608760000, mid: 1.0846 },
        { time: 1650610560000, mid: 1.0845 },
        { time: 1650612360000, mid: 1.0843 },
        { time: 1650614160000, mid: 1.0846 },
        { time: 1650615960000, mid: 1.0844 },
        { time: 1650617760000, mid: 1.083 },
        { time: 1650619560000, mid: 1.0814 },
        { time: 1650621360000, mid: 1.0807 },
        { time: 1650623160000, mid: 1.0798 },
        { time: 1650624960000, mid: 1.0799 },
        { time: 1650626760000, mid: 1.0806 },
        { time: 1650628560000, mid: 1.0804 },
        { time: 1650630360000, mid: 1.0806 },
        { time: 1650632160000, mid: 1.0812 },
        { time: 1650633960000, mid: 1.0824 },
        { time: 1650635760000, mid: 1.0821 },
        { time: 1650637560000, mid: 1.0822 },
        { time: 1650639360000, mid: 1.0821 },
        { time: 1650641160000, mid: 1.0832 },
        { time: 1650642960000, mid: 1.0813 },
        { time: 1650644760000, mid: 1.0802 },
        { time: 1650646560000, mid: 1.078 }
      ]
    }
  ];
}

export function getGraphData(data: GraphPoint[]): GraphPoint[] {    // <<-------- NOWE
  console.warn("GRAPH DATA = ", data)
  return data;                                                      // <<-------- NOWE
}


export default {
  currencies,
  currencyPairs,
  counterCurrencies,
  getCustomer,
  getIndicativePairs,
  setIndicativePairs,
  getIndicativeRates,
  getFxTenors,
  getMmTenors,
  getAccounts,
  getFxNormalPreferences,
  getDepositPreferences,
  getCollateralTypes,
  getDefaultAccounts,
  getOrders,
  getDealHistory,
  getDealReview,
  getDepositDetails,
  getIndicativeGraphs,
  getNotificationDates,
  getNotificationActions,
  getNotificationActionsNewDPW,
  getGraphConfig,
  getGraphData,
  getNews,
  getNewsDetal,
  customerLei,
  getSettings,
  //collateralAccounts,
  PostExchangeInit,
  getCurrencies,
  getDepositHistory,
  getOrderDetails,
  getAlertHistory,
  getcounterCurrencies,
  GetExchangeRate,
  GetExchangeStatus,
  PostDepositInit,
  GetDepositRate
  //decisionTimeChange
};

const roundNumber = (x: number): number => Math.round((x + Number.EPSILON) * 10000) / 10000;
