import { Component, OnInit } from '@angular/core';
import { Address } from '../activation.model';
import { ActivationService } from '../activation.service';
import { slideRightAnimation } from '../../../common/animations/animations';
import { UserService } from '../../../common/_core/session/user-service';

@Component({
  selector: 'app-activation-address',
  templateUrl: './activation-address.component.html',
  styleUrls: ['./activation-address.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationAddressComponent implements OnInit {
  public address: Address;

  constructor(public userService: UserService, public activationService: ActivationService) {
    this.address = this.activationService.pageData.client?.companyAddress;
  }

  ngOnInit(): void {
  }
}
