<div
  class="
    wv-global-modal wv-global-modal--white wv-global-modal--info
    wv-global-modal--displayed wv-global-modal--visible
  "
  *ngIf="message"
  [@slideInOut]
>
  <div class="wv-global-modal__content-container">
    <div class="wv-global-modal__icon wv-global-modal__icon--success" *ngIf="message.success"></div>
    <div class="wv-summary-modal-blik-cancel__cancel-container" *ngIf="message.error">
      <div class="wv-summary-modal-blik-cancel__cancel-ico"></div>
    </div>
    <div class="wv-global-modal__content-container" *ngIf="message.info">
      <div class="wv-global-modal__icon wv-global-modal__icon--info"></div>
    </div>
    <div class="wv-global-modal__header" [innerHTML]="message.header"></div>
    <div class="wv-global-modal__subheader" *ngIf="message.description" [innerHTML]="message.description"></div>
  </div>
  <div class="wv-global-modal__button-container">
    <app-message-button *ngFor="let button of message.buttons" [button]="button"> </app-message-button>
  </div>
</div>
