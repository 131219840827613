<ng-container *ngIf="accountsByCurrency">
  <app-section-header [title]="'Profile.DefaultAccounts.Header' | translate"></app-section-header>
  <div class="wv-transaction-details__text-container">
    <app-input-wrapper [label]="currencyGroup.key" *ngFor="let currencyGroup of accountsByCurrency | keyvalue">
      <button
        class="wv-select-account__button wv-select-account__button--active"
        (click)="openDefaultAccountSelection(currencyGroup.value)"
      >
        <app-account-button-content
          [account]="getDefaultAccount(currencyGroup.value)"
          [hideNumber]="true"
        ></app-account-button-content>
      </button>
    </app-input-wrapper>
  </div>
</ng-container>
