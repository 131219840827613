import { Component, Input } from "@angular/core";
import { hasRole } from "@utils/cookie.utils";

import { MenuItem, MenuSection } from "./menu.model";
@Component({
  selector: "app-menu-section",
  templateUrl: "menu-section.component.html"
})
export class MenuSectionComponent {
  @Input() section: MenuSection;
  constructor() {}

  trackByName(_: number, item: MenuItem) {
    return item.name;
  }

  get visibleItems() {
    return this.section.items.filter(x => hasRole(x.requiredRoles));
  }
}
