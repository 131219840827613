<div class="wv-global-modal wv-global-modal--white wv-global-modal--info wv-global-modal--displayed wv-global-modal--visible" *ngIf="show" [@slideInOut]>
  <div class="wv-container wv-global-modal__content-container">
    <div class="wv-summary-modal-blik-cancel__cancel-container">
      <div class="wv-summary-modal-blik-cancel__cancel-ico"></div>
    </div>
    <div class="wv-global-modal__header">{{ "Errors.GenericTitle" | translate }}</div>
    <div class="wv-global-modal__subheader">
      {{ message }}
    </div>
  </div>
  <div class="wv-global-modal__button-container">
    <button class="wv-global-button wv-global-button--white-normal" (click)="restart()" translate>TryAgain</button>
    <button class="wv-global-button ripple" (click)="back()" translate>OK</button>
  </div>
</div>
