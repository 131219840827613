<div
  class="wv-webview-preloader"
  [class.absolute]="absolute"
  [ngStyle]="{ height: height, width: width }"
>
  <div
    class="wv-webview-preloader__indicator"
    [class.wv-webview-preloader__indicator--android]="isAndroid"
  ></div>
</div>
