<div class="wv-transaction-details__text-container">
  <app-summary-item label="Transaction.Details.Id">{{ details.id }}</app-summary-item>

  <app-summary-item-date label="Transaction.Details.Date" [date]="details.dealDate"></app-summary-item-date>

  <app-summary-item label="Transaction.Details.Type">
    {{ "Transaction.DealTypes." + details.productType | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.SubType" *ngIf="details.subType">
    {{ "Transaction.SubTypes." + details.subType | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.LinkedDeal" *ngIf="details.linkedDealId">
    <a [routerLink]="['../', details.linkedDealId]">{{ details.linkedDealId }}</a>
  </app-summary-item>

  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li *ngFor="let tab of tabs; index as i; trackBy: trackByTitle" [ngbNavItem]="i">
      <a ngbNavLink>{{ tab.title | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="legTemplate" [ngTemplateOutletContext]="{ leg: tab.context }"></ng-container>
      </ng-template>
    </li>
  </ul>
</div>

<div class="wv-transaction-details__text-container wv-transaction-details__text-container--bottom">
  <div [ngbNavOutlet]="nav"></div>
</div>

<ng-template #legTemplate let-leg="leg">
  <app-summary-item-amount
    label="Transaction.Details.{{ leg.side }}"
    [amount]="leg.dealAmount"
    [currency]="leg.dealCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Vs"
    [amount]="leg.counterAmount"
    [currency]="leg.counterCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount label="Transaction.Details.Rate" [amount]="leg.rate" [decimals]="4"></app-summary-item-amount>

  <app-summary-item-date label="SettlementDate" [date]="leg.settlementDate"></app-summary-item-date>

  <app-summary-item label="Status">
    {{ "Transaction.Statuses." + (leg.isSettled ? "Settled" : "Unsettled") | translate }}
  </app-summary-item>

  <app-summary-item label="Transaction.Details.SettlementType" *ngIf="leg.isNdf">
    {{ "Transaction.Details.Netto" | translate }}
  </app-summary-item>

  <app-summary-item-account
    [label]="leg.isNdf ? 'SettlementAccount' : 'FromAccount'"
    *ngIf="leg.sellAccount"
    [account]="leg.sellAccount"
  >
  </app-summary-item-account>

  <app-summary-item-account
    [label]="leg.isNdf ? 'SettlementAccount' : 'ToAccount'"
    *ngIf="leg.sellAccount"
    [account]="leg.buyAccount"
  >
  </app-summary-item-account>

  <app-summary-collateral *ngIf="leg.collateral" [collateral]="leg.collateral"></app-summary-collateral>
</ng-template>
