<div class="wv-global-modal wv-global-modal--info new-transaction-last-modal wv-global-modal--displayed wv-global-modal--visible" *ngIf="show" [@slideInOut]>
  <div class="wv-global-modal__content-container">
    <div class="wv-webview-preloader dealer-intervention--preloader">
      <div class="wv-webview-preloader__indicator wv-webview-preloader__indicator--android"></div>
    </div>
    <div class="wv-global-modal__header">{{ 'DealerWait.Header' | translate }}</div>
    <div class="wv-global-modal__subheader">
      {{ message | translate }}
    </div>
  </div>
  <div class="wv-fixed-buttons-container">
    <button class="wv-global-button wv-global-button--fixed wv-global-button--white-normal" (click)="cancel()">{{ 'DealerWait.Cancel' | translate}}</button>
  </div>
</div>
