import { negate } from "@utils/function.utils";
import {
  DepositTenorDates,
  DepositTenors,
  nearTenorCodes,
  TenorDate,
  TenorDateDto,
} from "./dealing.model";

export const splitDepositTenors = (tenors: string[]): DepositTenors => {
  const nearTenors = tenors.filter(isNearTenor);
  const farTenors = tenors.filter(negate(isNearTenor));

  return { nearTenors, farTenors };
};

export const splitDepositTenorDates = (tenorDates: TenorDate[]): DepositTenorDates => {
  const nearTenorDates = tenorDates.filter(isNearTenorDate);
  const farTenorDates = tenorDates.filter(negate(isNearTenorDate));

  return { nearTenorDates, farTenorDates };
};

export const parseTenorDates = (tenorDates: TenorDateDto[]): TenorDate[] => {
  return tenorDates.map(({ tenor, date }) => {
    // incoming format is local ISO yyyy-MM-ddThh:mm:ss
    // this sets them at midnight UTC (and as the same format as datepicker)
    return { tenor, date: new Date(date.slice(0, 10)) };
  });
};

export const isNearTenor = (tenor: string) => nearTenorCodes.includes(tenor);
const isNearTenorDate = ({ tenor }: TenorDate) => isNearTenor(tenor);
