import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-status-filter",
  templateUrl: "./status-filter.component.html",
  styleUrls: ["./status-filter.component.scss"]
})
export class StatusFilterComponent implements OnInit {
  @Input() startStatus: string;

  @Input() statuses: string[];
  @Input() translationPath: string;
  @Input() filtersCount: number = 0;
  public statusFilterFormGroup: FormGroup;

  @Output() openFilters = new EventEmitter<any>();
  @Output() statusChange = new EventEmitter<string>();

  get statusControl() {
    return this.statusFilterFormGroup.get("status");
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createGroup();
    this.statusControl.valueChanges.pipe(distinctUntilChanged()).subscribe(status => this.statusChange.emit(status));
  }

  filter(): void {
    this.openFilters.emit();
  }

  createGroup(): void {
    this.statusFilterFormGroup = this.formBuilder.group({
      status: [this.startStatus || this.statuses[0], Validators.required]
    });
  }
}
