import { WebViewService } from "@common/mobile/webview.service";
import { ActionItem } from "@common/mobile/webview.model";

export interface UserNotification {
  key: string;
  name: string;
  date: Date;
  deleted?: boolean;
}

export interface MessageNotificationItem extends UserNotification {
  id: number;
  title: string;
  description: string;
  segment: string;
  startDate: Date;
}

export interface NewDpw extends UserNotification {
  dpwType: string;
  expirationDate: Date;
  amount: number;
  currency: string;
  dpwNumber: string;
  currencyPair: string;
  status: string;
}

export interface UnsettledDeal extends UserNotification {
  dealId: string;
  dealSide: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
}

export interface ExpiredOrder extends UserNotification {
  orderId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  expectedRate: number;
  expired: boolean;
}

export interface ExecutedOrder extends UserNotification {
  orderId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  executionRate: number;
  executedDealId: number;
}

export interface ExecutedAlert extends UserNotification {
  alertId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  executionRate: number;
}

export interface ExpiredAlert extends UserNotification {
  alertId: string;
  side: string;
  currency1: string;
  amount1: number;
  currency2: string;
  amount2: number;
  expectedRate: number;
  expired: boolean;
}

export interface EndingDeposit extends UserNotification {
  dealId: string;
  settlementAmount: number;
  currency: string;
  interestRate: number;
}

export abstract class UserNotificationComponent {
  constructor(public _webViewService: WebViewService) {}

  abstract get icon(): string;

  abstract mainAction();
  abstract openMenu(event);

  drawMenu(event, actions: ActionItem[]) {
    this._webViewService.showActionSheet(event.target.getBoundingClientRect(), actions);
    event.stopPropagation();
  }
}
