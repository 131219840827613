<div
  class="wv-digit-step-three-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible"
  style="z-index: 4"
  *ngIf="activationService.page >= 3"
  [@slideInOut]
>
  <div class="wv-view-digit-step-three">
    <div class="wv-top-progress-bar">
      <div class="wv-top-progress-bar__inner" style="width: 75%"></div>
    </div>

    <div class="wv-section-header">
      <div class="wv-section-header__title" translate>Activation.Address</div>
    </div>

    <div class="wv-container wv-container--extra-padding">
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.Street</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.street }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.HouseNumber</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.number }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.BlockNumber</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.blockNumber }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.UnitNumber</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.unitNumber }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.ZipCode</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.zipCode }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.City</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.city }}
      </div>
      <div class="wv-view-digit-step-three__customer-header" translate>Activation.Country</div>
      <div class="wv-view-digit-step-three__customer-data">
        {{ address.country }}
      </div>
    </div>
    <div class="wv-container wv-container--grey wv-container--extra-padding">
      <div class="wv-view-digit-step-three__info" translate>Activation.UpdateData</div>
    </div>
    <div class="wv-container wv-container--border-top wv-container--fixed">
      <div class="wv-view-digit-step-three__info-button">
        <button id="digitStepFour" class="wv-global-button ripple" (click)="activationService.next()" translate>Next</button>
      </div>
    </div>
  </div>
</div>
