import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { WebViewService } from "@common/mobile/webview.service";
import { DepositPreferences } from "@common/user/settings.model";
import { SettingsStore } from "@common/user/settings.service";
import { DefaultSettingsService } from "../default-settings.service";

@Component({
  selector: "app-deposit-settings",
  templateUrl: "./deposit-settings.component.html"
})
export class DepositSettingsComponent implements OnInit {
  constructor(
    public defaultSettingsService: DefaultSettingsService,
    private settingsStore: SettingsStore,
    private formBuilder: FormBuilder,
    private webViewService: WebViewService
  ) {
    this.tenors$ = defaultSettingsService.getDepositTenors();
  }

  public settingsForm: FormGroup;
  public tenors$: Observable<{ nearTenors: string[]; farTenors: string[] }>;

  get startTenor() {
    return this.settingsForm.get("startTenor");
  }

  get endTenor() {
    return this.settingsForm.get("endTenor");
  }

  get currency() {
    return this.settingsForm.get("currency");
  }

  get amount() {
    return this.settingsForm.get("amount");
  }

  ngOnInit() {
    this.webViewService.updateScreen("depositSettings");
    this.createGroup();
    this.loadPreferences();
  }

  save() {
    const amount = parseFloat(this.amount.value) || 0;
    const settings: DepositPreferences = { ...this.settingsForm.value, amount };
    this.settingsStore.saveDepositPreferences(settings);
    this.webViewService.showSnackBar("DefaultSettingsSection.SettingsSaved");
  }

  private loadPreferences(): void {
    this.settingsStore.loadDepositPreferences().subscribe(({ amount, currency, endTenor, startTenor }) => {
      this.amount.setValue(amount);
      this.currency.setValue(currency);
      this.endTenor.setValue(endTenor);
      this.startTenor.setValue(startTenor);
    });
  }

  private createGroup(): void {
    this.settingsForm = this.formBuilder.group({
      startTenor: [null],
      endTenor: [null],
      currency: [null],
      amount: [null]
    });
  }
}
