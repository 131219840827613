import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { LogSource } from "../logging/logging.model";

import { LoggingService } from "../logging/logging.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: LoggingService) {}
  handleError(error: Error | HttpErrorResponse | any): void {
    if (error instanceof HttpErrorResponse) {
      this.logger.logError(LogSource.Global, `Unhandled HTTP error`, error);
    }

    this.logger.logError(LogSource.Global, `Unhandled application error`, error);
  }
}
