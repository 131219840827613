import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { filter, map, takeUntil, take } from "rxjs/operators";
import { WebViewService } from "src/app/common/mobile/webview.service";
import { ConfigStore } from "src/app/common/_core/config/config.service";
import { IndicativesService } from "../indicatives.service";
import { propCount } from "src/app/utils/object.utils";
import { merge, Subject } from "rxjs";
import { IndicativePair } from "../indicatives.model";
import { NavigationService } from "src/app/common/_core/navigation/navigation.service";

@Component({
  selector: "app-indicative-pair-selection",
  templateUrl: "indicative-pair-selection.component.html"
})
export class IndicativePairSelectionComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private indicativesService: IndicativesService,
    private webViewService: WebViewService,
    private navigation: NavigationService,
    configStore: ConfigStore
  ) {
    this.max = configStore.config.indicatives.pairLimit;
  }

  private readonly destroy$ = new Subject();
  private _pairs: IndicativePair[];
  public min: number = 1;
  public max: number;

  public form: FormGroup;

  get selectedPairGroup() {
    return this.form.get("selectedPairs") as FormGroup;
  }

  get unselectedNonDefaultOrderPairGroup() {
    return this.form.get("unselectedNonDefaultOrderPairs") as FormGroup;
  }

  get unselectedDefaultOrderPairGroup() {
    return this.form.get("unselectedDefaultOrderPairs") as FormGroup;
  }

  get selectedCount() {
    return propCount(this.selectedPairGroup.controls);
  }

  get unselectedNonDefaultCount() {
    return propCount(this.unselectedNonDefaultOrderPairGroup.controls);
  }

  ngOnInit() {
    this.webViewService.updateScreen("indicativePairs", () => this.back());

    this.createGroup();
    this.fillArray();
    this.subscribeToSelection();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  back() {
    const selectedPairs = Object.keys(this.selectedPairGroup.value).map(x => x.substr(5));
    this.indicativesService.savePairs(selectedPairs).subscribe(() => {
      this.navigation.back();
    });
  }

  private createGroup(): void {
    this.form = this.formBuilder.group({
      selectedPairs: this.formBuilder.group({}),
      unselectedNonDefaultOrderPairs: this.formBuilder.group({}),
      unselectedDefaultOrderPairs: this.formBuilder.group({})
    });
  }

  private fillArray() {
    this.indicativesService.pairs
      .pipe(
        filter(x => x.length > 0),
        take(1)
      )
      .subscribe(pairs => {
        this._pairs = pairs;
        for (const pair of pairs) {
          const control = this.formBuilder.control(pair.isSelected);
          if (pair.isSelected) {
            const order = propCount(this.selectedPairGroup.controls);
            this.selectedPairGroup.addControl(this.getControlName(order, pair.code), control);
          } else {
            this.getUnselectedPairGroup(pair.nonDefaultSortingOrder).addControl(
              this.getControlName(pair.order, pair.code),
              control
            );
          }
        }
      });
  }

  private subscribeToSelection() {
    merge(
      ...[
        this.observeSelection(this.selectedPairGroup, false),
        this.observeSelection(this.unselectedDefaultOrderPairGroup, true),
        this.observeSelection(this.unselectedNonDefaultOrderPairGroup, true)
      ]
    ).subscribe(({ sourceGroup, pairs }) => {
      for (const [name, isSelected] of pairs) {
        sourceGroup.removeControl(name);
        const control = this.formBuilder.control(isSelected);
        this.addControlToDesitionGroup(name, isSelected, control);
      }
    });
  }

  getUnselectedPairGroup(nonDefaultSortingOrder: boolean) {
    return nonDefaultSortingOrder ? this.unselectedNonDefaultOrderPairGroup : this.unselectedDefaultOrderPairGroup;
  }

  private addControlToDesitionGroup(oldName: string, isSelected: unknown, control: FormControl) {
    const pairCode = oldName.substr(5);
    if (isSelected) {
      const lastPair = Object.keys(this.selectedPairGroup.controls).pop();
      const order = parseInt(lastPair.substr(0, 4)) + 1;
      this.selectedPairGroup.addControl(this.getControlName(order, pairCode), control);
    } else {
      const pair = this._pairs.find(x => x.code === pairCode);
      this.getUnselectedPairGroup(pair.nonDefaultSortingOrder).addControl(
        this.getControlName(pair.order, pair.code),
        control
      );
    }
  }

  private getControlName(order: number, code: string): string {
    return order.toString().padStart(4, "0") + "_" + code;
  }

  private observeSelection(sourceGroup: FormGroup, selectFilter: boolean) {
    return sourceGroup.valueChanges.pipe(
      takeUntil(this.destroy$),
      map(group => Object.entries(group).filter(([, isSelected]) => isSelected === selectFilter)),
      filter(pairs => !!pairs.length),
      map(pairs => ({ sourceGroup, pairs }))
    );
  }
}
