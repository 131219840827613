<form [formGroup]="group">
  <app-input-wrapper for="{{ label }}-id" [label]="label" [control]="selectedDate">
    <app-input-date
      ngDefaultControl
      ngId="{{ label }}-id"
      formControlName="date"
      [min]="minDate"
      [max]="maxDate"
    ></app-input-date>
  </app-input-wrapper>
</form>
