<form [formGroup]="settingsForm">
  <div formArrayName="settings">
    <app-input-toggle
      *ngFor="let _ of settingsFormArray.controls; index as i"
      ngDefaultControl
      ngId="toggle{{ i }}"
      formControlName="{{ i }}"
      [label]="settingsData[i].key + '.Title'"
      [description]="settingsData[i].key + '.Description'"
    >
    </app-input-toggle>
  </div>
</form>
