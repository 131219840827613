import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserNotificationComponent, NewDpw } from "@components/notifications/notifications.model";
import { WebViewService } from "@common/mobile/webview.service";
import { FxSpotService } from "@components/exchange/exchange.service";
import { FxSpot } from "@components/exchange/exchange.model";
import { ActionItem } from "@common/mobile/webview.model";

@Component({
  selector: "app-notifications-new-dpw",
  templateUrl: "./notifications-new-dpw.component.html",
  styleUrls: ["./notifications-new-dpw.component.scss"]
})
export class NotificationsNewDpwComponent extends UserNotificationComponent implements OnInit {
  @Input() public dpw: NewDpw;

  constructor(
    webViewService: WebViewService,
    private translate: TranslateService,
    private fxSpotService: FxSpotService) {
    super(webViewService);
  }

  ngOnInit(): void {}

  get icon(): string {
    return "dpw";
  }

  mainAction() {
  
    this.fxSpotService.returnPage = "dashboard";
    this._webViewService.navigateFromWebView(
      ["/dpw"],
      this.dpw.status === "A" ? {
        state: {
          initialData: {
            dealCurrency: this.dpw.currency,
            counterCurrency: this.dpw.currencyPair.replace(this.dpw.currency, ""),
            currencyPair: this.dpw.currencyPair,
            side: /in/i.test(this.dpw.dpwType) ? "Sell" : "Buy",
            settlementDate: new Date(),
            amount: this.dpw.amount,
            isNetSettlement: false,
            dealType: "FXON",
            dpwNumber: this.dpw.dpwNumber,
            isDpw: true
          } as FxSpot
        }
      } : {});
  }

  openMenu(event) {
    super.drawMenu(event, [
      {
        text: this.translate.instant("Notifications.CheckRate"),
        callback: () => this.mainAction()
      } as ActionItem,
      {
        text: this.translate.instant("Notifications.Details"),
        callback: () => {
          this._webViewService.navigateFromWebView(["/dpw"], { state: { returnPage: "dashboard" } });
        }
      } as ActionItem
    ]);
  }
}
