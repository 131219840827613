<div
  class="wv-digit-step-two-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible"
  style="z-index: 3"
  *ngIf="activationService.page >= 2"
  [@slideInOut]
>
  <div class="wv-view-digit-step-two">
    <div class="wv-top-progress-bar">
      <div class="wv-top-progress-bar__inner" style="width: 50%"></div>
    </div>

    <div class="wv-section-header">
      <div class="wv-section-header__title" translate>Activation.ClientData</div>
    </div>

    <div class="wv-container wv-container--extra-padding">
      <div class="wv-view-digit-step-two__customer-header" translate>Activation.ClientName</div>
      <div class="wv-view-digit-step-two__customer-data">
        {{ client.companyName }}
      </div>
      <div class="wv-view-digit-step-two__customer-header">Regon</div>
      <div class="wv-view-digit-step-two__customer-data">
        {{ client.regon }}
      </div>
      <div class="wv-view-digit-step-two__customer-header" style="z-index: 3">NIP</div>
      <div class="wv-view-digit-step-two__customer-data">
        {{ client.nip }}
      </div>
      <div class="wv-view-digit-step-two__customer-header">KRS</div>
      <div class="wv-view-digit-step-two__customer-data">
        {{ client.krs }}
      </div>
    </div>
    <div class="wv-container wv-container--grey wv-container--extra-padding">
      <div class="wv-view-digit-step-two__info" translate>Activation.UpdateData</div>
    </div>
    <div class="wv-container wv-container--border-top wv-container--fixed">
      <div class="wv-view-digit-step-two__info-button">
        <button id="digitStepThree" class="wv-global-button ripple" (click)="activationService.next()" translate>Next</button>
      </div>
    </div>
  </div>
</div>
