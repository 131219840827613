import { Component, EventEmitter, Input, Output } from "@angular/core";
import { selectAccount } from "src/app/common/dealing";
import { Account } from "../../common/accounts/account.model";
import { slideBottomAnimation } from "../../common/animations/animations";

@Component({
  selector: "app-account-selection",
  templateUrl: "./account-selection.component.html",
  styleUrls: ["./account-selection.component.scss"],
  animations: [slideBottomAnimation]
})
export class AccountSelectionComponent {
  @Input() public accounts: Account[];
  @Input() public title: string = "AccountSelection.DefaultTitle";
  @Input() public show: boolean;

  @Output() selected = new EventEmitter<Account>();

  constructor() {}

  get currency(): string {
    return this.accounts[0].currency;
  }

  public selectAccount(account: Account) {
    const oldAccount = this.accounts.find(x => x.isSelected);
    selectAccount(oldAccount, account);
    this.selected.emit(account);
  }
}
