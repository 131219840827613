import { Injectable } from "@angular/core";
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { RequestCache } from "../common/_core/cache/request-cache.model";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!isCacheable(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);

    return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache);
  }
}

function isCacheable(req: HttpRequest<any>): boolean {
  return req.method === "GET" && req.headers.get("Cache-Control") !== "no-cache";
}

function sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
  return next.handle(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        cache.put(req, event);
      }
    })
  );
}
