import { Component, Input } from "@angular/core";
import { Account } from "src/app/common/accounts/account.model";
import { Side } from "../../../common/dealing/dealing.model";
import { OrderRequest } from "../order.model";

@Component({
  selector: "app-order-quote-details",
  templateUrl: "./order-quote-details.component.html"
})
export class OrderQuoteDetailsComponent {
  @Input() details: OrderRequest;
  @Input() loading: boolean;

  constructor() {}

  get isTrailingStop(): boolean {
    return this.details.type === "TrailingStop";
  }

  get isLimit(): boolean {
    return this.details.type === "Limit";
  }

  get fromAccount(): Account {
    return this.details.side === Side.Sell ? this.details.settlementAccount : this.details.counterSettlementAccount;
  }

  get toAccount(): Account {
    return this.details.side !== Side.Sell ? this.details.settlementAccount : this.details.counterSettlementAccount;
  }
}
