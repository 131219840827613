import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { of, forkJoin, merge, Observable } from "rxjs";
import { pairwise, map, debounceTime, filter, distinctUntilChanged } from "rxjs/operators";

import { Account } from "@common/accounts/account.model";
import { FxSpotService } from "@components/exchange/exchange.service";
import { AccountService } from "@common/accounts/account.service";
import { WebViewService } from "@common/mobile/webview.service";
import { SettingsStore } from "@common/user/settings.service";
import { ExchangePreferences as Preferences } from "@common/user/settings.model";
import { UserService } from "@common/_core/session/user-service";
import { hideAnimation } from "@common/animations/animations";
import { ExchangeQuoteComponent } from "@components/exchange/exchange-quote/exchange-quote.component";
import { merge as mergeArrays } from "@utils/collection.utils";
import { propCount } from "@utils/object.utils";
import {
  collateralAccountRequiredValidator,
  settlementAccountRequiredValidator,
} from "src/app/validators";
import {
  findDefaultAccount,
  selectAccount,
  findDefaultCurrency,
  findCurrencyPair,
} from "@common/dealing";
import {
  Currency,
  TenorDate,
  productsByTenor,
  Side,
  CurrencyPair,
} from "@common/dealing/dealing.model";
import {
  Collateral,
  ExchangeFormData,
  FxSpot,
  CurrencySetup,
  FormInitialState,
} from "@components/exchange/exchange.model";
//import { currencies } from "@common/fake-backend/fake-backend.db";

@Component({
  selector: "app-exchange",
  templateUrl: "./exchange.component.html",
  styleUrls: ["./exchange.component.scss"],
  animations: [hideAnimation],
})
export class ExchangeComponent implements OnInit, AfterViewInit {
  public exchangeForm: FormGroup;

  public collateralAccounts: Observable<Account[]>;
  public collateralsByProduct: Record<string, Collateral[]> = {};
  public areCollateralsVisible: boolean;
  public product: string;
  public working: boolean;
  public loading: boolean;
  public holidays: string[];
  public ndfVisible: boolean = false;

  private _accountsByCurrency: Record<string, Account[]> = {};
  private _tenorDatesByCurrencyPair: Record<string, TenorDate[]> = {};
  private _preferences: Preferences;
  private _spotDate: Date;
  private _initialState: FormInitialState = {};
  private _dealAccountSelection: boolean;
  private _counterAccountSelection: boolean;
  private _collateralAccountSelection: boolean;

  private _ndfCurrencySetup: CurrencySetup;
  private _regularCurrencySetup: CurrencySetup;

  @ViewChild("quote") quote: ExchangeQuoteComponent;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private fxSpotService: FxSpotService,
    private accountService: AccountService,
    private webViewService: WebViewService,
    private settingsService: SettingsStore
  ) {
    this.fxSpotService.returnPage = history.state.returnPage || "dashboard";
    const data = history.state.initialData;
    if (data) {
      this._initialState = data;
    }

    console.warn("EXCHANGE init_data = ", data)
  }

  get animation() {
    const show =
      this.dealAccountSelection ||
      this.counterAccountSelection ||
      this.collateralAccountSelection ||
      this.working;

    return show ? "modalOpen" : "";
  }

  get currencies(): Currency[] {
    return this.setup?.currencies || [];

  }

  get counterCurrencies(): Currency[] {
    return this.setup?.counterCurrencies?.[this.dealCurrency.value?.code] || [];
  }

  get isForward(): boolean {
    return this.product === productsByTenor.postSpot;
  }

  get dpwNumber() {
    return this._initialState.dpwNumber;
  }

  get dealCurrency() {
    return this.exchangeForm.get("currencies.dealCurrency");
  }

  get dealAccount() {
    return this.exchangeForm.get("dealAccount");
  }

  get dealAmount() {
    return this.exchangeForm.get("dealAmount");
  }

  get counterCurrency() {
    return this.exchangeForm.get("currencies.counterCurrency");
  }

  get counterAccount() {
    return this.exchangeForm.get("counterAccount");
  }

  get tenor() {
    return this.exchangeForm.get("settlementDate.tenor");
  }

  get settlementDate() {
    return this.exchangeForm.get("settlementDate.date");
  }

  get collateralType() {
    return this.exchangeForm.get("collateral.type");
  }

  get collateralAccount() {
    return this.exchangeForm.get("collateral.account");
  }

  get side() {
    return this.exchangeForm.get("side");
  }

  get ndf() {
    return this.exchangeForm.get("ndf");
  }

  get isBuying(): boolean {
    return this.side.value === Side.Buy;
  }

  get dealAccounts(): Account[] {
    return this._accountsByCurrency[this.dealCurrency.value?.code];
  }

  get counterAccounts(): Account[] {
    return this._accountsByCurrency[this.counterCurrency.value?.code];
  }

  get currencyPair(): CurrencyPair {
    return findCurrencyPair(
      this.setup?.currencyPairs,
      this.dealCurrency.value?.code,
      this.counterCurrency.value?.code
    );
  }

  get tenorDates(): TenorDate[] {
    return this._tenorDatesByCurrencyPair[this.currencyPair?.code];
  }

  get dealAccountSelection(): boolean {
    return this._dealAccountSelection;
  }

  set dealAccountSelection(value: boolean) {
    if (value !== this._dealAccountSelection) {
      this._dealAccountSelection = value;
      this.webViewService.updateScreen(
        "exchange",
        value
          ? () => (this.dealAccountSelection = false)
          : () => this.fxSpotService.returnToInitialPage()
      );
    }
  }

  get counterAccountSelection(): boolean {
    return this._counterAccountSelection;
  }

  set counterAccountSelection(value: boolean) {
    if (value !== this._counterAccountSelection) {
      this._counterAccountSelection = value;
      this.webViewService.updateScreen(
        "exchange",
        value
          ? () => (this.counterAccountSelection = false)
          : () => this.fxSpotService.returnToInitialPage()
      );
    }
  }

  get collateralAccountSelection(): boolean {
    return this._collateralAccountSelection;
  }

  set collateralAccountSelection(value: boolean) {
    if (value !== this._collateralAccountSelection) {
      this._collateralAccountSelection = value;
      this.webViewService.updateScreen(
        "exchange",
        value
          ? () => (this.collateralAccountSelection = false)
          : () => this.fxSpotService.returnToInitialPage()
      );
    }
  }

  ngOnInit(): void {
    setTimeout(
      () =>
        this.webViewService.updateScreen("exchange", () =>
          this.fxSpotService.returnToInitialPage()
        ),
      400
    );
    this.createGroup();
    this.loadData();

    this.collateralAccounts = this.accountService.getCollateralAccounts();
  }

  ngAfterViewInit(): void {
    this.onChanges();
    if (this._initialState.onlyNdfDeal) {
      this.webViewService.showBottomSheet({
        title: "MissingAccount.Title",
        text: "MissingAccount.NdfText",
        primaryButton: {
          text: "MissingAccount.NdfAccept",
        },
        additionalButton: {
          text: "MissingAccount.NdfResign",
          callback: () => this.webViewService.navigateFromWebView(["/"]),
        },
      });
    }
  }

  public newDeal() {
    this.settingsService.loadExchangePreferences().subscribe((result) => {
      this.setDealAccount(null);
      this.setCounterAccount(null);
      this.handlePreferences(result);
      this.setDefaultAccount("deal");
      this.setDefaultAccount("counter");
    });
  }

  public onWorkingChanged(value: boolean) {
    if (this.working === value) {
      return;
    }

    this.working = value;
    if (value) {
      this.exchangeForm.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.exchangeForm.enable({ onlySelf: true, emitEvent: false });
      this.updateNdfSection();
      this.updateCollateralAccount(this.collateralType.value);
      this.webViewService.updateScreen("exchange", () => this.fxSpotService.returnToInitialPage());
    }
  }

  public getData(): FxSpot {
    const dealCurrency = this.dealCurrency.value.code,
      counterCurrency = this.counterCurrency.value.code;

    return {
      dealCurrency,
      counterCurrency,
      currencyPair: this.currencyPair.code,
      side: this.side.value,
      settlementDate: new Date(this.settlementDate.value),
      amount: parseFloat(this.dealAmount.value),
      dealAccount: this.dealAccount.value,
      counterAccount: this.counterAccount.value,
      collateral: this.collateralType.value?.type,
      collateralAccount: this.collateralAccount.value,
      isNetSettlement: this.ndf.value || false,
      dealType: this.product,
      dpwNumber: null,
      isDpw: false,

    } as FxSpot
  }

  public openRiskInfo() {
    this.webViewService.openUrl(this.userService.appData.riskInfoLink);
  }

  private onChanges(): void {
    this.fxSpotService.working.subscribe(this.onWorkingChanged.bind(this));

    merge(...[this.observeCurrencies("deal"), this.observeCurrencies("counter")]).subscribe(
      ({ isNewPair, source }) => {
        if (isNewPair) {
          this.updateCurrencyPair();
        }

        if (source !== "deal" || !this.ndf.value) {
          this.setDefaultAccount(source);
        }
      }
    );

    this.ndf.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      this.toggleNdf(value);
    });

    this.settlementDate.valueChanges.pipe(debounceTime(300)).subscribe(() => this.updateProduct());

    this.collateralType.valueChanges.subscribe((value: Collateral) =>
      this.updateCollateralAccount(value)
    );
  }

  private observeCurrencies(source: "deal" | "counter") {
    return this[`${source}Currency`].valueChanges.pipe(
      pairwise(),
      filter(([prev, next]) => prev?.code !== next.code),
      map(([, { code }]) => {
        const opposite = source === "deal" ? "counter" : "deal";
        const isNewPair = code !== this[`${opposite}Currency`].value?.code;
        return { isNewPair, source };
      })
    );
  }

  private updateCollateralAccount(value: Collateral) {
    if (value?.type === "Deposit") {
      this.collateralAccount.enable();
    } else {
      this.collateralAccount.reset();
      this.collateralAccount.disable();
    }
  }

  private handleFormData(data: ExchangeFormData): void {
    const { currencies, counterCurrencies, currencyPairs, accountsByCurrency } = data;
    this._regularCurrencySetup = { currencies, counterCurrencies, currencyPairs };
    this._accountsByCurrency = { ...accountsByCurrency };
    this.setDefaultCurrencies();
    this.setDefaultTenor();
    this.setDefaultAccount("deal");
    this.setDefaultAccount("counter");
  }

  private handleNdfFormData(data: CurrencySetup) {
    this.ndf.enable();
    this._ndfCurrencySetup = data;
    if (this._initialState.onlyNdfDeal) {
      this.ndf.setValue(true);
      this.toggleNdf(true);
      this.ndf.disable();
    }
  }

  private handlePreferences(preferences: Preferences): void {
    this._preferences = preferences;
    this.side.setValue(this.defaults.side);
    this.dealAmount.setValue(this.defaults.amount);
    this.setDefaultCurrencies();
    this.setDefaultTenor();
  }

  private setDefaultAccount(which: "deal" | "counter") {
    const defaultAccount = findDefaultAccount(this[`${which}Accounts`]);
    which == "deal" ? this.setDealAccount(defaultAccount) : this.setCounterAccount(defaultAccount);
  }

  private setDefaultTenor(): void {
    this.tenor.setValue(this.defaults.nearTenor);
    this.updateProduct();
  }

  private setDefaultCurrencies(): void {
    if (!this.currencies?.length) {
      // in case preferences are fetched before currencies
      return;
    }

    const dealCurrency = findDefaultCurrency({
      currency: this.defaults.dealCurrency,
      defaultCurrency: "EUR",
      currencies: this.currencies,
      index: 0,
    });

    const counterCurrency = findDefaultCurrency(

      {
        currency: this.defaults.counterCurrency,
        defaultCurrency: "PLN",
        currencies: this.setup?.counterCurrencies[dealCurrency.code],
        index: 1,

      });

    this.dealCurrency.setValue(dealCurrency);
    this.counterCurrency.setValue(counterCurrency);
    this.updateCurrencyPair();
  }

  private updateCurrencyPair(): void {
    const { currencyPair, tenorDates } = this;

    if (!this.currencyPair) {
      return;
    }

    const dates$ = tenorDates
      ? of(tenorDates)
      : this.fxSpotService.getTenorDates(currencyPair.code);

    dates$.subscribe((tenorDates) => {
      this._tenorDatesByCurrencyPair[currencyPair.code] = tenorDates;
      this._spotDate = tenorDates.find((x) => x.tenor === "SPOT")?.date;
    });

    this.holidays = mergeArrays(
      this.dealCurrency.value.holidays || [],
      this.counterCurrency.value.holidays || []
    );
  }

  private updateProduct(): void {
    const tenor = this.tenor.value;

    const product =
      tenor && productsByTenor[tenor]
        ? productsByTenor[tenor]
        : new Date(this.settlementDate.value) < this._spotDate
          ? productsByTenor.preSpot
          : productsByTenor.postSpot;

    if (product === this.product) {
      return;
    }

    this.product = product;
    this.updateCollaterals();
    this.updateNdfSection();
  }

  private updateNdfSection() {
    if (this.isForward) {
      this.ndfVisible = true;
    } else {
      this.ndf.setValue(false);
      this.ndfVisible = false;
    }
  }

  private toggleNdf(toggle: boolean) {
    if (this.working) {
      return;
    }

    if (!toggle) {
      return this.dealAccount.enable();
    }

    this.dealAccount.disable();
    this.setDealAccount(null);

    if (this._ndfCurrencySetup) {
      return;
    }

    this.fxSpotService.getNdfData().subscribe((setup) => {
      this._ndfCurrencySetup = setup;
    });
  }

  private updateCollaterals() {
    const { product, collateralsByProduct } = this;
    const currentCollaterals = collateralsByProduct[product];
    const collateralsObservable = currentCollaterals
      ? of(currentCollaterals)
      : this.fxSpotService.getCollateralTypes(product);

    collateralsObservable.subscribe((collaterals) => {
      const hasCollaterals = collaterals.length > 0;
      const onlyBlock = collaterals.every((x) => x.type === "AmountBlock");

      this.collateralsByProduct[product] = collaterals;
      this.areCollateralsVisible = !onlyBlock && hasCollaterals;

      if (!hasCollaterals) {
        return;
      }

      this.collateralType.setValue(collaterals.length === 1 ? collaterals[0] : "");
    });
  }

  public onCounterAccountSelection(account: Account): void {
    this.counterAccountSelection = false;
    this.setCounterAccount(account);
  }

  public onDealAccountSelection(account: Account): void {
    this.dealAccountSelection = false;
    this.setDealAccount(account);
  }

  public onCollateralAccountSelection(account: Account): void {
    this.collateralAccountSelection = false;
    selectAccount(this.collateralAccount.value, account);
    this.collateralAccount.setValue(account);
  }

  private setDealAccount(account: Account) {
    selectAccount(this.dealAccount.value, account);
    this.dealAccount.setValue(account);
  }

  private setCounterAccount(account: Account) {
    selectAccount(this.counterAccount.value, account);
    this.counterAccount.setValue(account);
  }

  public openAccountOneSelection(): void {
    if (!this.isBuying) {
      this.dealAccountSelection = true;
    } else {
      this.counterAccountSelection = true;
    }
  }

  public openAccountTwoSelection(): void {
    if (this.isBuying) {
      this.dealAccountSelection = true;
    } else {
      this.counterAccountSelection = true;
    }
  }

  public openSettlementAccountSelection(): void {
    this.counterAccountSelection = true;
  }

  public openCollateralAccountSelection(): void {
    this.collateralAccountSelection = true;
  }

  private createGroup(): void {
    this.exchangeForm = this.formBuilder.group({
      side: [null, Validators.required],
      currencies: this.formBuilder.group({}),
      dealAmount: [null, [Validators.required, Validators.min(1)]],
      counterAmount: [null],
      ndf: { value: false, disabled: true },
      dealAccount: [null, settlementAccountRequiredValidator],
      counterAccount: [null, Validators.required],
      settlementDate: this.formBuilder.group({}),
      collateral: this.formBuilder.group({
        type: null,
        account: [{ value: null, disabled: true }, collateralAccountRequiredValidator],
      }),
    });
  }

  private loadData() {
    this.loading = true;
    const preferences$ = this.settingsService.loadExchangePreferences();
    const formData$ = this.fxSpotService.getFormData();
    const ndfData$ = this._initialState.onlyNdfDeal
      ? this.fxSpotService.getNdfData()
      : of(this._ndfCurrencySetup);
    forkJoin([preferences$, formData$, ndfData$]).subscribe({
      complete: () => (this.loading = false),
      next: ([preferences, data, ndfData]) => {
        this.handleNdfFormData(ndfData);
        this.handlePreferences(preferences);
        this.handleFormData(data);
      },
    });
  }

  private get setup(): CurrencySetup {
    return this.ndf?.value
      ? this._ndfCurrencySetup || this._regularCurrencySetup
      : this._regularCurrencySetup;
  }

  private get defaults(): Preferences {
    const hasInitialState = propCount(this._initialState) > 0;
    const selectedPairEqualToPreferences =
      hasInitialState &&
      this._initialState.currency === this._preferences.dealCurrency &&
      this._initialState.counterCurrency === this._preferences.counterCurrency;

    return {
      dealCurrency: this._initialState.currency || this._preferences.dealCurrency,
      counterCurrency: this._initialState.counterCurrency || this._preferences.counterCurrency,
      amount:
        this._initialState.amount ||
        (!hasInitialState || selectedPairEqualToPreferences ? this._preferences.amount : 0),
      side: this._initialState.side || this._preferences.side,
      nearTenor: this._initialState.tenor || this._preferences.nearTenor || "TOD",
    } as Preferences;
  }
}
