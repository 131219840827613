<div
  class="wv-global-modal wv-global-modal--info wv-global-modal--displayed wv-global-modal--visible"
>
  <app-preloader *ngIf="loading || saving" [absolute]="saving"></app-preloader>
  <form [formGroup]="alertForm" [hidden]="loading || success || failure">
    <div #container class="wv-container wv-container--grey">
      <app-input-switch ngDefaultControl formControlName="side" [options]="['Sell', 'Buy']"></app-input-switch>

      <app-select-currency-pair formGroupName="currencies"
                                [dealCurrencies]="currencies"
                                [counterCurrencies]="counterCurrencies"></app-select-currency-pair>

      <app-input-wrapper for="alert-amount" label="DealAmount" [control]="dealAmount">
        <app-input-amount ngDefaultControl
                          formControlName="dealAmount"
                          ngId="alert-amount"
                          placeholder="InsertAmount"
                          [decimals]="dealCurrency.value?.decimals"></app-input-amount>
        <span detail>{{ dealCurrency.value?.code }}</span>
      </app-input-wrapper>

      <app-input-wrapper for="alert-rate" label="ExpectedRate" [control]="rate">
        <app-input-amount ngDefaultControl formControlName="rate" ngId="alert-rate" [decimals]="4"></app-input-amount>
        <span detail>{{ currencyPair?.code | pair }}</span>
      </app-input-wrapper>

      <app-input-wrapper for="date" label="ValidTill" [control]="expirationDate">
        <app-input-date ngDefaultControl
                        ngId="date"
                        formControlName="expirationDate"
                        [min]="minDate"
                        [max]="maxDate"></app-input-date>
      </app-input-wrapper>

      <app-input-phone-number *ngIf="useSmsNotification"
                              formGroupName="phone"
                              [prefixes]="phonePrefixes"></app-input-phone-number>

      <div [ngClass]="{ 'wv-global-button--sticky-to-bottom': shouldButtonBeSticky }" *ngIf="!loading">
        <button class="wv-global-button" [disabled]="alertForm.invalid" (click)="save()">
          {{ "SaveAlert" | translate }}
        </button>
      </div>
    </div>
  </form>

  <app-alert-failure
    *ngIf="failure"
    [title]="errorTitle"
    [description]="errorDescription"
    [hideRetry]="hideRetry"
    (newAlert)="openNewAlert()"
  ></app-alert-failure>
  <app-alert-success *ngIf="success" [alert]="alertRequest" (newAlert)="openNewAlert(true)"></app-alert-success>
</div>
