import { Component, Input } from "@angular/core";
import { Collateral } from "../../exchange-history/exchange-history.model";

@Component({
  selector: "app-summary-collateral",
  templateUrl: "summary-collateral.component.html"
})
export class SummaryCollateralComponent {
  @Input() collateral: Collateral;
  constructor() {}
}
