import { Side } from "../dealing/dealing.model";
import { DepositPreferences, ExchangePreferences } from "./settings.model";

const exchange: ExchangePreferences = {
  side: Side.Buy,
  amount: 10,
  dealCurrency: "EUR",
  counterCurrency: "PLN",
  nearTenor: "TOD"
};

const deposit: DepositPreferences = {
  amount: 1000,
  currency: "PLN",
  startTenor: "ON",
  endTenor: "1D"
};

export default { exchange, deposit };
