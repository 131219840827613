import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroupDirective } from "@angular/forms";

import { uid } from "@utils/string.utils";
import { resetFieldWhenDefault } from "@utils/form.utils";
import { FormGroupComponent } from "../_form-group/form-group.component";

@Component({
  selector: "app-input-range",
  templateUrl: "input-range.component.html"
})
export class InputRangeComponent extends FormGroupComponent implements OnInit, AfterViewInit {
  @Input() type: "date" | "amount";
  @Input("label") groupLabel?: string;
  @Input() labelFrom?: string;
  @Input() labelTo?: string;
  @Input() placeholder?: Placeholder;
  @Input() decimals: number = 0;

  fromId: string;
  toId: string;

  constructor(_groupDirective: FormGroupDirective, private _fb: FormBuilder) {
    super(_groupDirective);
  }

  get from(): AbstractControl {
    return this.group.get("from");
  }

  get to(): AbstractControl {
    return this.group.get("to");
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setIds();
    this.addControls();
  }

  ngAfterViewInit(): void {
    resetFieldWhenDefault(this.from);
    resetFieldWhenDefault(this.to);
  }

  private addControls() {
    this.group.addControl("from", this._fb.control(null));
    this.group.addControl("to", this._fb.control(null));
  }

  private setIds() {
    const { type } = this;
    const id = uid();
    this.fromId = `${type}-range-from-${id}`;
    this.toId = `${type}-range-to-${id}`;
  }
}

type Placeholder = { from?: string; to?: string };
