import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { NotificationsService } from "./notifications.service";
import { UserNotification } from "./notifications.model";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  public dates: string[];
  public form: FormGroup;
  public expanded: boolean = false;

  constructor(private notificationService: NotificationsService, private _fb: FormBuilder) {}

  get dateIndex() {
    return this.form.get("dateIndex");
  }

  get notifications(): UserNotification[] {
    return this.notificationService.getNotifications(this.dateIndex.value) || [];
  }

  get loading(): boolean {
    return this.notificationService.loading;
  }

  get isToggleVisible(): boolean {
    return this.notifications.length > 3;
  }

  get limit() {
    return this.expanded ? undefined : 3;
  }

  ngOnInit(): void {
    this.createGroup();
    this.notificationService.loadDates().subscribe((dates) => {
      this.dates = dates;
      this.notificationService.loadNotifications(dates[1], true);
    });

  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  private createGroup() {
    this.form = this._fb.group({
      dateIndex: [1],
    });

    this.dateIndex.valueChanges.subscribe((i: number) => {
      this.notificationService.loadNotifications(this.dates[i]);
    });
  }
}
