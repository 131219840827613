import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { sleep, slideRightAnimation } from "../../common/animations/animations";
import { WebViewService } from "../../common/mobile/webview.service";
import { IndicativePair } from "../indicatives/indicatives.model";
import { IndicativesService } from "../indicatives/indicatives.service";
import { filter, take } from "rxjs/operators";

@Component({
  selector: "app-pair-selection",
  templateUrl: "./pair-selection.component.html",
  styleUrls: ["./pair-selection.component.scss"],
  animations: [slideRightAnimation]
})
export class PairSelectionComponent implements OnInit {
  @Input() pair: string;
  @Input() currency: string;
  @Output() pairSelected = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private indicativesService: IndicativesService,
    private webViewService: WebViewService
  ) {}

  public show: boolean = true;
  public form: FormGroup;
  private _pairs: IndicativePair[];

  ngOnInit() {
    this.webViewService.updateScreen("indicativePairs", () => this.emit(""));

    this.createGroup();

    this.indicativesService.pairs
      .pipe(
        filter(x => x.length > 0),
        take(1)
      )
      .subscribe(pairs => {
        this._pairs = !this.currency ? pairs : pairs.filter(x => [0, 3].some(y => x.code.substr(y, 3) === this.currency));
        if (this.pair) {
          this.form.get("pair").setValue(this.pair);
        }
      });

    this.form.get("pair").valueChanges.subscribe(value => this.emit(value));
  }

  get selectedPairs() {
    return this._pairs.filter(x => x.isSelected);
  }

  get unselectedNonDefaultOrderingPairs() {
    return this._pairs.filter(x => !x.isSelected && x.nonDefaultSortingOrder);
  }

  get unselectedDefaultOrderingPairs() {
    return this._pairs.filter(x => !x.isSelected && !x.nonDefaultSortingOrder);
  }

  get search() {
    return this.form.get("search").value;
  }

  public hide(item: IndicativePair) {
    return this.search && !item.code.toLowerCase().includes(this.search.toLowerCase());
  }

  private createGroup(): void {
    this.form = this.formBuilder.group({
      pair: [null, Validators.required],
      search: [null]
    });
  }

  private emit(value: string) {
    this.show = false;
    sleep().then(() => {
      this.pairSelected.emit(value);
    });
  }
}
