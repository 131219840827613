import { Component, Input, ElementRef } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { ErrorKeyValue } from "../inputs.model";

@Component({
  selector: "app-input-wrapper",
  templateUrl: "input-wrapper.component.html",
  styleUrls: ["./input-wrapper.component.scss"]
})
export class InputWrapperComponent {
  @Input() label: string;
  @Input() for: string;
  @Input() control?: AbstractControl;
  @Input("errors") errorOverrides?: Record<string, ErrorKeyValue | string>;

  constructor(private element: ElementRef) {}

  get hasError(): boolean {
    return this.control && this.control.invalid && (this.control.dirty || this.control.touched);
  }

  get hasDetail() {
    return !!this.element.nativeElement.querySelector("[detail]");
  }
}
