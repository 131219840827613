<app-section-header [title]="'Customers' | translate"></app-section-header>

<div *ngIf="customers$ | async as customers; else loading">
  <div class="wv-menu-item ripple wv-menu-item--double" *ngFor="let customer of customers" (click)="set(customer)">
    <div class="wv-menu-item__header">
      <span>{{ customer.name }}</span>
    </div>
    <div class="wv-menu-item__subheader">
      <span>{{ customer.shortName }}</span>
    </div>
  </div>
</div>

<ng-template #loading> <app-preloader [absolute]="false" height="100vh"></app-preloader></ng-template>
<app-preloader *ngIf="selecting" [absolute]="true"></app-preloader>
