import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import { AccountService } from "@common/accounts/account.service";
import { Account } from "@common/accounts/account.model";
import { UserPreference } from "./user-profile.model";
import { WebViewService } from "@common/mobile/webview.service";

@Injectable({ providedIn: "root" })
export class UserProfileService {
  constructor(private _http: HttpClient, private _accountService: AccountService, private _wv: WebViewService) {}

  get defaultAccountsByCurrency() {
    return this._accountService.defaultAccountsByCurrency;
  }

  getConfigurablePreferences(): Observable<UserPreference[]> {
      return this._http.get<UserPreference[]>("/settings", { headers: noCacheHeader });
  }

  savePreference(key: string, value: any) {
    return this._http.post("/settings", JSON.stringify({ key, value }));
  }

  setDefaultAccounts(accounts: Account[], newDefault: Account) {
    const updatedAccounts = accounts.map(account => ({
      ...account,
      isDefault: account.number === newDefault.number
    }));

    this._accountService.saveDefaultAccounts(updatedAccounts).subscribe(() => {
      this._wv.showBottomSheet({
        title: "Profile.DefaultAccounts.Message.Title",
        text: "Profile.DefaultAccounts.Message.Text",
        primaryButton: { text: "Ok" }
      });
    });
  }
}
