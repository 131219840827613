<div class="wv-digit-step-five-modal wv-global-modal wv-global-modal--displayed wv-global-modal--visible" style="z-index: 6" *ngIf="activationService.page>=5" [@slideInOut]>
  <div class="wv-view-digit-wait">
    <div class="wv-container">
      <div class="wv-view-digit-wait__ico-container">
        <div class="wv-view-digit-wait__ico"></div>
      </div>
      <div class="wv-view-digit-wait__status">
        {{progress}}%
      </div>
      <div class="wv-view-digit-wait__status-bar">
        <div class="wv-view-digit-wait__status-bar-inner"></div>
      </div>
      <div class="wv-view-digit-wait__title" translate>
        Activation.InProgress
      </div>
      <div class="wv-view-digit-wait__info" [innerHTML]="'Activation.WaitingMessage' | translate">
      </div>
    </div>
  </div>
</div>
