import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, throwIfEmpty } from "rxjs/operators";
import { AppData } from "./app-data";
import { noCacheHeader } from "../cache/request-cache.model";
import { MessageItem } from "@components/message-item/message-item.model";
import { hasRole } from "@utils/cookie.utils";
import { WebViewService } from "@common/mobile/webview.service";
import { MobileApiService } from "@common/mobile/api.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  public appData: AppData;

  constructor(
    private _http: HttpClient,
    private _api: MobileApiService,                               //_TO_DELETE
  ) { }

  async load(): Promise<void> {
    const appDataAPPOld = await this._http.get<AppData>("/user", { headers: noCacheHeader }).pipe(
      tap(data => {
        console.error("fetch appDataAPP = ", JSON.stringify(data))    //_TO_DELETE                                          
      }),
      throwIfEmpty()
    ).toPromise()
      .catch(err => {
        console.error("!!!! CANNOT FETCH USER DATA = ", err)
        return {
          isDarkMode: false
        };
      });
    console.error("appDataAPP = ", appDataAPPOld)                    //_TO_DELETE


    const appDataAPP = {
      isDarkMode: window.matchMedia("(prefers-color-scheme: dark)").matches,
    }
    const appData: AppData = {
      userName: "Ala",
      customerName: "KLIENT TESTOWY",
      customerShortName: "K123456",
      platform: "d",
      source: "e",
      language: "pl",
      isDarkMode: appDataAPP?.isDarkMode ? appDataAPP.isDarkMode : false,
      tokenExpiration: 1800,
      riskInfoLink: "https://www.pkobp.pl/korporacje-i-samorzady/produkty-skarbowe/ryzyko-walutowe-zabezpieczenia/fx-forward/",
      appInfoLink: "https://app.pkobp.pl",
      hasAgreement: false,
      hasForex: false,
      unreadMessages: [],
      leiMessage: { show: false, expired: false, lei: { code: "923456789", expirationDate: new Date() }, infoLink: "https://info.pkobp.pl" },
    }

    this.appData = appData;
  }

  get leiMessage() {
    return this.appData.leiMessage;
  }

  get hasUnreadMessages(): boolean {
    return this.appData.unreadMessages.length > 0;
  }

  get unreadMessages(): MessageItem[] {
    return this.appData.unreadMessages;
  }

  get hasCustomerForex(): boolean {
    return this.appData.hasForex;
  }

  get hasAgreement(): boolean {
    return this.appData.hasAgreement;
  }

  get isPotentialCustomer(): boolean {
    return hasRole("PotentialCustomer");
  }

  get noAccess(): boolean {
    return !hasRole("Customer");
  }

  get noForex(): boolean {
    return !hasRole("FxSpot|FxForward|FxSwap");
  }

  get hasMm(): boolean {
    return hasRole("MmDeposit");
  }

  get hasCustomer(): boolean {
    return hasRole("HasCustomer");
  }
}
