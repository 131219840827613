import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";

import { slideRightAnimationWithoutInitial } from "@common/animations/animations";
import { WebViewService } from "@common/mobile/webview.service";
import {
  countDirtyControls,
  markAsPristineWhenDefault,
  resetFieldWhenDefault,
  setSingleField,
} from "@utils/form.utils";
import { OrderHistoryService } from "../order-history.service";

@Component({
  selector: "app-order-history-filters",
  templateUrl: "./order-history-filters.component.html",
  styleUrls: ["./order-history-filters.component.scss"],
  animations: [slideRightAnimationWithoutInitial],
})
export class OrderHistoryFiltersComponent implements OnInit {
  public filterForm: FormGroup;
  public pairSelection: boolean = false;

  private _filtersOpen: boolean = false;
  private _refreshOnClose: boolean;

  get filtersOpen() {
    return this._filtersOpen;
  }

  set filtersOpen(value) {
    if (value) {
      this._refreshOnClose = false;
      this.webViewService.updateScreen("orderFilters", () => {
        this.filtersOpen = false;
        if (this._refreshOnClose) {
          this.filter();
        }
      });
      setTimeout(() => this.setDefaultValues(), 0);
    } else {
      this.webViewService.updateScreen("history");
    }
    this._filtersOpen = value;
  }

  @Output() filterEvent = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    public orderHistoryService: OrderHistoryService,
    private webViewService: WebViewService
  ) {}

  ngOnInit(): void {
    this.createGroup();

    this.currencyPair.valueChanges.subscribe((pair) => {
      this.orderHistoryService.filterCurrencies(pair);
      if (pair) {
        this.currencyPair.markAsDirty();
      }
      if (!this.orderHistoryService.currencies.getValue().includes(this.currency)) {
        this.filterForm.get("currency").reset();
      }
    });

    markAsPristineWhenDefault(this.filterForm.get("currency"), null);
    markAsPristineWhenDefault(this.side, "All");
    markAsPristineWhenDefault(this.type, "All");
    resetFieldWhenDefault(this.filterForm.get("orderId"));
  }

  clear() {
    this.filterForm.reset();
    this.side.setValue("All");
    this.type.setValue("All");
    if (this.filtersOpen) {
      this._refreshOnClose = true;
    } else {
      this.filter();
    }
  }

  get orderId() {
    return this.filterForm.get("orderId").value;
  }

  get expirationDateFrom() {
    return this.filterForm.get("expirationDate.from")?.value;
  }

  get expirationDateTo() {
    return this.filterForm.get("expirationDate.to")?.value;
  }

  get type() {
    return this.filterForm.get("type");
  }

  get side() {
    return this.filterForm.get("side");
  }

  get currencyPair() {
    return this.filterForm.get("currencyPair");
  }

  get rateFrom() {
    return this.filterForm.get("rate.from")?.value;
  }

  get rateTo() {
    return this.filterForm.get("rate.to")?.value;
  }

  get amountFrom() {
    return this.filterForm.get("amount.from")?.value;
  }

  get amountTo() {
    return this.filterForm.get("amount.to")?.value;
  }

  get currency() {
    return this.filterForm.get("currency").value;
  }

  filter() {
    this.orderHistoryService.filters = {
      currency: this.currency,
      currencyPair: this.currencyPair.value,
      expirationDateEnd: this.expirationDateTo,
      expirationDateStart: this.expirationDateFrom,
      orderId: this.orderId,
      orderType: this.type.value,
      pageNumber: 1,
      pageSize: 10,
      side: this.side.value,
      status: this.orderHistoryService.filters.status,
      amountFrom: this.amountFrom,
      amountTo: this.amountTo,
      rateFrom: this.rateFrom,
      rateTo: this.rateTo,
    };
    this.orderHistoryService.filtersCount = countDirtyControls(this.filterForm.controls);
    this.filtersOpen = false;
    this.orderHistoryService.allLoaded = true;
    setTimeout(() => this.filterEvent.emit(), 400);
  }

  setDefaultValues() {
    setSingleField(this.filterForm, "type", this.orderHistoryService.filters.orderType, "All");
    setSingleField(this.filterForm, "side", this.orderHistoryService.filters.side, "All");
    setSingleField(this.filterForm, "orderId", this.orderHistoryService.filters.orderId);
    setSingleField(this.filterForm, "currency", this.orderHistoryService.filters.currency);
    setSingleField(this.filterForm, "currencyPair", this.orderHistoryService.filters.currencyPair);

    setSingleField(
      this.filterForm,
      "expirationDate.from",
      this.orderHistoryService.filters.expirationDateStart
    );
    setSingleField(
      this.filterForm,
      "expirationDate.to",
      this.orderHistoryService.filters.expirationDateEnd
    );
    setSingleField(this.filterForm, "rate.from", this.orderHistoryService.filters.rateFrom);
    setSingleField(this.filterForm, "rate.to", this.orderHistoryService.filters.rateTo);
    setSingleField(this.filterForm, "amount.from", this.orderHistoryService.filters.amountFrom);
    setSingleField(this.filterForm, "amount.to", this.orderHistoryService.filters.amountTo);
  }

  selectPair() {
    this.pairSelection = true;
  }

  onPairSelected(pair: string) {
    this.pairSelection = false;

    if (pair) {
      this.currencyPair.setValue(pair);
    }

    this.webViewService.updateScreen("orderFilters", () => {
      this.filtersOpen = false;
      if (this._refreshOnClose) {
        this.filter();
      }
    });
  }

  createGroup() {
    const filters = this.orderHistoryService.filters;
    this.filterForm = this.formBuilder.group({
      orderId: [filters.orderId],
      expirationDate: this.formBuilder.group({}),
      amount: this.formBuilder.group({}),
      rate: this.formBuilder.group({}),
      type: [filters.orderType],
      side: [filters.side],
      currencyPair: [filters.currencyPair],
      currency: [filters.currency],
    });
  }
}
