import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebViewService } from "src/app/common/mobile/webview.service";
import { DpwElement } from "./dpw-element/dpw-element.model";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ExchangeQuoteComponent } from "@components/exchange/exchange-quote/exchange-quote.component";
import { FxSpot } from "@components/exchange/exchange.model";
import { hideAnimation } from "@common/animations/animations";
import { FxSpotService } from "../exchange/exchange.service";

@Component({
  selector: "app-dpw",
  templateUrl: "./dpw.component.html",
  animations: [hideAnimation]
})
export class DpwComponent implements OnInit {
  data: DpwModel;
  isInitialized: boolean;
  @ViewChild("quote") quote: ExchangeQuoteComponent;

  constructor(private webViewService: WebViewService, private forexService: FxSpotService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    if (history.state.initialData) {
      this.isInitialized = true;
      this.forexService.returnPage = "dashboard";
      setTimeout(() => {
        this.startDeal(history.state.initialData);
      }, 0);
    }
    else {
      this.webViewService.updateScreen("dpw");
      this.forexService.returnPage = "dpw";
    }

    this.load();
  }

  startDeal(data: FxSpot) {
    this.quote.startGetQuote(data);
  }

  onDealCompleted(dpwNumber) {
    this.webViewService.updateScreen("dpw");
    if (!dpwNumber) return;
    this.data.active = this.data.active.filter(x => x.ssrid !== dpwNumber);
  }

  private load() {
    this.httpClient.get<DpwElement[]>("/dpw/history").pipe(
      map(list => {
        const active = list.filter(({ isActive }) => isActive);
        const pending = list.filter(({ isActive }) => !isActive);
        return { active, pending, isEmpty: !list.length };
      }),
      tap(() => this.isInitialized = false)
    ).subscribe((data) => this.data = data);
  }
}

interface DpwModel {
  active: DpwElement[];
  pending: DpwElement[];
  isEmpty: boolean;
}
