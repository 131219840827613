import { Currency } from "./dealing.model";
interface CurrencyPick {
  currency?: string;
  currencies: Currency[];
  defaultCurrency: string;
  index?: number;
}

export const baseCurrency = (pair: string): string => pair.substring(0, 3);

export const quoteCurrency = (pair: string): string => pair.substring(3);

export const breakCurrencyPair = (pair: string): [string, string] =>
  pair ? [baseCurrency(pair), quoteCurrency(pair)] : [undefined, undefined];

function getCurrencyByIndex(currencies: Currency[] = [], index: number = -1) {
  return (index >= 0 && currencies.length - 1 >= index && currencies[index]) || undefined;
}

/**
 * Returns the default currency from the array.
 */
export const findDefaultCurrency = ({
  currency,
  currencies = [],
  defaultCurrency,
  index = -1,
}: CurrencyPick) => {
  const search = currencies.find((x) => x.code === (currency || defaultCurrency));
  return (
    
    search ||
    currencies.find((x) => x.code === defaultCurrency) ||
    getCurrencyByIndex(currencies, index)
  );
};

/**
 * Returns a currency pair consisting of given currencies.
 * @param pairs An array to search.
 */
export const findCurrencyPair = (pairs: any[], currencyA: string, currencyB: string) =>
  pairs?.find(({ code }) => code.includes(currencyA) && code.includes(currencyB));

export const parseHolidays = (currencies: Currency[]): Currency[] =>
  currencies.map((currency) => {
    const holidays = currency.holidays?.map((date) => date.slice(0, 10)) || [];
    return { ...currency, holidays };
  });
