import { Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";

import { hideAnimation } from "@common/animations/animations";
import { Filters } from "@common/history/history.model";
import { WebViewService } from "@common/mobile/webview.service";
import { DepositService } from "./deposit-history.service";
import { DepositHistoryFiltersComponent } from "./deposit-history-filters/deposit-history-filters.component";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-deposit-history",
  templateUrl: "./deposit-history.component.html",
  styleUrls: ["./deposit-history.component.scss"],
  animations: [hideAnimation],
})
export class DepositHistoryComponent implements OnInit {
  public statuses = ["NotMatured", "Matured", "All"];

  @ViewChild("filters", { static: true })
  private _filters: DepositHistoryFiltersComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private depositService: DepositService,
    private webViewService: WebViewService
  ) { }

  get hasDeals() {
    return this.depositService.hasDeals;
  }

  get startStatus() {
    return this.depositService.filters?.type;
  }

  get filtersCount() {
    return this.depositService.filtersCount;
  }

  get filters(): Filters {
    return this.depositService.filters;
  }

  get deposits() {
    return this.depositService.deposits;
  }

  get filtersOpen(): boolean {
    return this._filters.filtersOpen;
  }

  get loading() {
    return this.depositService.loading;
  }

  ngOnInit(): void {
    this.webViewService.updateScreen("depositHistory", () =>
      this.webViewService.navigateFromWebView(["/more"])
    );
    if (history.state.noRefresh) {
      return;
    }
    this.depositService.reset(true);
    this.depositService.getHistory();
  }

  openFilters() {
    return (this._filters.filtersOpen = true);
  }

  onFilter() {
    this.depositService.reset();
    this.depositService.getHistory();
  }

  onStatusChanged(value) {
    this.depositService.reset();
    this.filters.type = value;
    this.depositService.getHistory();
  }

  @HostListener("body:scroll", [])
  onScroll(): void {
    /*  // <<-------- NOWE
      if (this.depositService.end || this.filtersOpen) {         
        return;
      }
  
      const { clientHeight } = document.getElementsByClassName("wv-view-my-negotiable-deposits")[0];
  
      if (this.document.body.clientHeight + this.document.body.scrollTop >= clientHeight - 30) {
        this.depositService.getHistory();
      }
    */
  }
}
