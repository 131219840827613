<button
  *ngIf="hasClick; else back"
  class="wv-global-button ripple"
  [class.wv-global-button--white-normal]="isNotDefault"
  (click)="button.click()"
>
  {{ button.text | translate }}
</button>

<ng-template #back>
  <button class="wv-global-button ripple" [class.wv-global-button--white-normal]="isNotDefault" backButton>
    {{ button.text | translate }}
  </button>
</ng-template>
