import { Pipe, PipeTransform } from "@angular/core";
import { datepickerFormat } from "@utils/time.utils";

@Pipe({
  name: "datepicker"
})
export class DatepickerPipe implements PipeTransform {
  transform(date: Date): string {
    return date && datepickerFormat(date);
  }
}
