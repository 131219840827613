import { Component, Input, OnInit } from "@angular/core";
import { WebViewService } from "../../../common/mobile/webview.service";
import { DocumentItem } from "../document-list.model";

@Component({
  selector: "app-document-item",
  templateUrl: "./document-item.component.html",
  styleUrls: ["./document-item.component.scss"]
})
export class DocumentItemComponent implements OnInit {
  @Input() document: DocumentItem;

  constructor(private webViewService: WebViewService) {}

  ngOnInit(): void {}

  showPdf() {
    this.webViewService.displayPdf(this.document.link);
  }
}
