<form [formGroup]="settingsForm" class="wv-container wv-container--grey wv-container--extra-padding">
  <div class="info">
    {{ "DefaultSettingsSection.Exchange.Description" | translate }}
  </div>

  <app-input-wrapper label="DefaultSettingsSection.Exchange.Side" for="side">
    <select id="side" formControlName="side">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let side of sides" [ngValue]="side">
        {{ side | translate }}
      </option>
    </select>
  </app-input-wrapper>
  
  <app-input-wrapper label="DefaultSettingsSection.Exchange.DealCurrency" for="dealCurrency">
    <select id="dealCurrency" formControlName="dealCurrency">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let currency of defaultSettingsService.currencies | async" [ngValue]="currency">
        {{ currency }}
      </option>
    </select>
  </app-input-wrapper>
  <app-input-wrapper label="DefaultSettingsSection.Exchange.CounterCurrency" for="counterCurrency">
    <select id="counterCurrency" formControlName="counterCurrency">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let currency of defaultSettingsService.currencies | async" [ngValue]="currency">
        {{ currency }}
      </option>
    </select>
  </app-input-wrapper>

  <app-input-wrapper label="DefaultSettingsSection.Exchange.Amount" for="amount">
    <app-input-amount
      ngDefaultControl
      formControlName="amount"
      ngId="amount"
      placeholder="DefaultSettingsSection.Exchange.PlaceholderAmount"
      [decimals]="2"
    >
    </app-input-amount>
  </app-input-wrapper>

  <app-input-wrapper label="DefaultSettingsSection.Exchange.NearTenor" for="nearTenor">
    <select id="nearTenor" formControlName="nearTenor">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let tenor of tenors | async" [ngValue]="tenor">
        {{ tenor | translate }}
      </option>
    </select>
  </app-input-wrapper>

  <button class="wv-global-button ripple wv-global-button--white-normal" backButton>
    {{ "DefaultSettingsSection.Cancel" | translate }}
  </button>
  <button class="wv-global-button" (click)="save()" backButton>
    {{ "DefaultSettingsSection.Save" | translate }}
  </button>
</form>

<ng-template #placeholderOption>
  <option value="" disabled hidden>{{ "DefaultSettingsSection.PlaceholderSelect" | translate }}</option>
</ng-template>

