import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { WebViewService } from "src/app/common/mobile/webview.service";

@Component({
  selector: "app-history",
  templateUrl: "history.component.html",
  styleUrls: ["history.component.scss"],
})
export class HistoryComponent implements OnInit {
  public switcher: FormGroup;

  public templates = [
    { value: "exchange", text: "HistoryHeaders.Deals" },
    { value: "orders", text: "HistoryHeaders.Orders" },
  ];

  constructor(private _wv: WebViewService, private _fb: FormBuilder) {}

  get showExchange(): boolean {
    return this.switcher.get("template").value === "exchange";
  }

  ngOnInit(): void {
    this._wv.updateScreen("history");
    this.createGroup();
  }

  private createGroup(): void {
    this.switcher = this._fb.group({
      template: history.state.template || "exchange",
    });
  }
}
