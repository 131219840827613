<div class="wv-view-course-details graph">
  <form [formGroup]="form">
    <div class="wv-container wv-container--grey wv-container--extra-padding" (click)="selectPair()">
      <div class="wv-input-my-currency-pairs">
        <input id="currency1" type="checkbox" class="wv-input-my-currency-pairs__input" readonly="readonly">
        <label for="currency1" class="wv-input-my-currency-pairs__label ripple">
          <app-pair-selection-item [pair]="pair"></app-pair-selection-item>
        </label>
      </div>

    </div>
    <div class="wv-container wv-container--extra-padding wv-container--border-bottom">
      <div class="wv-sell-buy-badges">
        <div class="wv-sell-buy-badges__badge"
          [ngClass]="{ 'wv-sell-buy-badges__badge--selected': side.value === 'Sell' }">
          <input class="wv-sell-buy-badges__badge"
            [ngClass]="{ 'wv-sell-buy-badges__badge--selected': side.value === 'Sell' }" id="side-sell" type="radio"
            value="Sell" formControlName="side" />
          <label for="side-sell" translate>Sell</label>
        </div>
        <div class="wv-sell-buy-badges__badge"
          [ngClass]="{ 'wv-sell-buy-badges__badge--selected': side.value === 'Buy' }">
          <input class="wv-sell-buy-badges__badge"
            [ngClass]="{ 'wv-sell-buy-badges__badge--selected': side.value === 'Buy' }" id="side-buy" type="radio"
            value="Buy" formControlName="side" />
          <label for="side-buy" translate>Buy</label>
        </div>
      </div>
      <app-preloader *ngIf="loading" [absolute]="false" [height]="'400px'"></app-preloader>
      <ng-container *ngIf="!loading">
        <ng_container *ngIf="hasData">
          <highcharts-chart [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartOptions"
            [callbackFunction]="chartCallback" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
            [runOutsideAngular]="runOutsideAngularFlag" style="width: 100%; height: 400px; display: block;">
          </highcharts-chart>
        </ng_container>
        <div *ngIf="!hasData" class="no-data"><span translate>MissingData.Graph</span></div>
      </ng-container>
    </div>
  </form>
  <div class="wv-container wv-container--extra-padding">
    <div class="wv-view-course-details__info">
      {{ 'Graphs.MoreInfo' | translate }} <a class="anchor" (click)="openInfo()">{{ 'here' | translate }}</a>
    </div>
    <button class="wv-global-button ripple" routerLink="/exchange" [state]="initialState"
      translate>Titles.FxSpot</button>
  </div>
  <div class="wv-container wv-container--grey wv-container--extra-padding">
    <div class="wv-detailed-courses-info">
      <div class="wv-detailed-courses-info__content">
        <div class="wv-detailed-courses-info__badge">
          <span class="wv-detailed-courses-info__badge-ico wv-detailed-courses-info__badge-ico--currency"></span>
        </div>
        <div class="wv-detailed-courses-info__text">
          <div class="wv-detailed-courses-info__title" [innerHTML]="'Graphs.SetOrderHeader' | translate">
          </div>
          <div class="wv-detailed-courses-info__message" [innerHTML]="'Graphs.SetOrderInfo' | translate">

          </div>
        </div>
      </div>
      <!--RM
      <button class="wv-global-button wv-global-button--white-normal ripple" routerLink="/order" [state]="initialState" translate>Graphs.SetOrder</button>
      -->
      <button class="wv-global-button wv-global-button--white-normal ripple" translate>Graphs.SetOrder</button>
    </div>

    <div class="wv-detailed-courses-info">
      <div class="wv-detailed-courses-info__content">
        <div class="wv-detailed-courses-info__badge">
          <span class="wv-detailed-courses-info__badge-ico wv-detailed-courses-info__badge-ico--rate"></span>
        </div>
        <div class="wv-detailed-courses-info__text">
          <div class="wv-detailed-courses-info__title" [innerHTML]="'Graphs.SetAlertHeader' | translate">
          </div>
          <div class="wv-detailed-courses-info__message" [innerHTML]="'Graphs.SetAlertInfo' | translate">
          </div>
        </div>
      </div>
      <!--RM
      <button class="wv-global-button wv-global-button--white-normal ripple" routerLink="/alert" [state]="initialState" translate>Graphs.SetAlert</button>
      -->
      <button class="wv-global-button wv-global-button--white-normal ripple" translate>Graphs.SetAlert</button>


    </div>

  </div>
</div>

<app-pair-selection *ngIf="pairSelection" (pairSelected)="onPairSelected($event)" [pair]="pair"> </app-pair-selection>