import { NgModule } from "@angular/core";
import { AccountPipe } from "./account.pipe";
import { DatepickerPipe } from "./datepicker.pipe";
import { PairPipe } from "./pair.pipe";

@NgModule({
  imports: [],
  exports: [AccountPipe, PairPipe, DatepickerPipe],
  declarations: [AccountPipe, PairPipe, DatepickerPipe],
  providers: []
})
export class PipesModule {}
