import { AfterViewInit, Component, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { combineLatest, Observable, Subscription } from "rxjs";
import { delay, distinctUntilChanged, filter, map } from "rxjs/operators";

import { MenuItem, topMenu } from "@components/menu-section/menu.model";
import { hasRole } from "@utils/cookie.utils";
import { NavbarService } from "./navbar.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./menu-navbar.component.html"
})
export class MenuNavbarComponent implements AfterViewInit {
  constructor(private _nav: NavbarService, private _router: Router, private _renderer: Renderer2) {}

  get menuItems(): MenuItem[] {
    return topMenu.filter(({ requiredRoles }) => hasRole(requiredRoles));
  }

  get visible(): Observable<boolean> {
    return this._nav.visible;
  }

  get currentPath(): string {
    return this._router.url;
  }

  ngAfterViewInit(): void {
    this.setIndicator();
  }

  trackByName(_: number, item: MenuItem) {
    return item.name;
  }

  private setIndicator(): Subscription {
    const routerObservable = this._router.events.pipe(
      filter(x => x instanceof NavigationEnd),
      map(({ url }: NavigationEnd) => url)
    );

    const visibilityObservable = this.visible.pipe(delay(0), distinctUntilChanged(), filter(Boolean));

    return combineLatest([routerObservable, visibilityObservable])
      .pipe(map(([url]) => url))
      .subscribe(url => {
        const element = document.getElementsByClassName("wv-tabs__link").namedItem(url) as HTMLElement;
        const indicator = document.getElementById("wvTabsIndicator");

        if (!indicator) {
          return;
        }

        if (element) {
          this._renderer.setStyle(
            indicator,
            "transform",
            `translateX(${element.offsetLeft}px) scaleX(${element.clientWidth / 100})`
          );
        }
        this._renderer.setStyle(indicator, "opacity", element ? "1" : "0");
      });
  }
}
