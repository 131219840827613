import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from "@angular/router";
import { hasRole } from "@utils/cookie.utils";

@Injectable()
export class AuthGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (hasRole(route.data.expectedRole)) {
      return true;
    }

    const redirectionUrl = !hasRole("Customer") ? (hasRole("PotentialCustomer") ? "/apply" : "/no-access") : "/";
    setTimeout(() => this.router.navigate([redirectionUrl]), 0);
    return false;
  }
}
