import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class NavbarService {
  private _visibleChange = new BehaviorSubject<boolean>(true);

  get visible(): Observable<boolean> {
    return this._visibleChange.asObservable();
  }

  constructor() {}

  hide() {
    this._visibleChange.next(false);
  }

  show() {
    this._visibleChange.next(true);
  }
}
