<div class="wv-view-my-orders" [@hide]="filtersOpen ? 'modalOpen' : ''">
  <div class="wv-container wv-container--grey wv-container--border-bottom" [@hide]="filtersOpen ? 'modalOpen' : ''">
    <app-status-filter
      [startStatus]="startStatus"
      [filtersCount]="filtersCount"
      [statuses]="statuses"
      translationPath="Order.Statuses."
      (openFilters)="openFilters()"
      (statusChange)="onStatusChanged($event)"
    >
    </app-status-filter>
  </div>
  <app-order-history-element
    *ngFor="let order of orderService.orders"
    [order]="order"
    [routerLink]="['/history/order', order.id]"
  >
  </app-order-history-element>
  <div class="wv-container wv-container--grey empty" *ngIf="!orderService.loading && !orderService.orders.length">
    {{ "MissingData.OrderHistory" | translate }}
  </div>
  <app-preloader *ngIf="orderService.loading" [absolute]="true"></app-preloader>
</div>

<app-order-history-filters #filters (filterEvent)="onFilter()"></app-order-history-filters>
