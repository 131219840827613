import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const collateralAccountRequiredValidator: ValidatorFn = (accountControl: AbstractControl): ValidationErrors | null => {
  const collateralType = accountControl.parent?.get("type")?.value?.type;
  return collateralType === "Deposit" ? Validators.required(accountControl) : null;
};

export const settlementAccountRequiredValidator: ValidatorFn = (accountControl: AbstractControl): ValidationErrors | null => {
  const isNdf = accountControl.parent?.get("ndf")?.value;
  return isNdf ? null : Validators.required(accountControl);
};
