import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "account"
})
export class AccountPipe implements PipeTransform {
  transform(number: string, hide?: boolean): string {
    return number && formatAccount(number, hide);
  }
}

const formatAccount = (accountNumber: string, hide?: boolean): string => {
  const acc = accountNumber.replace(/\s/g, "");

  if (hide) {
    const hidden = acc.match(/^([\w\s]{2})[\w\s]+([\w\s]{4})([\w\s]{4})$/);
    return hidden ? [hidden[1], "(...)", hidden[2], hidden[3]].join(" ") : accountNumber;
  }

  if (/^\d{26}$/.test(acc) || /^([A-Z]{2})?\d+$/.test(acc)) {
    return acc.match(/^([A-Z]{2})?\d{2}|\d{1,4}/g).join(" ");
  }

  return accountNumber;
};
