import { APP_INITIALIZER, LOCALE_ID, Provider } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { LeiStore } from "./lei/lei.service";
import { AccountService } from "./accounts/account.service";
import { ConfigStore } from "./_core/config/config.service";
import { SessionService } from "./_core/session/session-service";
import { UserService } from "./_core/session/user-service";

export const initialBlockingProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeBlockingFactory,
  deps: [ConfigStore, UserService, SessionService],
  multi: true
};

export const initialAsyncProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeObservableFactory,
  deps: [AccountService, LeiStore],
  multi: true
};

export const languageProvider: Provider = {
  provide: LOCALE_ID,
  deps: [TranslateService],
  useFactory: (translate: TranslateService) => translate.currentLang.substring(0, 2)
};

// These services block the app from initializing
// This happens when combining APP_INITIALIZER with a promise
function initializeBlockingFactory(configStore: ConfigStore, userService: UserService, sessionService: SessionService) {
  return async () => {
    await configStore.load();
    await userService.load();
    await sessionService.initialize();
  };
}

// These start loading at init, not blocking UI, but that means they don't wait for the config etc.
// If it's needed, I think they must be moved to AppComponent's onInit.
function initializeObservableFactory(accountService: AccountService, leiStore: LeiStore) {
  return () => {
    accountService.loadDefaultAccounts();
    leiStore.load();
  };
}
