<div class="wv-global-modal wv-global-modal--white wv-global-modal--displayed wv-global-modal--visible"
     *ngIf="message && message.requiresAgreement" [@slideInOut]>
  <div class="wv-container wv-container--extra-padding">
    <div class="wv-message__date">{{ message.publishDate | date: "dd.MM.yyyy, hh:mm" }}</div>
    <div class="wv-message__header">{{ message.title }}</div>
    <div class="wv-message__text">{{ message.content }}</div>
    <div class="wv-message__help-text" translate>Message.AcceptMessageHelpText</div>
  </div>

  <div class="wv-container wv-container--border-top wv-container--fixed">
    <button class="wv-global-button wv-global-button--white-normal ripple wv-global-button--fixed" (click)="logout()" translate>
      Button.DoNotAccept
    </button>
    <button class="wv-global-button ripple wv-global-button--fixed" (click)="accept()" translate>
      Button.ReadAndAccept
    </button>
  </div>
</div>
