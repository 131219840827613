import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { pairwise } from "rxjs/operators";
import { Observable } from "rxjs";

import { Side } from "@common/dealing/dealing.model";
import { ExchangePreferences } from "@common/user/settings.model";
import { SettingsStore } from "@common/user/settings.service";
import { WebViewService } from "@common/mobile/webview.service";
import { DefaultSettingsService } from "../default-settings.service";

@Component({
  selector: "app-settings-exchange",
  templateUrl: "exchange-settings.component.html"
})
export class ExchangeSettingsComponent implements OnInit {
  constructor(
    public defaultSettingsService: DefaultSettingsService,
    private settingsStore: SettingsStore,
    private formBuilder: FormBuilder,
    private webViewService: WebViewService
  ) {
    this.tenors = defaultSettingsService.getExchangeTenors();
  }

  public settingsForm: FormGroup;
  public sides: Side[] = Object.values(Side);
  public tenors: Observable<string[]>;
  
  get side() {
   return this.settingsForm.get("side");
  }

  get tenor() {
    return this.settingsForm.get("nearTenor");
  }

  get amount() {
    return this.settingsForm.get("amount");
  }

  get dealCurrency() {
    return this.settingsForm.get("dealCurrency");
  }

  get counterCurrency() {
    return this.settingsForm.get("counterCurrency");
  }

  ngOnInit() {
    this.webViewService.updateScreen("exchangeSettings");
    this.createGroup();
    this.onChanges();
  }

  save() {
    const amount = parseFloat(this.amount.value) || 0;
    const settings: ExchangePreferences = { ...this.settingsForm.value, amount };
    this.settingsStore.saveExchangePreferences(settings);
    this.webViewService.showSnackBar("DefaultSettingsSection.SettingsSaved");
  }

  private onChanges(): void {
    this.dealCurrency.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      if (next === this.counterCurrency.value) {
        this.counterCurrency.setValue(prev);
      }
    });

    this.counterCurrency.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
      if (next === this.dealCurrency.value) {
        this.dealCurrency.setValue(prev);
      }
    });

    this.settingsStore.loadExchangePreferences().subscribe(preferences => {
      const { side, amount, dealCurrency, counterCurrency, nearTenor } = preferences;
      this.side.setValue(side);
      this.amount.setValue(amount);
      this.tenor.setValue(nearTenor);
      this.dealCurrency.setValue(dealCurrency);
      this.counterCurrency.setValue(counterCurrency);
    });
  }

  private createGroup(): void {
    this.settingsForm = this.formBuilder.group({
      side: [null],
      dealCurrency: [null],
      counterCurrency: [null],
      amount: [null],
      nearTenor: [null]
    });
  }
}
