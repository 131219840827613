import { Inject, Injectable } from "@angular/core";
import { DOCUMENT, registerLocaleData } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, timer, forkJoin } from "rxjs";
import { switchMap } from "rxjs/operators";

import { getCookie, setCookie } from "@utils/cookie.utils";
import { WebViewService } from "@common/mobile/webview.service";
import { UserService } from "./user-service";

@Injectable({
  providedIn: "root"
})
export class SessionService {
  public interval: ReturnType<typeof setInterval>;
  private tokenTimeout: Subscription;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private webViewService: WebViewService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async initialize(): Promise<void> {
    const {
      appData: { language, tokenExpiration }
    } = this.userService;

    const [, locale] = await forkJoin([
      this.translate.use(language),
      /* webpackInclude: /(pl|en|de|cs)\.js$/ */
      import(`@angular/common/locales/${language}.js`)
    ]).toPromise();

    this.translate.setDefaultLang("pl");
    registerLocaleData(locale.default);

    this.setTokenTimeout(tokenExpiration);
    this.webViewService.hideSpinner();

    setTimeout(() => {
      if (this.userService.isPotentialCustomer) {
        this.router.navigate(["/apply"]);
      } else if (this.userService.noAccess || this.userService.noForex) {
        this.router.navigate(["/no-access"]);
      } else if (!this.userService.hasCustomer) {
        this.router.navigate(["/customer"]);
      } else {
        this.router.navigate([window.location.pathname]);
      }
    });

    this.interval = setInterval(() => this.countdown(), 1e3);
  }

  setTokenTimeout(tokenExpiration: number) {
    if (this.tokenTimeout) {
      this.tokenTimeout.unsubscribe();
    }

    if (tokenExpiration <= 0) {
      return;
    }

    this.tokenTimeout = timer(tokenExpiration * 6e4)
      .pipe(switchMap(() => this.http.post<number>("/account/extend-token", null)))
      .subscribe(result => {
        this.setTokenTimeout(result);
      });
  }

  private countdown() {
    const sessionExpiration = getCookie("sessionExpiration");
    const newExpiration = parseInt(sessionExpiration) - 1;
    setCookie("sessionExpiration", newExpiration.toString());
    if (newExpiration === 0) {
      clearInterval(this.interval);
      this.webViewService.logout();
    }
  }
}
