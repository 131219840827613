import { Component, Input } from "@angular/core";
import { Deal } from "../exchange-history.model";

@Component({
  selector: "app-exchange-history-item",
  templateUrl: "./exchange-history-item.component.html",
  styleUrls: ["./exchange-history-item.component.scss"]
})
export class ExchangeHistoryItemComponent {
  @Input() deal: Deal;

  get settlementStatusClass(): string {
    switch (this.deal.settlementStatus) {
      case "Settled":
        return "wv-transactions__status--green";
      case "Unsettled":
        return "wv-transactions__status--blue";
      default:
        return "wv-transactions__status--grey";
    }
  }

  constructor() {}
}
