<div class="wv-my-currency-pairs__chart ripple" (click)="openGraph()">
  <div class="wv-my-currency-pairs__chart-title">{{ pair.code | pair }}</div>

  <highcharts-chart
    *ngIf="options; else noData"
    [Highcharts]="Highcharts"
    [constructorType]="'stockChart'"
    [options]="options.options"
    [callbackFunction]="options.callback"
    [(update)]="options.update"
    [oneToOne]="options.oneToOne"
    [runOutsideAngular]="options.runOutsideAngular"
  >
  </highcharts-chart>
</div>

<ng-template #noData>
  <div class="chart-no-data">
    <div>{{ "MissingData.Amount" | translate }}</div>
    <div>{{ "MissingData.IndicativeGraph" | translate }}</div>
  </div>
</ng-template>
