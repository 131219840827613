import { DOCUMENT, formatNumber } from "@angular/common";
import { AfterViewChecked, Directive, ElementRef, HostListener, Inject, Input, Self } from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

const SPECIAL_KEYS = ["Control", "Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "Delete"];

@Directive({
  selector: "[numeric]"
})
export class NumericDirective implements AfterViewChecked {
  private locale: string;
  private decimals: number = 0;
  private regex: RegExp = /^[1-9][0-9]{0,9}$/;

  @Input("paste") allowPaste: boolean = false;

  @Input("decimals") set decimalPoints(value: number) {
    if (value > 0) {
      this.decimals = value;
      this.regex = new RegExp(`^(?:0|[1-9][0-9]{0,9})(?:[\\.,][0-9]{0,${value}})?$`);
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Self() private ngControl: NgControl,
    private el: ElementRef<HTMLInputElement>,
    translate: TranslateService
  ) {
    this.locale = translate.currentLang;
  }

  get control(): AbstractControl {
    return this.ngControl.control;
  }

  get element(): HTMLInputElement {
    return this.el.nativeElement;
  }

  ngAfterViewChecked(): void {
    if (this.element !== this.document.activeElement) {
      this.onBlur();
    }
  }

  @HostListener("focus") onFocus() {
    this.ngControl.valueAccessor.writeValue(this.control.value);
  }

  @HostListener("blur") onBlur() {
    const value = this.control.value;

    if (shouldNotFormat(value)) {
      return;
    }

    const parsed = this.parse(value);
    const normalized = parsed ? parsed.toString() : "";

    // update the model
    if (normalized !== value) {
      this.control.setValue(normalized, { emitEvent: false });
    }
    // update just the DOM separately
    this.ngControl.valueAccessor.writeValue(this.format(parsed));
  }

  @HostListener("paste", ["$event"]) onPaste(event: ClipboardEvent) {
    if (!this.allowPaste) {
      return event.preventDefault();
    }

    this.runAfter(this.element.value);
  }

  @HostListener("drop", ["$event"]) onDrop(event: DragEvent) {
    if (!this.allowPaste) {
      return event.preventDefault();
    }

    this.runAfter(this.element.value);
  }

  @HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent) {
    if (event.key === "Unidentified") {
      // Android keyboard (all non-digit keys are Unidentified, code 229)
      this.runAfter(this.element.value);
    } else {
      this.runBefore(event);
    }
  }

  private runBefore(event: KeyboardEvent) {
    if (this.shouldIgnore(event)) {
      return;
    }

    const { value, selectionStart } = this.element;
    const next = [value.slice(0, selectionStart), event.key, value.slice(selectionStart)].join("");

    if (!this.validate(next)) {
      event.preventDefault();
    }
  }

  private runAfter(previous: string) {
    setTimeout(() => {
      const current: string = this.element.value;

      if (!this.validate(current)) {
        this.control.setValue(previous, { emitEvent: false });
      }
    });
  }

  private shouldIgnore(event: KeyboardEvent): boolean {
    return (
      // Allow specified special keys
      SPECIAL_KEYS.indexOf(event.key) !== -1 ||
      // Allow copy, paste, cut
      ((event.ctrlKey === true || event.metaKey === true) &&
        (["a", "c", "x"].includes(event.key) || (event.key === "v" && this.allowPaste)))
    );
  }

  private validate(value: string): boolean {
    return value === "" || this.regex.test(String(value));
  }

  private parse(value: string | number): number {
    return Number.parseFloat(value.toString().replace(",", "."));
  }

  private format(value: number): string {
    if (!value) {
      return "";
    }

    const { locale, decimals } = this;
    return formatNumber(value, locale, `1.${decimals}-${decimals}`);
  }
}

const shouldNotFormat = (value: any) => !value && value !== 0 && !isNaN(value);
