<form [formGroup]="settingsForm" class="wv-container wv-container--grey wv-container--extra-padding">
  <div class="info">
    {{ "DefaultSettingsSection.Deposit.Description" | translate }}
  </div>

  <app-input-wrapper label="DefaultSettingsSection.Deposit.Currency" for="currency">
    <select id="currency" formControlName="currency">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let currency of defaultSettingsService.currencies | async" [ngValue]="currency">
        {{ currency }}
      </option>
    </select>
  </app-input-wrapper>

  <app-input-wrapper label="DefaultSettingsSection.Deposit.Amount" for="amount">
    <app-input-amount
      ngDefaultControl
      formControlName="amount"
      ngId="amount"
      placeholder="DefaultSettingsSection.Deposit.PlaceholderAmount"
      [decimals]="2"
    >
    </app-input-amount>
  </app-input-wrapper>

  <app-input-wrapper label="DefaultSettingsSection.Deposit.StartDate" for="startTenor">
    <select id="startTenor" formControlName="startTenor">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let tenor of (tenors$ | async)?.nearTenors" [ngValue]="tenor">
        {{ tenor | translate }}
      </option>
    </select>
  </app-input-wrapper>

  <app-input-wrapper label="DefaultSettingsSection.Deposit.TimeSpan" for="endTenor">
    <select id="endTenor" formControlName="endTenor">
      <ng-container *ngTemplateOutlet="placeholderOption"></ng-container>
      <option *ngFor="let tenor of (tenors$ | async)?.farTenors" [ngValue]="tenor">
        {{ tenor | translate }}
      </option>
    </select>
  </app-input-wrapper>

  <button class="wv-global-button ripple wv-global-button--white-normal" backButton>
    {{ "DefaultSettingsSection.Cancel" | translate }}
  </button>

  <button class="wv-global-button" (click)="save()" backButton>
    {{ "DefaultSettingsSection.Save" | translate }}
  </button>
</form>

<ng-template #placeholderOption>
  <option value="" disabled hidden>{{ "DefaultSettingsSection.PlaceholderSelect" | translate }}</option>
</ng-template>
