export function getCookie(key: string): string {
  const cookie = document.cookie
    .split(";")
    .map(cookie => cookie.trim().split("="))
    .find(([cookieKey]) => cookieKey === key);

  return cookie && decodeURIComponent(cookie[1]);
}

export function setCookie(key: string, value: string): void {
  document.cookie = `${key}=${value}`;
}

export function getRoles(): string[] {
  return getCookie("roles")
    .split(",")
    .map(x => x.trim());
}

export function hasRole(role: string): boolean {
  if (!role) {
    return;
  }

  return role.split(",").map(compareAlternatives).every(Boolean);
}

const compareAlternatives = (role: string) => {
  const roles = getRoles();

  return role
    .split("|")
    .map(x => x.trim())
    .some(x => roles.includes(x));
};
