import { Currency, CurrencyPair } from "../../common/dealing/dealing.model";
import { PhonePrefix } from "../../common/phones/phone.model";

export interface AlertFormData {
  currencies: Currency[];
  currencyPairs: CurrencyPair[];
  counterCurrencies: Record<string, Currency[]>;
  phonePrefixes: PhonePrefix[];
  useSmsNotification: boolean;
}

export interface RateAlert {
  id?: number;
  amount: number;
  currency: string;
  currencyPair: string;
  side: string;
  targetRate: number;
  expirationDate: Date;
  phonePrefix: string;
  phoneNumber: string;
  useSmsNotification: boolean;
}

export interface InitializationResult {
  guid: string;
  success: boolean;
  failCode: number;
  failReason: string;
}

export interface AlertStatus {
  success: boolean;
  retry: boolean;
  failCode: number;
  failReason: string;
}

export interface InitialState {
  side?: string;
  counterCurrency?: string;
  currency?: string;
  amount?: number;
}

export const errorDetails = {
  1001: "Errors.1009",
  1009: "Errors.1009",
  1340: "Errors.1340",
  2000: "Errors.End_Date_Longer_Than_3_Months",
  2002: "Errors.AlertDuplicated",
  2004: "Errors.Max_Number_Of_Alerts_Achieved"
};
