import { Injectable } from "@angular/core";
import { NavigationExtras } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { ActionItem, BottomSheetData, DialogData, isIOS, MobileScreen } from "./webview.model";
import * as screens from "./screen-constants";
import { ActionService } from "./action.service";
import { MobileApiService } from "./api.service";
import { UserService } from "@common/_core/session/user-service";
import { SessionAccountsService } from "@common/session/session-accounts.service";
import { NavigationService } from "@common/_core/navigation/navigation.service";
import { NavbarService } from "@components/menu-navbar/navbar.service";

@Injectable({ providedIn: "root" })
export class WebViewService {
  private _currentScreen: MobileScreen;

  constructor(
    private _navigation: NavigationService,
    private _nav: NavbarService,
    private _translate: TranslateService,
    private _actionService: ActionService,
    private _api: MobileApiService,
    private _userService: UserService,
    private _sessionAccountsService: SessionAccountsService
  ) { }

  /**
   * Starts the operation authorization process.
   * @param id Transaction's id.
   * @param token Transaction's token.
   */
  authorizeOperation(id: number, token: string) {
    if (!id || !token) {
      throw new Error("Authorization arguments missing!");
    }

    this._api.call("authorizeOperation", {
      operationId: id.toString(),
      additionalParams: [{ key: "token", value: token }]
    });
  }

  /**
   * Closes the webview.
   */
  async closeForm() {
    if (this._userService.hasCustomer) {
      const accounts = await this._sessionAccountsService.getAccounts().toPromise();
      if (accounts.length > 0) {
        this._api.call("forceClearAccountsCache", {
          accountsList: accounts
        });
      }
    }

    this._api.call("closeForm");
  }

  /**
   * Downloads and displays a PDF document.
   * @param id Relative url of the document.
   */
  displayPdf(id: string) {
    this._api.call("displayPdfDocument", {
      urlSuffix: id
    });
  }

  /**
   * Natively opens a url.
   * @param url address to redirect to.
   */
  openUrl(url: string) {
    this._api.call("openUrl", {
      url: url
    });
  }

  /**
   * Uses the device's native share function.
   * @param message A message to share.
   */
  share(message: string) {
    this._api.call("nativeShare", { text: message });
  }

  /**
   * Shows context menu in the tapped place
   * @param coords Coordinates of the tap
   * @param actionItems Actions items to be displayed with their callbacks
   */
  showActionSheet(coords: DOMRect, actionItems: ActionItem[]) {
    const items = this._actionService.setActions(actionItems);

    this._api.call("showFormContextMenu", {
      topLeftX: coords.left,
      topLeftY: coords.top,
      bottomRightX: coords.right,
      bottomRightY: coords.bottom,
      items
    });
  }

  /**
   * Opens a consent (full-screen) popup dialog.
   * @param text Consent's specific text. As it comes from the Marketing Manager, it is already translated.
   * @param title Consent's title, not required.
   * @param actionItems An array of max 2 ConsentPopupCallback objects (effectively buttons).
   */
  showConsentPopup(text: string, title?: string, actionItems: ActionItem[] = [{ text: "OK" }]) {
    const data = this._prepareDialog({ text, title });
    if (isIOS()) {
      data.title = `__${data.title}__`;
    }

    const callbacks = this._actionService.setActions(actionItems);

    this._api.call("showConsentPopup", { callbacks, ...data });
  }

  /**
   * Opens a dialog sheet for the user sliding from the bottom.
   */
  showBottomSheet({ title = "", text = "", primaryButton, additionalButton, onClose }: BottomSheetData) {
    const buttons = [primaryButton];
    additionalButton && buttons.push(additionalButton);
    onClose && buttons.push({ text: "", callback: onClose });

    const [primary, ...rest] = this._actionService.setActions(buttons);
    const [additional] = additionalButton ? rest : [undefined];
    const closeButton = onClose ? rest.pop().callback : undefined;

    // IKO draws primary button higher than additional if both are provided
    const first = {
      title: this._translate.instant(primary.text),
      callback: primary.callback,
      type: "blue"
    };
    const second = additionalButton
      ? {
        title: this._translate.instant(additional.text),
        callback: additional.callback,
        type: "white"
      }
      : undefined;

    this._api.call("showBottomSheet", {
      ...this._prepareDialog({ text, title }, true),
      primaryButton: second || first,
      additionalButton: second && first,
      closeButton
    });
  }

  /**
   * Opens an infotip popup with a close button.
   * @param text Infotip's specific text. Can contain special markups.
   * @param title Infotip's title, not required.
   */
  showInfotip(text: string, title?: string, translate: boolean = true) {
    const data = this._prepareDialog({ text, title }, translate);
    this._api.call("showInfotip", data);
  }

  /**
   * Pops up a snack bar from the top of the screen.
   * @param key Text to display (untranslated)
   */
  showSnackBar(key: string) {
    this._api.call("showSnackbar", {
      text: this._translate.instant(key),
      position: "top"
    });
  }

  /**
   * Hides the loading spinner.
   */
  hideSpinner() {
    this._api.call("hideSpinner");
  }

  /**
   * Shows or hides the loading spinner.
   * @param toggleOn Whether the spinner should be shown or hidden.
   */
  toggleSpinner(toggleOn: boolean) {
    const command = toggleOn ? "showSpinner" : "hideSpinner";
    this._api.call(command);
  }

  /**
   * Toggles display of a filter icon on the top toolbar.
   * @param visible Is the icon visible?
   * @param active Is the icon clickable?
   * @param callback Action to execute on button click.
   * @param type Icon type.
   */
  toggleFiltersIcon(visible: boolean, active: boolean, callback: Function, type: "filter" | "remove_filter") {
    const [func] = this._actionService.setActions([{ text: "", callback }]);
    this._api.call("configureToolbarAdditionalButton", {
      type: type,
      visible: visible,
      active: active,
      callback: func.callback
    });
  }

  /**
   * Router navigation with an NgZone for calls outside of the application (e.g. on IKO button tap)
   * @param commands standard router navigation commands
   */
  navigateFromWebView(commands: any[], extras?: NavigationExtras) {
    this._navigation.navigate(commands, extras);
  }

  updateScreen(screenName: string, returnCallback: Function = null) {
    const screen = screens[screenName] as MobileScreen;
    const back = screen.logoutOnClose ? this.logout.bind(this) : returnCallback;

    this._updateScreen({ ...screen, back });
  }

  public logout() {
    this.closeForm().then(() => {
      // window.location.href = "/logout";      // #####= NOWE =##### PREVENT 404 on logout
    });
  }

  private _updateScreen(screen: MobileScreen) {
    if (this._currentScreen?.title !== screen.title) {
      const title = this._translate.instant(`Titles.${screen.title}`);
      const subtitle = screen.showCustomer ? this._userService.appData.customerName : undefined;

      this._api.call("setTitle", { title, subtitle });
    }

    if (screen.navigationIcon !== "none") {
      this._api.call("configureToolbarBackButton", {
        type: screen.navigationIcon,
        callback: this._actionService.setReturn(screen.back)
      });
    }

    screen.showNavbar ? this._nav.show() : this._nav.hide();

  }

  private _prepareDialog({ text, title }: Partial<DialogData>, translate?: boolean): DialogData {
    if (!text && !title) {
      throw new Error("Missing dialog data");
    }

    return {
      text: translate && text ? this._translate.instant(text) : text,
      title: translate && title ? this._translate.instant(title) : title
    };
  }
}
