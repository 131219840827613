<app-input-wrapper [label]="label" [control]="control" [for]="id">
  <button [id]="id" class="wv-select-account__button wv-select-account__button--active" (click)="openAccountSelection()">
    <app-account-button-content
      *ngIf="control.value; else noAccountBlock"
      [account]="control.value"
      [hideNumber]="true"
    ></app-account-button-content>
  </button>
</app-input-wrapper>

<ng-template #noAccountBlock>
  <app-account-button-content [account]="{}" [style.visibility]="'hidden'"></app-account-button-content>
</ng-template>
