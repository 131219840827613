import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";

import { Config, defaultConfig } from "./config.model";

@Injectable({ providedIn: "root" })
export class ConfigStore {
  private _config: Config = defaultConfig;

  constructor(private httpClient: HttpClient) {}

  get config(): Config {
    return this._config;
  }

  getProp<T>(prop: string): T {
    const section = this._config[prop];
    return !section ? null : (section as T);
  }

  async load() {
    /*
    const config = await this.httpClient
      .get<Config>("/browser/config")
      .pipe(catchError(() => of(defaultConfig)))
      .toPromise();
    this._config = config;
    */
  }
}
