import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Account } from "../../common/accounts/account.model";
import { Currency, productsByTenor, Side } from "../../common/dealing/dealing.model";
import { WebViewService } from "../../common/mobile/webview.service";
import { OrderFormData, OrderPair, OrderRequest, OrderTypeOption, specialPairs } from "./order.model";
import { OrderService } from "./order.service";
import { distinctUntilChanged } from "rxjs/operators";
import { PhonePrefix } from "../../common/phones/phone.model";
import { OrderConfirmationComponent } from "./order-confirmation/order-confirmation.component";
import {
  maxDateValidator,
  minDateValidator,
  pointsRequiredValidator,
  rateRequiredValidator,
  trailingTimeValidator,
  weekendValidator
} from "src/app/validators";
import { addDays, datepickerFormat, firstDateAfterWeekend, isSameDay, timepickerFormat } from "src/app/utils/time.utils";
import { baseCurrency, findCurrencyPair, findDefaultCurrency, findDefaultAccount } from "src/app/common/dealing";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"]
})
export class OrderComponent implements OnInit, AfterViewInit {
  public orderForm: FormGroup;

  public confirming: boolean = false;
  public currencies: Currency[] = [];
  public tenors: string[] = [];
  public types: OrderTypeOption[] = [];
  public phonePrefixes: PhonePrefix[] = [];
  public loading: boolean = true;
  public useSmsNotification: boolean = false;

  public minExpirationDate: string;
  public maxExpirationDate: string;

  private _accountsByCurrency: Record<string, Account[]> = {};
  private _counterCurrenciesByCurrency: Record<string, Currency[]> = {};
  private _limitCounterCurrencies: Record<string, Currency[]> = {};
  private _trailingStopCounterCurrencies: Record<string, Currency[]> = {};
  private _stopMarketCounterCurrencies: Record<string, Currency[]> = {};
  private _currencyPairs: OrderPair[] = [];
  private _nightShiftStart: Date;
  private _nightShiftEnd: Date;
  private _nextNightShiftStart: Date;
  private _nightShiftInSevenDays: Date;
  private _initialState: { side?: string; counterCurrency?: string; currency?: string; amount?: number } = {};
  private _dealAccountSelection: boolean = false;
  private _counterAccountSelection: boolean = false;

  @ViewChild("confirmation", { static: true })
  private _confirmationComponent: OrderConfirmationComponent;

  public get dealAccountSelection(): boolean {
    return this._dealAccountSelection;
  }

  public set dealAccountSelection(value: boolean) {
    if (value !== this._dealAccountSelection) {
      this._dealAccountSelection = value;
      this.webViewService.updateScreen(
        "order",
        value ? () => (this.dealAccountSelection = false) : () => this.orderService.returnToInitialPage()
      );
    }
  }

  public get counterAccountSelection(): boolean {
    return this._counterAccountSelection;
  }

  public set counterAccountSelection(value: boolean) {
    if (value !== this._counterAccountSelection) {
      this._counterAccountSelection = value;
      this.webViewService.updateScreen(
        "order",
        value ? () => (this.counterAccountSelection = false) : () => this.orderService.returnToInitialPage()
      );
    }
  }

  get side() {
    return this.orderForm.get("side");
  }

  get dealCurrency() {
    return this.orderForm.get("currencies.dealCurrency");
  }

  get counterCurrency() {
    return this.orderForm.get("currencies.counterCurrency");
  }

  get dealAmount() {
    return this.orderForm.get("dealAmount");
  }

  get counterAccount() {
    return this.orderForm.get("counterAccount");
  }

  get dealAccount() {
    return this.orderForm.get("dealAccount");
  }

  get rate() {
    return this.orderForm.get("rate");
  }

  get type() {
    return this.orderForm.get("type");
  }

  get tenor() {
    return this.orderForm.get("tenor");
  }

  get points() {
    return this.orderForm.get("points");
  }

  get expirationTime() {
    return this.orderForm.get("expiration.expirationTime");
  }

  get expirationDate() {
    return this.orderForm.get("expiration.expirationDate");
  }

  get phonePrefix() {
    return this.orderForm.get("phone.prefix");
  }

  get phoneNumber() {
    return this.orderForm.get("phone.number");
  }

  get isBuying(): boolean {
    return this.side.value === Side.Buy;
  }

  get dealAccounts(): Account[] {
    return this._accountsByCurrency[this.dealCurrency?.value?.code];
  }

  get counterAccounts(): Account[] {
    return this._accountsByCurrency[this.counterCurrency?.value?.code];
  }

  get counterCurrencies(): Currency[] {
    return this._counterCurrenciesByCurrency[this.dealCurrency?.value?.code];
  }

  get currencyPair(): OrderPair {
    return findCurrencyPair(this._currencyPairs, this.dealCurrency.value?.code, this.counterCurrency.value?.code);
  }

  get productType(): string {
    const tenor = this.tenor.value;

    return tenor && productsByTenor[tenor] ? productsByTenor[tenor] : "FXFORW";
  }

  get isTrailingStop(): boolean {
    return this.type.value?.id === "TrailingStop";
  }

  get isStopMarket(): boolean {
    return this.type.value?.id === "StopMarket";
  }

  constructor(private formBuilder: FormBuilder, private orderService: OrderService, private webViewService: WebViewService) {
    this._initialState = history.state.initialData || {};
    this.orderService.returnPage = history.state.returnPage || "history";
  }

  ngOnInit(): void {
    this.webViewService.updateScreen("order", () => this.orderService.returnToInitialPage());
    this.createGroup();

    this.orderService.getFormData().subscribe(results => {
      this.handleFormData(results);
      this.loading = false;
    });
  }

  ngAfterViewInit(): void {
    this.onChanges();
  }

  public confirm(): void {
    if (this.isTrailingStop || this.isStopMarket) {
      this.webViewService.showBottomSheet({
        title: "Order.AgreementTitle",
        text: "Order.AgreementDescription",
        primaryButton: {
          text: "Order.AcceptAndConfirm",
          callback: () => {
            this._confirmationComponent.displayConfirmation(this.getData());
          }
        },
        additionalButton: { text: "Order.Resign" }
      });
    } else {
      this._confirmationComponent.displayConfirmation(this.getData());
    }
  }

  public getData(): OrderRequest {
    this.confirming = true;
    return {
      rate: this.rate.value ? parseFloat(this.rate.value) : 0,
      amount: parseFloat(this.dealAmount.value),
      pair: this.currencyPair.code,
      counterCurrency: this.counterCurrency.value.code,
      tenor: this.tenor.value,
      settlementAccount: this.dealAccount.value,
      counterSettlementAccount: this.counterAccount.value,
      counterAmount: this.calculateCounterAmount(),
      dealCurrency: this.dealCurrency.value.code,
      type: this.type.value.id,
      points: this.points.value ? parseInt(this.points.value) : 0,
      side: this.side.value,
      expirationDateTime: this.getExpirationDate(),
      productType: this.productType,
      phonePrefix: this.phonePrefix?.value?.code || "",
      phoneNumber: this.phoneNumber?.value || "",
      useSmsNotification: this.useSmsNotification
    };
  }

  public onCounterAccountSelection(account: Account): void {
    this.counterAccountSelection = false;
    this.counterAccount.setValue(account);
  }

  public onDealAccountSelection(account: Account): void {
    this.dealAccountSelection = false;
    this.dealAccount.setValue(account);
  }

  public openAccountOneSelection(): void {
    if (!this.isBuying) {
      this.dealAccountSelection = true;
    } else {
      this.counterAccountSelection = true;
    }
  }

  public openAccountTwoSelection(): void {
    if (this.isBuying) {
      this.dealAccountSelection = true;
    } else {
      this.counterAccountSelection = true;
    }
  }

  public openNewOrder(clearForm: boolean): void {
    this.confirming = false;
    if (clearForm) {
      this.orderForm.reset();
      this.setDefaultValues();
    }
  }

  private calculateCounterAmount(): number {
    if (this.type.value.id !== "Limit") {
      return 0;
    }

    const amount = parseFloat(this.dealAmount.value);

    if (amount <= 0) {
      return 0;
    }

    const counterCurrency = this.counterCurrency.value;
    const rate = parseFloat(this.rate.value);
    const pair = this.currencyPair.code;
    const multiplier = specialPairs.includes(pair) ? 100 : 1;

    const calculatedAmount =
      baseCurrency(pair) === this.dealCurrency.value.code ? (amount * rate) / multiplier : (amount / rate) * multiplier;

    return parseFloat(calculatedAmount.toFixed(counterCurrency.decimals));
  }

  private handleFormData(data: OrderFormData): void {
    this._accountsByCurrency = data.accountsByCurrency;
    this._currencyPairs = data.currencyPairs;
    this._limitCounterCurrencies = data.limitCounterCurrencies;
    this._stopMarketCounterCurrencies = data.stopMarketCounterCurrencies;
    this._trailingStopCounterCurrencies = data.trailingStopCounterCurrencies;
    this.tenors = data.tenors;
    this.types = data.orderTypes;
    this.phonePrefixes = data.phonePrefixes;
    this.useSmsNotification = data.useSmsNotification;
    this.currencies = data.currencies;
    this._nextNightShiftStart = data.nextNightShiftStart;
    this._nightShiftEnd = data.nightShiftEnd;
    this._nightShiftStart = data.nightShiftStart;
    this._nightShiftInSevenDays = firstDateAfterWeekend(addDays(data.nightShiftStart, 7));

    setTimeout(() => this.setDefaultValues(), 0);
  }

  private setDefaultValues() {
    if (this.types.length > 0) {
      this.updateCounterCurrenciesList(this.types[0]);
      this.type.setValue(this.types[0]);

      if (this.types.length === 1) {
        this.type.disable();
      }
    }

    this.tenor.setValue("");
    this.side.setValue(this._initialState.side || Side.Sell);
    this.setDefaultCurrencies();
    this.setDefaultAccount("deal");
    this.setDefaultAccount("counter");
  }

  private setDefaultAccount(which: "deal" | "counter") {
    const defaultAccount = findDefaultAccount(this[`${which}Accounts`]);
    this[`${which}Account`].setValue(defaultAccount);
  }

  private setDefaultCurrencies(): void {
    const dealCurrency = findDefaultCurrency({
      currency: this._initialState.currency,
      defaultCurrency: "EUR",
      currencies: this.currencies
    });

    const counterCurrency = findDefaultCurrency({
      currency: this._initialState.counterCurrency,
      defaultCurrency: "PLN",
      currencies: this._counterCurrenciesByCurrency[dealCurrency.code]
    });

    this.dealCurrency.setValue(dealCurrency);
    this.counterCurrency.setValue(counterCurrency);
  }

  private onChanges(): void {
    this.type.valueChanges.pipe(distinctUntilChanged()).subscribe(x => this.onTypeChanged(x));
    this.points.valueChanges.pipe(distinctUntilChanged()).subscribe(x => this.validatePointsLimit(x));
    this.dealCurrency.valueChanges.pipe(distinctUntilChanged()).subscribe(() => this.setDefaultAccount("deal"));
    this.counterCurrency.valueChanges.pipe(distinctUntilChanged()).subscribe(() => this.setDefaultAccount("counter"));
    this.expirationDate.valueChanges.pipe(distinctUntilChanged()).subscribe(this.setTimeValidator.bind(this));
  }

  private setTimeValidator(date: string) {
    const validator = isSameDay(new Date(), new Date(date)) ? trailingTimeValidator : Validators.required;
    this.expirationTime.setValidators(validator);
    this.expirationTime.updateValueAndValidity();
  }

  private onTypeChanged(typeOption: OrderTypeOption): void {
    if (!typeOption) {
      return;
    }

    this.points.updateValueAndValidity();
    this.rate.updateValueAndValidity();
    this.updateExpirationDate(typeOption);
    this.updateCounterCurrenciesList(typeOption);
  }

  private updateExpirationDate(typeOption: OrderTypeOption): void {
    const getExpirationDate = (): Date => {
      const now = new Date();
      switch (true) {
        case typeOption.id === "Limit":
          return this._nightShiftInSevenDays;
        case now < this._nightShiftStart:
          return this._nightShiftStart;
        case now > this._nightShiftEnd:
          return this._nextNightShiftStart;
        default:
          return this._nightShiftEnd;
      }
    };
    const expirationDate = getExpirationDate();

    this.expirationDate.setValue(datepickerFormat(expirationDate));
    this.expirationTime.setValue(timepickerFormat(expirationDate));
    this.minExpirationDate = datepickerFormat(typeOption.minTime || new Date());
    this.maxExpirationDate = typeOption.maxTime ? datepickerFormat(typeOption.maxTime) : "";

    if (typeOption.id === "Limit") {
      this.expirationDate.enable();
    } else {
      this.expirationDate.disable();
    }

    const validators = [weekendValidator, minDateValidator(this.minExpirationDate)];
    this.maxExpirationDate && validators.push(maxDateValidator(this.maxExpirationDate));
    this.expirationDate.setValidators(Validators.compose(validators));
  }

  private updateCounterCurrenciesList(typeOption: OrderTypeOption): void {
    switch (typeOption.id) {
      case "TrailingStop":
        this._counterCurrenciesByCurrency = this._trailingStopCounterCurrencies;
        break;
      case "StopMarket":
        this._counterCurrenciesByCurrency = this._stopMarketCounterCurrencies;
        break;
      default:
        this._counterCurrenciesByCurrency = this._limitCounterCurrencies;
        break;
    }
  }

  private validatePointsLimit(value: number) {
    if (value > 999) {
      this.points.setValue(999);
    }
  }

  private getExpirationDate(): Date {
    const date = this.expirationDate.value;
    const time = this.expirationTime.value;

    return new Date(date + "T" + time);
  }

  private createGroup(): void {
    this.orderForm = this.formBuilder.group({
      side: [null, Validators.required],
      dealAmount: [null, [Validators.required, Validators.min(1)]],
      currencies: this.formBuilder.group({}),
      tenor: [null, Validators.required],
      rate: [null, rateRequiredValidator],
      points: [null, pointsRequiredValidator],
      type: [null, Validators.required],
      phone: this.formBuilder.group({}),
      dealAccount: [null, Validators.required],
      counterAccount: [null, Validators.required],
      expiration: this.formBuilder.group({
        expirationDate: [null, [Validators.required, weekendValidator]],
        expirationTime: [null, Validators.required]
      })
    });
  }
}
