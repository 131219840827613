import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../common/_core/session/user-service';
import { ActivationService } from '../activation.service';
import { WebViewService } from '../../../common/mobile/webview.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-activation-start',
  templateUrl: './activation-start.component.html',
  styleUrls: ['./activation-start.component.scss']
})
export class ActivationStartComponent implements OnInit {
  constructor(public userService: UserService, public activationService: ActivationService, private webViewService: WebViewService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.webViewService.updateScreen('activationStart');
  }

  openInfo() {
    this.webViewService.openUrl(this.userService.appData.appInfoLink);
  }
}
