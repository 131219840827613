import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import { WebViewService } from "@common/mobile/webview.service";
import { groupByCurrency, parseHolidays, parseTenorDates } from "@common/dealing";
import {
  Currency,
  InitializationResult,
  TenorDate,
  TenorDateDto,
  TransactionStatus,
} from "@common/dealing/dealing.model";
import {
  Collateral,
  ForexRate,
  CurrencySetup,
  FxSpotDto,
  ExchangeFormData,
} from "./exchange.model";

@Injectable({ providedIn: "root" })
export class FxSpotService {
  public returnPage: "dashboard" | "history" | "more" | "graph" | "dpw";
  public working: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient, private webViewService: WebViewService) {}

  getFormData(): Observable<ExchangeFormData> {
    return this.http.get<any>("/exchange/data").pipe(
      map((data) => {
        const { currencyPairs, tenors, settlementAccounts } = data;
        const accountsByCurrency = groupByCurrency(settlementAccounts);
        return { tenors, ...handleCurrencies(data), currencyPairs, accountsByCurrency };
      })
    );
  }

  getNdfData(): Observable<CurrencySetup> {
    return this.http.get<CurrencySetup>("/exchange/ndf-data").pipe(
      map((data) => {
        return { ...data, ...handleCurrencies(data) };
      })
    );
  }

  getTenorDates(pair: string): Observable<TenorDate[]> {
    const params = new HttpParams().set("currencyPair", pair);
    return this.http
      .get<TenorDateDto[]>("/exchange/tenor-dates", { params })
      .pipe(map(parseTenorDates));
  }

  getCollateralTypes(product: string): Observable<Collateral[]> {
    const params = new HttpParams().set("product", product);
    return this.http.get<Collateral[]>("/collateral/types", { params });
  }

  initialize(data: FxSpotDto): Observable<InitializationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<InitializationResult>("exchange/initialize", data, { headers });
  }

  getRate(guid: string): Observable<ForexRate> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<ForexRate>(`exchange/rate/${guid}`, { headers });
  }

  reject(guid: string): Observable<any> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<any>("exchange/reject", `"${guid}"`, { headers });
  }

  confirm(dealGuid: string, rateGuid: string): Observable<boolean> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<boolean>("exchange/confirm", { dealGuid, rateGuid }, { headers });
  }

  getStatus(guid: string): Observable<TransactionStatus> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<TransactionStatus>(`exchange/status/${guid}`, { headers });
  }

  returnToInitialPage(): void {
    if (this.returnPage === "dashboard") {
      this.webViewService.navigateFromWebView(["/"]);
    } else if (this.returnPage === "graph") {
      this.webViewService.navigateFromWebView(["/graph"]);
    } else if (this.returnPage === "history") {
      this.webViewService.navigateFromWebView(["/history"]);
    } else {
      this.webViewService.navigateFromWebView(["/more"]);
    }
  }
}

const handleCurrencies = ({ currencies, counterCurrencies}) => {
  return {
    currencies: parseHolidays(currencies),
    counterCurrencies: Object.entries(counterCurrencies)
      .map(([dealCurrency, counters]: [string, Currency[]]
        ) => (
        {
          dealCurrency,
        counterCurrencies: parseHolidays(counters),
       }
      )
        )
      .reduce((acc, curr) => {
        acc[curr.dealCurrency] = curr.counterCurrencies;
        return acc;
      },{}),
      
  };
};
