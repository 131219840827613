import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { difference } from "src/app/utils/time.utils";
import { sleep, slideRightAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { SessionAccountsService } from "../../../common/session/session-accounts.service";
import { Deposit, DepositDto, DepositRate, DepositError } from "../deposit.model";
import { MmDepositService } from "../deposit.service";

@Component({
  selector: "app-deposit-quote",
  templateUrl: "./deposit-quote.component.html",
  styleUrls: ["./deposit-quote.component.scss"],
  animations: [slideRightAnimation]
})
export class DepositQuoteComponent implements OnInit {
  @Input() public data: Deposit;

  public depositRate: DepositRate;
  public loading: boolean;
  public stopRatePolling: boolean;
  public decisionTime: number;
  public depositId: string;
  public isFirstRate: boolean;
  public dealerWait: boolean;
  public confirming: boolean;
  public failure: boolean;
  public error: DepositError;

  private _rateCaughtTime: Date;
  private _countdownInterval: ReturnType<typeof setInterval>;

  @Output() closed = new EventEmitter<boolean>();

  get timeEnd(): boolean {
    if (!this.depositRate) {
      return false;
    }

    return this.decisionTime <= Math.ceil(this.depositRate.decisionTime / 4);
  }

  constructor(
    private depositService: MmDepositService,
    private webViewService: WebViewService,
    private sessionAccountsService: SessionAccountsService
  ) {}

  ngOnInit(): void {
    this.startGetQuote();
  }

  startGetQuote() {
    this.webViewService.updateScreen("deposit", () => this.returnToInitialPage());
    this.renewQuote();
  }

  renewQuote(): void {
    this.isFirstRate = true;
    this.confirming = false;
    this.dealerWait = false;
    this.failure = false;
    this.depositId = null;
    this.depositRate = null;
    this.stopRatePolling = false;
    this.loading = true;
    this.getQuote();
  }

  getQuote(): void {
    this.depositService.initializeDeposit(this.getDto(this.data)).subscribe(
      result => {
        console.log("Initialization succeeded");

        if (result.errorCode) {
          this.setErrorDetails(result);
        } else {
          this.getRate(result.guid);
        }
      },
      msg => {
        this.setErrorDetails();
        console.log("Initialization failed", msg);
      }
    );
  }

  getDto(deposit: Deposit): DepositDto {
    const { account, daysUntilEnd, ...dto } = deposit;
    return { ...dto, account: account?.number };
  }

  getRate(guid: string) {
    if (this.stopRatePolling) {
      return;
    }

    this.depositService.getRate(guid).subscribe(
      result => {
        console.log("Get rate succeeded");

        if (this.stopRatePolling) {
          return;
        }

        if (result.failCode || result.failReason) {
          clearInterval(this._countdownInterval);
          console.log("ERROR! GetRate");
          this.setErrorDetails(result);
        } else {
          switch (result.responseType) {
            case "RETRY":
              setTimeout(() => this.getRate(guid), 500);
              return;
            case "DEALERWAIT":
              this.isFirstRate = false;
              this.dealerWait = true;
              setTimeout(() => this.getRate(guid), 2000);
              return;
            case "DEALERCHANGE":
              this.isFirstRate = false;
              this.dealerWait = true;
              setTimeout(() => this.getRate(guid), 2000);
              break;
            case "PRICE":
              this.isFirstRate = false;
              this.loading = false;
              this.failure = false;
              this.dealerWait = false;
              if (this.depositRate == null) {
                this.depositRate = result;
                this._rateCaughtTime = new Date();
                this.decisionTime = result.decisionTime;
                this.countdown();
              }
              this.depositRate = result;
              setTimeout(() => this.getRate(guid), 500);
          }
        }
      },
      msg => {
        this.setErrorDetails();
        console.log("Get rate failed", msg);
      }
    );
  }

  countdown() {
    const el = document.querySelector(".wv-summary-modal__action-timer--active"),
      newone = el.cloneNode(true) as Element;

    const prev = el.previousSibling as Element;
    el.remove();

    newone.setAttribute(
      "style",
      `animation-duration: ${this.depositRate.decisionTime}s; -webkit-animation-duration: ${this.depositRate.decisionTime}s`
    );

    prev.after(newone);

    this._countdownInterval = setInterval(() => {
      const expired = difference(new Date(), this._rateCaughtTime, "seconds");
      this.decisionTime = Math.round(this.depositRate.decisionTime - expired);

      if (this.decisionTime <= 0) {
        this.reject();
      }
    }, 1000);
  }

  reject() {
    this.stopRatePolling = true;
    clearInterval(this._countdownInterval);
    this.loading = true;
    this.depositService.reject(this.depositRate.dealGuid).subscribe(
      () => {
        console.log("Rejected");
      },
      msg => {
        console.log("Reject failed", msg);
      }
    );
  }

  confirm() {
    this.stopRatePolling = true;
    this.confirming = true;
    clearInterval(this._countdownInterval);
    this.depositService.confirm(this.depositRate.dealGuid, this.depositRate.rateGuid).subscribe(
      () => {
        this.getStatus();
      },
      msg => {
        this.setErrorDetails();
        console.log("Confirm failed", msg);
      }
    );
  }

  getStatus() {
    this.depositService.getStatus(this.depositRate.dealGuid).subscribe(
      result => {
        if (!result) {
          setTimeout(() => this.getStatus(), 500);
        } else {
          if (result.transactionId) {
            this.confirming = false;
            this.depositId = result.transactionId;
            this.sessionAccountsService.store([this.data.account?.number]);
          } else {
            this.setErrorDetails(result);
          }
        }
      },
      msg => {
        this.setErrorDetails();
        console.log("Get status failed", msg);
      }
    );
  }

  public openNewDeposit(clearForm: boolean = false) {
    this.depositId = null;
    this.failure = false;
    this.dealerWait = false;
    this.webViewService.updateScreen("deposit", () => this.depositService.returnToInitialPage());
    this.closed.emit(clearForm);
  }

  setErrorDetails(error: DepositError = { errorCode: "GENERIC", failCode: null }) {
    this.error = error;
    this.isFirstRate = false;
    this.confirming = false;
    this.failure = true;
  }

  returnToInitialPage() {
    if (!this.stopRatePolling) {
      this.reject();
    }
    this.openNewDeposit();
  }
}
