import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  InitializationResult,
  TenorDate,
  TenorDateDto,
  TransactionStatus,
} from "@common/dealing/dealing.model";
import { groupByCurrency, isNearTenor, parseHolidays, parseTenorDates } from "@common/dealing";
import { noCacheHeader } from "@common/_core/cache/request-cache.model";
import { NavigationService } from "@common/_core/navigation/navigation.service";
import { DepositDto, DepositRate, DepositFormData } from "./deposit.model";
import { negate } from "@utils/function.utils";

@Injectable({ providedIn: "root" })
export class MmDepositService {
  public returnPage: "history" | "more";

  constructor(private http: HttpClient, private _navigation: NavigationService) {}

  getFormData(): Observable<DepositFormData> {
    return this.http.get<any>("/deposit/data").pipe(
      map((data) => {
        const { tenors, settlementAccounts } = data;
        const accountsByCurrency = groupByCurrency(settlementAccounts);
        const currencies = parseHolidays(data.currencies);
        const farTenors = tenors.filter(negate(isNearTenor));

        return { farTenors, currencies, accountsByCurrency };
      })
    );
  }

  getTenorDates(startTenor: string): Observable<TenorDate[]> {
    const params = new HttpParams().set("startTenor", startTenor);

    return this.http
      .get<TenorDateDto[]>("/deposit/tenor-dates", { params })
      .pipe(map(parseTenorDates));
  }

  initializeDeposit(data: DepositDto): Observable<InitializationResult> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<InitializationResult>("/deposit/initialize", data, { headers });
  }

  getRate(guid: string): Observable<DepositRate> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<DepositRate>(`/deposit/rate/${guid}`, { headers });
  }

  reject(guid: string): Observable<any> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<any>("/deposit/reject", `"${guid}"`, { headers });
  }

  confirm(dealGuid: string, rateGuid: string): Observable<boolean> {
    const headers = { "x-no-retry": "1" };
    return this.http.post<boolean>("/deposit/confirm", { dealGuid, rateGuid }, { headers });
  }

  getStatus(guid: string): Observable<TransactionStatus> {
    const headers = { "x-no-retry": "1", ...noCacheHeader };
    return this.http.get<TransactionStatus>(`/deposit/status/${guid}`, { headers });
  }

  returnToInitialPage(): void {
    const url = this.returnPage === "history" ? "/history/deposit" : "/more";
    this._navigation.navigate([url]);
  }
}
