<div class="wv-section-header" [ngClass]="{ 'wv-section-header--no-border': noBorder }">
  <div class="wv-section-header__title">
    {{ title }}
    <span class="wv-tooltip" *ngIf="tooltip" (click)="tooltip()"></span>

    <div class="wv-notification-badge__container" *ngIf="notification">
      <span class="wv-notification-badge__indicator-container ripple" [id]="notificationId">
        <span class="wv-notification-badge__indicator"></span>
      </span>
    </div>
  </div>
  <div *ngIf="subtitle" class="wv-section-header__subtitle">
    {{ subtitle }}
  </div>
</div>
