<app-preloader [absolute]="true" *ngIf="loading"></app-preloader>
<div class="wv-view-my-negotiable-deposits" [@hide]="filtersOpen ? 'modalOpen' : ''">
  <div class="wv-container wv-container--grey">
    <app-status-filter [startStatus]="startStatus"
                       [statuses]="statuses"
                       [filtersCount]="filtersCount"
                       translationPath="Deposit.Statuses."
                       (openFilters)="openFilters()"
                       (statusChange)="onStatusChanged($event)">
    </app-status-filter>
    <app-deposit-history-element *ngFor="let deposit of deposits"
                                 [deposit]="deposit"
                                 [routerLink]="['/history/deposit', deposit.systemReference]">
    </app-deposit-history-element>
    <div class="wv-transactions-empty-list" *ngIf="hasDeals !== null && !loading && !deposits.length">
      {{ (hasDeals ? "MissingData.NoSearchResults" : "MissingData.DepositHistory") | translate }}
    </div>
  </div>
  <button
    class="wv-floating-button wv-floating-button--active"
    style="background-image: url('assets/wv-img/icon_add_fab.svg'); display: block"
    routerLink="/deposit"
    [state]="{ returnPage: 'history' }"
  ></button>
</div>
<app-deposit-history-filters #filters (filterEvent)="onFilter()"></app-deposit-history-filters>
