import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../menu-section/menu.model";

@Component({
  selector: "app-menu-button",
  templateUrl: "menu-button.component.html"
})
export class MenuButtonComponent implements OnInit {
  @Input() item: MenuItem;
  @Input() returnPage: string;
  private canClick = true;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.canClick = true;
  }

  get state() {
    const {
      returnPage,
      item: { initialData }
    } = this;

    return returnPage || initialData ? { returnPage, initialData } : undefined;
  }

  public handleClick(e: Event) {
    console.warn("######     this.item.link = ", this.item.link)
    if (!this.canClick) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    this.canClick = false;
    this.router.navigateByUrl(this.item.link, { state: this.state })
  }
}
