export interface Filters {
  pageSize?: number;
  pageNumber?: number;
  type?: "All" | "MaturingToday" | "NotMatured" | "Matured" | "MaturingWeek";
  dealType?: string;
  dateRange?: Range<Date>;
  settlementRange?: Range<Date>;
  currencyPair?: string;
  currency?: string;
  amountRange?: Range<number>;
  rateRange?: Range<number>;
  transactionId?: number;
  side?: string;
  settlementStatus?: string;
}

export interface Range<T = Date | number> {
  from: T;
  to: T;
}

export const defaultExchangeFilters: Partial<Filters> = {
  pageNumber: 1,
  dealType: "Forex",
  side: null,
  type: "All",
  settlementStatus: "All"
};

export const defaultDepositFilters: Partial<Filters> = {
  pageNumber: 1,
  type: "All",
};
