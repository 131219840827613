<div class="wv-my-alerts ripple">
  <div class="wv-my-alerts__info">
    <div class="wv-my-alerts__header" translate>
      Alerts.CurrencyPair
    </div>
    <div class="wv-my-alerts__value">
      {{ alert.currencyPair | pair }}
    </div>
    <div class="wv-my-alerts__header" translate>
      Alerts.ExpectedRate
    </div>
    <div class="wv-my-alerts__value">
      {{ alert.targetRate | number: "1.4-4" }}
    </div>
    <div class="wv-my-alerts__header" translate>
      PhoneNumber
    </div>
    <div class="wv-my-alerts__value">
      {{ alert.phoneNumber}}
    </div>
  
    
  </div>
  <div class="wv-my-alerts__info">
    <div class="wv-my-alerts__header">
      {{ (alert.side === "B" ? "Buy" : "Sell") | translate }}
    </div>
    <div class="wv-my-alerts__value">
      {{ alert.amount | number: "1.2-2"  }} {{ alert.currency }}
    </div>
    <div class="wv-my-alerts__header" translate>
      Alerts.ValidUntil
    </div>
    <div class="wv-my-alerts__value">
      {{ alert.expirationDate | date: "dd.MM.yyyy" }}
    </div>
  </div>
  <div class="wv-notification-badge__container" (click)="openMenu($event)">
    <span class="wv-notification-badge__indicator-container ripple">
      <span class="wv-notification-badge__indicator"></span>
    </span>
  </div>

</div>
