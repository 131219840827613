import { Component, Input } from "@angular/core";
import { DealDetails } from "../exchange-history.model";

@Component({
  selector: "app-exchange-details-simple",
  templateUrl: "exchange-details-simple.component.html",
})
export class ExchangeDetailsSimpleComponent {
  @Input() details: DealDetails;

  constructor() {}

  get showButton(): boolean {
    return !this.details.isIob && !this.details.isClosing;
  }

  get status(): string {
    const { isSettled, isIob } = this.details;
    return isSettled ? "Settled" : isIob ? "Undefined" : "Unsettled";
    //return isSettled ? "Settled" : "Unsettled";

  }

  get subType(): string {
    const { subType, isIob, paymentType } = this.details;

    return isIob && paymentType?.length ? `${subType}.${paymentType}` : subType;
  }
}
