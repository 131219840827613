import { Component, Input } from "@angular/core";

@Component({
  selector: "app-indicative-pair-item",
  templateUrl: "indicative-pair-item.component.html"
})
export class IndicativePairItemComponent {
  @Input() item: { key: string; value: boolean };
  constructor() {}

  get base(): string {
    return this.item.key.substring(5, 8);
  }

  get quote(): string {
    return this.item.key.substring(8);
  }

  get icon(): string {
    return this.item.value ? "remove" : "add";
  }
}
