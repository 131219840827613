export interface MenuSection {
  header: string;
  items: MenuItem[];
}

export interface MenuItem {
  name: string;
  link: string;
  requiredRoles: string;
  icon?: string;
  initialData?: Record<string, string>;
}

export const topMenu: MenuItem[] = [
  { name: "Menu.Dashboard", link: "/", requiredRoles: "Customer,HasCustomer" },
  { name: "Menu.History", link: "/history", requiredRoles: "Customer,HasCustomer" },
  { name: "Menu.Profile", link: "/settings", requiredRoles: "Customer,HasCustomer" },
  { name: "Menu.More", link: "/more", requiredRoles: "Customer,HasCustomer" }
];

export const menuSections: MenuSection[] = [
  {
    header: "Menu.Forex.Header",
    items: [
      { name: "Menu.Forex.Normal", 
      link: "/exchange", 
      //link: "", 
      requiredRoles: "Customer,HasCustomer", 
      icon: "new_deal" },
      {
        name: "Menu.Forex.Forward",
        link: "/exchange",
        //link: "",
        requiredRoles: "Customer,HasCustomer",
        icon: "new_forward",
        initialData: { tenor: "SN" }
      },
      { name: "Menu.Forex.Order", 
        //link: "/order", 
        link: "/more", 
        requiredRoles: "Customer,HasCustomer", 
        icon: "new_order" 
      },
      { name: "Menu.Forex.DPW", 
        link: "/dpw", 
        //link: "", 
        requiredRoles: "Customer,HasCustomer", 
        icon: "dpw" 
      }
    ]
  },
  {
    header: "Menu.Alerts.Header",
    items: [
      { name: "Menu.Alerts.Form", 
        //link: "/alert", 
        link: "/more", 
        requiredRoles: "Customer,HasCustomer", 
        icon: "new_alert" 
      },
      { name: "Menu.Alerts.List", 
        link: "/alerts", 
        requiredRoles: "Customer,HasCustomer", 
        icon: "alerts" 
      }
    ]
  },
  {
    header: "Menu.Deposits.Header",
    items: [
      { name: "Menu.Deposits.Form", 
        link: "/deposit", 
        //link: "",
        requiredRoles: "Customer,HasCustomer", 
        icon: "new_deposit" 
      },
      {
        name: "Menu.Deposits.List",
        link: "/history/deposit",
        requiredRoles: "Customer,HasCustomer",
        icon: "deposits"
      }
    ]
  },
  {
    header: "Menu.Help.Header",
    items: [{ name: "Menu.Help.Documents", 
              //link: "/documents", 
              link: "/more",
              requiredRoles: "Customer", 
              icon: "help" 
            }]
  }
];
