import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { slideRightAnimation } from "../../common/animations/animations";
import { WebViewService } from "../../common/mobile/webview.service";

@Component({
  selector: "app-dealer-wait",
  templateUrl: "./dealer-wait.component.html",
  styleUrls: ["./dealer-wait.component.scss"],
  animations: [slideRightAnimation]
})
export class DealerWaitComponent implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Output() public closed = new EventEmitter<any>();
  @Output() public cancelled = new EventEmitter<any>();

  @Input()
  get show(): boolean {
    return this._show;
  }
  set show(show: boolean) {
    this._show = show;
    if (show && this.title) {
      this.webViewService.updateScreen(this.title, () => this.close());
    }
  }
  private _show: boolean = false;

  constructor(private webViewService: WebViewService) {}

  ngOnInit(): void {}

  cancel(): void {
    this.cancelled.emit();
  }

  close() {
    this.closed.emit();
  }
}
