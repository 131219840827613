import { Account } from "../../common/accounts/account.model";
import { Currency } from "../../common/dealing/dealing.model";
import { PhonePrefix } from "../../common/phones/phone.model";

export interface OrderFormData {
  orderTypes: OrderTypeOption[];
  tenors: string[];
  plnCurrency: Currency;
  currencies: Currency[];
  accountsByCurrency: Record<string, Account[]>;
  limitCounterCurrencies: Record<string, Currency[]>;
  stopMarketCounterCurrencies: Record<string, Currency[]>;
  trailingStopCounterCurrencies: Record<string, Currency[]>;
  currencyPairs: OrderPair[];
  nightShiftStart: Date;
  nightShiftEnd: Date;
  nextNightShiftStart: Date;
  workingHoursStartMessage: string;
  phonePrefixes: PhonePrefix[];
  useSmsNotification: boolean;
}

export interface ExpiryOption {
  id: string;
  time?: Date;
}

export interface OrderPair {
  code: string;
  places: number;
  multiplier: number;
  limit: boolean;
  stopMarket: boolean;
  trailingStop: boolean;
}

export interface OrderTypeOption {
  id: string;
  minTime?: Date;
  maxTime?: Date;
}

export interface OrderInitResult {
  guid: string;
  success: boolean;
  error: string;
}

export interface OrderStatus {
  retry: boolean;
  orderId: number;
  state: string;
  failCode: number;
  failReason: string;
}

export interface OrderRequest {
  type: string;
  rate: number;
  points: number;
  side: string;
  amount: number;
  counterAmount: number;
  dealCurrency: string;
  counterCurrency: string;
  settlementAccount: Account;
  counterSettlementAccount: Account;
  pair: string;
  tenor: string;
  expirationDateTime?: Date;
  productType: string;
  phonePrefix: string;
  phoneNumber: string;
  useSmsNotification: boolean;
}

export interface OrderRequestDto {
  type: string;
  rate: number;
  points: number;
  side: string;
  amount: number;
  counterAmount: number;
  dealCurrency: string;
  settlementAccountId: string;
  counterSettlementAccountId: string;
  pair: string;
  tenor: string;
  expirationDateTime?: Date;
  productType: string;
  phonePrefix: string;
  phoneNumber: string;
  useSmsNotification: boolean;
}

export interface OrderValidationResult {
  isValid: boolean;
  error: string;
}

export const specialPairs = ["JPYPLN", "HUFPLN"];
