import { Pipe, PipeTransform } from "@angular/core";
import { baseCurrency, quoteCurrency } from "../common/dealing";

@Pipe({
  name: "pair"
})
export class PairPipe implements PipeTransform {
  transform(pair: string): string {
    return pair && baseCurrency(pair) + "/" + quoteCurrency(pair);
  }
}
