import { Component, Input } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { isAndroid } from "src/app/common/mobile/webview.model";

@Component({
  selector: "app-input-toggle",
  templateUrl: "input-toggle.component.html"
})
export class InputToggleComponent {
  constructor(private ngControl: NgControl) {}

  @Input("ngId") id: string = "toggle";
  @Input() name: string;
  @Input() label: string;
  @Input() description: string;

  get osClass(): string {
    return isAndroid() ? "wv-accounts-toggle-round-android" : "wv-accounts-toggle-round-ios";
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
