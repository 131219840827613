import { LogLevel } from "../logging/logging.model";

export interface Config {
  logging: Logging;
  httpRetries: number;
  httpTimeout: number;
  indicatives: Indicatives;
}

export interface Logging {
  default: LogLevel;
  apiJs: LogLevel;
}

export interface Indicatives {
  pollingInterval: number;
  pairLimit: number;
}

export const defaultConfig: Config = {
  logging: { default: LogLevel.Warning } as Logging,
  indicatives: { pollingInterval: 10, pairLimit: 8 }
} as Config;
