import { HttpRequest, HttpResponse } from "@angular/common/http";

export interface RequestCacheEntry {
  url: string;
  response: HttpResponse<any>;
  lastRead: number;
}

export abstract class RequestCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void;
}

export const noCacheHeader = { "Cache-Control": "no-cache" };
