import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActionItem } from "src/app/common/mobile/webview.model";
import { WebViewService } from "src/app/common/mobile/webview.service";
import { UserNotificationComponent, ExecutedAlert } from "@components/notifications/notifications.model";
import { FormInitialState } from "@components/exchange/exchange.model";

@Component({
  selector: "app-notifications-executed-alert",
  templateUrl: "./notifications-executed-alert.component.html",
  styleUrls: ["./notifications-executed-alert.component.scss"]
})
export class NotificationsExecutedAlertComponent extends UserNotificationComponent implements OnInit {
  @Input() public alert: ExecutedAlert;

  constructor(webViewService: WebViewService, private translate: TranslateService) {
    super(webViewService);
  }

  ngOnInit(): void {}

  get icon(): string {
    return "executed_alert";
  }

  mainAction() {
    this._webViewService.navigateFromWebView(["/exchange"], {
      state: {
        initialData: {
          amount: this.alert.amount1,
          currency: this.alert.currency1,
          counterCurrency: this.alert.currency2,
          side: this.alert.side
        } as FormInitialState
      }
    });
  }

  openMenu(event) {
    super.drawMenu(event, [
      {
        text: this.translate.instant("Notifications.NewDeal"),
        callback: () => this.mainAction()
      } as ActionItem
    ]);
  }
}
