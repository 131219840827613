<div class="wv-transaction-details__quote-container">
  <app-summary-item label="OfferedRate">
    <span class="wv-transaction-details__quote-rate">
      {{ loading ? ("MissingData.Amount" | translate) : (rate | number: "1.4-4") }}
    </span>
  </app-summary-item>

  <app-summary-item-amount
    [label]="details.side"
    [amount]="details.amount"
    [currency]="details.dealCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-amount
    label="Vs"
    *ngIf="!loading; else noDataVsBlock"
    [amount]="counterAmount"
    [currency]="details.counterCurrency"
    [flag]="true"
  ></app-summary-item-amount>

  <app-summary-item-date
    label="SettlementDate"
    [date]="details.settlementDate"
  ></app-summary-item-date>

  <ng-container *ngIf="details.isDpw">
    <app-summary-item label="TransactionType">
      {{ "Transaction.SubTypes.Iob." + subtype | translate }}
    </app-summary-item>
    <app-summary-item label="DpwNumber">
      {{ details.dpwNumber }}
    </app-summary-item>
  </ng-container>

  <ng-container *ngIf="!details.isDpw">
    <app-summary-item label="TransactionType">
      {{ "Products." + details.dealType | translate }}
    </app-summary-item>

    <ng-container *ngIf="!details.isNetSettlement">
      <app-summary-item-account
        label="FromAccount"
        [account]="fromAccount"
      ></app-summary-item-account>
      <app-summary-item-account label="ToAccount" [account]="toAccount"></app-summary-item-account>
    </ng-container>

    <ng-container *ngIf="details.isNetSettlement">
      <app-summary-item label="TransactionSubType">
        {{ "NetSettlement" | translate }}
      </app-summary-item>
      <app-summary-item-account
        label="SettlementAccount"
        [account]="details.counterAccount"
      ></app-summary-item-account>
    </ng-container>

    <app-summary-item label="Collateral.Type" *ngIf="showCollateralType">
      {{ "Collateral.Types." + details.collateral | translate }}
    </app-summary-item>

    <app-summary-item-account
      label="Collateral.Account"
      *ngIf="details.collateralAccount"
      [account]="details.collateralAccount"
    ></app-summary-item-account>

    <ng-container *ngIf="details.collateralAccount">
      <app-summary-item-amount
        label="Collateral.Amount"
        *ngIf="!loading; else noDataCollateralBlock"
        [amount]="collateralAmount"
        [currency]="details.collateralAccount.currency"
      ></app-summary-item-amount>
    </ng-container>
  </ng-container>
</div>

<ng-template #noDataVsBlock>
  <app-summary-item label="Vs">{{ "MissingData.Amount" | translate }}</app-summary-item>
</ng-template>

<ng-template #noDataCollateralBlock>
  <app-summary-item label="Collateral.Amount">{{
    "MissingData.Amount" | translate
  }}</app-summary-item>
</ng-template>
