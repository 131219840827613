import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts/highstock";

import { CurrencyPair } from "@common/dealing/dealing.model";
import { NavigationService } from "@common/_core/navigation/navigation.service";
import { UserService } from "@common/_core/session/user-service";
import darkTheme from "@components/graph/themes/graph.dark.theme";
import lightTheme from "@components/graph/themes/graph.light.theme";
import { ChartOptions, IndicativeGraph } from "../indicatives.model";

@Component({
  selector: "app-indicative-chart",
  templateUrl: "./indicative-chart.component.html",
  styleUrls: ["./indicative-chart.component.scss"],
})
export class IndicativeChartComponent implements OnInit {
  public Highcharts: typeof Highcharts = Highcharts;
  public options: ChartOptions;

  @Input() public graphs: IndicativeGraph[];
  @Input() public pair: CurrencyPair;

  get darkMode(): boolean {
    return this.userService.appData.isDarkMode;
  }

  constructor(private _navigation: NavigationService, private userService: UserService) {}

  ngOnInit(): void {
    this.darkMode ? darkTheme(Highcharts) : lightTheme(Highcharts);

    this.setChartOptions();
  }

  openGraph() {
    this._navigation.navigate(["/graph"], { state: { pair: this.pair.code } });
  }

  private setChartOptions() {
    const { points } = this.graphs?.find(({ pair }) => pair === this.pair.code) || {};

    if (!points) {
      return;
    }

    const options: Record<string, Highcharts.Options> = {
      global: { useUTC: true },
      credits: { enabled: false },
      tooltip: { enabled: false },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      rangeSelector: { enabled: false },
      plotOptions: {
        series: {
          lineWidth: 1.5,
          allowPointSelect: false,
          enableMouseTracking: false,
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        visible: false,
        min: Math.min(...points.map((x) => x.time)),
        max: Math.max(...points.map((x) => x.time)),
      },
      yAxis: {
        visible: false,
        endOnTick: false,
        startOnTick: false,
        min: Math.min(...points.map((x) => x.mid)) - 0.0002,
        max: Math.max(...points.map((x) => x.mid)) + 0.0002,
      },
      chart: {
        zoomType: "",
        pinchType: "",
        panning: false,
        margin: [0, 0, 0, 0],
      },
      series: [
        {
          name: "mid",
          data: points.map((x) => [x.time, x.mid]),
          color: this.darkMode ? "#006EF5" : "#004c9a",
        },
      ],
    };

    this.options = {
      options,
      callback: () => {},
      update: false,
      oneToOne: true,
      runOutsideAngular: false,
    };
  }
}
