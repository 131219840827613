<div
  class="wv-global-modal wv-global-modal--info wv-global-modal--visible"
  [@slideInOut]="pairSelection ? 'modalOpen' : filtersOpen ? 'enter' : 'leave'"
>
  <form
    [formGroup]="filterForm"
    class="wv-container wv-container--grey wv-filters{{ filterForm.dirty ? '--dirty' : '' }}"
  >
    <app-input-wrapper for="transaction-id" label="Filters.Search">
      <app-input-amount
        ngDefaultControl
        formControlName="transactionId"
        ngId="transaction-id"
        placeholder="Filters.InsertDealNumber"
      ></app-input-amount>
    </app-input-wrapper>

    <app-input-range
      type="date"
      formGroupName="date"
      label="Filters.DealDate"
      [placeholder]="{ from: 'Filters.From', to: 'Filters.To' }"
    ></app-input-range>

    <app-input-range
      type="date"
      formGroupName="settleDate"
      label="Filters.SettlementDateExchangeFrom"
      [placeholder]="{ from: 'Filters.From', to: 'Filters.To' }"
    ></app-input-range>

    <app-input-wrapper for="side" label="Filters.Side">
      <select id="side" formControlName="side">
        <option ngValue="Any" translate>Filters.All</option>
        <option ngValue="Buy" translate>Buy</option>
        <option ngValue="Sell" translate>Sell</option>
      </select>
    </app-input-wrapper>

    <app-input-wrapper label="Filters.TransactionType" for="transaction-dealType">
      <select id="transaction-dealType" formControlName="dealType">
        <option *ngFor="let dealType of dealTypes" [ngValue]="dealType">
          {{ "Transaction.DealTypes." + dealType | translate }}
        </option>
      </select>
    </app-input-wrapper>

    <app-input-wrapper label="Filters.CurrencyPair" (click)="selectPair()">
      <div class="wv-select-currency-pair__button">
        <app-pair-selection-item
          *ngIf="currencyPair.value; else selectCurrency"
          [pair]="currencyPair.value"
        ></app-pair-selection-item>
      </div>
    </app-input-wrapper>

    <app-input-range
      formGroupName="rate"
      type="amount"
      labelFrom="Filters.RateFrom"
      labelTo="Filters.RateTo"
      [decimals]="4"
    ></app-input-range>
    <app-input-range
      formGroupName="amount"
      type="amount"
      labelFrom="Filters.AmountFrom"
      labelTo="Filters.AmountTo"
      [decimals]="2"
    ></app-input-range>

    <app-input-wrapper label="Filters.Currency" for="currency">
      <select id="currency" formControlName="currency">
        <option [ngValue]="null" *ngIf="!currencyPair.value">
          {{ "Filters.All" | translate }}
        </option>
        <option *ngFor="let currency of currencies | async" [ngValue]="currency">
          {{ currency }}
        </option>
      </select>
    </app-input-wrapper>
  </form>

  <div class="wv-fixed-buttons-container">
    <button
      *ngIf="filterForm.dirty"
      class="wv-global-button wv-global-button--fixed wv-global-button--white-normal ripple"
      (click)="clear()"
    >
      {{ "Filters.Clear" | translate }}
    </button>
    <button class="wv-global-button wv-global-button--fixed ripple" (click)="filter()">
      {{ "Filters.Show" | translate }}
    </button>
  </div>
</div>

<app-pair-selection
  *ngIf="pairSelection"
  [currency]="currency.value"
  (pairSelected)="onPairSelected($event)"
  [pair]="currencyPair.value"
>
</app-pair-selection>

<ng-template #selectCurrency>
  <div class="wv-placeholder" translate>Select</div>
</ng-template>
