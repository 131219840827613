import { Component, Input } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";

import {
  UserNotification,
  MessageNotificationItem,
} from "@components/notifications/notifications.model";
import { NotificationsService } from "@components/notifications/notifications.service";

@Component({
  selector: "app-notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.scss"],
  animations: [
    trigger("slideOut", [
      transition("* => deleted", [
        style({
          right: 0,
          position: "relative",
          display: "block",
        }),
        animate("300ms ease-in", style({ right: "-100vw" })),
      ]),
    ]),
  ],
})
export class NotificationsListComponent {
  @Input() limit: number;
  @Input() notifications: UserNotification[];

  constructor(private _notificationsService: NotificationsService) {}

  onMessageRemoved(notification: MessageNotificationItem) {
    notification.deleted = true;
    setTimeout(() => {
      this._notificationsService.removeFromCache(notification);
    }, 300);
  }

  trackByKey(_: number, { key }: UserNotification) {
    return key;
  }
}
