import { Component, OnInit, HostListener, ViewChild, Inject } from "@angular/core";
import { ExchangeHistoryService } from "./exchange-history.service";
import { Deal } from "./exchange-history.model";
import { DOCUMENT, KeyValue } from "@angular/common";
import { ExchangeHistoryFiltersComponent } from "./exchange-history-filters/exchange-history-filters.component";

@Component({
  selector: "app-exchange-history",
  templateUrl: "exchange-history.component.html",
  styleUrls: ["exchange-history.component.scss"],
})
export class ExchangeHistoryComponent implements OnInit {
  public statuses: string[] = ["All", "Unsettled", "Settled", "Undefined"];

  @ViewChild("filters", { static: true })
  private _filters: ExchangeHistoryFiltersComponent;

  get startStatus() {
    return this.historyService.filters?.type;
  }

  get filtersCount() {
    return this.historyService.filtersCount;
  }

  get filtersOpen() {
    return this._filters.filtersOpen;
  }

  get empty() {
    return this.historyService.empty;
  }

  get hasDeals() {
    return this.historyService.hasDeals;
  }

  get dealsByDate() {
    return this.historyService.dealsByDate;
  }

  get loading() {
    return this.historyService.loading;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private historyService: ExchangeHistoryService
  ) { }

  ngOnInit(): void {
    if (history.state.noRefresh) {
      return;
    }

    this.historyService.reset(true);
    this.historyService.getList();
  }

  openFilters() {
    this._filters.filtersOpen = true;
  }

  onFilter() {
    this.historyService.reset();
    this.historyService.getList();
  }

  onStatusChanged(status) {
    this.historyService.filters.settlementStatus = status;
    this.historyService.reset();
    this.historyService.getList();
  }

  dateOrder = (a: KeyValue<string, Deal[]>, b: KeyValue<string, Deal[]>): number => {
    const date1 = new Date(a.key);
    const date2 = new Date(b.key);
    if (date1 == date2) {
      return 0;
    }
    return date1 > date2 ? -1 : 1;
  };

  @HostListener("body:scroll", [])
  onScroll(): void {
    // if (this.historyService.end || this.filtersOpen) return;    // <<-------- NOWE

    // const { clientHeight } = document.getElementsByClassName("wv-measure-container")[0];

    // if (this.document.body.clientHeight + this.document.body.scrollTop >= clientHeight - 30) {
    //   this.historyService.getList();
    // }
  }
}
