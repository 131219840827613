import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigStore } from "../config/config.service";
import { Logging } from "../config/config.model";
import { LogEntry, LogLevel, LogSource } from "./logging.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LoggingService {
  constructor(private httpClient: HttpClient, private configStore: ConfigStore) {}

  private get config() {
    return this.configStore.getProp<Logging>("logging");
  }

  logDebug(source: LogSource, message: string) {
    this.log(source, message, LogLevel.Debug);
  }

  logInfo(source: LogSource, message: string) {
    this.log(source, message, LogLevel.Information);
  }

  logWarning(source: LogSource, message: string) {
    this.log(source, message, LogLevel.Warning);
  }

  logError(source: LogSource, message: string, error?: Error) {
    const errorMessage = message + (error && " | " + error.message);
    this.log(source, errorMessage, LogLevel.Error);

    if (!environment.production) {
      console.error(error);
    }
  }

  private log(source: LogSource, message: string, level: LogLevel) {
    if (!this.canLog(source, level)) {
      return;
    }

    const entry = new LogEntry();
    entry.level = level;
    entry.source = source;
    entry.message = message;

    const headers = { "x-no-retry": "*" };

    this.httpClient
      .post("/browser/log", entry, { headers })
      .toPromise()
      .catch(() => console.log("Logging error!"));

    console.log(entry.toString());
  }

  private canLog(source: LogSource, level: LogLevel): boolean {
    const allowedLevel: string = this.config[source] || this.config.default;
    return level >= LogLevel[allowedLevel];
  }
}
