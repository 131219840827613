import { Component, OnInit } from '@angular/core';
import { ActivationService } from '../activation.service';
import { slideRightAnimation } from '../../../common/animations/animations';

@Component({
  selector: 'app-activation-waiting-page',
  templateUrl: './activation-waiting-page.component.html',
  styleUrls: ['./activation-waiting-page.component.scss'],
  animations: [slideRightAnimation]
})
export class ActivationWaitingPageComponent implements OnInit {
  public progress: number;
  public error: boolean;
  public success: boolean;
  private startTime: Date;
  private frozenProgress: number;
  private readonly endTime: number = 5000;

  constructor(public activationService: ActivationService) { }

  ngOnInit(): void {
    if (this.activationService.error) {
      return;
    }

    setTimeout(() => this.startWaiting(), 400);
  }

  startWaiting() {
    const style = (document.querySelector('.wv-view-digit-wait__status-bar-inner') as HTMLElement).style;
    style['transition'] = `width ${this.activationService.pageData.statusPollingConfiguration.totalPollingTime / 1000}s linear 0s`;
    setTimeout(function () { style['width'] = '100%' }, 0);
    this.startTime = new Date();
    this.updateProgressBar();
    this.getStatus();
  }

  updateProgressBar() {
    const pollingConfiguration = this.activationService.pageData.statusPollingConfiguration;
    const progressInterval = setInterval(() => {
      let end = this.success || this.error;
      let progress = Math.round(
        (new Date().getTime() - this.startTime.getTime()) / (end ? this.endTime : pollingConfiguration.totalPollingTime) * 100);

      if (end) {
        this.progress = Math.round(this.frozenProgress + progress / 100 * (100 - this.frozenProgress));
      }
      else {
        this.progress = progress;
      }

      if (this.progress >= 100) {
        this.progress = 100;
        clearInterval(progressInterval);
        this.activationService.next();
      }
    }, 100);
  }

  getStatus() {
    const pollingConfiguration = this.activationService.pageData.statusPollingConfiguration;
    setTimeout(() => {
      if ((new Date().getTime() - this.startTime.getTime()) > pollingConfiguration.totalPollingTime) {
        return;
      }

      this.activationService.getStatus().subscribe((data) => {
        if (!data.success && !data.error) {
          this.getStatus();
          return;
        }
        this.success = data.success;
        this.error = data.error;
        this.startTime = new Date();
        this.frozenProgress = this.progress;
        const element = document.querySelector('.wv-view-digit-wait__status-bar-inner') as HTMLElement;
        const style = element.style;
        style['transition'] = `width ${this.endTime/1000}s linear 0s`;
        style['width'] = '0%';
        element.getBoundingClientRect();
        setTimeout(function () {
          style['width'] = '100%';
        }, 10);
      });
    }, pollingConfiguration.pollingInterval);
  }
}
