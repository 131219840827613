export interface Currency {
  code: string;
  decimals: number;
  dealingDays: string;
  holidays: string[];
}

export interface CurrencyPair {
  code: string;
}

export interface TenorDate {
  tenor: string;
  date: Date;
  product?: string;
}

export interface TenorDateDto {
  tenor: string;
  date: string;
}

export interface TransactionStatus {
  transactionId: string;
  errorCode: string;
}

export interface InitializationResult {
  success: boolean;
  guid: string;
  errorCode: string;
  additionalData: any;
}

export const nearTenorCodes = ["ON", "TN", "SPOT"];

export enum Side {
  Sell = "Sell",
  Buy = "Buy",
}

export const productsByTenor = {
  TOD: "FXON",
  TOM: "FXTN",
  SPOT: "FXSPOT",
  SN: "FXFORW",
  preSpot: "FXTN",
  postSpot: "FXFORW",
};

export interface DepositTenorDates {
  nearTenorDates: TenorDate[];
  farTenorDates: TenorDate[];
}

export interface DepositTenors {
  nearTenors: string[];
  farTenors: string[];
}
