import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { WebViewService } from "src/app/common/mobile/webview.service";
import { OrderDetails } from "../order-history.model";
import { OrderHistoryService } from "../order-history.service";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"]
})
export class OrderDetailsComponent implements OnInit {
  public details$: Observable<OrderDetails>;
  public show: boolean = true;
  public orderId: number;

  private _returnPage: "dashboard" | "history";
  private _isFromSuccessPage: boolean = false;

  @ViewChild("container")
  container: ElementRef;

  get shouldButtonBeSticky(): boolean {
    if (this.container) {
      return window.screen.height > this.container.nativeElement.offsetHeight + 100;
    }
    return false;
  }

  constructor(
    private orderService: OrderHistoryService,
    private activatedRoute: ActivatedRoute,
    private webViewService: WebViewService
  ) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("orderDetail", () => this.back());

    this._returnPage = history.state.returnPage || "history";
    this._isFromSuccessPage = history.state.isFromSuccessPage === "true";
    this.orderId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    this.details$ = this.orderService.getDetails(this.orderId);
  }

  cancel() {
    this.webViewService.showBottomSheet({
      title: "Order.RemoveOrderQuestion",
      primaryButton: {
        text: "Yes",
        callback: () => {
          this.orderService.cancel(this.orderId);
          this.back();
        }
      },
      additionalButton: { text: "No" }
    });
  }

  back() {
    this.show = false;
    if (this._returnPage === "dashboard") {
      this.webViewService.navigateFromWebView(["/"]);
    } else {
      this.webViewService.navigateFromWebView(["/history"], {
        state: {
          template: "orders",
          noRefresh: !this._isFromSuccessPage
        }
      });
    }
  }
}
