import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class HomeService {
  constructor(private httpClient: HttpClient) { }

  public track() {
   // this.httpClient.post("/action/dashboard", {}).subscribe();
  }
}
