<ng-container *ngIf="news$ | async as news">
  <div
    class="wv-economic-informations ripple"
    *ngFor="let newsItem of news | slice: 0:limit"
    [routerLink]="['/news', newsItem.id]"
  >
    <div class="wv-economic-informations__info">
      <div class="wv-economic-informations__date">
        {{ newsItem.date | date: "dd-MM-yyyy HH:mm" }}
      </div>
      <div class="wv-economic-informations__title">
        {{ newsItem.headline }}
      </div>
    </div>
    <div class="wv-economic-informations__more">
      <span class="wv-economic-informations__more-ico"></span>
    </div>
  </div>

  <div class="wv-container wv-container--border-bottom" *ngIf="news.length; else empty">
    <div class="wv-view-desktop__show-less" (click)="toggle()">
      <a class="anchor">{{ (expanded ? "ShowLess" : "ShowMore") | translate }}</a>
    </div>
  </div>
</ng-container>

<ng-template #empty>
  <div class="wv-economic-informations-empty">
    {{ "MissingData.MarketNews" | translate }}
  </div>
</ng-template>
