import { Component, Input } from "@angular/core";

@Component({
  selector: "app-summary-item",
  templateUrl: "summary-item.component.html"
})
export class SummaryItemComponent {
  @Input() label: string;

  constructor() {}
}
