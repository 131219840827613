<div class="wv-summary-modal__text-block">
  <span class="wv-summary-modal__text-label">
    {{ label | translate }}
    <ng-content select=".wv-tooltip"></ng-content>
  </span>
  <span class="wv-summary-modal__text-value">
    <ng-content></ng-content>
  </span>
  <ng-content select="span"></ng-content>
  <ng-content select="img"></ng-content>
</div>
