/**
 * Counts properties of an object. Useful for finding empty objects.
 * @param object An object whose props are to be counted.
 * @returns Amount of object's props
 */
export function propCount(object: Object): number {
  return (object && Object.keys(object).length) || 0;
}

/**
 * Creates an object without props with given values.
 * @param object An object with possible props to remove
 * @param value An undesirable value or array of values
 * @returns A new object without undesirable props.
 */
export function removePropsByValue(
  object: Object,
  value: undefined | null | string | number | boolean | Array<undefined | null | string | number | boolean>
): Object {
  const compare = Array.isArray(value) ? (val: any): boolean => value.includes(val) : (val: any): boolean => val === value;

  return Object.entries(object).reduce((acc, [key, val]) => {
    if (!compare(val)) {
      acc[key] = val;
    }

    return acc;
  }, {});
}
