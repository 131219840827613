import { Injectable } from "@angular/core";
import { WebViewService } from "@common/mobile/webview.service";
import { GraphPoint } from "@components/graph/graph.model";
import { IndicativePair, IndicativeRate } from "@components/indicatives/indicatives.model";
import { BehaviorSubject, Subject } from "rxjs";

// <<-------- NOWE CAŁE !!!

export const oldCommonData: any = {}

@Injectable({ providedIn: "root" })
export class FakeBackendService {
    private commonData: any = {};
    public commonData$: Subject<any> = new Subject();
    public isReady$: Subject<Boolean> = new BehaviorSubject(false);
    // 
    public downloadCountMax = 0;
    public downloadCount = 0;

    constructor(
        private _wv: WebViewService
    ) {
        // -------------- PODJEŚCIE STARE
        // fetchCsvData("waluty.csv")
        //     .then(data => {
        //         const tabToRise = [];
        //         const tab = convertToCurrencyPairs(data);
        //         this.commonData["/currency"] = tab;
        //         tab.forEach((el: any, index: number) => {

        //             tabToRise.push(fetchCsvData(el.para + ".csv")
        //                 .then(data2 => {
        //                     const tab2 = convertToGraphPoint(data2);
        //                     // alert(`1) fetchCsvData ${el.para} | data | ${tab2.length ?? 0}`)    //__ TO_DELETE
        //                     this.commonData["/graph/" + el.para] = tab2;
        //                 })
        //                 .then(part => {
        //                     // alert(`2) POPRAWNIE ZACZYTANO DANE ${el.para}`)                     //__ TO_DELETE
        //                 })
        //                 .catch(exc => {
        //                     // alert(`1# FETCH GRAPH DATA ERROR | ${el.para} | ${exc}`)            //__ TO_DELETE
        //                 })
        //             )
        //         })
        //         try {
        //             Promise.all(tabToRise)
        //                 .then(finish => {
        //                     this.getCommonData();

        //                     const indicativeRates: IndicativeRate[] = [];
        //                     const commonCurrencies = this.commonData["/currency"] ?? [];
        //                     commonCurrencies.forEach((element: { para: string; wybrane: Boolean; kup: number; odchylenie: number; sprzedaj: number; }) => {
        //                         indicativeRates.push({
        //                             pair: {
        //                                 code: element.para,
        //                                 // isSelected: element.wybrane,
        //                                 // accountMissing: boolean;
        //                                 // order: number;
        //                                 // nonDefaultSortingOrder: boolean;
        //                             } as IndicativePair,
        //                             buy: element.kup + (Math.random() * 2 * element.odchylenie - element.odchylenie),
        //                             sell: element.sprzedaj + (Math.random() * 2 * element.odchylenie - element.odchylenie),
        //                         })
        //                     })
        //                     oldCommonData["/indicatives/getrates"] = indicativeRates;

        //                     this.isReady$.next(true);
        //                 })
        //         } catch (exc) {
        //             // alert(`2# FETCH ALL GRAPH DATA ERROR | ${exc}`)         //__ TO_DELETE
        //             // console.error("FETCH DATA ERROR | ", exc)
        //         }
        //     })
        //     .catch(exc => {
        //         // alert(`3# FETCH waluty.csv ERROR | ${exc}`)                 //__ TO_DELETE
        //     })


        // -------------- PODJEŚCIE NOWE
        fetchCsvData("waluty.csv")
            .then(data => {
                const tabToRise = [];
                const tab = convertToCurrencyPairs(data);
                this.setDownloadCount(tab?.length ? tab.length : 0);
                this.commonData["/currency"] = tab;
                tab.forEach((el: any, index: number) => {

                    fetchCsvData(el.para + ".csv")
                        .then(data2 => {
                            const tab2 = convertToGraphPoint(data2);
                            this.commonData["/graph/" + el.para] = tab2;
                            this.updateCount();
                        })
                        .catch(exc => {
                            console.error("ERROR PART | ", exc)
                        })
                })
            })
            .catch(exc => {
                console.error("ERROR ALL | ", exc)
            })
    }

    getCommonData() {
        this.commonData$.next(this.commonData);
    }


    // ------------------- NOWE PODEJŚCIE
    setDownloadCount(max: number = 0) {
        this.downloadCountMax = max;
        console.warn("** setDownloadCount = ", this.downloadCountMax)
    }

    updateCount() {
        this.downloadCount++;
        console.warn("** updateCount = ", this.downloadCount)
        if (this.downloadCount === this.downloadCountMax) {
            console.warn("** updateCount FINISH ")
            this.finishDownload();
        }
    }

    finishDownload() {
        this.getCommonData();

        const indicativeRates: IndicativeRate[] = [];
        const commonCurrencies = this.commonData["/currency"] ?? [];
        commonCurrencies.forEach((element: { para: string; wybrane: Boolean; kup: number; odchylenie: number; sprzedaj: number; }) => {
            indicativeRates.push({
                pair: {
                    code: element.para,
                    // isSelected: element.wybrane,
                    // accountMissing: boolean;
                    // order: number;
                    // nonDefaultSortingOrder: boolean;
                } as IndicativePair,
                buy: element.kup + (Math.random() * 2 * element.odchylenie - element.odchylenie),
                sell: element.sprzedaj + (Math.random() * 2 * element.odchylenie - element.odchylenie),
            })
        })
        oldCommonData["/indicatives/getrates"] = indicativeRates;

        this.isReady$.next(true);
        console.warn("** updateCount FINISH     isReady TRUE")
    }
    // --------------------
}

export async function fetchCsvData(fileName: string) {
    const res = await fetch(
        "./assets/waluty/" + fileName,
        {
            method: 'get',
            headers: {
                'content-type': 'text/csv;charset=UTF-8',
            }
        }
    );
    const data = await res.text();
    // console.error(`CSV ${fileName} DATA  = "`, data + '"');
    return formatCsvToArray(data);
}

export function formatCsvToArray(csvString: string): any[] {
    const stringArray = csvString.split("\r\n");
    const stringArrayLength = stringArray.length;
    if (stringArrayLength > 2) {
        const dataToReturn: string[][] = [];
        for (let i = 1; i < stringArrayLength - 1; i++) {
            if (stringArray[i] !== "") {
                // dataToReturn.push(stringArray[i].split(";"));
                stringArray[i] = stringArray[i].replace(/,/g, ".");
                dataToReturn.push(stringArray[i].split(/[;|\t]/));
            }
        }
        return dataToReturn;
    }
    return [];
}

export function convertToCurrencyPairs(tab: string[][]): { para: string, wybrane: boolean, kup: number, sprzedaj: number, odchylenie: number }[] {
    const arrayToReturn: { para: string, wybrane: boolean, kup: number, sprzedaj: number, odchylenie: number }[] = [];
    tab.forEach(el => {
        arrayToReturn.push({
            para: el[0],
            wybrane: (el[1] === "true") ? true : false,
            kup: Number(el[2]),
            sprzedaj: Number(el[3]),
            odchylenie: Number(el[4]),
        })
    })
    return arrayToReturn;
}
export function convertToGraphPoint(tab: string[][]): GraphPoint[] {
    const arrayToReturn: GraphPoint[] = [];
    /*
        dzien       - 5min,
        tydzien     - 1h, 
        1 miesiac   - 8h,
        3 miesiace  - 1d,
        1 rok       - 1d
    */
    tab.forEach(el => {

        arrayToReturn.push({
            // openTime: new Date(el[2]).getTime(),
            // closeTime: new Date(el[3]).getTime(),
            // openBid: Number(el[5]),
            // highBid: Number(el[6]),
            // lowBid: Number(el[7]),
            // closeBid: Number(el[8]),
            // openAsk: Number(el[9]),
            // highAsk: Number(el[10]),
            // closeAsk: Number(el[11]),
            // lowAsk: Number(el[12]),

            // time: new Date(el[2]).getTime(),
            time: new Date(el[3]).getTime(),
            // time: new Date(el[3].split(" ").join("T")).getTime(),
            bid: Number(el[8]),
            ask: Number(el[11])
        })
    })
    arrayToReturn.sort((gp1: GraphPoint, gp2: GraphPoint) => gp1.time - gp2.time);
    return arrayToReturn;
}