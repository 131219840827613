<label class="wv-input-currency-pairs-edit__label wv-input-currency-pairs-edit__label--{{ icon }}" [for]="item.key">
  <span class="wv-input-currency-pairs-edit__label-left-container">
    <img src="assets/wv-img/wv-flags/{{ base }}.svg"
         class="wv-input-currency-pairs-edit__label-img"
         [alt]="base"
         style="vertical-align: unset" />
    <span class="wv-input-currency-pairs-edit__label-curr">{{ base }}</span>
  </span>
  <span class="wv-input-currency-pairs-edit__label-divider">/</span>
  <span class="wv-input-currency-pairs-edit__label-right-container">
    <img src="assets/wv-img/wv-flags/{{ quote }}.svg"
         class="wv-input-currency-pairs-edit__label-img"
         [alt]="quote"
         style="vertical-align: unset" />
    <span class="wv-input-currency-pairs-edit__label-curr">{{ quote }}</span>
  </span>
</label>
