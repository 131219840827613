<div
  class="
    wv-global-modal
    wv-select-account-modal
    wv-global-modal
    wv-global-modal--hidden-swipe-bottom
    wv-global-modal--displayed
    wv-global-modal--visible-swipe-bottom
  "
  *ngIf="show"
  [@slideInOut]
>
  <div class="wv-container wv-container--grey" *ngIf="accounts?.length">
    <div class="wv-select-account-modal__separator no-top-margin">
      <span>{{ title | translate: { currency: currency } }}</span>
    </div>
    <div class="wv-select-account-modal-element" *ngFor="let account of accounts">
      <button
        (click)="selectAccount(account)"
        [ngClass]="{
          'wv-select-account__button': true,
          'wv-select-account-modal-element__button--active': account.isSelected,
          'wv-select-account-modal-element__button--disabled': account.isInactive
        }"
      >
        <app-account-button-content [account]="account" [hideNumber]="true"></app-account-button-content>
      </button>
    </div>
  </div>
</div>
