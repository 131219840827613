import { Component, Input } from "@angular/core";
import { hasValue } from "@utils/number.utils";
import { Account } from "src/app/common/accounts/account.model";

@Component({
  selector: "app-summary-item-account",
  templateUrl: "summary-item-account.component.html"
})
export class SummaryItemAccountComponent {
  @Input() label: string;
  @Input() account: Account;

  constructor() {}

  get showBalance(): boolean {
    return hasValue(this.account.balance);
  }
}
