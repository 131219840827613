import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { sleep, slideBottomAnimation } from "../../../common/animations/animations";
import { WebViewService } from "../../../common/mobile/webview.service";
import { RateAlert } from "../alert.model";
import { AlertService } from "../alert.service";

@Component({
  selector: "app-alert-success",
  templateUrl: "./alert-success.component.html",
  animations: [slideBottomAnimation]
})
export class AlertSuccessComponent implements OnInit {
  @Input() public alert: RateAlert;
  @Output() public newAlert = new EventEmitter<any>();

  public show: boolean = true;

  constructor(private alertService: AlertService, private webViewService: WebViewService) {}

  ngOnInit(): void {
    this.webViewService.updateScreen("alertModal", () => this.back());
  }

  openNewAlert(): void {
    this.show = false;
    sleep().then(() => {
      this.newAlert.emit();
    });
  }

  back() {
    this.show = false;
    sleep().then(() => {
      this.alertService.returnToInitialPage();
    });
  }
}
